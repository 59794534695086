import type { TFilters } from '@/features/activity';

export function getOrderSubhead(
  filters?: Partial<TFilters>,
  searchQuery?: string
) {
  const filterText =
    !!filters && Object.values(filters).some((filter) => !!filter)
      ? 'filtered'
      : 'latest';
  const searchQueryText = searchQuery ? `matching "${searchQuery}"` : '';
  return `Displaying ${filterText} transactions ${searchQueryText}`;
}
