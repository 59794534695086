import { format, parse } from 'date-fns';

import { DateFormats } from '@/config/language';

import { formatGiftCardBalance } from '../../utils/format-gift-card-balance';
import { timeUntilExpiry } from '../../utils/time-until-expiry';

import type { GiftCardTypes } from '../../types/gift-card-types';

export const giftCardDetails = {
  addGiftCardDetails(card: GiftCardTypes.Components.TGiftCard) {
    return {
      ...card,
      currentBalance: giftCardDetails.currentBalance(card),
      expires: giftCardDetails.getExpiry(card),
      last4Digits: giftCardDetails.last4Digits(card),
    };
  },

  currentBalance(card: GiftCardTypes.Components.TGiftCard) {
    return !!card?.live_data
      ? formatGiftCardBalance(card.live_data.current_balance)
      : '';
  },

  getExpiry(card: GiftCardTypes.Components.TGiftCard) {
    const { day, month, time, year } = card?.data?.expiry || {};

    const expiryDate =
      !!day && !!month && !!year && !!time
        ? parse(
            `${year}-${month}-${day}-${time}`,
            DateFormats.DAY_MONTH_YEAR_TIME_FORMAT,
            new Date()
          ).toISOString()
        : card?.live_data?.expiry_datetime;

    return !!expiryDate
      ? {
          formattedDate: format(
            expiryDate,
            DateFormats.DAY_MONTH_YEAR_LONG_ALT
          ),
          isoDate: expiryDate,
          timeLeft: timeUntilExpiry(expiryDate),
        }
      : {};
  },

  last4Digits(card: GiftCardTypes.Components.TGiftCard) {
    return !!card?.data?.visualization?.masked_card_number
      ? card.data.visualization.masked_card_number.slice(-4)
      : '';
  },
};
