import { clsx } from 'clsx';

import { ColesGroupLogoIcon } from '@/components';

import styles from './mycoles-card.module.css';

export enum LogoCardTypes {
  MYCOLES = 'mycoles',
}

export interface ILogoCardProps {
  size?: 'lg' | 'md' | 'sm' | 'xs';
  type?: `${LogoCardTypes}`;
}

const images = {
  [LogoCardTypes.MYCOLES]: ColesGroupLogoIcon,
};

export function LogoCard({ size = 'lg', type }: ILogoCardProps) {
  if (!type || !images[type]) {
    return null;
  }

  const Icon = images[type];

  return (
    <div
      className={clsx(
        styles.card,
        styles['logo-card'],
        styles[`card-${size}`],
        styles[type]
      )}
    >
      <div className={styles.logo}>
        <Icon />
      </div>
    </div>
  );
}
