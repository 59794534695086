import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { GiftCardsDialogSuccessStates, useGiftCardsStore } from '@/store';

import { giftCardsQueryKeys } from '../gift-cards-query-keys';
import { useGetGiftCards } from '../use-get-gift-cards/use-get-gift-cards';

import type { GiftCardTypes } from '../../types/gift-card-types';

export function useAddGiftCardSuccess() {
  const queryClient = useQueryClient();
  const getGiftCards = useGetGiftCards();

  const {
    setCurrentGiftCard,
    setGiftCardsDialogSuccessState,
    setIsAddGiftCardOpen,
    setIsAddGiftCardPending,
  } = useGiftCardsStore().use.actions();

  const giftCardsDialogSuccessState =
    useGiftCardsStore().use.giftCardsDialogSuccessState();

  useEffect(() => {
    // Only hide the add gift card side sheet once we finished getting the updated max gift card slots
    if (
      !getGiftCards.isFetching &&
      giftCardsDialogSuccessState === GiftCardsDialogSuccessStates.GIFT_CARD_ADD
    ) {
      setIsAddGiftCardOpen(false);
      setIsAddGiftCardPending(false);
    }
  }, [
    getGiftCards.isFetching,
    giftCardsDialogSuccessState,
    setIsAddGiftCardOpen,
    setIsAddGiftCardPending,
  ]);

  return function useAddGiftCardSuccessCallback(
    res: GiftCardTypes.Operations.AddGiftCard.TResponse
  ) {
    queryClient.invalidateQueries({
      queryKey: giftCardsQueryKeys.allCardsGiftcards,
    });

    setCurrentGiftCard(res);
    setGiftCardsDialogSuccessState(GiftCardsDialogSuccessStates.GIFT_CARD_ADD);
  };
}
