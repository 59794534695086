import { FormHint, SelectField, TextField } from '@/components';
import { MAX_CHARS_ADDRESS_NICKNAME } from '@/features/profile';

import { AddressAutocomplete } from '../address-autocomplete';

import {
  AddressInputFieldNames,
  AddressInputLabels,
  AddressInputPlaceholders,
} from './enums';
import { Styled } from './styles';

interface IAddressInput {
  isSubmitting?: boolean;
}

const statesOptions = [
  { label: 'ACT', value: 'ACT' },
  { label: 'NSW', value: 'NSW' },
  { label: 'NT', value: 'NT' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' },
  { label: 'QLD', value: 'QLD' },
];

export const AddressInput = ({ isSubmitting }: IAddressInput) => {
  return (
    <Styled.Fieldset>
      <FormHint />
      <TextField
        disabled={!!isSubmitting}
        label={AddressInputLabels.ADDRESS_NICKNAME}
        maxLength={MAX_CHARS_ADDRESS_NICKNAME}
        name={AddressInputFieldNames.ADDRESS_NICKNAME}
      />

      <TextField
        autocomplete="name"
        disabled={!!isSubmitting}
        label={AddressInputLabels.ADDRESS_NAME}
        maxLength={60}
        name={AddressInputFieldNames.ADDRESS_NAME}
        required={true}
      />

      <AddressAutocomplete />

      <TextField
        autocomplete="address-line2"
        disabled={!!isSubmitting}
        label={AddressInputLabels.ADDRESS_LINE_2}
        maxLength={60}
        name={AddressInputFieldNames.ADDRESS_LINE_2}
        placeholder={AddressInputPlaceholders.ADDRESS_LINE_2}
      />

      <TextField
        autocomplete="address-level2"
        disabled={!!isSubmitting}
        label={AddressInputLabels.ADDRESS_CITY}
        maxLength={60}
        name={AddressInputFieldNames.ADDRESS_CITY}
        required={true}
      />

      <Styled.GridParentWrapper>
        <Styled.GridParent>
          <SelectField
            disabled={!!isSubmitting}
            id="state"
            label={AddressInputLabels.ADDRESS_STATE}
            name={AddressInputFieldNames.ADDRESS_STATE}
            options={statesOptions}
            required
          />
          <TextField
            autocomplete="postal-code"
            disabled={!!isSubmitting}
            inputMode="numeric"
            label={AddressInputLabels.ADDRESS_POSTCODE}
            maxLength={4}
            name={AddressInputFieldNames.ADDRESS_POSTCODE}
            required={true}
          />
        </Styled.GridParent>
      </Styled.GridParentWrapper>
    </Styled.Fieldset>
  );
};
