import { useActivityStore } from '@/store';

import { OrderDetailsHeader } from '../order-details-header/order-details-header';
import { OrderDetailsMerchantLocation } from '../order-details-merchant-location/order-details-merchant-location';
import { OrderPaymentSummary } from '../order-payment-summary/order-payment-summary';

import styles from './order-details.module.css';

export function OrderDetails() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (!selectedOrder) {
    return null;
  }

  return (
    <div className={styles['details-wrapper']}>
      <OrderDetailsHeader />
      <OrderDetailsMerchantLocation />
      <OrderPaymentSummary />
    </div>
  );
}
