import { CustomerService } from '@/components';
import { ExclamationMarkTriangleIcon } from '@/components/icons';

import { Styled } from './styles';

export const PanelError = ({
  description,
  title,
}: {
  description: string;
  title: string;
}) => {
  return (
    <Styled.PanelCardContentContainer>
      <Styled.ErrorIcon>
        <ExclamationMarkTriangleIcon
          color="var(--colors-error-high)"
          size={54}
        />
      </Styled.ErrorIcon>
      <Styled.PanelCardContent>
        <Styled.PanelCardTitle>{title} not loading</Styled.PanelCardTitle>
        <Styled.PanelCardDescription>
          We can{'\u2019'}t seem to load your {description} just now. Try
          refreshing the page or <CustomerService />.
        </Styled.PanelCardDescription>
      </Styled.PanelCardContent>
    </Styled.PanelCardContentContainer>
  );
};
