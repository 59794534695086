import clsx from 'clsx';

import { useActivityStore } from '@/store';

import { OrderPayment } from '../order-payment/order-payment';

import styles from './order-payment-summary-payments.module.css';

export function OrderPaymentSummaryPayments() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (!selectedOrder) {
    return null;
  }

  return (
    <ul className={clsx(styles['summary-group'])}>
      {selectedOrder.payments.map((payment) => (
        <OrderPayment key={payment.id} payment={payment} />
      ))}
    </ul>
  );
}
