import { Button, Dialog, TryAgainMessage } from '@/components';
import { ErrorIcon } from '@/components/icons';
import { BUTTON_TEXT_TRY_AGAIN } from '@/config/language/errors';
import { useSetA11yActions } from '@/store';

import styles from './action-button.module.css';
import { Styled } from './styles';

interface TErrorDialog {
  buttonText?: string;
  icon?: React.ReactNode;
  isOpen: boolean;
  message?: React.ReactNode;
  onOpenChange: () => void;
  title: string;
  tryAgain?: boolean;
  tryWhat?: string;
}

export const ErrorDialogBody = ({
  buttonText,
  icon,
  message,
  onOpenChange,
  title,
  tryAgain = true,
  tryWhat,
}: Partial<TErrorDialog>) => {
  return (
    <Styled.DialogWrapper>
      {icon ?? <ErrorIcon />}

      <Styled.DialogHeader>
        <Styled.DialogTitle>{title}</Styled.DialogTitle>
        <Styled.DialogDescription>
          {message} {tryAgain && <TryAgainMessage what={tryWhat} />}
        </Styled.DialogDescription>
      </Styled.DialogHeader>

      <Button
        className={styles['action-button']}
        onClick={onOpenChange}
        size="medium"
      >
        {buttonText || BUTTON_TEXT_TRY_AGAIN}
      </Button>
    </Styled.DialogWrapper>
  );
};

export const ErrorDialog = ({
  buttonText,
  icon,
  isOpen,
  message,
  onOpenChange,
  title,
  tryAgain,
  tryWhat,
}: TErrorDialog) => {
  const { setA11yAnnouncement } = useSetA11yActions();

  const handleOpenChange = () => {
    if (isOpen) {
      // If already open, we will be closing this dialog
      setA11yAnnouncement('Dialog closed');
    }
    onOpenChange?.();
  };
  return (
    <Dialog
      body={
        <ErrorDialogBody
          buttonText={buttonText}
          icon={icon}
          isOpen={isOpen}
          message={message}
          onOpenChange={handleOpenChange}
          title={title}
          tryAgain={tryAgain}
          tryWhat={tryWhat}
        />
      }
      description={title}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
    />
  );
};
