import { bankCardsSlots } from './bank-cards-slots';
import { sortBankCards } from './sort-bank-cards';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function transformBankCards(
  response: BankCardsTypes.Operations.GetBankCards.TResponse
) {
  response.cards = sortBankCards(response.cards);
  return bankCardsSlots.addBankCardsSlotAttributes(response);
}
