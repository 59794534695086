import { useActivityStore } from '@/store';

import type { FilterFields } from '../../enums/filter-fields';
import type { TFilters } from '../filters/filters';
import type { FieldValues } from 'react-hook-form';

export function useFilterFormSubmit() {
  const setIsViewFilterCalendar =
    useActivityStore().use.actions().setIsViewFilterCalendar;
  const setActiveFilters = useActivityStore().use.actions().setActiveFilters;
  const setIsFiltersSideSheetOpen =
    useActivityStore().use.actions().setIsFiltersSideSheetOpen;

  return ({ dateFrom, dateTo, maxAmount, minAmount, type }: FieldValues) => {
    const filteredFields = cleanFilters({
      dateFrom,
      dateTo,
      maxAmount,
      minAmount,
      type,
    });
    setActiveFilters(filteredFields);
    setIsViewFilterCalendar(false);
    setIsFiltersSideSheetOpen(false);
  };
}

function cleanFilters(filters?: TFilters) {
  return !!filters
    ? Object.entries(filters).reduce((acc, [key, value]) => {
        acc[key as FilterFields] = value === '' ? undefined : value;
        return acc;
      }, {} as TFilters)
    : undefined;
}
