import { Button } from '@/components';
import { useActivityStore } from '@/store';

import styles from './filter-form-footer.module.css';

export function FilterFormFooter() {
  const {
    setActiveFilters,
    setIsFiltersSideSheetOpen,
    setIsViewFilterCalendar,
  } = useActivityStore().use.actions();

  const handleClear = () => {
    setActiveFilters(undefined);
    setIsFiltersSideSheetOpen(false);
    setIsViewFilterCalendar(false);
  };

  return (
    <div className={styles['footer']}>
      <Button level="primary" size="medium" type="submit" variant="neutral">
        Apply
      </Button>
      <Button
        level="secondary"
        onClick={handleClear}
        size="medium"
        type="button"
        variant="neutral"
      >
        Clear Filters
      </Button>
    </div>
  );
}
