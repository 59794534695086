import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '@/features/bank-cards';

import { useUpdateCardNicknameError } from './use-update-card-nickname-error';
import { useUpdateCardNicknameRequest } from './use-update-card-nickname-request';
import { useUpdateCardNicknameSuccess } from './use-update-card-nickname-success';

import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useUpdateCardNickname(
  cardId: string,
  options?: UseMutationOptions<
    BankCardsTypes.Operations.UpdateBankCardNickname.TResponse,
    unknown,
    BankCardsTypes.Operations.UpdateBankCardNickname.TRequest,
    unknown
  >
) {
  return useMutation({
    mutationFn: useUpdateCardNicknameRequest(cardId),
    mutationKey: paymentMethodQueryKeys.cardsBankUpdateNickname,
    onError: useUpdateCardNicknameError(),
    onSuccess: useUpdateCardNicknameSuccess(),
    ...options,
  });
}
