import { useActivityStore } from '@/store';

import styles from './order-details-meta.module.css';

export function OrderDetailsMeta() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (!selectedOrder) {
    return null;
  }

  const { created_at, orderId, orderReference } = selectedOrder;

  const orderDate = !!created_at.dayMonthTime ? created_at.dayMonthTime : '-';

  const orderReferenceOrId = orderReference ?? orderId;

  return (
    <ul className={styles['order-meta']}>
      <li>
        <p className={styles['text']}>{`Order date: ${orderDate}`}</p>
      </li>
      <li>
        <p className={styles['text']}>{`Order ID: ${orderReferenceOrId}`}</p>
      </li>
    </ul>
  );
}
