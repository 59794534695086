import { Button, ButtonSpinner, Dialog } from '@/components';
import { BREAKPOINT_BOTTOM_SHEET_TO_DIALOG } from '@/config/breakpoints';
import { useWindowSize } from '@/hooks';
import { useSetA11yActions } from '@/store';

import styles from './action-button.module.css';
import { Styled } from './styles';

interface IConfirmationDialog {
  acceptButtonText: string;
  cancelButtonText?: string;
  icon?: React.ReactNode;
  intro?: React.ReactNode;
  isDanger?: boolean;
  isOpen: boolean;
  loading?: boolean;
  onClickAccept: () => void;
  onClickCancel: () => void;
  title: string;
}

const ConfirmDialogBody = ({
  acceptButtonText,
  cancelButtonText,
  icon,
  intro,
  isDanger,
  loading,
  onClickAccept,
  onClickCancel,
  title,
}: Partial<IConfirmationDialog>) => {
  const windowSize = useWindowSize();

  const isMobile =
    (windowSize?.width || 0) <= BREAKPOINT_BOTTOM_SHEET_TO_DIALOG;

  return (
    <Styled.DialogWrapper>
      {icon && <Styled.DialogIcon>{icon}</Styled.DialogIcon>}
      <Styled.DialogHeader>
        <Styled.DialogTitle>{title}</Styled.DialogTitle>
        <Styled.DialogDescription>{intro}</Styled.DialogDescription>
      </Styled.DialogHeader>

      <Styled.DialogActions $isMobile={isMobile}>
        <Button
          className={styles['action-button']}
          level="secondary"
          onClick={onClickCancel}
          size={isMobile ? 'medium' : 'large'}
          variant="neutral"
        >
          {cancelButtonText || 'Cancel'}
        </Button>
        <ButtonSpinner
          level="primary"
          loading={loading}
          onClick={onClickAccept}
          size={isMobile ? 'medium' : 'large'}
          spinnerColor={'var(--colors-error-lowest)'}
          {...(isDanger && { variant: 'destructive' })}
        >
          {acceptButtonText}
        </ButtonSpinner>
      </Styled.DialogActions>
    </Styled.DialogWrapper>
  );
};

export const ConfirmationDialog = ({
  acceptButtonText,
  cancelButtonText,
  icon,
  intro,
  isDanger,
  isOpen,
  loading,
  onClickAccept,
  onClickCancel,
  title,
}: IConfirmationDialog) => {
  const { setA11yAnnouncement } = useSetA11yActions();

  const handleClickCancel = () => {
    if (isOpen) {
      // If already open, we will be closing this dialog
      setA11yAnnouncement('Dialog closed');
    }
    onClickCancel?.();
  };

  return (
    <Dialog
      body={
        <ConfirmDialogBody
          acceptButtonText={acceptButtonText}
          cancelButtonText={cancelButtonText}
          icon={icon}
          intro={intro}
          isDanger={isDanger}
          loading={loading}
          onClickAccept={onClickAccept}
          onClickCancel={handleClickCancel}
          title={title}
        />
      }
      description={title}
      isOpen={isOpen}
      onOpenChange={handleClickCancel}
    />
  );
};
