import { BankCardsDialogSuccessStates, useBankCardsStore } from '@/store';

import { useBankCardShowSuccessDialog } from '../../hooks/use-bank-card-show-success-dialog';

export function useUpdateCardNicknameSuccess() {
  const showBankCardSuccessDialog = useBankCardShowSuccessDialog();

  const { setIsViewBankCardDetailsOpen } = useBankCardsStore().use.actions();
  const { setIs3dsIframeOpen, setIsEditingCard, setIsEditingCardNickname } =
    useBankCardsStore().use.actions();

  return function updateCardNicknameSuccessCallback() {
    setIs3dsIframeOpen(false);
    setIsViewBankCardDetailsOpen(false);
    setIsEditingCardNickname(false);
    setIsEditingCard(false);
    showBankCardSuccessDialog(
      BankCardsDialogSuccessStates.BANK_CARD_UPDATE_NICKNAME
    );
  };
}
