import { Title } from '@/components';
import { getOrderSubhead } from '@/features/activity/utils/get-order-subhead';
import { useBreakpoints } from '@/hooks';
import { useActivityStore } from '@/store';

import { ActivityHeaderSearch } from '../activity-header-search/activity-header-search';

import styles from './activity-header.module.css';

export function ActivityHeader() {
  const activeFilters = useActivityStore().use.activeFilters();
  const searchQuery = useActivityStore().use.searchQuery();

  const breakpoints = useBreakpoints();

  const orderSubhead = getOrderSubhead(activeFilters, searchQuery?.query);

  return (
    <div className={styles['header-container']}>
      <div className={styles['header-details-wrapping']}>
        <div className={styles['header-row']}>
          <Title spacing={false} title="Activity" />
          {!breakpoints.lg && (
            <h2 className={styles['sub-heading']} role="alert">
              {orderSubhead}
            </h2>
          )}
          <div className={styles['search-wrapper']}>
            <ActivityHeaderSearch />
          </div>
        </div>
        {breakpoints.lg && (
          <h2 className={styles['sub-heading']} role="alert">
            {orderSubhead}
          </h2>
        )}
      </div>
    </div>
  );
}
