import { GiftCardsDialogErrorStates, useGiftCardsStore } from '@/store';

export function useDeleteGiftCardError() {
  const {
    setGiftCardsDialogErrorState,
    setIsDeleteGiftCardPending,
    setIsOpenDeleteDialog,
  } = useGiftCardsStore().use.actions();

  return function useAddGiftCardErrorCallback() {
    setIsDeleteGiftCardPending(false);
    setIsOpenDeleteDialog(false);
    setGiftCardsDialogErrorState(
      GiftCardsDialogErrorStates.GIFT_CARD_DELETE_ERROR
    );
  };
}
