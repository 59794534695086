import type { TActivityStoreSet } from './activity-store-types';
import type { TFilters } from '@/features/activity';
import type { OrderTypes } from '@/features/activity/types';
import type { TSearchQuery } from '@/pages/activity/types';

export function activityStoreActions(set: TActivityStoreSet) {
  return {
    setActiveFilters: (activeFilters?: TFilters) => set({ activeFilters }),
    setIsFiltersSideSheetOpen: (isFiltersSideSheetOpen: boolean) =>
      set({ isFiltersSideSheetOpen }),
    setIsViewFilterCalendar: (isViewFilterCalendar: boolean) =>
      set({ isViewFilterCalendar }),
    setIsViewOrderDetailsOpen: (isViewOrderDetailsOpen: boolean) =>
      set({ isViewOrderDetailsOpen }),
    setSearchQuery: (searchQuery: TSearchQuery) => set({ searchQuery }),
    setSelectedOrder: (selectedOrder?: OrderTypes.TOrderInfo) =>
      set({ selectedOrder }),
  };
}
