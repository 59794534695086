import { Button } from '@/components';
import { TrashIcon } from '@/components/icons';
import { useAnalytics } from '@/hooks';
import { useGiftCardsStore } from '@/store';

import styles from './gift-card-details-footer.module.css';

export function GiftCardDetailsFooterDeleteCardButton() {
  const { track } = useAnalytics();
  const { setIsOpenDeleteDialog } = useGiftCardsStore().use.actions();

  const handleClickRemove = () => {
    track('Delete Gift Card Started');
    setIsOpenDeleteDialog(true);
  };

  return (
    <Button
      className={styles['action-button']}
      level="tertiary"
      onClick={handleClickRemove}
      size="medium"
      variant="destructive"
    >
      <TrashIcon />
      Remove gift card
    </Button>
  );
}
