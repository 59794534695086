import styled, { keyframes } from 'styled-components';

const closeAnimation = keyframes`
  from {
    transform: translateX(0);
    visibility: visible;

  }
  to {
    transform: translateX(100%);
    visibility: hidden;
  }
`;

const closeBackgroundAnimation = keyframes`
  from {
   opacity: 0.3;
  }
  to {
    opacity: 0;
  }
`;

const closeWrapperAnimation = keyframes`
  from {
   visibility: visible;
  }
  to {
    visibility: hidden;
  }
`;

const openAnimation = keyframes`
  from {
    transform: translateX(100%);
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
`;

const openBackgroundAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
`;

const openWrapperAnimation = keyframes`
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
`;

const Button = styled.button`
  align-items: center;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 32px;

  &:focus,
  &:hover {
    border: 1px solid var(--colors-on-surface-b);
    border-radius: 10px;
  }
`;

const SideSheetBackground = styled.div<{
  $canPlay?: boolean;
  $isOpen?: boolean;
}>`
  animation: ${({ $canPlay, $isOpen }) =>
      $canPlay
        ? // eslint-disable-next-line unicorn/no-nested-ternary
          $isOpen
          ? openBackgroundAnimation
          : closeBackgroundAnimation
        : ''}
    0.3s ease-in-out;
  animation-fill-mode: forwards;
  background-color: var(--colors-surface-inverse);
  inset: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const SideSheetBody = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 353px;
  padding-top: var(--spaces-2xl);

  & form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`;

const SideSheetContent = styled.div<{ $canPlay?: boolean; $isOpen?: boolean }>`
  animation: ${({ $canPlay, $isOpen }) =>
      $canPlay ? ($isOpen ? openAnimation : closeAnimation) : ''}
    0.3s ease-in-out;
  animation-fill-mode: forwards;
  background-color: var(--colors-surface-lowest);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  max-width: 480px;
  overflow-y: auto;
  padding: var(--spaces-xl);

  position: relative;
  scrollbar-gutter: stable;
  transform: translateX(100%);
  visibility: hidden;
  width: 100vw;

  @media (width >= 30rem) {
    padding: 3.5rem;
    width: 100%;
  }
`;

const SideSheetHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  max-width: 353px;
  position: relative;
`;

const SideSheetHeaderTitleGroup = styled.div`
  align-items: center;
  border-bottom: 1px solid #807f80;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: var(--spaces-m);
`;

const SideSheetHeaderTitle = styled.h2`
  color: var(--colors-on-surface-b);
  font-size: 18px;
  font-weight: var(--font-weights-bold);
  line-height: 140%;
  margin: 0;

  @media (width >= 30rem) {
    font-size: var(--font-sizes-24);
  }
`;

const SideSheetHeaderSubtitle = styled.div`
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-normal);
  line-height: var(--line-heights-half);
`;

const SideSheetLoading = styled.div`
  align-items: center;
  background: rgb(255 255 255 / 50%);
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
`;

const SideSheetOuter = styled.div`
  flex-shrink: 1;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div<{ $canPlay?: boolean; $isOpen?: boolean }>`
  align-items: flex-end;
  animation: ${({ $canPlay, $isOpen }) =>
      $canPlay ? ($isOpen ? openWrapperAnimation : closeWrapperAnimation) : ''}
    0.3s ease-in-out;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: row;
  inset: 0;
  justify-content: flex-end;
  position: fixed;
  visibility: hidden;
  z-index: 50;
`;

export const Styled = {
  Button,
  SideSheetBackground,
  SideSheetBody,
  SideSheetContent,
  SideSheetHeader,
  SideSheetHeaderSubtitle,
  SideSheetHeaderTitle,
  SideSheetHeaderTitleGroup,
  SideSheetLoading,
  SideSheetOuter,
  Wrapper,
};
