/* eslint-disable @typescript-eslint/no-explicit-any */

import { validateCardExpiryDate } from '../../../../utils/validation/card-expiry-date';
import { BankCardsEnums } from '../../enums/bank-cards-enums';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function sortBankCards(cards: BankCardsTypes.Components.TBankCards) {
  const paymentCards = chunkCards(cards);
  const sortedVerifiedCards = sortVerifiedCards(paymentCards.verified);

  return [
    ...paymentCards.default,
    ...sortedVerifiedCards,
    ...paymentCards.expired,
    ...paymentCards.validated,
    ...paymentCards.invalid,
  ];
}

function chunkCards(cards: BankCardsTypes.Components.TBankCards) {
  const notDefault = cards.filter((card) => !card.is_default_pay);
  return {
    default: cards.filter((card) => card.is_default_pay),
    expired: notDefault.filter(
      (card) => card.status === BankCardsEnums.BankCardStatus.EXPIRED
    ),
    invalid: notDefault.filter(
      (card) => card.status == BankCardsEnums.BankCardStatus.INVALID
    ),
    validated: notDefault.filter(
      (card) => card.status === BankCardsEnums.BankCardStatus.VALIDATED
    ),
    verified: notDefault.filter(
      (card) => card.status === BankCardsEnums.BankCardStatus.VERIFIED
    ),
  };
}

function sortVerifiedCards(cards: BankCardsTypes.Components.TBankCards) {
  const expirySorted = sortExpiredLast(cards);
  return [...expirySorted].sort((a, b) => {
    return (a.is_default_pay as any) - (b.is_default_pay as any);
  });
}

function sortExpiredLast(cards: BankCardsTypes.Components.TBankCards) {
  return [...cards].sort((a, b) => {
    const aExpiry = `${a.data.expiry.month}/${a.data.expiry.year}`;
    const bExpiry = `${b.data.expiry.month}/${b.data.expiry.year}`;
    return (
      (!validateCardExpiryDate(aExpiry) as any) -
      (!validateCardExpiryDate(bExpiry) as any)
    );
  });
}
