import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { TopBarLoggedOut } from '@/components';
import { CheckIcon, ExclamationMarkIcon } from '@/components/icons';
import { genericContent } from '@/config/language';
import { landingUrl } from '@/config/links';

import { Styled } from './styles';

type TLogOutProps = {
  description?: string;
  title: string;
  warning?: boolean;
};
const ICON_SIZE = 80;

export const LoggedOut = ({ description, title, warning }: TLogOutProps) => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      // If a user lands here directly tough luck, we're gonna log you out regardless
      auth.removeUser();
    }
  }, [auth]);

  return (
    <Styled.LayoutWrapper>
      <TopBarLoggedOut />
      <Styled.ContentWrapper>
        {warning ? (
          <ExclamationMarkIcon
            color={'var(--colors-brand1-on-high)'}
            height={ICON_SIZE}
            width={ICON_SIZE}
          />
        ) : (
          <CheckIcon
            color={'var(--colors-brand1-on-high)'}
            height={ICON_SIZE}
            width={ICON_SIZE}
          />
        )}
        <Styled.Title>{title}</Styled.Title>
        {!!description && (
          <Styled.Description>{description}</Styled.Description>
        )}
        <Styled.Link href={landingUrl}>
          {genericContent.BACK_TO_APP}
        </Styled.Link>
      </Styled.ContentWrapper>
    </Styled.LayoutWrapper>
  );
};
