import { useState } from 'react';

import { Button, Dropdown } from '@/components';
import { FilterIcon } from '@/components/icons';
import { SearchOptions } from '@/features/activity/enums/search';
import { getTotalActiveFilters } from '@/features/activity/utils/get-total-active-filters';
import { useActivityStore } from '@/store';

import { SearchInput } from './activity-header-search-input';
import styles from './activity-header-search.module.css';

import type { TOption } from '@/components/dropdown';

export function ActivityHeaderSearch() {
  const searchQuery = useActivityStore().use.searchQuery();
  const setSearchQuery = useActivityStore().use.actions().setSearchQuery;
  const activeFilters = useActivityStore().use.activeFilters();
  const filterNumber = getTotalActiveFilters(activeFilters);
  const isFiltersSideSheetOpen =
    useActivityStore().use.isFiltersSideSheetOpen();
  const setIsFiltersSideSheetOpen =
    useActivityStore().use.actions().setIsFiltersSideSheetOpen;

  const [searchValue, setSearchValue] = useState(searchQuery?.query);
  const [categoryValue, setCategoryValue] = useState(searchQuery?.category);

  const onClickClear = () => {
    setSearchValue('');
    setSearchQuery({ category: searchQuery.query, query: '' });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchQuery({ category: categoryValue, query: searchValue });
  };

  const onDropdownChange = (value: TOption | null) => {
    setCategoryValue(value?.value ?? '');
  };

  const handleOpenFilters = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    setIsFiltersSideSheetOpen(!isFiltersSideSheetOpen);
  };

  return (
    <form className={styles['search-container']} onSubmit={onSubmit}>
      <div className={styles['search-item']}>
        <Dropdown
          id={'searchBy'}
          label="Search by"
          onChange={onDropdownChange}
          options={SearchOptions}
          placeholder={'Select...'}
          required={false}
          size={'small'}
        />
      </div>
      <div className={styles['search-item']}>
        <label className={styles['label']} htmlFor="searchFor">
          Search for
        </label>
        <SearchInput
          id="searchFor"
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          onClickClear={onClickClear}
          placeholder={'Search...'}
          showClear={!!searchValue}
          value={searchValue}
        />
      </div>
      <Button
        className={styles['search-button']}
        level="secondary"
        size="small"
        type="submit"
        variant="branded"
      >
        Search
      </Button>
      <Button
        className={styles['filter-button']}
        level="secondary"
        onClick={handleOpenFilters}
        size="small"
        variant="branded"
      >
        <FilterIcon />
        Filters {filterNumber}
      </Button>
    </form>
  );
}
