import flow from 'lodash.flow';

import { giftCardDetails } from './gift-card-details';
import { giftCardStatus } from './gift-card-status';

import type { GiftCardTypes } from '../../types/gift-card-types';

export function transformGiftCard(
  card: GiftCardTypes.Components.TGiftCard
): GiftCardTypes.Components.TGiftCard {
  return flow(
    giftCardDetails.addGiftCardDetails,
    giftCardStatus.addGiftCardStatuses
  )(card);
}
