import { Pill } from '@/components/pill/pill';
import { PillVariants } from '@/components/pill/pill';

import { getGiftCardStatus } from '../../utils/get-gift-card-status';

import type { GiftCardEnums } from '../../enums/gift-card-enums';

type TAllowGiftCardStatuses =
  | GiftCardEnums.GiftCardStatus.EXPIRED
  | GiftCardEnums.GiftCardStatus.INVALID
  | GiftCardEnums.GiftCardStatus.USED;

type TGiftCardCardPillProps = {
  status: TAllowGiftCardStatuses;
};

export function GiftCardStatusPill({ status }: TGiftCardCardPillProps) {
  const pillText = getGiftCardStatus(status);

  return (
    <Pill
      rootCSS={{
        borderRadius: '120px',
        justifyContent: 'center',
        width: 'fit-content',
      }}
      size={'xs'}
      variant={PillVariants.GENERIC}
    >
      {pillText}
    </Pill>
  );
}
