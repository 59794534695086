import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-l);
`;

export const Styled = {
  Footer,
};
