import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '@/features/bank-cards';

import { useDeleteBankCardError } from './use-delete-bank-card-error';
import { useDeleteBankCardRequest } from './use-delete-bank-card-request';
import { useDeleteBankCardSuccess } from './use-delete-bank-card-success';

import type { MutationOptions } from '@tanstack/react-query';

export function useDeleteBankCard(cardId: string, options?: MutationOptions) {
  return useMutation({
    mutationFn: useDeleteBankCardRequest(cardId),
    mutationKey: paymentMethodQueryKeys.cardsBankDelete,
    onError: useDeleteBankCardError(),
    onSuccess: useDeleteBankCardSuccess(),
    ...options,
  });
}
