import { useFormContext } from 'react-hook-form';

import { TextField } from '@/components';
import { useGiftCardsStore } from '@/store';

import { useGetGiftCards } from '../../services/use-get-gift-cards/use-get-gift-cards';
import { formatCardNumber } from '../../utils/format-card-number';

import styles from './add-gift-card-form-body.module.css';
import { FieldName, InputLabel } from './add-gift-card-form-enums';

export function AddGiftCardFormBody() {
  const formContext = useFormContext();

  const isAddGiftCardPending = useGiftCardsStore().use.isAddGiftCardPending();
  const setIsViewEligibleGiftCardsOpen =
    useGiftCardsStore().use.actions().setIsViewEligibleGiftCardsOpen;
  const getGiftCards = useGetGiftCards();

  const handleEligibleClick = () => {
    setIsViewEligibleGiftCardsOpen(true);
  };

  const onChangeCardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formContext.setValue(FieldName.CARD_NUMBER, formatCardNumber(value));
  };

  const onChangePin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formContext.setValue(FieldName.PIN, formatCardNumber(value));
  };

  return (
    <div className={styles['add-gift-card-form-container']}>
      <div>
        <p className={styles['paragraph']}>
          Check out in part or in full with a gift card.{' '}
          {!!getGiftCards.data?.giftCardSlotsMaxLimit && (
            <>Add up to {getGiftCards.data.giftCardSlotsMaxLimit} gift cards.</>
          )}
        </p>
        <p className={styles['paragraph']}>
          <button
            className={styles['eligible-link']}
            onClick={handleEligibleClick}
            type="button"
          >
            View eligible gift cards.
          </button>
        </p>
        <p className={styles['paragraph']}>
          (Fields marked with * are required)
        </p>
      </div>

      <div className={styles['add-gift-card-form-body']}>
        <TextField
          disabled={isAddGiftCardPending}
          label={InputLabel[FieldName.CARD_NUMBER]}
          name={FieldName.CARD_NUMBER}
          onChange={onChangeCardNumber}
          required
        />
        <TextField
          disabled={isAddGiftCardPending}
          label={InputLabel[FieldName.PIN]}
          name={FieldName.PIN}
          onChange={onChangePin}
          required
        />
      </div>
      <p className={styles['disclaimer']}>
        You are adding a copy of the gift card, we recommend keeping your
        physical gift card handy where flypay is not accepted.
      </p>
    </div>
  );
}
