import { Routes } from '@/routes/constants';
import { getDefaultTheme } from '@/theme';

import { MobileDetails } from './mobile-details';
import { Styled } from './styles';
import { WidescreenDetails } from './widesceen-details';

export interface IUserDetails {
  avatar?: string;
  details?: string;
  name?: string;
}
export type TTopNav = {
  isMobileOpen?: boolean;
  onLogoClick?: () => void;
  onLogout?: () => void;
  onMobileToggleClick?: () => void;
  userDetails?: IUserDetails;
};

export const TopBar = ({
  isMobileOpen = false,
  onLogout,
  onMobileToggleClick,
  userDetails,
}: TTopNav) => {
  const theme = getDefaultTheme();

  const logo = theme.icon.inverse;
  const altImg = `${theme.brand.title} wallet home`;
  return (
    <Styled.TopNavWrapper>
      <Styled.TopNavLogoLink to={Routes.HOME}>
        <Styled.TopNavLogo alt={altImg} src={logo} />
      </Styled.TopNavLogoLink>

      <WidescreenDetails {...userDetails} onLogout={onLogout} />
      <MobileDetails
        {...userDetails}
        isOpen={isMobileOpen}
        onMobileToggleClick={onMobileToggleClick}
      />
    </Styled.TopNavWrapper>
  );
};
