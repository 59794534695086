import compact from 'lodash.compact';
import { useState } from 'react';

import {
  Button,
  ButtonSpinner,
  ConfirmationDialog,
  ErrorDialog,
} from '@/components';
import { EditIcon, ExclamationMarkIcon, TrashIcon } from '@/components/icons';
import {
  ERROR_DELETE_ADDRESS_GENERIC,
  ERROR_SET_DEFAULT_ADDRESS_ALREADY_DEFAULT,
  ERROR_SET_DEFAULT_ADDRESS_FAILED,
  ERROR_TITLE_DELETE_ADDRESS,
  ERROR_TITLE_SET_DEFAULT_ADDRESS,
  ERROR_TITLE_SET_DEFAULT_ADDRESS_ALREADY_DEFAULT,
} from '@/config/language/errors';
import {
  useDeleteAddress,
  useUpdateAddress,
} from '@/features/profile/services';
import { useAnalytics } from '@/hooks';

import styles from './address-details.module.css';
import { Styled } from './styles';

import type { AddressesTypes } from '@/features/profile/';

interface IAddressDetailsProps {
  address?: AddressesTypes.AddressResponse | null;
  isDefault?: boolean;
  onClickEdit: () => void;
  reloadAddresses: (isDelete?: boolean) => void;
  selectedAddressId: string;
}

export const AddressDetails = ({
  address,
  isDefault,
  onClickEdit,
  reloadAddresses,
  selectedAddressId,
}: IAddressDetailsProps) => {
  const { track } = useAnalytics();

  const [isDefaultDialogOpen, setIsDefaultDialogOpen] = useState(false);
  const [isDefaultError, setIsDefaultError] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteError, setIsDeleteError] = useState(false);

  const setDefaultAddress = useUpdateAddress({
    onError: () => {
      if (isDefault) {
        setIsDeleteDialogOpen(false);
        setIsDeleteError(true);
      } else {
        setIsDefaultError(true);
      }
    },
    onSuccess: () => {
      if (isDefault) {
        handleRemove();
      } else {
        reloadAddresses();
      }
    },
  });

  const deleteAddress = useDeleteAddress(selectedAddressId || '', {
    onError: () => {
      setIsDeleteDialogOpen(false);
      setIsDeleteError(true);
    },
    onSuccess: () => {
      setIsDeleteDialogOpen(false);
      reloadAddresses(true);
    },
  });

  const onClickSetDefault = () => {
    if (isDefault) {
      setIsDefaultDialogOpen(true);
    } else {
      track('Update Default Address');
      setDefaultAddress.mutate({
        address: {
          address_line_1: address?.address_line_1,
          address_line_2: address?.address_line_2,
          country_code: address?.country_code,
          is_shipping: true,
          postcode: address?.postcode,
          state: address?.state,
          suburb: address?.suburb,
        },
        addressId: selectedAddressId,
      });
    }
  };

  const handleRemove = () => {
    deleteAddress?.mutate();
  };

  const onClickDelete = () => {
    track('Delete Address Started');
    setIsDeleteDialogOpen(true);
  };

  const onClickDeleteConfirm = () => {
    track('Delete Address Confirmed');
    handleRemove();
  };

  const addressText = compact([
    address?.address_line_1,
    address?.address_line_2,
  ])
    .join(', ')
    .trim();

  const isLoading = deleteAddress.isPending || setDefaultAddress.isPending;

  return (
    <>
      {isDefaultDialogOpen && (
        <ErrorDialog
          buttonText="Continue"
          icon={<ExclamationMarkIcon />}
          isOpen={isDefaultDialogOpen}
          message={ERROR_SET_DEFAULT_ADDRESS_ALREADY_DEFAULT}
          onOpenChange={() => setIsDefaultDialogOpen(false)}
          title={ERROR_TITLE_SET_DEFAULT_ADDRESS_ALREADY_DEFAULT}
          tryAgain={!isDefault}
        />
      )}
      {isDefaultError && (
        <ErrorDialog
          isOpen={isDefaultError}
          message={ERROR_SET_DEFAULT_ADDRESS_FAILED}
          onOpenChange={() => setIsDefaultError(false)}
          title={ERROR_TITLE_SET_DEFAULT_ADDRESS}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          acceptButtonText="Yes, remove it"
          isDanger
          isOpen={isDeleteDialogOpen}
          loading={isLoading}
          onClickAccept={onClickDeleteConfirm}
          onClickCancel={() => setIsDeleteDialogOpen(false)}
          title="Are you sure you want to remove this address?"
        />
      )}
      {isDeleteError && (
        <ErrorDialog
          isOpen={isDeleteError}
          message={ERROR_DELETE_ADDRESS_GENERIC}
          onOpenChange={() => setIsDeleteError(false)}
          title={ERROR_TITLE_DELETE_ADDRESS}
        />
      )}
      <Styled.AddressDetailsWrapper>
        <Styled.AddressDetailsContainer>
          <Styled.AddressDetails role="list">
            <Styled.Grid>
              <Styled.AddressDetailsColumn role="listitem">
                <Styled.TextLabel>Address nickname</Styled.TextLabel>
                <Styled.TextValue
                  $color={`var(--colors-on-surface${address?.nickname ? 'b' : 'c'})`}
                >
                  {address?.nickname || '(Not set)'}
                </Styled.TextValue>
              </Styled.AddressDetailsColumn>
              <Styled.DefaultAddressColumn role="listitem">
                {isDefault && (
                  <Styled.DefaultAddressPill>Default</Styled.DefaultAddressPill>
                )}
              </Styled.DefaultAddressColumn>
            </Styled.Grid>
            <Styled.Grid>
              <Styled.AddressDetailsColumn role="listitem">
                <Styled.TextLabel>Name</Styled.TextLabel>
                <Styled.TextValue>{address?.recipient_name}</Styled.TextValue>
              </Styled.AddressDetailsColumn>
            </Styled.Grid>
            <Styled.Grid>
              <Styled.AddressDetailsColumn $width="100%" role="listitem">
                <Styled.TextLabel>Street address</Styled.TextLabel>
                <Styled.TextValue>{addressText}</Styled.TextValue>
              </Styled.AddressDetailsColumn>
            </Styled.Grid>
            <Styled.Grid>
              <Styled.AddressDetailsColumn role="listitem">
                <Styled.TextLabel>City / Suburb</Styled.TextLabel>
                <Styled.TextValue>{address?.suburb}</Styled.TextValue>
              </Styled.AddressDetailsColumn>
            </Styled.Grid>
            <Styled.Grid $noJustify={true}>
              <Styled.AddressDetailsColumn $width="5rem" role="listitem">
                <Styled.TextLabel>State</Styled.TextLabel>
                <Styled.TextValue>{address?.state}</Styled.TextValue>
              </Styled.AddressDetailsColumn>
              <Styled.AddressDetailsColumn role="listitem">
                <Styled.TextLabel>Postcode</Styled.TextLabel>
                <Styled.TextValue>{address?.postcode}</Styled.TextValue>
              </Styled.AddressDetailsColumn>
            </Styled.Grid>
          </Styled.AddressDetails>
        </Styled.AddressDetailsContainer>
        <Styled.Actions>
          <Button
            className={styles['action-button']}
            level="primary"
            onClick={onClickEdit}
            size="medium"
            variant="branded"
          >
            <EditIcon color={'var(--colors-brand1-on-high)'} />
            Edit Address
          </Button>
          <ButtonSpinner
            level="secondary"
            loading={setDefaultAddress.isPending}
            onClick={onClickSetDefault}
            size="medium"
            spinnerColor={'var(--colors-on-surface-c)'}
            variant="branded"
          >
            Set as default address
          </ButtonSpinner>
          <Button
            className={styles['action-button']}
            level="tertiary"
            onClick={onClickDelete}
            size="medium"
            variant="destructive"
          >
            <TrashIcon />
            Remove Address
          </Button>
        </Styled.Actions>
      </Styled.AddressDetailsWrapper>
    </>
  );
};
