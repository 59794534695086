import type { IIcon } from './typings';

export const AddressMenuIcon = ({
  color = 'var(--colors-on-surface-b)',
}: IIcon) => {
  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6.49481C9.46119 6.49481 9.83505 6.12095 9.83505 5.65976C9.83505 5.19857 9.46119 4.82471 9 4.82471C8.53881 4.82471 8.16495 5.19857 8.16495 5.65976C8.16495 6.12095 8.53881 6.49481 9 6.49481Z"
        fill={color}
      />
      <path
        clipRule="evenodd"
        d="M3.57216 5.72936C3.47989 5.72936 3.39139 5.76602 3.32614 5.83127C3.26088 5.89652 3.22423 5.98502 3.22423 6.0773V14.4278C3.22423 14.5201 3.26088 14.6086 3.32614 14.6738C3.39139 14.7391 3.47989 14.7758 3.57216 14.7758H14.4278C14.5201 14.7758 14.6086 14.7391 14.6739 14.6738C14.7391 14.6086 14.7758 14.5201 14.7758 14.4278V6.0773C14.7758 5.98502 14.7391 5.89652 14.6739 5.83127C14.6086 5.76602 14.5201 5.72936 14.4278 5.72936H13.5928C13.3238 5.72936 13.1057 5.51128 13.1057 5.24225C13.1057 4.97323 13.3238 4.75514 13.5928 4.75514H14.4278C14.7785 4.75514 15.1148 4.89444 15.3627 5.14239C15.6107 5.39034 15.75 5.72664 15.75 6.0773V14.4278C15.75 14.7785 15.6107 15.1148 15.3627 15.3627C15.1148 15.6107 14.7785 15.75 14.4278 15.75H3.57216C3.2215 15.75 2.88521 15.6107 2.63725 15.3627C2.3893 15.1148 2.25 14.7785 2.25 14.4278V6.0773C2.25 5.72664 2.3893 5.39034 2.63725 5.14239C2.88521 4.89444 3.22151 4.75514 3.57216 4.75514H4.40722C4.67624 4.75514 4.89433 4.97323 4.89433 5.24225C4.89433 5.51128 4.67624 5.72936 4.40722 5.72936H3.57216Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9 3.22423C8.35405 3.22423 7.73455 3.48083 7.2778 3.93759C6.82104 4.39435 6.56444 5.01384 6.56444 5.65979C6.56444 6.08031 6.71515 6.60626 6.97793 7.18812C7.23711 7.76201 7.58705 8.35099 7.94396 8.88637C8.29991 9.42028 8.6568 9.89199 8.92511 10.2306C8.95094 10.2632 8.97593 10.2945 9 10.3245C9.02407 10.2945 9.04907 10.2632 9.0749 10.2306C9.3432 9.89199 9.7001 9.42028 10.056 8.88637C10.413 8.35099 10.7629 7.76201 11.0221 7.18812C11.2848 6.60626 11.4356 6.08031 11.4356 5.65979C11.4356 5.01384 11.179 4.39435 10.7222 3.93759C10.2654 3.48083 9.64595 3.22423 9 3.22423ZM9 11.0876L9.36659 11.4084C9.27409 11.5141 9.14047 11.5747 9 11.5747C8.85954 11.5747 8.72591 11.5141 8.63341 11.4084L9 11.0876ZM6.58891 3.24871C7.22837 2.60925 8.09567 2.25 9 2.25C9.90433 2.25 10.7716 2.60925 11.4111 3.24871C12.0505 3.88817 12.4098 4.75546 12.4098 5.65979C12.4098 6.28309 12.1952 6.95753 11.91 7.5891C11.6211 8.22864 11.2404 8.86614 10.8666 9.42677C10.4919 9.98886 10.1181 10.4827 9.83844 10.8356C9.69839 11.0124 9.5814 11.1544 9.49901 11.2528C9.4578 11.302 9.42521 11.3403 9.40267 11.3666L9.37656 11.3969L9.3695 11.4051L9.36751 11.4073L9.36659 11.4084C9.36659 11.4084 9.36659 11.4084 9 11.0876C8.63341 11.4084 8.63341 11.4084 8.63341 11.4084L8.63249 11.4073L8.63051 11.4051L8.62344 11.3969L8.59733 11.3666C8.57479 11.3403 8.5422 11.302 8.50099 11.2528C8.4186 11.1544 8.30162 11.0124 8.16156 10.8356C7.88186 10.4827 7.50809 9.98886 7.13336 9.42677C6.75961 8.86614 6.37888 8.22864 6.09005 7.5891C5.80482 6.95753 5.59021 6.28309 5.59021 5.65979C5.59021 4.75546 5.94945 3.88817 6.58891 3.24871Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.25681 10.7432C7.44704 10.9334 7.44704 11.2418 7.25681 11.4321L4.75165 13.9372C4.56142 14.1275 4.253 14.1275 4.06277 13.9372C3.87254 13.747 3.87254 13.4386 4.06277 13.2483L6.56793 10.7432C6.75815 10.553 7.06658 10.553 7.25681 10.7432Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.7432 10.7432C10.9334 10.553 11.2418 10.553 11.4321 10.7432L13.9372 13.2483C14.1275 13.4386 14.1275 13.747 13.9372 13.9372C13.747 14.1275 13.4386 14.1275 13.2483 13.9372L10.7432 11.4321C10.553 11.2418 10.553 10.9334 10.7432 10.7432Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
