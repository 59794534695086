import { useAnalytics } from '@/hooks/use-analytics';
import { BankCardsDialogSuccessStates } from '@/store';

import { useBankCardShowSuccessDialog } from '../../hooks/use-bank-card-show-success-dialog';
import { useViewCardReset } from '../../hooks/use-view-card-reset';

export function useEditCardFinalizeSuccess() {
  const showBankCardSuccessDialog = useBankCardShowSuccessDialog();
  const viewCardReset = useViewCardReset();
  const analytics = useAnalytics();

  return function editCardDetailsFinalizeSuccessCallback() {
    viewCardReset();
    analytics.track('3ds Complete', {
      context: 'update_card',
      passed: 'true',
    });
    showBankCardSuccessDialog(BankCardsDialogSuccessStates.BANK_CARD_EDIT);
  };
}
