import clsx from 'clsx';

import { useActivityStore } from '@/store';

import { ViewOrderStatus } from '../../enums/order-status';

import styles from './order-summary-mycoles.module.css';

export function OrderPaymentSummaryMycoles() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (!selectedOrder?.hasMyColes) {
    return null;
  }

  const mycolesAppliedText =
    selectedOrder?.orderStatus === ViewOrderStatus.PENDING
      ? ViewOrderStatus.PENDING
      : 'Applied';

  return (
    <div className={styles['summary-row']}>
      <p className={clsx(styles['text'], styles['color-c'])}>
        mycoles discount card
      </p>
      <p className={styles['text']}>{mycolesAppliedText}</p>
    </div>
  );
}
