import {
  GiftCardsDialogErrorStates,
  GiftCardsDialogSuccessStates,
} from './gift-cards-store-enums';

import type { IGiftCardsStoreStateValues } from './gift-cards-store-types';

export const giftCardsStoreDefaultValues: IGiftCardsStoreStateValues = {
  currentGiftCard: undefined,
  giftCardsDialogErrorState: GiftCardsDialogErrorStates.NULL,
  giftCardsDialogSuccessState: GiftCardsDialogSuccessStates.NULL,
  isAddGiftCardOpen: false,
  isAddGiftCardPending: false,
  isDeleteGiftCardPending: false,
  isOpenDeleteDialog: false,
  isOpenGiftCardsDialogError: false,
  isOpenGiftCardsDialogSuccess: false,
  isViewEligibleGiftCardsOpen: false,
  isViewGiftCardOpen: false,
  selectedGiftCard: undefined,
};
