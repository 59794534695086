import { clsx } from 'clsx';

import styles from './divider.module.css';

type TDividerProps = {
  color?: string;
  decorative?: boolean;
  orientation?: 'horizontal' | 'vertical';
  role?: string;
};

export function Divider({
  color,
  decorative = true,
  orientation = 'horizontal',
  role = 'separator',
}: TDividerProps) {
  return (
    <div
      aria-hidden={decorative ? 'true' : undefined}
      aria-orientation={orientation}
      className={clsx(styles.divider, styles[orientation])}
      role={role}
      style={{
        backgroundColor: color,
      }}
    />
  );
}
