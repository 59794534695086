import { useBankCardsStore } from '@/store';

import { ViewBankCardFooterDeleteCardButton } from './view-bank-card-footer-delete-card-button';
import { ViewBankCardFooterEditCardButton } from './view-bank-card-footer-edit-card-button';
import { ViewBankCardFooterSetDefaultCardButton } from './view-bank-card-footer-set-default-card-button';
import styles from './view-bank-card-footer.module.css';

export function ViewCardFooter() {
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();

  return (
    <div>
      {!selectedBankCard?.isInvalid && (
        <div className={styles.action}>
          <ViewBankCardFooterEditCardButton />

          {!selectedBankCard?.isValidated && (
            <>
              <ViewBankCardFooterSetDefaultCardButton />
              <ViewBankCardFooterDeleteCardButton />
            </>
          )}
        </div>
      )}
    </div>
  );
}
