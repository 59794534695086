import { clsx } from 'clsx';
import { useState } from 'react';

import GiftCardFallback from '@/assets/images/gift-card-fallback.svg';

import styles from './gift-card-art.module.css';

type TGiftCardProps = {
  giftCardName?: string;
  imageUrl?: string;
  size: 'lg' | 'md' | 'sm';
};

export function GiftCardArt({
  giftCardName,
  imageUrl,
  size = 'sm',
}: TGiftCardProps) {
  const [showImage, setShowImage] = useState(!!imageUrl);

  const handleImageLoad = () => {
    setShowImage(true);
  };

  const handleImageError = () => {
    setShowImage(true);
  };

  return (
    <figure
      aria-label={giftCardName}
      className={clsx(styles['card'], styles[`card-${size}`])}
    >
      <div
        className={clsx(styles['card-content'], styles['logo'], {
          [styles['card-content-simple']]: size !== 'md',
        })}
      >
        {showImage ? (
          <img
            alt=""
            className={styles['image']}
            onError={handleImageError}
            onLoad={handleImageLoad}
            src={imageUrl}
          />
        ) : (
          <img alt="" className={styles['image']} src={GiftCardFallback} />
        )}
      </div>
    </figure>
  );
}
