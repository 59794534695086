import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

import { hasAuthAccessToken, trackSegmentRequestError } from '@/services';

import type { AxiosError } from 'axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      networkMode: 'always',
      retry: false,
    },
    queries: {
      enabled: hasAuthAccessToken(),
      networkMode: 'always',
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Number.POSITIVE_INFINITY,
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => {
      trackSegmentRequestError(queryClient, error as AxiosError);
    },
  }),
  queryCache: new QueryCache({
    onError: (error) => {
      trackSegmentRequestError(queryClient, error as AxiosError);
    },
  }),
});
