import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { DatePicker } from '@/components';
import { DateFormats } from '@/config/language';
import { useActivityStore } from '@/store';

import { FilterFields } from '../../enums/filter-fields';
import { getDateRange } from '../../utils';

import type { TFilters } from '../filters/filters';
import type { RefObject } from 'react';
import type { DateRange } from 'react-day-picker';

type TFilterFormBodyDateCalendar = {
  calendarRef: RefObject<HTMLDivElement | null>;
  setViewMonth: (date: Date) => void;
  viewMonth: Date;
};

export function FilterFormBodyDatePicker({
  calendarRef,
  setViewMonth,
  viewMonth,
}: TFilterFormBodyDateCalendar) {
  const { clearErrors, setValue, watch } = useFormContext<TFilters>();

  const isViewFilterCalendar = useActivityStore().use.isViewFilterCalendar();
  const setIsViewFilterCalendar =
    useActivityStore().use.actions().setIsViewFilterCalendar;

  const dateRange = getDateRange(watch());

  const handleCloseCalendar = () => {
    setIsViewFilterCalendar(false);
  };

  const handleCalendarChange = (dateRange?: DateRange, selectedDay?: Date) => {
    const { from, to } = dateRange ?? {};
    clearErrors();

    // Reset dates to new day if range is already set
    if (!!watch(FilterFields.DATE_FROM) && !!watch(FilterFields.DATE_TO)) {
      setValue(
        FilterFields.DATE_FROM,
        selectedDay ? format(selectedDay, DateFormats.DAY_MONTH_YEAR) : ''
      );
      setValue(FilterFields.DATE_TO, '');
    } else {
      setValue(
        FilterFields.DATE_FROM,
        from ? format(from, DateFormats.DAY_MONTH_YEAR) : ''
      );
      setValue(
        FilterFields.DATE_TO,
        to ? format(to, DateFormats.DAY_MONTH_YEAR) : ''
      );
    }
  };

  return (
    <DatePicker
      anchor={calendarRef}
      onChange={handleCalendarChange}
      onClickAway={handleCloseCalendar}
      onMonthChange={setViewMonth}
      showCalendar={isViewFilterCalendar}
      value={dateRange}
      viewMonth={viewMonth}
    />
  );
}
