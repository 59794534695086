import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from '../endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '../query-keys';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useAuthorizeCardRequest() {
  const auth = useAuth();

  return async function authorizeCardRequest(
    payload: BankCardsTypes.Operations.AuthorizeBankCard.TRequest
  ) {
    const { alias, cardCipherText, device } = payload.data;

    return await request<BankCardsTypes.Operations.AuthorizeBankCard.TResponse>(
      endpoints.BANK_CARDS_AUTHORIZE,
      auth,
      {
        data: {
          ...(!!alias && {
            alias,
          }),
          card: {
            cipher_text: cardCipherText,
          },
          ...(!!device?.inauth_transaction_id && {
            device: {
              inauth_transaction_id: device.inauth_transaction_id,
            },
          }),
        },
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_ADD_3DS_AUTHORIZE,
        transaction: paymentMethodTransactionKeys.bankCardAdd3DSAuthorize,
      }
    );
  };
}
