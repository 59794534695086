import { ExclamationMarkIcon } from '@/components/icons';
import { useActivityStore } from '@/store';

import styles from './orders-table-empty.module.css';

export function OrdersTableEmpty() {
  const activeFilters = useActivityStore().use.activeFilters();

  const search = useActivityStore().use.searchQuery();

  const isSearchActive = !!search.query || !!search.category;

  const message =
    !!activeFilters || isSearchActive
      ? ', try adjusting your filters or search.'
      : '';

  return (
    <div className={styles['container']} role="alert">
      <ExclamationMarkIcon height={62} width={62} />
      <div className={styles['message']}>
        <h1> Nothing to see here</h1>
        <p>We found no transactions made with your flypay account{message}</p>
      </div>
    </div>
  );
}
