import * as Separator from '@radix-ui/react-separator';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const sideNavButtonStyles = css`
  align-items: center;
  border-radius: var(--radii-lg);
  color: var(--colors-on-surface-b);
  display: flex;
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-normal);
  padding: var(--spaces-xs) var(--spaces-s);
  text-decoration: none;
  width: inherit;

  &:focus {
    outline: 1px solid var(--colors-focus-light);
  }

  &:hover {
    color: var(--colors-on-surface-a);
  }

  &.active {
    background: var(--colors-brand1-highest);
    color: var(--colors-brand1-on-high);
    cursor: default;

    svg path,
    rect {
      stroke: var(--colors-brand1-on-high);
    }
  }
`;

const SideNavRoot = styled.nav`
  background-color: var(--colors-surface-lowest);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 240px;
  overflow: auto;
  padding: var(--spaces-xl);
  position: relative;
  z-index: 1;
`;

const SideNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  &.footer {
    margin-top: var(--spaces-m);
  }
`;

const SideNavItem = styled.li<{ $mobileOnly?: boolean }>`
  margin-bottom: var(--spaces-m);
  width: 100%;

  @media (width >= 768px) {
    display: ${(props) => (props.$mobileOnly ? 'none' : 'block')};
  }
`;

const SideNavLink = styled(NavLink)`
  ${sideNavButtonStyles}
  gap:8px
`;

const SideNavButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  ${sideNavButtonStyles}
`;

const SideNavIcon = styled.img`
  height: 16px;
  margin-right: var(--spaces-s);
  object-fit: contain;
  object-position: center;
  width: 16px;
`;

const SideNavIconSpacer = styled.div`
  margin-right: var(--spaces-s);
`;

const SideNavSeparator = styled(Separator.Root)`
  background-color: var(--colors-on-surface-c);
  height: 1px;
  margin: var(--spaces-m) 0;
  width: 100%;
`;

const SideNavLogoWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-grow: 2;
  margin-top: var(--spaces-2xl);
  padding: 0 var(--spaces-s);
`;

export const Styled = {
  SideNavButton,
  SideNavIcon,
  SideNavIconSpacer,
  SideNavItem,
  SideNavLink,
  SideNavList,
  SideNavLogoWrapper,
  SideNavRoot,
  SideNavSeparator,
};
