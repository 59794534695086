import { useAuth } from 'react-oidc-context';

import { Layout, LoadingScreen, SideSheet } from '@/components';
import { Filters, OrdersTable } from '@/features/activity';
import { usePageTitle } from '@/hooks/use-page-title';
import { useActivityStore } from '@/store';

import { ActivityHeader } from '../../features/activity/components/activity-header/activity-header';
import { OrderDetails } from '../../features/activity/components/order-details/order-details';

import styles from './activity.module.css';

export function Activity() {
  usePageTitle('Activity');
  const auth = useAuth();

  const setIsViewOrderDetailsOpen =
    useActivityStore().use.actions().setIsViewOrderDetailsOpen;
  const isViewOrderDetailsOpen =
    useActivityStore().use.isViewOrderDetailsOpen();

  const handleOnClose = () => {
    setIsViewOrderDetailsOpen(false);
  };

  if (auth.isLoading) {
    return (
      <Layout>
        <LoadingScreen />
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles['activity-wrapper']}>
        <ActivityHeader />
        <OrdersTable />
      </div>
      <Filters />
      <SideSheet
        onClose={handleOnClose}
        open={isViewOrderDetailsOpen}
        title="Activity details"
      >
        <OrderDetails />
      </SideSheet>
    </Layout>
  );
}
