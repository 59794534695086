import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { FilterFields } from '../../enums/filter-fields';
import {
  validateAmount,
  validateAmountRange,
} from '../../utils/validate-amount-range';
import { FilterInput } from '../filter-input/filter-input';

import styles from './filter-form-body.module.css';

import type { TFilters } from '../filters/filters';

export function FilterFormBodyAmount() {
  const {
    clearErrors,
    formState: { errors },
    register,
  } = useFormContext<TFilters>();

  const inputWrapperClasses = clsx(
    styles['input-wrapper'],
    (!!errors.minAmount?.message || !!errors.maxAmount?.message) &&
      styles['error']
  );

  const validRange =
    (fieldName: FilterFields) =>
    (_: string | undefined, formValues: TFilters) => {
      clearErrors(fieldName);
      return validateAmountRange(formValues);
    };

  return (
    <div
      aria-labelledby="amountRange"
      className={styles['filter-group']}
      role="group"
    >
      <p className={styles['group-label']} id="amountRange">
        Amount
      </p>
      <div
        className={clsx(styles['input-wrapper'], styles['input-label-wrapper'])}
      >
        <label className={styles['input-label']} htmlFor="minAmount">
          Minimum ($)
        </label>
        <label className={styles['input-label']} htmlFor="maxAmount">
          Maximum ($)
        </label>
      </div>
      <div className={inputWrapperClasses}>
        <FilterInput
          {...register(FilterFields.MIN_AMOUNT, {
            validate: {
              validAmount: validateAmount,
              validRange: validRange(FilterFields.MAX_AMOUNT),
            },
          })}
          aria-label="minimum amount"
          beforeIcon="$"
          error={errors.minAmount?.message}
          inputMode="numeric"
        />
        <div className={styles['separator']} />
        <FilterInput
          {...register(FilterFields.MAX_AMOUNT, {
            validate: {
              validAmount: validateAmount,
              validRange: validRange(FilterFields.MIN_AMOUNT),
            },
          })}
          aria-label="maximum amount"
          beforeIcon="$"
          error={errors.maxAmount?.message}
          inputMode="numeric"
        />
      </div>
    </div>
  );
}
