import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--colors-error-lowest);
  border: 1px solid var(--colors-error-on-lowest);
  border-radius: var(--radii-md);
  display: flex;
  margin-bottom: var(--spaces-l);
  padding: var(--spaces-m) var(--spaces-m) var(--spaces-m) var(--spaces-xs);
`;

const IconColumn = styled.div`
  flex-basis: 1.5rem;
`;

const TextColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--spaces-xs);
`;

const Text = styled.div`
  font-size: var(--font-sizes-14);
`;

const Title = styled.h2`
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-bold);
  margin: 0;
`;

export const Styled = {
  Container,
  IconColumn,
  Text,
  TextColumn,
  Title,
};
