import { AddGiftCardForm } from '../add-gift-card-form/add-gift-card-form';
import { EligibleGiftCards } from '../eligible-gift-cards/eligible-gift-cards';

import styles from './add-gift-card.module.css';

export function AddGiftCard() {
  return (
    <div className={styles['form']}>
      <AddGiftCardForm />
      <EligibleGiftCards />
    </div>
  );
}
