import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { request } from '@/services';

import { endpoints } from '../endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '../query-keys';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useGetPublicKeyRequest() {
  const auth = useAuth();

  return async function getPublicKey() {
    return await request<BankCardsTypes.Components.TPublicKey>(
      endpoints.PUBLIC_KEY,
      auth,
      {},
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.PUBLIC_KEY,
        transaction: paymentMethodTransactionKeys.publicKey,
      }
    );
  };
}
