import { format, isValid } from 'date-fns';

import { DateFormats } from '@/config/language';

import { parseDate } from './parse-date';

export const formatDateValue = (value?: string) => {
  const date = parseDate(value);

  if (!isValid(date)) {
    return value;
  }

  return !!date ? format(date, DateFormats.DAY_MONTH_YEAR) : value;
};
