import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

export function useDeleteBankCardError() {
  const { setBankCardsDialogErrorState } = useBankCardsStore().use.actions();

  return function deleteBankCardErrorCallback() {
    setBankCardsDialogErrorState(
      BankCardsDialogErrorStates.BANK_CARD_DELETE_ERROR
    );
  };
}
