import clsx from 'clsx';
import { forwardRef, useId } from 'react';

import { ErrorIcon } from '@/components/icons';

import { FilterInputIcon } from './filter-input-icon';
import styles from './filter-input.module.css';

type Ref = HTMLInputElement;

interface IDateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  afterIcon?: React.ReactNode;
  beforeIcon?: React.ReactNode;
  error?: string;
  iconLabel?: string;
  onClickAfterIcon?: () => void;
  onClickBeforeIcon?: () => void;
}

export const FilterInput = forwardRef<Ref, IDateInputProps>(
  (
    {
      afterIcon,
      beforeIcon,
      error,
      iconLabel,
      max,
      min,
      name,
      onBlur,
      onChange,
      onClickAfterIcon,
      onClickBeforeIcon,
      value,
      ...props
    },
    ref
  ) => {
    const fieldErrorId = useId();

    const handleClick =
      (iconType: 'after' | 'before') => (e: React.MouseEvent) => {
        e.preventDefault();
        iconType === 'after' ? onClickAfterIcon?.() : onClickBeforeIcon?.();
      };

    const inputBoxStyles = clsx(
      styles['input-box'],
      !!error && styles['error']
    );

    return (
      <div className={styles['input-wrapper']}>
        <div className={inputBoxStyles}>
          {!!beforeIcon && (
            <FilterInputIcon
              ariaExpanded={props['aria-expanded']}
              hasOnclick={!!onClickBeforeIcon}
              label={iconLabel}
              onClick={handleClick('before')}
            >
              {beforeIcon}
            </FilterInputIcon>
          )}
          <input
            className={styles['input']}
            {...props}
            aria-describedby={!!error ? fieldErrorId : undefined}
            aria-errormessage={!!error ? fieldErrorId : undefined}
            aria-expanded={undefined}
            aria-invalid={!!error}
            max={max}
            min={min}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            value={value}
          />
          {!!afterIcon && (
            <FilterInputIcon
              ariaExpanded={props['aria-expanded']}
              hasOnclick={!!onClickAfterIcon}
              label={iconLabel}
              onClick={handleClick('after')}
            >
              {afterIcon}
            </FilterInputIcon>
          )}
        </div>
        {!!error && (
          <div className={styles['input-error']} id={fieldErrorId} role="alert">
            <ErrorIcon height={20} width={20} />
            {error}
          </div>
        )}
      </div>
    );
  }
);

FilterInput.displayName = 'FilterInput';
