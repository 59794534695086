import isEmpty from 'lodash.isempty';
import React, { useId } from 'react';
import { get } from 'react-hook-form';

import { ConnectForm, ErrorMessage } from '@/components';
import { BankCardArt } from '@/features/bank-cards';

import { Styled } from './styles';

import type { TTextFieldProps } from './typings';
import type { TConnectFormCallback } from '@/components/form';

export const TextField = React.forwardRef<
  HTMLInputElement | null,
  TTextFieldProps
>(
  (
    {
      autocomplete,
      cardScheme,
      disabled,
      errorId,
      helpText,
      id,
      label,
      labelCSS,
      maxLength,
      name,
      onBlur,
      onChange,
      onKeyDown,
      onKeyUp,
      pattern,
      placeholder,
      prefix,
      required,
      shouldUnregister,
      type = 'text',
      validate,
      value,
    },
    ref
  ) => {
    const fieldErrorId = useId();

    if (!name) {
      return null;
    }

    return (
      <ConnectForm>
        {({ formState: { errors }, register }: TConnectFormCallback) => {
          const {
            onBlur: onBlurHookFormField,
            onChange: onChangeHookFormField,
            ref: hookFormFieldRef,
            ...registration
          } = register(name, {
            disabled,
            onBlur,
            onChange,
            pattern,
            shouldUnregister,
            validate,
            value,
          });

          const onChangeTextField = (
            e: React.ChangeEvent<HTMLInputElement>
          ) => {
            onChange?.(e);
            onChangeHookFormField?.(e);
          };

          const error = !isEmpty(errors) && get(errors, name);
          const errorMessageId = errorId ?? fieldErrorId;

          return (
            <Styled.InputWrapper>
              <Styled.Label htmlFor={id || name} style={labelCSS}>
                {label}
              </Styled.Label>
              {!!helpText && <Styled.HelpText>{helpText}</Styled.HelpText>}
              <Styled.InputRoot
                $disabled={disabled}
                $hasError={!isEmpty(error?.message)}
              >
                {!!prefix && <Styled.Prefix>{prefix}</Styled.Prefix>}
                <Styled.TextInput
                  $hasError={!isEmpty(error?.message)}
                  aria-describedby={errorMessageId}
                  aria-errormessage={errorMessageId}
                  aria-invalid={!!error}
                  aria-required={!!required ? 'true' : 'false'}
                  autoComplete={autocomplete}
                  id={id || name}
                  maxLength={maxLength}
                  onBlur={onBlurHookFormField}
                  onChange={onChangeTextField}
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  placeholder={placeholder}
                  required={!!required}
                  role="textbox"
                  type={type}
                  {...registration}
                  ref={(e: HTMLInputElement) => {
                    hookFormFieldRef(e);

                    if (!!ref) {
                      (
                        ref as React.MutableRefObject<HTMLInputElement | null>
                      ).current = e;
                    }
                  }}
                />
                {!!cardScheme && (
                  <Styled.CardImageContainer>
                    <BankCardArt schemes={[cardScheme]} size="xs" />
                  </Styled.CardImageContainer>
                )}
              </Styled.InputRoot>

              <ErrorMessage errors={errors} id={errorMessageId} name={name} />
            </Styled.InputWrapper>
          );
        }}
      </ConnectForm>
    );
  }
);

TextField.displayName = 'TextField';
