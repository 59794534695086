import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { GiftCardsEndpointKeys } from '../gift-cards-endpoint-keys';
import { giftCardsEndpoints } from '../gift-cards-endpoints';
import { giftCardsTransactionKeys } from '../gift-cards-transaction-keys';

import { generateGiftCardCipherText } from './generate-gift-card-cipher-text';

import type { GiftCardTypes } from '../../types/gift-card-types';

export function useAddGiftCardsRequest() {
  const auth = useAuth();

  return async function addGiftCardsRequest(
    payload: GiftCardTypes.Operations.AddGiftCard.TRequest
  ) {
    const giftCardCipherText = await generateGiftCardCipherText(payload);

    return await request<GiftCardTypes.Operations.AddGiftCard.TResponse>(
      giftCardsEndpoints.GIFT_CARDS,
      auth,
      {
        data: {
          data: {
            cipher_text: giftCardCipherText,
          },
        },
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P2,
        requestKey: GiftCardsEndpointKeys.GIFT_CARDS_BY_ID,
        transaction: giftCardsTransactionKeys.giftCardsDelete,
      }
    );
  };
}
