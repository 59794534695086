import { useBankCardsStore } from '@/store';

export function useViewBankCardSideSheetTitle() {
  const isEditingCard = useBankCardsStore().use.isEditingCard();
  const isEditingCardNickname = useBankCardsStore().use.isEditingCardNickname();

  if (isEditingCardNickname) {
    return 'Edit nickname';
  }

  if (isEditingCard) {
    return 'Edit payment card';
  }

  return 'Card details';
}
