import { ButtonSpinner } from '@/components';

import styles from './edit-card-form-footer.module.css';

import type { TEditCardFormFooter } from './edit-card-form-footer-types';

export function EditCardFormFooter({ isProcessing }: TEditCardFormFooter) {
  const buttonText = isProcessing ? 'Loading' : 'Save';

  return (
    <div className={styles['edit-card-form-footer']}>
      <ButtonSpinner
        disabled={isProcessing}
        level="primary"
        loading={isProcessing}
        size="medium"
        spinnerColor={'var(--colors-brand1-on-high)'}
        type="submit"
        variant="branded"
      >
        {buttonText}
      </ButtonSpinner>
    </div>
  );
}
