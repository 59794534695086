import { useBankCardsStore } from '@/store';

import { BankCardArt } from '../bank-card-art/bank-card-art';

import styles from './view-bank-card-display.module.css';

export function ViewBankCardDisplay() {
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();

  return (
    <div className={styles['view-bank-card-display']}>
      <BankCardArt
        alias={selectedBankCard?.alias || selectedBankCard?.data.issuer_name}
        cardType={selectedBankCard?.data.type}
        lastFour={selectedBankCard?.data.visualization.last_four_digits}
        schemes={selectedBankCard!.data.schemes}
        size="lg"
      />
    </div>
  );
}
