import { useBankCardsStore } from '@/store';

export function useViewBankCardSideSheetRefocusState() {
  const is3dsIframeOpen = useBankCardsStore().use.is3dsIframeOpen();
  const isEditingCard = useBankCardsStore().use.isEditingCard();
  const isEditingCardNickname = useBankCardsStore().use.isEditingCardNickname();

  return is3dsIframeOpen
    ? 'is3DS'
    : isEditingCard || isEditingCardNickname
      ? 'isEditing'
      : '';
}
