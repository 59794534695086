import { Portal } from '@radix-ui/react-dialog';
import { Content, Overlay } from '@radix-ui/react-dialog';
import { clsx } from 'clsx';
import { isValidElement } from 'react';
import { useId } from 'react';

import { DialogContentBody } from './dialog-content-body';
import { DialogContentFooter } from './dialog-content-footer';
import { DialogContentHeader } from './dialog-content-header';
import { DialogHeader } from './dialog-header';
import styles from './dialog.module.css';

type TDialogContentProps = {
  ariaLabel?: string;
  body?: React.ReactNode;
  contentCss?: string;
  description?: string;
  footer?: React.ReactNode;
  hasCloseIcon?: boolean;
  hideOverflow?: boolean;
  intro?: React.ReactNode;
  title?: string;
};

export function DialogContent({
  ariaLabel,
  body,
  contentCss,
  description,
  footer,
  hasCloseIcon,
  hideOverflow,
  intro,
  title,
}: TDialogContentProps) {
  const showHeader = !!description || !!title || hasCloseIcon || !!intro;
  const visuallyHidden = !!description && !title && !intro && !hasCloseIcon;
  const id = useId();
  const secondaryId = useId();
  const titleId = !!title ? id : undefined;
  const describeId = !!description ? secondaryId : titleId;

  const contentStyles = clsx(
    styles['dialog-inner'],
    contentCss,
    hideOverflow && styles['overflow-hidden']
  );

  return (
    <Portal>
      <Overlay className={styles['dialog-overlay']} role="presentation" />
      <Content
        aria-describedby={describeId}
        aria-label={ariaLabel}
        aria-labelledby={titleId}
        aria-modal="true"
        className={contentStyles}
      >
        {showHeader && (
          <DialogHeader visuallyHidden={visuallyHidden}>
            <DialogContentHeader
              describeId={describeId}
              description={description}
              hasCloseIcon={hasCloseIcon}
              intro={intro}
              title={title}
              titleId={titleId}
            />
          </DialogHeader>
        )}
        {isValidElement(body) && <DialogContentBody body={body} />}
        {isValidElement(footer) && <DialogContentFooter footer={footer} />}
      </Content>
    </Portal>
  );
}
