import { useFormContext } from 'react-hook-form';

import { Radio } from '@/components/radio';

import { FilterFields } from '../../enums/filter-fields';

import styles from './filter-form-body.module.css';

import type { TFilters } from '../filters/filters';

export function FilterFormBodyOrderType() {
  const { register } = useFormContext<TFilters>();
  return (
    <div
      aria-labelledby="order-type-group"
      className={styles['filter-group']}
      role="group"
    >
      <p className={styles['group-label']} id="order-type-group">
        Type
      </p>
      <Radio
        {...register(FilterFields.TYPE)}
        defaultChecked
        id="type-all"
        value=""
      >
        All
      </Radio>
      <Radio {...register(FilterFields.TYPE)} id="type-online" value="online">
        Online
      </Radio>
      <Radio
        {...register(FilterFields.TYPE)}
        id="type-instore"
        value="in_store"
      >
        In-store
      </Radio>
    </div>
  );
}
