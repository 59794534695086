import { BankCardsEnums } from '../enums/bank-cards-enums';

import type { BankCardsTypes } from '../types/bank-cards-types';

export const bankCardStatusText = {
  DEFAULT: 'Default',
  EXPIRED: 'Expired',
  UNAVAILABLE: 'Unavailable',
  UNVERIFIED: 'Unverified',
};

export function getBankCardStatus(
  status: BankCardsTypes.Components.TBankCardStatus,
  isDefaultBankCard: boolean
) {
  switch (status) {
    case BankCardsEnums.BankCardStatus.EXPIRED: {
      return bankCardStatusText.EXPIRED;
    }

    case BankCardsEnums.BankCardStatus.INVALID: {
      return bankCardStatusText.UNAVAILABLE;
    }

    case BankCardsEnums.BankCardStatus.VALIDATED: {
      return bankCardStatusText.UNVERIFIED;
    }

    case BankCardsEnums.BankCardStatus.VERIFIED: {
      return isDefaultBankCard ? bankCardStatusText.DEFAULT : undefined;
    }

    default: {
      return undefined;
    }
  }
}
