import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { GiftCardArt } from '../gift-card-art/gift-card-art';

import styles from './eligible-gift-card-list-item.module.css';

import type { TGetGiftCardProgram } from '../../types/gift-card-component-types';

type TEligibleGiftCardListItem = {
  currentIndex: number;
  program: TGetGiftCardProgram;
  totalCount: number;
};
export function EligibleGiftCardListItem({
  currentIndex,
  program,
  totalCount,
}: TEligibleGiftCardListItem) {
  return (
    <li className={styles['container']}>
      <div>
        <GiftCardArt imageUrl={program.display?.card_art_url} size={'sm'} />
      </div>
      <div className={styles['program-name']}>
        <VisuallyHidden>
          Gift card program, {`${currentIndex} of ${totalCount}`},
        </VisuallyHidden>
        <strong>{program.display_name}</strong>
        <VisuallyHidden>.</VisuallyHidden>
      </div>
    </li>
  );
}
