import { BankCardsDialogSuccessStates, useBankCardsStore } from '@/store';

import { useBankCardShowSuccessDialog } from '../../hooks/use-bank-card-show-success-dialog';

export function useDeleteBankCardSuccess() {
  const {
    setIs3dsIframeOpen,
    setIsEditingCard,
    setIsEditingCardNickname,
    setIsOpenBankCardsList,
    setIsOpenDeleteDialog,
    setIsViewBankCardDetailsOpen,
  } = useBankCardsStore().use.actions();

  const showBankCardSuccessDialog = useBankCardShowSuccessDialog();

  return function deleteBankCardSuccessCallback() {
    setIs3dsIframeOpen(false);
    setIsViewBankCardDetailsOpen(false);
    setIsEditingCardNickname(false);
    setIsEditingCard(false);
    setIsOpenDeleteDialog(false);
    setIsOpenBankCardsList(false);
    showBankCardSuccessDialog(BankCardsDialogSuccessStates.BANK_CARD_DELETE);
  };
}
