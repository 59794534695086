import { useActivityStore } from '@/store';
import { formatCurrency } from '@/utils/format/currency';

import { ViewOrderStatus } from '../../enums/order-status';
import { MerchantLogo } from '../merchant-logo';
import { OrderDetailsMeta } from '../order-details-meta/order-details-meta';
import { OrderDetailsStatus } from '../order-details-status/order-details-status';

import styles from './order-details-header.module.css';

export function OrderDetailsHeader() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (!selectedOrder) {
    return null;
  }

  const { amount, merchantId, merchantName, orderStatus } = selectedOrder;

  const paymentText =
    orderStatus !== ViewOrderStatus.PENDING ? ' paid to' : ' payment to';

  return (
    <div className={styles['details-header']}>
      <MerchantLogo
        merchantId={merchantId}
        merchantName={merchantName}
        size="lg"
      />
      <h3 className={styles['heading']}>
        <strong>{formatCurrency(amount)}</strong>
        {paymentText}
        <br />
        <strong>{merchantName}</strong>
      </h3>
      <OrderDetailsMeta />
      <OrderDetailsStatus />
    </div>
  );
}
