import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '../query-keys';

import { useFinalizeCardRequest } from './use-finalize-card-request';

import type { TBaasAddPaymentMethodErrors } from '../../types';
import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useFinalizeCard(
  options?: UseMutationOptions<
    unknown,
    TBaasErrorResponse<TBaasAddPaymentMethodErrors>,
    BankCardsTypes.Operations.FinalizeBankCard.TRequest,
    unknown
  >
) {
  return useMutation({
    mutationFn: useFinalizeCardRequest(),
    mutationKey: paymentMethodQueryKeys.cardsBankAddFinalize,
    ...options,
  });
}
