import type { IIcon } from './typings';

export const ActivityMenuIcon = ({
  color = 'var(--colors-on-surface-b)',
}: IIcon) => {
  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        height="2.75"
        rx="1.21456"
        stroke={color}
        strokeWidth="1.4"
        width="6.35"
        x="6.95039"
        y="12.1242"
      />
      <rect
        height="3.2"
        rx="1.21456"
        stroke={color}
        strokeWidth="1.4"
        width="8.6"
        x="2.45039"
        y="7.62422"
      />
      <rect
        height="3.2"
        rx="1.21456"
        stroke={color}
        strokeWidth="1.4"
        width="8.15"
        x="7.40059"
        y="3.12422"
      />
    </svg>
  );
};
