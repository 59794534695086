import { sortGiftCards } from './sort-gift-cards';
import { transformGiftCards } from './transform-gift-cards';

import type { GiftCardTypes } from '../../types/gift-card-types';

export const select = {
  sortedGiftCards(response: GiftCardTypes.Operations.GetGiftCards.TResponse) {
    const giftCards = transformGiftCards(response);

    return { ...giftCards, cards: sortGiftCards(giftCards.cards) };
  },
};
