import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { TOP_BAR_HEIGHT } from '@/config/breakpoints';

const focusStyles = css`
  &:focus,
  :focus-visible {
    outline: 2px solid var(--colors-focus-light);
    outline-offset: 2px;
  }
`;

const openHamburgerToggle = css`
  .bar-1 {
    top: 5px;
    transform: rotate(135deg);
    width: 14px;
  }

  .bar-2 {
    width: 0;
  }

  .bar-3 {
    top: 5px;
    transform: rotate(-135deg);
    width: 14px;
  }
`;

const wrapperStyles = css`
  align-items: center;
  flex-direction: row;
  gap: var(--spaces-s);
  justify-content: center;
`;

const DropdownContent = styled(DropdownMenu.Content)`
  background: var(--colors-surface-low);
  border-radius: 0 0 var(--radii-lg) var(--radii-lg);
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  padding: var(--spaces-l);
`;

const DropdownTrigger = styled(DropdownMenu.Trigger)`
  all: unset;
  align-items: center;
  border-radius: var(--radii-sm);
  cursor: pointer;
  display: flex;
  gap: var(--spaces-s);
  padding-left: var(--spaces-xs);

  &:focus,
  :focus-visible {
    outline: 2px solid var(--colors-focus-light);
    outline-offset: 2px;
  }
`;

const HamburgerToggle = styled.div<{ $isOpen: boolean }>`
  height: 12px;
  opacity: 1;
  transform: scale(1);
  transition:
    opacity 300ms ease-out,
    transform 300ms ease-out;
  width: 12px;

  span {
    background: var(--colors-surface-lowest);
    border-radius: var(--radii-sm);
    height: 2px;
    left: 0;
    position: absolute;
    transition:
      transform 300ms ease-out,
      right 300ms ease-in-out,
      width 200ms ease-out,
      left 300ms ease-in-out,
      top 300ms ease-in-out;
    width: 12px;
  }

  .bar-1 {
    top: 0;
  }

  .bar-2 {
    top: 5px;
    transition:
      transform 300ms ease-out,
      right 300ms ease-in-out,
      width 200ms ease-out,
      left 300ms ease-in-out,
      top 300ms ease-in-out;
    width: 10px;
  }

  .bar-3 {
    top: 10px;
    width: 8px;
  }

  ${(props) => props.$isOpen && openHamburgerToggle}
`;

const ItemButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: var(--radii-sm);
  color: var(--colors-on-surface-b);
  cursor: pointer;
  display: flex;
  font-size: var(--font-sizes-16);
  gap: var(--spaces-s);
  line-height: var(--line-heights-half);

  &:focus,
  :focus-visible {
    outline: 2px solid var(--colors-focus-light);
    outline-offset: 2px;
  }
`;

const TopNavDetailsWrapper = styled.button`
  all: unset;
  ${wrapperStyles}
  border-radius: var(--radii-sm);
  cursor: pointer;
  display: flex;

  @media (width >= 768px) {
    display: none;
  }
  ${focusStyles}
`;

const TopNavDetailsWrapperWidescreen = styled.div`
  ${wrapperStyles}
  display: none;

  @media (width >= 768px) {
    display: flex;
  }
`;

const TopNavLogo = styled.img`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

const TopNavLogoLink = styled(Link)`
  border-radius: var(--radii-sm);

  ${focusStyles}
`;

const TopNavWrapper = styled.div`
  align-items: center;
  background-color: var(--colors-brand1-highest);
  display: flex;
  justify-content: space-between;
  min-height: ${TOP_BAR_HEIGHT};
  padding: var(--spaces-xs) var(--spaces-xl);
  width: 100%;

  @media (width >= 768px) {
    padding: var(--spaces-xs) var(--spaces-2xl);
  }
`;

export const Styled = {
  DropdownContent,
  DropdownTrigger,
  HamburgerToggle,
  ItemButton,
  TopNavDetailsWrapper,
  TopNavDetailsWrapperWidescreen,
  TopNavLogo,
  TopNavLogoLink,
  TopNavWrapper,
};
