import { clsx } from 'clsx';

import { AmexLogoIcon } from '@/components/icons/amex-logo-icon';
import { AmexLogoSmallIcon } from '@/components/icons/amex-logo-small-icon';
import { EftposLogoIcon } from '@/components/icons/eftpos-logo-icon';
import { EftposLogoSmallIcon } from '@/components/icons/eftpos-logo-small-icon';
import { MastercardLogoIcon } from '@/components/icons/mastercard-logo-icon';
import { VisaLogoIcon } from '@/components/icons/visa-logo-icon';

import { BankCardsEnums } from '../../enums/bank-cards-enums';

import styles from './bank-card-art.module.css';
import { getDisplayScheme } from './get-display-scheme';
import { iconHeights } from './icon-heights';

import type { BankCardsTypes } from '../../types/bank-cards-types';

const schemeIcons = {
  [BankCardsEnums.BankCardSchemes.AMEX]: AmexLogoIcon,
  [BankCardsEnums.BankCardSchemes.DEFAULT]: undefined,
  [BankCardsEnums.BankCardSchemes.EFTPOS]: EftposLogoIcon,
  [BankCardsEnums.BankCardSchemes.MASTERCARD]: MastercardLogoIcon,
  [BankCardsEnums.BankCardSchemes.VISA]: VisaLogoIcon,
};

const schemeIconsSmall = {
  [BankCardsEnums.BankCardSchemes.AMEX]: AmexLogoSmallIcon,
  [BankCardsEnums.BankCardSchemes.DEFAULT]: undefined,
  [BankCardsEnums.BankCardSchemes.EFTPOS]: EftposLogoSmallIcon,
  [BankCardsEnums.BankCardSchemes.MASTERCARD]: MastercardLogoIcon,
  [BankCardsEnums.BankCardSchemes.VISA]: VisaLogoIcon,
};

export type TBankCardArtProps = {
  alias?: string;
  cardType?: BankCardsTypes.Components.TBankCardFunding;
  issuer?: string;
  lastFour?: string;
  schemes: BankCardsTypes.Components.TBankCardSchemes;
  size: 'lg' | 'md' | 'sm' | 'xs';
};

export function BankCardArt({
  alias,
  cardType,
  issuer,
  lastFour,
  schemes = ['default'],
  size = 'xs',
}: TBankCardArtProps) {
  const displayScheme = getDisplayScheme(schemes);
  const shouldShowIssuer = !alias || alias.trim() === '';
  const shouldShowCardType =
    displayScheme !== BankCardsEnums.BankCardSchemes.AMEX;

  const cardTitle = shouldShowIssuer ? issuer : alias;

  const SchemeIcon = schemes && schemeIcons[`${displayScheme}`];
  const SchemeIconSmall = schemes && schemeIconsSmall[`${displayScheme}`];
  const iconHeight = schemes && iconHeights[`${displayScheme}`][`${size}`];

  return (
    <div
      className={clsx(
        styles.card,
        styles[`card-${size}`],
        styles[`card-${displayScheme}`]
      )}
    >
      <div
        className={clsx(styles['card-content'], styles.logo, {
          [styles['card-content-simple']]: size !== 'lg',
        })}
      >
        {(size === 'xs' || size === 'sm') && SchemeIconSmall && (
          <SchemeIconSmall height={iconHeight} />
        )}

        {size === 'md' && (
          <>
            {SchemeIcon && <SchemeIcon height={iconHeight} />}

            {cardType && shouldShowCardType && (
              <p
                className={clsx(styles['card-text'], styles['spaced-text'])}
                style={{ fontSize: 'var(--font-sizes-12)' }}
              >
                {cardType?.toUpperCase()}
              </p>
            )}
          </>
        )}

        {size === 'lg' && (
          <>
            <div className={styles['card-lhs']}>
              <p
                className={styles['card-text']}
                style={{ marginBottom: 'var(--spaces-xs)' }}
              >
                {cardTitle}
              </p>

              {lastFour && (
                <p className={clsx(styles['card-text'], styles['spaced-text'])}>
                  ••• {lastFour}
                </p>
              )}
            </div>

            <div className={styles['card-rhs']}>
              {SchemeIcon && <SchemeIcon height={iconHeight} />}

              {cardType && shouldShowCardType && (
                <p
                  className={clsx(styles['card-text'], styles['spaced-text'])}
                  style={{ fontSize: 'var(--font-sizes-14)' }}
                >
                  {cardType?.toUpperCase()}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
