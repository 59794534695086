import styled, { css } from 'styled-components';

const BottomSheetWrapper = styled.div<{ $isOpen?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  inset: 0;
  justify-content: flex-end;
  max-height: 100%;
  overflow: auto;
  position: fixed;
  transition: background-color 0.4s ease-in-out;
  width: 100%;
  z-index: 1000;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          background-color: rgb(0 0 0 / 30%);
        `
      : css`
          background-color: transparent;
          pointer-events: none;
        `}
`;

const BottomSheet = styled.div<{ $isOpen?: boolean }>`
  background-color: var(--colors-surface-lowest);
  border-radius: 24px 24px 0 0;
  box-shadow: var(--shadows-l1);
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  margin: 0 auto;
  max-width: 480px;
  overflow: auto;
  padding: var(--spaces-l);
  transition: transform 0.4s ease-in-out;
  width: 100%;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          transform: translateY(0);
        `
      : css`
          transform: translateY(100%);
        `}
`;

const BottomSheetHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  button {
    align-items: center;
    align-self: baseline;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
`;

const BottomSheetTitle = styled.h2`
  align-items: center;
  color: #333;
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-half);
  margin: 0;
`;

export const Styled = {
  BottomSheet,
  BottomSheetHeader,
  BottomSheetTitle,
  BottomSheetWrapper,
};
