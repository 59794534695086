import { BankCardsEnums } from '../../enums/bank-cards-enums';

import { bankCardStatus } from './bank-card-status';
import { transformBankCards } from './transform-bank-cards';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export const select = {
  removableCards(response: BankCardsTypes.Operations.GetBankCards.TResponse) {
    const removableCards = response?.cards?.filter(
      (card) =>
        !card.is_default_pay &&
        card.status === BankCardsEnums.BankCardStatus.VERIFIED
    );

    return { ...response, removableCards: removableCards };
  },

  sortedBankCards(
    response: BankCardsTypes.Operations.GetBankCards.TResponse
  ): BankCardsTypes.Operations.GetBankCards.TResponse {
    const bankCards = transformBankCards(response);

    bankCards.cards = bankCards.cards.map(
      (card: BankCardsTypes.Components.TBankCard) =>
        bankCardStatus.addBankCardStatuses(card)
    );

    return bankCards;
  },
};
