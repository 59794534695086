import type { TFilters } from '..';

export const getTotalActiveFilters = (filters: TFilters | undefined) => {
  if (!filters) {
    return '';
  }
  let filterNumber = 0;
  (!!filters.minAmount || !!filters.maxAmount) && filterNumber++;
  (!!filters.dateFrom || !!filters.dateTo) && filterNumber++;

  return filterNumber > 0 ? `(${filterNumber})` : '';
};
