import isEmpty from 'lodash.isempty';
import { createContext, useState } from 'react';
import { createStore } from 'zustand';

import { giftCardsStoreActions } from './gift-cards-store-actions';
import { giftCardsStoreDefaultValues } from './gift-cards-store-defaults';

import type {
  IGiftCardsStoreStateValues,
  TCardStoreProvider,
} from './gift-cards-store-types';
import type { IGiftCardsStore } from './gift-cards-store-types';

export const GiftCardsStoreContext = createContext<ReturnType<
  typeof createGiftCardsStore
> | null>(null);

export function GiftCardsStoreProvider({
  children,
  initialValues,
}: TCardStoreProvider) {
  const initialStoreValue = {
    ...giftCardsStoreDefaultValues,
    ...(!isEmpty(initialValues) && initialValues),
  };

  const [store] = useState(() => createGiftCardsStore(initialStoreValue));

  return (
    <GiftCardsStoreContext.Provider value={store}>
      {children}
    </GiftCardsStoreContext.Provider>
  );
}

function createGiftCardsStore(initialStoreValue: IGiftCardsStoreStateValues) {
  return createStore<IGiftCardsStore>()((set) => {
    return {
      ...initialStoreValue,
      actions: giftCardsStoreActions(set),
    };
  });
}
