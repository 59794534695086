import { useEffect } from 'react';

import { Dialog } from '@/components';
import { useGetWalletConfigOpen } from '@/features/wallet-config';
import { useAnalytics } from '@/hooks';
import { GiftCardsDialogSuccessStates, useGiftCardsStore } from '@/store';

import { useGetGiftCards } from '../../services/use-get-gift-cards/use-get-gift-cards';

import { GiftCardsDialogSuccessContent } from './gift-cards-dialog-success-content';
import { giftCardsSuccessCopy } from './gift-cards-dialog-success-copy';
import { useGiftCardsDialogSuccessReset } from './use-gift-cards-dialog-success-reset';

export function GiftCardsDialogSuccess() {
  const { track } = useAnalytics();

  const getGiftCards = useGetGiftCards();
  const getWalletConfig = useGetWalletConfigOpen();

  const currentGiftCard = useGiftCardsStore().use.currentGiftCard();
  const giftCardsDialogSuccessState =
    useGiftCardsStore().use.giftCardsDialogSuccessState();

  const giftCardsDialogSuccessProps = giftCardsSuccessCopy?.[
    giftCardsDialogSuccessState
  ]?.({
    giftCard: currentGiftCard,
    metadata: {
      giftCardSlotsMaxLimit: getGiftCards.data?.giftCardSlotsMaxLimit,
      giftCardSlotsRemaining: getGiftCards.data?.giftCardSlotsRemaining,
      giftCardSlotsUsed: getGiftCards.data?.giftCardSlotsUsed,
      isMaxedOutGiftCardSlots: getGiftCards.data?.isMaxedOutGiftCardSlots,
    },
    walletName: getWalletConfig.data?.wallet_name,
  });

  // Only show the success sheet after getting the updated max gift card slots from API
  const isOpenGiftCardsDialogSuccess =
    giftCardsDialogSuccessState !== GiftCardsDialogSuccessStates.NULL &&
    !getGiftCards.isFetching;

  const handleOpenChange = useGiftCardsDialogSuccessReset();

  useEffect(() => {
    if (
      giftCardsDialogSuccessState === GiftCardsDialogSuccessStates.GIFT_CARD_ADD
    ) {
      track('Add Gift Card Confirmed');
    }
    if (
      giftCardsDialogSuccessState ===
      GiftCardsDialogSuccessStates.GIFT_CARD_DELETE
    ) {
      track('Delete Gift Card Confirmed');
    }
  }, [giftCardsDialogSuccessState, track]);

  return (
    <Dialog
      body={
        <GiftCardsDialogSuccessContent
          buttonText={giftCardsDialogSuccessProps?.buttonText || 'OK'}
          description={giftCardsDialogSuccessProps?.description}
          handleOpenChange={handleOpenChange}
          meta={giftCardsDialogSuccessProps?.meta}
          title={giftCardsDialogSuccessProps?.title || ''}
        />
      }
      description={giftCardsDialogSuccessProps?.title || ''}
      isOpen={isOpenGiftCardsDialogSuccess}
      onOpenChange={handleOpenChange}
    />
  );
}
