import GiftCardIcon from '@/assets/images/gift-card-icon.svg';
import { CardsPanel, CardsPanelContentListItem, SideSheet } from '@/components';
import { useAnalytics } from '@/hooks';
import { useGiftCardsStore } from '@/store';

import { useGetGiftCards } from '../../services/use-get-gift-cards/use-get-gift-cards';
import { AddGiftCard } from '../add-gift-card/add-gift-card';
import { AddGiftCardButton } from '../add-gift-card-button/add-gift-card-button';
import { GiftCardDetails } from '../gift-card-details/gift-card-details';
import { GiftCardListItem } from '../gift-card-list-item/gift-card-list-item';
import { GiftCardsDialogError } from '../gift-cards-dialog-error/gift-cards-dialog-error';
import { GiftCardsDialogSuccess } from '../gift-cards-dialog-success/gift-cards-dialog-success';

import { useGiftCardsEvents } from './use-gift-cards-events';

export function GiftCards() {
  const getGiftCards = useGetGiftCards();
  const selectedGiftCard = useGiftCardsStore().use.selectedGiftCard();

  const isViewGiftCardOpen = useGiftCardsStore().use.isViewGiftCardOpen();
  const isAddGiftCardOpen = useGiftCardsStore().use.isAddGiftCardOpen();
  const { track } = useAnalytics();

  const {
    handleAddCardClose,
    handleAddCardOpen,
    handleSetSelectedGiftCard,
    handleViewGiftCardClose,
  } = useGiftCardsEvents();

  const handlePanelEmptyAddCard = () => {
    track('Add Gift Card Started');
    handleAddCardOpen();
  };

  return (
    <>
      <CardsPanel
        addCardButton={<AddGiftCardButton />}
        panelEmpty={{
          addBtnText: 'Add gift card',
          description: 'Pay in-store with QR using your gift card.',
          icon: GiftCardIcon,
          onClickAdd: handlePanelEmptyAddCard,
          title: 'Redeem gift cards at checkout',
        }}
        panelError={{
          description: 'gift cards',
          title: 'Gift Cards',
        }}
        query={getGiftCards}
        title={'Gift Cards'}
      >
        {!getGiftCards.isError &&
          !!getGiftCards?.data?.cards?.length &&
          getGiftCards?.data?.cards?.map((giftCard) => {
            const isSelectedGiftCard =
              isViewGiftCardOpen && giftCard.id === selectedGiftCard?.id;

            return (
              <CardsPanelContentListItem key={giftCard.id}>
                <GiftCardListItem
                  giftCard={giftCard}
                  onClick={handleSetSelectedGiftCard(giftCard)}
                  selected={isSelectedGiftCard}
                />
              </CardsPanelContentListItem>
            );
          })}
      </CardsPanel>

      <SideSheet
        onClose={handleAddCardClose}
        open={isAddGiftCardOpen}
        title="Add a gift card"
      >
        <AddGiftCard />
      </SideSheet>

      <SideSheet
        onClose={handleViewGiftCardClose}
        open={isViewGiftCardOpen}
        title={'Gift card details'}
      >
        <GiftCardDetails />
      </SideSheet>

      <GiftCardsDialogError />
      <GiftCardsDialogSuccess />
    </>
  );
}
