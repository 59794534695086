import { useEffect } from 'react';

import { sentry } from '@/features/monitoring';
import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import { PaymentMethodEnums } from '../../enums';
import { use3dsCardListener } from '../../hooks/use-3ds-card-listener';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useAddCardFinalize(
  finalizeCard: BankCardsTypes.Operations.FinalizeBankCard.TMutation
) {
  const { message, setMessage } = use3dsCardListener();

  const finalizePayload = useBankCardsStore().use.finalizePayload();
  const is3dsIframeOpen = useBankCardsStore().use.is3dsIframeOpen();

  const setIs3dsIframeOpen =
    useBankCardsStore().use.actions().setIs3dsIframeOpen;

  const setBankCardsDialogErrorState =
    useBankCardsStore().use.actions().setBankCardsDialogErrorState;

  useEffect(() => {
    if (is3dsIframeOpen) {
      if (
        message === PaymentMethodEnums.MpgsPostMessage.AUTH_SUCCESS &&
        !finalizeCard.isPending
      ) {
        if (finalizePayload) {
          finalizeCard.mutate(finalizePayload);
        } else {
          sentry.captureLog('Card Add Failed 3DS Authorize', message);
          setBankCardsDialogErrorState(
            BankCardsDialogErrorStates.BANK_CARD_ADD_ERROR
          );
        }
        setMessage(null);
      } else if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_FAIL) {
        setIs3dsIframeOpen(false);
        setMessage(null);
        setBankCardsDialogErrorState(
          BankCardsDialogErrorStates.BANK_CARD_ADD_ERROR
        );
      }
    }
  }, [
    finalizeCard,
    finalizePayload,
    is3dsIframeOpen,
    message,
    setIs3dsIframeOpen,
    setBankCardsDialogErrorState,
    setMessage,
  ]);
}
