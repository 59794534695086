export type TComboBoxOption = {
  label: string;
  value: string;
};

export const DELETE_REASONS: TComboBoxOption[] = [
  {
    label: 'Not using the service',
    value: 'Not using the service',
  },
  {
    label: 'Have another account',
    value: 'Have another account',
  },
  {
    label: 'Security or privacy concerns',
    value: 'Security or privacy concerns',
  },
  {
    label: 'Don’t know how to use it',
    value: 'Don’t know how to use it',
  },
  {
    label: 'Too many emails/messages',
    value: 'Too many emails/messages',
  },
  {
    label: 'Prefer not to say',
    value: 'Prefer not to say',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
