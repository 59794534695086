import { useEffect } from 'react';

import { useActivityStore } from '@/store';

import type { TFilters } from '../filters/filters';
import type { UseFormReturn } from 'react-hook-form';

export function useFilterFormReset(form: UseFormReturn<TFilters>) {
  const activeFilters = useActivityStore().use.activeFilters();
  const isFiltersSideSheetOpen =
    useActivityStore().use.isFiltersSideSheetOpen();
  useEffect(() => {
    if (!activeFilters && !isFiltersSideSheetOpen) {
      form.reset();
    }
  }, [form, activeFilters, isFiltersSideSheetOpen]);
}
