import { useContext } from 'react';

import { createStoreSelectors } from '../create-selectors';

import { GiftCardsStoreContext } from './gift-cards-store-context';

export function useGiftCardsStore() {
  const store = useContext(GiftCardsStoreContext);

  if (!store) {
    throw new Error('Missing GiftCardsStoreProvider');
  }

  return createStoreSelectors(store);
}
