import clsx from 'clsx';
import { format } from 'date-fns';

import { PendingIcon, RefundIcon, TickIcon } from '@/components/icons';
import { DateFormats } from '@/config/language';

import styles from './order-payment-summary-header.module.css';

import type { OrderTypes } from '../../types';

type TPaymentSummaryHeaderProps = {
  payment: OrderTypes.TPaymentInfo;
};

export function PaymentSummaryHeader({ payment }: TPaymentSummaryHeaderProps) {
  const getHeaderContent = (payment: OrderTypes.TPaymentInfo) => {
    let paymentIcon = <TickIcon />;
    let paymentText = 'Payment';

    if (payment.hasRefund) {
      paymentIcon = <RefundIcon />;
      paymentText = 'Refund';
    }
    if (payment.isPending) {
      paymentIcon = <PendingIcon />;
      paymentText = 'Pending';
    }
    return { paymentIcon, paymentText };
  };

  const { paymentIcon, paymentText } = getHeaderContent(payment);

  return (
    <div className={styles['payment-header']}>
      {paymentIcon}
      <p className={styles['text']}>
        <strong>{paymentText}</strong>
      </p>
      <p className={clsx(styles['text'], styles['paid-date'])}>
        {!payment.isPending && format(payment.date, DateFormats.DAY_MONTH)}
      </p>
    </div>
  );
}
