import { useQueryClient } from '@tanstack/react-query';

import { ButtonSpinner } from '@/components';
import { useAnalytics } from '@/hooks';
import {
  BankCardsDialogErrorStates,
  BankCardsDialogSuccessStates,
  useBankCardsStore,
} from '@/store';

import { PaymentMethodEnums } from '../../enums';
import { useBankCardShowSuccessDialog } from '../../hooks/use-bank-card-show-success-dialog';
import { useViewCardReset } from '../../hooks/use-view-card-reset';
import { paymentMethodQueryKeys } from '../../services';
import { useSetDefaultBankCard } from '../../services/use-set-default-bank-card/use-set-default-bank-card';

export function ViewBankCardFooterSetDefaultCardButton() {
  const queryClient = useQueryClient();
  const viewCardReset = useViewCardReset();
  const showBankCardSuccessDialog = useBankCardShowSuccessDialog();

  const { track } = useAnalytics();

  const selectedBankCard = useBankCardsStore().use.selectedBankCard();

  const setBankCardsDialogErrorState =
    useBankCardsStore().use.actions().setBankCardsDialogErrorState;

  const setDefaultCard = useSetDefaultBankCard(selectedBankCard?.id || '', {
    onError: () => {
      setBankCardsDialogErrorState(
        BankCardsDialogErrorStates.BANK_CARD_SET_DEFAULT_ERROR
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: paymentMethodQueryKeys.cardsBankGet,
      });

      viewCardReset();

      showBankCardSuccessDialog(
        BankCardsDialogSuccessStates.BANK_CARD_SET_DEFAULT
      );
    },
  });

  const handleSetDefaultCard = () => {
    track('Update Default Card');

    if (selectedBankCard?.is_default_pay) {
      setBankCardsDialogErrorState(
        BankCardsDialogErrorStates.BANK_CARD_SET_DEFAULT_ALREADY_DEFAULT_ERROR
      );
    } else if (
      selectedBankCard?.status !== PaymentMethodEnums.BankCardStatus.VERIFIED
    ) {
      setBankCardsDialogErrorState(
        BankCardsDialogErrorStates.BANK_CARD_SET_DEFAULT_ERROR
      );
    } else {
      setDefaultCard.mutate();
    }
  };

  return (
    <ButtonSpinner
      disabled={setDefaultCard.isPending}
      level="secondary"
      loading={setDefaultCard.isPending}
      onClick={handleSetDefaultCard}
      size="medium"
      spinnerColor={'var(--colors-brand1-highest)'}
      variant="branded"
    >
      Set as default card
    </ButtonSpinner>
  );
}
