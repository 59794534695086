import { forwardRef } from 'react';

import { Styled } from './styles';

import type { ComponentPropsWithRef, PropsWithChildren } from 'react';

type Ref = HTMLInputElement;

export const Radio = forwardRef<
  Ref,
  PropsWithChildren<ComponentPropsWithRef<'input'>>
>(({ children, id, name, value, ...props }, ref) => (
  <Styled.Wrapper>
    <Styled.Label htmlFor={id}>
      {children}
      <Styled.Input {...props} id={id} name={name} ref={ref} value={value} />
      <Styled.Checkmark />
    </Styled.Label>
  </Styled.Wrapper>
));

Radio.displayName = 'Radio';
