/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import type { ComponentProps, ComponentType } from 'react';

type TProviders = [ComponentType<any>, ComponentProps<any>?][];

export function combineProviders(providers: TProviders) {
  return providers.reduce(
    (AccumulatedProviders, [Provider, props = {}]) =>
      ({ children }) => (
        <AccumulatedProviders>
          <Provider {...props}>
            <>{children}</>
          </Provider>
        </AccumulatedProviders>
      ),
    ({ children }: any) => <>{children}</>
  );
}
