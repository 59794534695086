import { ButtonSpinner } from '@/components';
import { LogoCard } from '@/features/my-coles';

import { Styled } from '../styles';

import { formatBarCode } from './utils';

import type { PaymentMethodTypes } from '@/features/bank-cards';

interface IMycolesDetailsProps {
  card?: PaymentMethodTypes.TLoyaltyCard;
  handleClickUnlink: () => void;
  isUnlinkLoading: boolean;
}

export const MycolesDetails = ({
  card,
  handleClickUnlink,
  isUnlinkLoading,
}: IMycolesDetailsProps) => {
  if (!card) {
    return null;
  }

  let num_users_used;

  const max_users = card.validation_data?.max_users_allowed || 0;
  const available_users = card.validation_data?.available_users_count || 0;

  if (!!max_users) {
    num_users_used = max_users - available_users;
  }

  return (
    <>
      <Styled.LoyaltyDetailsWrapper>
        <Styled.LoyaltyDetailsBodyContainer>
          <LogoCard size="lg" type="mycoles" />
          <Styled.LoyaltyDetailsBody>
            <Styled.LoyaltyDetailsTitle>
              mycoles discount card
            </Styled.LoyaltyDetailsTitle>
            <Styled.LoyaltyDetails>
              <Styled.LoyaltyDetailsRow>
                <Styled.LoyaltyDetailsText>
                  Card barcode
                </Styled.LoyaltyDetailsText>
                <Styled.LoyaltyDetailsValue>
                  {formatBarCode(card?.data?.bar_code)}
                </Styled.LoyaltyDetailsValue>
              </Styled.LoyaltyDetailsRow>
              {!!max_users && (
                <>
                  <Styled.LoyaltyDetailsRow>
                    <Styled.LoyaltyDetailsText>
                      Linked accounts
                    </Styled.LoyaltyDetailsText>
                    <Styled.LoyaltyDetailsValue>
                      Card is linked to {num_users_used} of {max_users}{' '}
                      available accounts
                    </Styled.LoyaltyDetailsValue>
                  </Styled.LoyaltyDetailsRow>
                </>
              )}
            </Styled.LoyaltyDetails>
          </Styled.LoyaltyDetailsBody>
        </Styled.LoyaltyDetailsBodyContainer>

        <Styled.LoyaltyDetailsActions>
          <ButtonSpinner
            disabled={isUnlinkLoading}
            level="tertiary"
            loading={isUnlinkLoading}
            onClick={handleClickUnlink}
            size="medium"
            spinnerColor={'var(--colors-on-surface-c)'}
            variant="destructive"
          >
            Unlink mycoles discount card
          </ButtonSpinner>
        </Styled.LoyaltyDetailsActions>
      </Styled.LoyaltyDetailsWrapper>
    </>
  );
};
