import ColesPayLogo from '@/assets/images/coles-pay.svg';
import { landingUrl } from '@/config/links';

import { Feature } from '../feature-flags';

export const theme = {
  brand: {
    title: 'Coles Pay',
  },
  content: {
    heading: <h1>A more rewarding way to pay</h1>,
    subHeading: (
      <h2>
        Quickly pay for goods online with flypay at participating retailers,
        collect and pay with Flybuys points on qualifying transactions at
        participating eligible partners.
      </h2>
    ),
  },
  features: [
    Feature.ACTIVITY,
    Feature.FLYBUYS,
    Feature.MYCOLES,
    Feature.ACCOUNT,
    Feature.ADDRESSES,
    Feature.WALLET,
  ],
  homePage: Feature.WALLET,
  icon: {
    inverse: ColesPayLogo,
    large: ColesPayLogo,
    small: ColesPayLogo,
  },
  urls: {
    contact: `${landingUrl}/contact`,
    faq: `${landingUrl}/faq`,
    privacy: `${landingUrl}/privacy`,
    terms: `${landingUrl}/tnc`,
  },
};
