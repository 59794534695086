import { BaasErrors, type TBaasErrorResponse } from '@/services';
import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import type { TBaasAddPaymentMethodErrors } from '../../types';

export function useAuthorizeCardError() {
  const setBankCardsDialogErrorState =
    useBankCardsStore().use.actions().setBankCardsDialogErrorState;

  return function addCardAuthorizeErrorCallback(
    error: TBaasErrorResponse<TBaasAddPaymentMethodErrors>
  ) {
    error?.details?.error_number?.toString() === BaasErrors.MAX_CARDS_LIMIT
      ? setBankCardsDialogErrorState(
          BankCardsDialogErrorStates.BANK_CARD_COUNT_LIMIT_REACHED
        )
      : setBankCardsDialogErrorState(
          BankCardsDialogErrorStates.BANK_CARD_ADD_ERROR
        );
  };
}
