import clsx from 'clsx';

import { useActivityStore } from '@/store';

import { ViewOrderStatus } from '../../enums/order-status';
import { OrderPaymentSummaryMycoles } from '../order-payment-summary-mycoles/order-summary-mycoles';
import { OrderPaymentSummaryPayments } from '../order-payment-summary-payments/order-payment-summary-payments';
import { OrderPaymentSummaryTotal } from '../order-payment-summary-total/order-payment-summary-total';

import styles from './order-payment-summary.module.css';

export function OrderPaymentSummary() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (!selectedOrder) {
    return null;
  }

  const isOrderPending = selectedOrder.orderStatus === ViewOrderStatus.PENDING;

  return (
    <div className={styles['summary-wrapper']}>
      <h4 className={styles['sub-heading']}>Payment Summary</h4>
      <OrderPaymentSummaryMycoles />
      <OrderPaymentSummaryPayments />
      <OrderPaymentSummaryTotal />
      {isOrderPending && (
        <p className={clsx(styles['text'], styles['text-xs'])}>
          *Prices shown are estimates only. While you may see a pending payment
          in your bank account, we'll only charge you the final amount once your
          order is packed.
        </p>
      )}
      <p className={styles['text']}>
        To view your full digital receipt please visit the retailer website
        under account information.
      </p>
    </div>
  );
}
