import { CardsPanelButton } from '@/components';
import { useGetGiftCards } from '@/features/gift-cards';
import { useAnalytics } from '@/hooks';
import { GiftCardsDialogErrorStates, useGiftCardsStore } from '@/store';

export function AddGiftCardButton() {
  const isAddGiftCardOpen = useGiftCardsStore().use.isAddGiftCardOpen();
  const setIsAddGiftCardOpen =
    useGiftCardsStore().use.actions().setIsAddGiftCardOpen;
  const getGiftCards = useGetGiftCards();
  const { track } = useAnalytics();

  const { setGiftCardsDialogErrorState } = useGiftCardsStore().use.actions();

  const handleAddCardOpen = () => {
    if (!isAddGiftCardOpen) {
      if (!!getGiftCards?.data?.isMaxedOutGiftCardSlots) {
        setGiftCardsDialogErrorState(
          GiftCardsDialogErrorStates.GIFT_CARD_COUNT_LIMIT_REACHED
        );
        return;
      }

      track('Add Gift Card Started');
      setIsAddGiftCardOpen(true);
    }
  };

  return (
    <CardsPanelButton
      addLabel="gift card"
      handleAddCardOpen={handleAddCardOpen}
    />
  );
}
