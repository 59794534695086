import { EditIcon } from '@/components/icons';
import { useAnalytics } from '@/hooks';
import { useBankCardsStore } from '@/store';

import styles from './edit-card-nickname-button.module.css';

export function EditCardNicknameButton() {
  const { track } = useAnalytics();
  const setIsEditingCardNickname =
    useBankCardsStore().use.actions().setIsEditingCardNickname;

  const handleEditNickname = () => {
    track('Update Card Nickname Started');
    setIsEditingCardNickname(true);
  };

  return (
    <button
      aria-label="Edit Nickname"
      className={styles['edit-card-nickname-button']}
      onClick={handleEditNickname}
    >
      <EditIcon height={24} width={24} /> Edit
    </button>
  );
}
