import { cva } from 'class-variance-authority';

import styles from './callout.module.css';

export const calloutVariants = cva(styles.callout, {
  defaultVariants: {
    size: 'sm',
    type: 'default',
  },
  variants: {
    size: {
      md: styles.md,
      sm: styles.sm,
    },
    type: {
      default: styles.default,
      error: styles.error,
      success: styles.success,
    },
  },
});
