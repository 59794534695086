const profile = {
  ADDRESS: '/v1/profiles/addresses',
  ADDRESS_BY_ID: (id: string) => `/v1/profiles/addresses/${id}`,
  PROFILE: '/v1/profiles',
  PROFILE_DELETE_ACCOUNT: (walletId?: string) =>
    `/v1/profiles/wallets/${walletId}`,
  PROFILE_EMAIL_INITIATE_CHANGE: '/v1/profiles/email/initiate-change',
  PROFILE_EMAIL_RESEND_CHANGE: '/v1/profiles/email/resend-change',
  PROFILE_EMAIL_VERIFY: '/v1/profiles/email/verify',
  PROFILE_MARKETING_UNSUBSCRIBE: '/v1/profiles/marketing/unsubscribe',
  PROFILE_MOBILE_CANCEL_CHANGE: '/v1/profiles/mobile-number/cancel-change',
  PROFILE_MOBILE_INITIATE_CHANGE: '/v1/profiles/mobile-number/initiate-change',
  PROFILE_MOBILE_RESEND_CHANGE: '/v1/profiles/mobile-number/resend-change',
  PROFILE_MOBILE_VERIFY: '/v1/profiles/mobile-number/verify',
};

const addresses = {
  ADDRESSES: '/v1/profiles/addresses',
  ADDRESS_BY_ID: (id: string) => `/v1/profiles/addresses/${id}`,
};

export const endpoints = { addresses, profile };
