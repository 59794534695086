import { useEffect } from 'react';

import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import { PaymentMethodEnums } from '../../enums';
import { use3dsCardListener } from '../../hooks/use-3ds-card-listener';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useEditCardFinalize(
  finalizeCard: BankCardsTypes.Operations.FinalizeBankCard.TMutation
) {
  const { message, setMessage } = use3dsCardListener();

  const is3dsIframeOpen = useBankCardsStore().use.is3dsIframeOpen();
  const finalizePayload = useBankCardsStore().use.finalizePayload();

  const setBankCardsDialogErrorState =
    useBankCardsStore().use.actions().setBankCardsDialogErrorState;

  const setIs3dsIframeOpen =
    useBankCardsStore().use.actions().setIs3dsIframeOpen;

  useEffect(() => {
    if (is3dsIframeOpen) {
      if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_SUCCESS) {
        if (finalizePayload) {
          finalizeCard.mutate(finalizePayload);
        } else {
          setIs3dsIframeOpen(false);
          setBankCardsDialogErrorState(
            BankCardsDialogErrorStates.BANK_CARD_EDIT_ERROR
          );
        }
        setMessage(null);
      } else if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_FAIL) {
        setIs3dsIframeOpen(false);
        setBankCardsDialogErrorState(
          BankCardsDialogErrorStates.BANK_CARD_EDIT_ERROR
        );
        setMessage(null);
      }
    }
  }, [
    finalizeCard,
    finalizePayload,
    is3dsIframeOpen,
    message,
    setBankCardsDialogErrorState,
    setIs3dsIframeOpen,
    setMessage,
  ]);
}
