import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { authClientId } from '@/services/auth';

import { walletConfigQueryKeys } from '../wallet-config-query-keys';

import { useGetWalletConfigOpenRequest } from './use-get-wallet-config-open-request';

import type { WalletConfigOpenTypes } from '../../types/wallet-config-open-types';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetWalletConfigOpen(
  options?: UseQueryOptions<WalletConfigOpenTypes.Operations.GetWalletConfigOpen.TResponse>
) {
  const auth = useAuth();

  return useQuery({
    enabled: auth.isAuthenticated && !!authClientId,
    queryFn: useGetWalletConfigOpenRequest(),
    queryKey: walletConfigQueryKeys.getWalletConfigOpen,
    ...options,
  });
}
