import { useAuth } from 'react-oidc-context';

import { request } from '@/services';

import { endpoints } from '../endpoints';

import type { WalletConfigOpenTypes } from '../../types/wallet-config-open-types';

export function useGetWalletConfigOpenRequest() {
  const auth = useAuth();

  return async function getWalletConfigOpenRequest() {
    return await request<WalletConfigOpenTypes.Operations.GetWalletConfigOpen.TResponse>(
      endpoints.WALLET_CONFIGURATION_OPEN,
      auth
    );
  };
}
