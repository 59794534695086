import type { IIcon } from './typings';

export const WalletMenuIcon = ({
  color = 'var(--colors-on-surface-b)',
}: IIcon) => {
  return (
    <svg
      fill="none"
      height="19"
      viewBox="0 0 18 19"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66341 11.6467L2.51904 10.4243C2.3119 10.0755 2.22437 9.76873 2.25645 9.50386C2.28854 9.23898 2.44661 8.96344 2.73067 8.67723C5.79157 5.88168 7.51693 4.28022 7.90673 3.87284C8.14079 3.62823 8.39913 3.39258 9.02055 3.39258C9.43484 3.39258 9.78198 3.55266 10.062 3.87284L12.6634 6.93243"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.4"
      />
      <path
        d="M14.4647 8.32031H6.32185C5.61177 8.32031 5.03613 8.89595 5.03613 9.60603V14.3203C5.03613 15.0304 5.61177 15.606 6.32185 15.606H14.4647C15.1748 15.606 15.7504 15.0304 15.7504 14.3203V9.60603C15.7504 8.89595 15.1748 8.32031 14.4647 8.32031Z"
        stroke={color}
        strokeWidth="1.4"
      />
      <path
        clipRule="evenodd"
        d="M8.79321 12.1429C8.37479 11.7245 8.37479 11.0461 8.79321 10.6277C9.21163 10.2093 9.89002 10.2093 10.3084 10.6277L10.4529 10.7721C10.4568 10.776 10.4631 10.776 10.467 10.7721L10.6115 10.6277C11.0299 10.2093 11.7083 10.2093 12.1267 10.6277C12.5451 11.0461 12.5451 11.7245 12.1267 12.1429L10.467 13.8026C10.4631 13.8065 10.4568 13.8065 10.4529 13.8026L8.79321 12.1429Z"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="1.4"
      />
    </svg>
  );
};
