import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-weight: normal;
  position: relative;
  width: 100%;
`;

export const labelCSS = css`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-half);
  margin: 0;
  text-align: left;
`;

export const Label = styled.label`
  ${labelCSS}
`;

export const baseInputCSS = css`
  background-color: var(--colors-surface-lowest);
  border: 1px solid var(--colors-on-surface-c);
  border-radius: var(--radii-md);
  box-sizing: border-box;
  color: var(--colors-on-surface-a);
  font-family: inherit;
  font-size: var(--font-sizes-16);
  height: 2.5rem;
  margin-top: var(--spaces-xs);
  padding: var(--spaces-s) var(--spaces-m);
  width: 100%;

  @media (width >= 48rem) {
    margin-bottom: var(--spaces-xs);
  }
`;

export const baseInputDisabledCSS = css`
  background-color: var(--colors-surface-low);
  border: 1px solid var(--colors-on-surface-d);
  color: var(--colors-on-surface-c);
  cursor: not-allowed;
`;

export const InputRoot = styled.div<{
  $disabled?: boolean;
  $hasError: boolean;
}>`
  ${baseInputCSS}
  align-items: center;
  display: flex;
  justify-content: space-between;

  &:hover {
    border: ${({ $hasError }) =>
      $hasError
        ? '1px solid var(--colors-error-on-lowest)'
        : '1px solid var(--colors-on-surface-b)'};
  }

  &:focus-within {
    outline: 2px solid var(--colors-focus-light);
    outline-offset: 2px;
  }

  ${(props) =>
    props.$disabled &&
    css`
      ${baseInputDisabledCSS}

      input {
        cursor: not-allowed;
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: var(--colors-error-on-lowest);
    `}
`;

const CardImageContainer = styled.div`
  width: 3rem;
`;

export const Prefix = styled.p`
  color: var(--colors-on-surface-a);
`;

export const TextInput = styled.input<{ $hasError: boolean }>`
  background-color: transparent;
  border-color: transparent;
  font-family: inherit;
  outline: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const DummyLabel = styled.div`
  ${labelCSS}
`;

const DummyInput = styled.div`
  ${baseInputCSS}
  ${baseInputDisabledCSS}
`;

const HelpText = styled.div`
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-14);
  padding-top: var(--spaces-2xs);
`;

export const Styled = {
  CardImageContainer,
  DummyInput,
  DummyLabel,
  HelpText,
  InputRoot,
  InputWrapper,
  Label,
  Prefix,
  TextInput,
};
