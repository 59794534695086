import styled from 'styled-components';

import { visuallyHidden } from '@/styles/mixins';

const Checkmark = styled.span`
  border: 2px solid var(--colors-on-surface-b);
  border-radius: var(--radii-md);
  height: 1.25rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;

  &::after {
    border: solid white;
    border-width: 0 1px 1px 0;
    content: '';
    display: none;
    height: 10px;
    left: 6px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    width: 5px;
  }
`;

const Input = styled.input.attrs({ type: 'radio' })`
  ${visuallyHidden}
`;

const Label = styled.label`
  color: var(--colors-on-surface-b);
  cursor: pointer;
  display: block;
  font-size: var(--font-sizes-16);
  padding-left: 1.75rem;
  position: relative;
  user-select: none;

  &:hover ${Checkmark} {
    background-color: var(--colors-on-surface-e);
  }

  &:focus-within ${Checkmark} {
    outline: 2px solid var(--colors-focus-light);
    outline-offset: 2px;
  }

  ${Input}:checked ~ ${Checkmark} {
    background-color: var(--colors-brand1-highest);
    border-color: var(--colors-brand1-highest);

    &::after {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);
`;

export const Styled = { Checkmark, Input, Label, Wrapper };
