import styled from 'styled-components';

const Title = styled.h1<{ $spacing: boolean }>`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-24);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-half);
  margin: ${({ $spacing }) => ($spacing ? '0 0 var(--spaces-m)' : '0')};

  &:focus {
    outline: none;
  }

  @media (width >= 48rem) {
    font-size: var(--font-sizes-32);
    margin-bottom: ${({ $spacing }) => ($spacing ? 'var(--spaces-xl)' : '0')};
  }
`;

export const Styled = {
  Title,
};
