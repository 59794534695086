import { useAuth } from 'react-oidc-context';

import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/bank-cards/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from '../endpoints';

export function useDeleteBankCardRequest(cardId: string) {
  const auth = useAuth();

  return async function deleteBankCardRequest() {
    return await request(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        method: RequestMethod.DELETE,
      },
      {
        priority: EventPriority.P3,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_DELETE,
        transaction: paymentMethodTransactionKeys.bankCardDelete,
      }
    );
  };
}
