import { FilterErrors } from '../consts/filter-form-errors';

import type { TFilters } from '../components/filters/filters';

export const validateAmount = (value?: string) => {
  // valid if empty or containing only numbers and up to 2 decimal places
  return !value || /^\d*\.?\d{1,2}$/g.test(value) || FilterErrors.AMOUNT_VALID;
};

export const validateAmountRange = (values: TFilters) => {
  if (!!values.maxAmount && !!values.minAmount) {
    return (
      Number.parseInt(values.maxAmount) >= Number.parseInt(values.minAmount) ||
      FilterErrors.AMOUNT_RANGE
    );
  }
  return true;
};
