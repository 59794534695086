import { getNameInitials } from '@/utils';

import { ProfileDetails } from '../profile-details';

import { Styled } from './styles';

import type { IUserDetails } from './top-bar';

interface IMobileDetails extends IUserDetails {
  isOpen?: boolean;
  onMobileToggleClick?: () => void;
}

export const MobileDetails = ({
  details,
  isOpen = false,
  name,
  onMobileToggleClick,
}: IMobileDetails) => {
  return (
    <Styled.TopNavDetailsWrapper
      aria-controls="mobile-side-nav"
      aria-expanded={isOpen}
      aria-label={`${getNameInitials(name)} Menu`}
      id="mobile-nav-button"
      onClick={onMobileToggleClick}
    >
      <ProfileDetails
        active={isOpen}
        avatarAlt={name}
        details={details}
        detailsColor={'brand1-on-high'}
        headerColor={'brand1-on-high'}
        isAvatarOnly={true}
        name={name}
        size="sm"
      />
      <Styled.HamburgerToggle $isOpen={isOpen}>
        <span className="bar-1" />
        <span className="bar-2" />
        <span className="bar-3" />
      </Styled.HamburgerToggle>
    </Styled.TopNavDetailsWrapper>
  );
};
