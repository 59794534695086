import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  gap: var(--spaces-m);
  padding-top: var(--spaces-l);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-2xl);
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
`;

const FormHint = styled.div`
  p {
    margin: 0;
    text-align: center;
  }
`;

const Icon = styled.div`
  text-align: center;
`;

const Message = styled.div`
  text-align: center;
`;

const Title = styled.h2`
  font-size: var(--font-sizes-24);
  margin: 0 0 var(--spaces-s);
  text-align: center;
`;

const TitleContainer = styled.div`
  text-align: center;
`;

export const Styled = {
  Actions,
  Body,
  FormFields,
  FormHint,
  Icon,
  Message,
  Title,
  TitleContainer,
};
