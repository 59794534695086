import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const Subheader = styled.p`
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-normal);
  line-height: var(--line-heights-half);
  margin: 0 0 var(--spaces-xl) 0;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-l);
  margin-top: var(--spaces-xl);
`;

export const Styled = {
  Container,
  Footer,
  Subheader,
};
