import { SideSheet } from '@/components';
import { useActivityStore } from '@/store';

import { getTotalActiveFilters } from '../../utils/get-total-active-filters';
import { FilterForm } from '../filter-form/filter-form';

import type { FilterFields } from '../../enums/filter-fields';

export type TFilters = Record<`${FilterFields}`, string | undefined>;

export function Filters() {
  const activeFilters = useActivityStore().use.activeFilters();
  const isFiltersSideSheetOpen =
    useActivityStore().use.isFiltersSideSheetOpen();

  const setIsViewFilterCalendar =
    useActivityStore().use.actions().setIsViewFilterCalendar;
  const setIsFiltersSideSheetOpen =
    useActivityStore().use.actions().setIsFiltersSideSheetOpen;

  const filterCount = getTotalActiveFilters(activeFilters);

  const handleClose = () => {
    setIsFiltersSideSheetOpen(false);
    setIsViewFilterCalendar(false);
  };

  return (
    <SideSheet
      onClose={handleClose}
      open={isFiltersSideSheetOpen}
      title={`Filters ${!!filterCount ? filterCount : ''}`}
    >
      <FilterForm />
    </SideSheet>
  );
}
