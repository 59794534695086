import React, { createContext, useContext, useState } from 'react';

import { useGetWalletConfigOpen } from '@/features/wallet-config';
import { useWalletConfiguration } from '@/hooks';

import { theme as flypayTheme } from '../config/themes/flypay';

import type { Feature } from '../config/feature-flags';
import type { ReactNode } from 'react';

export type TTheme = {
  brand: {
    title: string;
  };
  content?: {
    heading?: ReactNode;
    subHeading?: ReactNode;
  };
  features: Feature[];
  homePage: Feature;
  icon: {
    inverse: string;
    large: string;
    small: string;
  };
  urls?: {
    contact?: string;
    faq?: string;
    privacy?: string;
    terms?: string;
  };
};

export const defaultTheme: TTheme = flypayTheme;

export type TThemeContext = {
  theme: TTheme;
  updateTheme: (theme: TTheme) => void;
};

export const ThemeContext = createContext<TThemeContext | null>(null);

export const ThemeWrapper = ({
  children,
  initialTheme,
}: {
  children?: React.ReactNode;
  initialTheme?: TTheme;
}) => {
  const [theme, setTheme] = useState<TTheme>(initialTheme ?? defaultTheme);
  const { data: walletConfigData } = useGetWalletConfigOpen();
  useWalletConfiguration(walletConfigData);

  const updateTheme = (newTheme: TTheme) => {
    setTheme({ ...theme, ...newTheme });
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        updateTheme,
      }}
    >
      <div>{children}</div>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const { theme, updateTheme } = useContext(ThemeContext) as TThemeContext;

  return {
    theme,
    updateTheme,
  };
};
