import styled from 'styled-components';

const LoyaltyCardItemContainer = styled.div`
  align-items: center;
  background: var(--colors-surface-lowest);
  display: flex;
  flex-direction: row;
  gap: var(--spaces-m);
  padding: var(--spaces-xs);
`;

const LoyaltyCardItemContainerButton = styled.button<{ $selected?: boolean }>`
  align-items: center;
  background: var(--colors-surface-lowest);
  border: 1px solid
    ${(props) =>
      props.$selected
        ? 'var(--colors-brand1-low)'
        : 'var(--colors-surface-lowest)'};
  border-radius: var(--radii-lg);
  cursor: pointer;
  display: flex;
  gap: var(--spaces-m);
  padding: var(--spaces-xs);
  width: 100%;

  &:hover {
    border-color: var(--colors-brand1-low);
  }

  &:focus {
    border-color: var(--colors-brand1-low);
  }
`;

const LoyaltyCardItemImage = styled.div`
  > div {
    width: 160px;
  }
`;

const LoyaltyCardItemDetails = styled.div`
  align-items: flex-start;
  color: var(--colors-on-surface-a);
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: 2px;
  line-height: 120%;
  text-align: left;
`;

const LoyaltyCardItemTitle = styled.div`
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-bold);
`;

const LoyaltyCardItemAmount = styled.p`
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-normal);
  margin: 0;
`;

const LoyaltyCardItemPoints = styled.p`
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-normal);
  margin: 0;
`;

const LoyaltyCardPanel = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  list-style: none;
  margin: 0;
  padding: 0;

  [aria-haspopup='dialog'] {
    display: none;
  }
`;

const LoyaltyInfoWrapper = styled.div`
  h3 {
    font-size: var(--font-sizes-16);
    margin: 0 0 var(--spaces-xs);
  }

  p {
    font-size: var(--font-sizes-14);
    margin: 0 0 var(--spaces-m);
  }
`;

const LinkButton = styled.button<{ $isNonProd: boolean }>`
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--colors-on-surface-a);
  border-radius: ${({ $isNonProd }) =>
    $isNonProd ? 'var(--radii-2xl)' : 'var(--radii-md)'};
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-sizes-14);
  gap: var(--spaces-xs);
  padding: var(--spaces-s) var(--spaces-m);
  text-decoration: none;
`;

const SpinnerWrapper = styled.div`
  > div {
    align-items: flex-start;
  }
`;

const LoyaltyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: var(--spaces-xl);
  width: 100%;
`;

const LoyaltyDetails = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-l);
  list-style: none;
  padding: 0;
  width: 100%;
`;

const LoyaltyDetailsRow = styled.li`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: var(--font-weights-normal);
  gap: var(--spaces-2xs);
  line-height: 1.25;
`;

const LoyaltyDetailsText = styled.div`
  color: var(--colors-on-surface-c);
  font-size: var(--font-sizes-14);
`;

const LoyaltyDetailsValue = styled.div`
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-16);
`;

const LoyaltyDetailsBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 36px;
  padding-bottom: var(--spaces-m);
  width: 100%;
`;

const LoyaltyDetailsTitle = styled.h3`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-24);
  font-style: normal;
  font-weight: var(--font-weights-bold);
  line-height: 1.2;
  margin: 0;
  text-align: left;
`;

const LoyaltyDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-l);
  width: 100%;
`;

const LoyaltyCardWrapper = styled.div`
  align-self: flex-start;
  display: flex;
`;

const LoyaltyDetailsActions = styled.div`
  border-top: 1px solid #807f80;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  padding-top: var(--spaces-l);
`;

export const Styled = {
  LinkButton,
  LoyaltyCardItemAmount,
  LoyaltyCardItemContainer,
  LoyaltyCardItemContainerButton,
  LoyaltyCardItemDetails,
  LoyaltyCardItemImage,
  LoyaltyCardItemPoints,
  LoyaltyCardItemTitle,
  LoyaltyCardPanel,
  LoyaltyCardWrapper,
  LoyaltyDetails,
  LoyaltyDetailsActions,
  LoyaltyDetailsBody,
  LoyaltyDetailsBodyContainer,
  LoyaltyDetailsRow,
  LoyaltyDetailsText,
  LoyaltyDetailsTitle,
  LoyaltyDetailsValue,
  LoyaltyDetailsWrapper,
  LoyaltyInfoWrapper,
  SpinnerWrapper,
};
