import { useId } from 'react';

export function ColesGroupLogoIcon({ width = 400 }) {
  const id = useId();
  const maskId = useId();
  const filterId = useId();
  const firstPaintId = useId();
  const secondPaintId = useId();
  const thirdPaintId = useId();
  const fourthPaintId = useId();

  const height = `${width * (39 / 61)}`;

  return (
    <svg
      aria-labelledby={id}
      fill="none"
      height={height}
      role="img"
      viewBox="0 0 366 234"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <text id={id}>mycoles Colesgroup</text>
      <g filter={`url(#${filterId})`}>
        <rect fill="#F1F1F3" height="225" rx="20" width="356" x="5" y="3" />
      </g>
      <mask
        height="227"
        id={maskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="358"
        x="4"
        y="2"
      >
        <path
          d="M361.249 216.624C361.249 223.063 356.031 228.281 349.592 228.281H16.6564C10.2182 228.281 5 223.063 5 216.624V14.9372C5 8.49895 10.2182 3.28076 16.6564 3.28076H349.592C356.031 3.28076 361.249 8.49895 361.249 14.9372V216.624Z"
          fill="#D9D9D9"
          stroke="#00AEEF"
          strokeWidth="0.734958"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <path
          d="M361.249 216.624C361.249 223.063 356.031 228.281 349.592 228.281H16.6564C10.2182 228.281 5 223.063 5 216.624V14.9372C5 8.49895 10.2182 3.28076 16.6564 3.28076H349.592C356.031 3.28076 361.249 8.49895 361.249 14.9372V216.624Z"
          stroke="#00AEEF"
          strokeWidth="0.734958"
        />
        <path
          d="M372.979 -9.65417H-7.72925V240.232H372.979V-9.65417Z"
          fill={`url(#${firstPaintId})`}
        />
        <path
          d="M278.257 200.793C291.413 160.283 332.203 136.47 372.979 143.423V81.2745C306.083 74.7334 241.274 115.185 219.637 181.758C213.317 201.234 211.288 221.064 213.037 240.246H275.141C273.098 227.443 273.951 213.979 278.243 200.808L278.257 200.793Z"
          fill={`url(#${secondPaintId})`}
          opacity="0.75"
        />
        <path
          d="M-7.72944 240.231H372.979V191.401C261.838 191.298 156.196 125.975 110.026 17.2009C107.483 11.2184 105.16 5.17701 103.058 -0.879039C102.044 -3.80417 101.074 -6.7293 100.162 -9.66913H-7.74414V240.217L-7.72944 240.231Z"
          fill={`url(#${thirdPaintId})`}
          opacity="0.25"
        />
        <path
          d="M106.571 -9.65417C98.3399 13.7616 86.2278 36.266 70.0881 56.9036C48.4068 84.6703 21.6985 106.19 -7.72925 121.242V240.232H123.255C153.124 218.521 180.508 192.356 204.365 161.811C244.993 109.806 270.893 50.9799 282.711 -9.65417H106.571Z"
          fill={`url(#${fourthPaintId})"`}
          opacity="0.45"
        />
        <path
          d="M128.502 40.161C126.768 33.2671 120.521 28.1665 113.083 28.1665C104.308 28.1665 97.1785 35.2809 97.1785 44.0563C97.1785 52.8317 104.293 59.9461 113.083 59.9461C120.521 59.9461 126.753 54.8455 128.502 47.9516H121.8C120.3 51.2736 116.978 53.5961 113.098 53.5961C107.835 53.5961 103.558 49.3333 103.558 44.0563C103.558 38.7793 107.835 34.5166 113.098 34.5166C116.978 34.5166 120.315 36.839 121.8 40.161H128.502Z"
          fill="#ED1C24"
        />
        <path
          d="M161.722 44.0563C161.722 35.2809 154.608 28.1665 145.818 28.1665C137.028 28.1665 129.913 35.2809 129.913 44.0563C129.913 52.8317 137.028 59.9461 145.818 59.9461C154.608 59.9461 161.722 52.8317 161.722 44.0563ZM155.358 44.0563C155.358 49.3186 151.08 53.5961 145.818 53.5961C140.556 53.5961 136.278 49.3333 136.278 44.0563C136.278 38.7793 140.556 34.5166 145.818 34.5166C151.08 34.5166 155.358 38.794 155.358 44.0563Z"
          fill="#ED1C24"
        />
        <path
          d="M170.748 18.6709H163.78V59.1671H170.748V18.6709Z"
          fill="#ED1C24"
        />
        <path
          d="M202.953 45.4968C202.997 44.9824 203.027 44.4532 203.012 43.9093C203.027 32.1353 194.09 28.1665 187.916 28.1665C181.728 28.1665 172.805 32.1353 172.82 43.9093C172.805 53.6696 179.89 59.9608 187.916 59.9608C194.398 59.9608 200.278 55.845 202.277 49.1275H195.442C193.884 52.1408 191.018 53.9194 187.916 53.9194C183.712 53.9194 179.949 50.6709 179.273 45.4968H196.559H202.953ZM187.916 33.8845C190.812 33.8845 194.766 35.5308 196.148 40.1022H179.685C181.066 35.5308 185.02 33.8845 187.916 33.8845Z"
          fill="#ED1C24"
        />
        <path
          d="M216.83 59.8727C208.804 59.8727 204.188 56.0068 204.188 49.2599V49.1276H210.479V49.2599C210.759 52.8612 213.022 54.6104 217.388 54.6104C221.033 54.6104 223.282 53.1258 223.282 50.7445C223.282 48.716 221.754 47.9517 218.726 47.202L214.287 46.0702C210.979 45.2617 208.774 44.3063 207.334 43.0569C205.761 41.6898 205.026 39.9259 205.026 37.53C205.026 34.7812 206.084 32.4588 208.098 30.8125C210.156 29.1368 213.111 28.2548 216.668 28.2548C220.225 28.2548 223.429 29.1662 225.59 30.9007C227.722 32.6058 228.853 35.0164 228.853 37.8681V37.9268H222.621V37.8681C222.445 34.9723 220.313 33.4289 216.433 33.4289C213.39 33.4289 211.494 34.6342 211.494 36.5745C211.494 38.7353 213.361 39.5879 216.389 40.2787L220.152 41.1166C226.34 42.5571 229.691 44.3798 229.691 49.9361C229.691 52.9788 228.53 55.5071 226.34 57.2122C224.12 58.9614 220.828 59.8727 216.83 59.8727Z"
          fill="#ED1C24"
        />
        <path
          d="M21.272 29.6659H25.2701V34.3255C27.2104 30.5919 31.5026 28.8427 34.9863 28.8427C38.1907 28.8427 41.2187 29.8276 43.3942 32.1941C44.1879 32.9585 45.2169 34.9429 45.3933 35.2662C45.8489 34.4431 46.481 33.2966 47.6276 32.1353C49.6266 30.1068 52.6547 28.8427 56.0943 28.8427C59.2399 28.8427 62.2679 29.7247 64.3846 31.8119C67.0746 34.3843 67.8683 37.574 67.8683 42.7922V59.1965H63.6349V42.9539C63.6349 39.823 63.1205 37.0301 61.3419 35.1045C60.0777 33.5611 58.1374 32.6351 55.227 32.6351C52.5371 32.6351 49.9059 33.6787 48.4801 35.7072C46.9367 37.7945 46.6574 39.4408 46.6574 42.7922V59.1965H42.4241V42.7922C42.5417 39.4996 42.0272 37.574 40.8218 35.766C39.396 33.8404 37.2205 32.6939 34.0749 32.6351C31.2233 32.5322 28.8126 33.8992 27.5632 35.6484C26.255 37.3535 25.5053 39.6025 25.5053 42.7334V59.1965H21.272V29.6659Z"
          fill="#231F20"
        />
        <path
          d="M80.936 58.6379L69.0444 29.6511H73.5571L83.2144 53.5225L93.1657 29.6511H97.796L80.2452 70.3825H75.6737L80.936 58.6379Z"
          fill="#231F20"
        />
        <path
          d="M31.3849 206.835C30.7087 204.174 28.3128 202.205 25.4464 202.205C22.0656 202.205 19.3169 204.953 19.3169 208.334C19.3169 211.715 22.0656 214.464 25.4464 214.464C28.3128 214.464 30.7087 212.494 31.3849 209.833H28.7978C28.2246 211.112 26.9311 212.009 25.4464 212.009C23.418 212.009 21.7717 210.363 21.7717 208.334C21.7717 206.306 23.418 204.659 25.4464 204.659C26.9458 204.659 28.2246 205.556 28.7978 206.835H31.3849Z"
          fill="#ED1C24"
        />
        <path
          d="M44.188 208.334C44.188 204.953 41.4393 202.205 38.0585 202.205C34.6777 202.205 31.929 204.953 31.929 208.334C31.929 211.715 34.6777 214.464 38.0585 214.464C41.4393 214.464 44.188 211.715 44.188 208.334ZM41.7333 208.334C41.7333 210.363 40.087 212.009 38.0585 212.009C36.03 212.009 34.3837 210.363 34.3837 208.334C34.3837 206.306 36.03 204.659 38.0585 204.659C40.087 204.659 41.7333 206.306 41.7333 208.334Z"
          fill="#ED1C24"
        />
        <path
          d="M47.6716 198.559H44.9817V214.17H47.6716V198.559Z"
          fill="#ED1C24"
        />
        <path
          d="M60.0779 208.893C60.0926 208.687 60.1073 208.496 60.1073 208.275C60.1073 203.733 56.6677 202.205 54.2864 202.205C51.9052 202.205 48.4656 203.733 48.4656 208.275C48.4656 212.038 51.1849 214.464 54.2864 214.464C56.7853 214.464 59.049 212.876 59.828 210.289H57.1969C56.5942 211.45 55.4918 212.141 54.3011 212.141C52.6842 212.141 51.229 210.892 50.9791 208.893H57.6378H60.1073H60.0779ZM54.2864 204.424C55.4036 204.424 56.9323 205.056 57.4615 206.82H51.1114C51.6406 205.056 53.1693 204.424 54.2864 204.424Z"
          fill="#ED1C24"
        />
        <path
          d="M65.4284 214.434C62.3269 214.434 60.563 212.95 60.563 210.348V210.289H62.9883V210.333C63.0912 211.715 63.9732 212.391 65.6489 212.391C67.0453 212.391 67.9273 211.818 67.9273 210.892C67.9273 210.113 67.3393 209.819 66.1634 209.525L64.4583 209.099C63.1794 208.79 62.3269 208.422 61.783 207.937C61.1804 207.408 60.8864 206.732 60.8864 205.806C60.8864 204.748 61.298 203.851 62.0623 203.219C62.8561 202.572 63.9879 202.234 65.3549 202.234C66.7219 202.234 67.9567 202.587 68.7945 203.248C69.6177 203.91 70.0586 204.836 70.0586 205.938L67.6627 205.953C67.6039 204.806 66.7807 204.218 65.2814 204.218C64.1055 204.218 63.3705 204.689 63.3705 205.424C63.3705 206.262 64.0908 206.585 65.252 206.85L66.7072 207.173C69.0885 207.732 70.382 208.437 70.382 210.568C70.382 211.744 69.9411 212.715 69.0885 213.376C68.236 214.052 66.9718 214.405 65.4284 214.405"
          fill="#ED1C24"
        />
        <path
          d="M72.881 208.29C72.881 210.627 74.5273 212.906 77.5112 212.906C78.8194 212.906 79.9366 212.406 80.745 211.583C81.5388 210.745 82.0091 209.584 82.0385 208.319C82.0385 207.349 81.7152 206.217 80.9802 205.291C80.2746 204.365 79.1134 203.689 77.4671 203.689C74.9388 203.689 72.881 205.571 72.881 208.275V208.29ZM83.5672 212.729C83.5672 214.919 82.8617 216.316 81.8474 217.271C80.7156 218.388 79.0399 219.05 77.4671 219.05C74.5861 219.05 72.4841 217.403 71.7638 215.316H73.5865C73.8658 215.816 74.2774 216.375 74.9535 216.845C75.7914 217.448 76.7762 217.683 77.5112 217.683C79.7161 217.683 81.9797 215.963 81.9797 213.097V211.612C81.1713 213.244 79.5544 214.375 77.3201 214.375C73.7188 214.375 71.2935 211.627 71.2935 208.305C71.2935 204.983 73.807 202.19 77.3201 202.19C79.5985 202.19 81.3477 203.483 81.9944 204.894V202.513H83.5672V212.729Z"
          fill="#414042"
        />
        <path
          d="M113.436 214.155H111.937V211.994C111.29 213.493 109.732 214.39 107.674 214.375C104.072 214.317 102.808 211.862 102.808 209.054V202.513H104.381V208.76C104.381 211.244 105.498 212.92 108.1 212.905C110.496 212.891 111.878 211.45 111.878 209.04V202.513H113.465V214.155H113.436Z"
          fill="#414042"
        />
        <path
          d="M125.577 208.275C125.577 206.115 124.048 203.66 120.947 203.66C119.653 203.66 118.522 204.174 117.684 205.012C116.89 205.85 116.39 207.011 116.39 208.319C116.39 209.628 116.89 210.759 117.713 211.597C118.522 212.406 119.683 212.906 120.976 212.906C123.578 212.906 125.562 210.936 125.562 208.29L125.577 208.275ZM114.92 202.513H116.42V204.909C117.36 203.233 119.036 202.19 121.241 202.19C125.121 202.19 127.165 205.35 127.165 208.319C127.165 211.539 124.827 214.375 121.241 214.375C118.683 214.375 117.037 212.891 116.493 211.7V218.432H114.906V202.513H114.92Z"
          fill="#414042"
        />
        <path
          d="M95.6057 202.205C92.2396 202.205 89.5203 204.924 89.5203 208.29C89.5203 211.656 92.2396 214.375 95.6057 214.375C98.9718 214.375 101.691 211.656 101.691 208.29C101.691 204.924 98.9718 202.205 95.6057 202.205ZM95.6057 203.748C98.1046 203.748 100.148 205.791 100.148 208.29C100.148 210.789 98.1046 212.832 95.6057 212.832C93.1069 212.832 91.0637 210.789 91.0637 208.29C91.0637 205.791 93.1069 203.748 95.6057 203.748Z"
          fill="#414042"
        />
        <path
          d="M91.8577 202.205H90.2408C88.8885 202.249 87.3892 202.704 86.5513 204.409V202.528H85.052V214.17H86.6248V207.79C86.6248 205.189 87.8595 203.792 90.0644 203.777C90.5642 203.16 91.1815 202.631 91.8577 202.219V202.205Z"
          fill="#1A1A1A"
        />
        <path
          d="M83.4052 217.565C83.2141 217.8 83.0231 218.036 82.8026 218.241C82.7291 218.315 82.6409 218.374 82.5674 218.447H113.656V217.565H83.4052Z"
          fill="#414042"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="233.834"
          id={filterId}
          width="364.834"
          x="0.583073"
          y="0.0553817"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1.47231" />
          <feGaussianBlur stdDeviation="2.20846" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_4698_848"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4698_848"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={firstPaintId}
          x1="390.221"
          x2="-344.737"
          y1="51.9059"
          y2="276.303"
        >
          <stop stopColor="white" />
          <stop offset="0.07" stopColor="#F1F2F2" />
          <stop offset="0.28" stopColor="#EDEEEE" />
          <stop offset="0.47" stopColor="#E2E4E4" />
          <stop offset="0.64" stopColor="#D0D2D3" />
          <stop offset="0.81" stopColor="#B7B9BC" />
          <stop offset="0.85" stopColor="#B1B3B6" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={secondPaintId}
          x1="238.731"
          x2="335.246"
          y1="208.863"
          y2="112.334"
        >
          <stop offset="0.2" stopColor="white" />
          <stop offset="0.96" stopColor="#BCBEC0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={thirdPaintId}
          x1="55.0066"
          x2="606.372"
          y1="118.184"
          y2="300.557"
        >
          <stop stopColor="#F1F2F2" />
          <stop offset="0.81" stopColor="#414042" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={fourthPaintId}
          x1="30.9589"
          x2="482.708"
          y1="223.592"
          y2="-326.156"
        >
          <stop offset="0.09" stopColor="#E6E7E8" />
          <stop offset="0.25" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}
