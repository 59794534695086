import styled from 'styled-components';

const RangeWrapper = styled.fieldset`
  align-items: flex-start;
  border: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);
  padding: 0;
  width: 100%;
`;

const InputWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: var(--spaces-s);
  margin-bottom: var(--spaces-xs);
`;

const RangeBox = styled.div`
  align-items: center;
  background-color: var(--colors-surface-lowest);
  border: 1px solid var(--colors-on-surface-a);
  border-radius: var(--radii-lg);
  color: var(--colors-on-surface-b);
  display: flex;
  font-size: var(--font-sizes-16);
  gap: 0.25rem;
  padding: var(--spaces-s) var(--spaces-m);
  width: 100%;

  &:focus-within {
    outline: 2px solid var(--colors-focus-light);
    outline-offset: 2px;
  }
`;

const RangeInput = styled.input`
  background-color: transparent;
  border: 0;
  font-size: var(--font-sizes-16);
  line-height: var(--line-heights-half);
  padding: 0;
  width: 100%;

  &:focus {
    outline: 0;
  }
`;

const InputLabel = styled.label`
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-half);
  width: 100%;
`;

const InputSeparator = styled.div`
  background-color: var(--colors-on-surface-c);
  height: 1px;
  width: var(--spaces-s);
`;

const LabelWrapper = styled(InputWrapper)`
  gap: var(--spaces-xl);
  margin-bottom: 0;
`;

export const Styled = {
  InputLabel,
  InputSeparator,
  InputWrapper,
  LabelWrapper,
  RangeBox,
  RangeInput,
  RangeWrapper,
};
