import { type JSX, useEffect } from 'react';

import { Feature } from '@/config';
import { type TTheme, useTheme } from '@/contexts/theme';
import { useGetProfile } from '@/features/profile';
import { useAnalytics } from '@/hooks/use-analytics';
import { Account, Activity, Addresses, Wallet } from '@/pages';
import { useProfileStore } from '@/store';

const getHomePage = (theme: TTheme): JSX.Element => {
  switch (theme.homePage) {
    case Feature.ACTIVITY: {
      return <Activity />;
    }
    case Feature.WALLET: {
      return <Wallet />;
    }
    case Feature.ACCOUNT: {
      return <Account />;
    }
    case Feature.ADDRESSES: {
      return <Addresses />;
    }
    default: {
      return <Wallet />;
    }
  }
};

export const Home = () => {
  const { theme } = useTheme();
  const { identify } = useAnalytics();

  const { data: user } = useGetProfile();
  const { setProfile } = useProfileStore.use.actions();

  useEffect(() => {
    if (!!user) {
      identify(user);
      setProfile(user);
    }
  }, [identify, setProfile, user]);

  return getHomePage(theme);
};
