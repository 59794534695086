import { theme as beemTheme } from '@/config/themes/beem';
import { theme as colesPayTheme } from '@/config/themes/coles-pay';
import { theme as flypayTheme } from '@/config/themes/flypay';

export const getDefaultTheme = () => {
  const themeDefault = import.meta.env.VITE_REACT_APP_DEFAULT_THEME;
  if (themeDefault === 'colespay') {
    return colesPayTheme;
  } else if (themeDefault === 'flypay') {
    return flypayTheme;
  } else {
    return beemTheme;
  }
};
