import { CardsPanelButton } from '@/components';
import { useGetBankCards } from '@/features/bank-cards/services';
import { useAnalytics } from '@/hooks';
import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

export function AddCardButton() {
  const getBankCards = useGetBankCards();
  const { track } = useAnalytics();

  const isAddBankCardOpen = useBankCardsStore().use.isAddBankCardOpen();
  const setIsAddBankCardOpen =
    useBankCardsStore().use.actions().setIsAddBankCardOpen;

  const { setBankCardsDialogErrorState } = useBankCardsStore().use.actions();

  const handleAddCardOpen = () => {
    if (!isAddBankCardOpen) {
      if (!!getBankCards?.data?.isMaxedOutBankCardSlots) {
        setBankCardsDialogErrorState(
          BankCardsDialogErrorStates.BANK_CARD_COUNT_LIMIT_REACHED
        );
        return;
      }

      track('Add Card Started');
      setIsAddBankCardOpen(true);
    }
  };

  return (
    <CardsPanelButton
      addLabel="payment card"
      handleAddCardOpen={handleAddCardOpen}
    />
  );
}
