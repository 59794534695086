import { useBankCardsStore } from '@/store';

export function useViewCardReset() {
  const {
    setIs3dsIframeOpen,
    setIsEditingCard,
    setIsEditingCardNickname,
    setIsViewBankCardDetailsOpen,
  } = useBankCardsStore().use.actions();

  return function onViewCardClose() {
    setIs3dsIframeOpen(false);
    setIsViewBankCardDetailsOpen(false);
    setIsEditingCardNickname(false);
    setIsEditingCard(false);
  };
}
