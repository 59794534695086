import { format } from 'date-fns';

import { DateFormats } from '@/config/language';
import { V2QrEnums } from '@/services';

import { ViewOrderStatus } from '../enums/order-status';

import type { OrderTypes } from '../types';
import type { V2QrTypes } from '@/services';

export const orderGet = {
  merchantDetails(
    merchant?: V2QrTypes.TMerchantResponse
  ): Pick<OrderTypes.TOrderInfo, 'merchantId' | 'merchantName'> | undefined {
    if (!merchant) {
      return;
    }
    return {
      merchantId: merchant.id,
      merchantName: merchant.name,
    };
  },

  merchantLocation(
    location?: V2QrTypes.TOpapiAddress
  ): OrderTypes.TAddress | undefined {
    if (!location) {
      return;
    }
    const { address_line_1, address_line_2, city, postcode, state } = location;
    // remove undefined values and join the remainders
    const line3 = [city, state, postcode].filter(Boolean).join(' ');

    return {
      line1: address_line_1,
      line2: address_line_2,
      line3,
    };
  },

  orderAmount(
    payments?: OrderTypes.TPaymentInfo[],
    status?: `${V2QrEnums.OrderStatusV2}`
  ): number {
    if (!payments || status === V2QrEnums.OrderStatusV2.ABORTED) {
      return 0;
    }
    return payments.reduce((total, payment) => {
      if (payment.hasRefund) {
        return total - payment.amount;
      } else {
        return total + payment.amount;
      }
    }, 0);
  },

  orderDate(date?: string): OrderTypes.TOrderInfoCreatedAt {
    if (!date) {
      return {
        dayMonthTime: '',
        dayMonthYear: '',
        iso: '',
      };
    }
    return {
      dayMonthTime: format(date, DateFormats.DAY_MONTH_TIME),
      dayMonthYear: format(date, DateFormats.DAY_MONTH_YEAR),
      iso: date,
    };
  },

  orderStatus(
    status?: `${V2QrEnums.OrderStatusV2}`,
    amount?: number,
    hasRefund?: boolean
  ): ViewOrderStatus {
    if (status === V2QrEnums.OrderStatusV2.ABORTED) {
      return ViewOrderStatus.CANCELLED;
    }

    if (status === V2QrEnums.OrderStatusV2.FINALIZED && amount !== undefined) {
      return !hasRefund
        ? ViewOrderStatus.PAID
        : amount <= 0
          ? ViewOrderStatus.REFUNDED
          : ViewOrderStatus.PARTIALLY_REFUNDED;
    }
    return ViewOrderStatus.PENDING;
  },
};
