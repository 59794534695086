import styled from 'styled-components';

const Iframe = styled.iframe`
  border: 0;
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const LoadingWrapper = styled.div`
  background-color: var(--colors-surface-lowest);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
`;

export const Styled = {
  Iframe,
  LoadingWrapper,
};
