import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ButtonSpinner,
  Divider,
  ErrorDialog,
  Form,
  FormHint,
  SuccessDialog,
  TextField,
} from '@/components';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_MYCOLES_LINKING,
  ERROR_MYCOLES_LINKING_ALREADY_ADDED,
  ERROR_MYCOLES_LINKING_INVALID,
  ERROR_TITLE_MYCOLES_LINKING,
  ERROR_TITLE_MYCOLES_LINKING_ALREADY_ADDED,
  ERROR_TITLE_MYCOLES_LINKING_INVALID,
} from '@/config/language';
import { PaymentMethodEnums } from '@/features/bank-cards';
import { useAddLoyaltyCard } from '@/features/loyalty/services';
import { useAnalytics } from '@/hooks';
import { formatMycolesBarcode, validate } from '@/utils';

import {
  LOYALTY_ERRORS,
  LOYALTY_PROGRAM_ID_COLES_TEAM_MEMBERS,
  LOYALTY_SCHEME_NAMES,
} from '../../constants';

import { Styled } from './styles';

import type { TUseAddLoyaltyCardRequest } from '@/features/loyalty/services';
import type { TBaasError } from '@/services';
import type { FieldValues } from 'react-hook-form';

const InputBarcode = 'barcode';

const validationSchema = Joi.object({
  [InputBarcode]: validate.mycolesBarcode(),
});

const getPayload = (barCodeWithSpaces: string): TUseAddLoyaltyCardRequest => {
  const bar_code = barCodeWithSpaces.replaceAll(' ', '');
  return {
    data: {
      bar_code,
      bar_code_format: PaymentMethodEnums.BarcodeDisplayFormat.EAN_13,
    },
    program: {
      id: LOYALTY_PROGRAM_ID_COLES_TEAM_MEMBERS,
      type: PaymentMethodEnums.CardProgramType.KNOWN as const,
    },
  };
};

interface IMycolesAddCard {
  handleCloseAddCard: () => void;
}

export const MycolesAddCard = ({ handleCloseAddCard }: IMycolesAddCard) => {
  const { track } = useAnalytics();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isErrorInvalidDialogOpen, setIsErrorInvalidDialogOpen] =
    useState(false);
  const [isErrorOverLimitDialogOpen, setIsErrorOverLimitDialogOpen] =
    useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);

  const onAddCardError = (error: TBaasError) => {
    setIsAddLoading(false);
    const error_code = error.response?.data?.details?.error_number || 0;
    if (
      error_code.toString() ===
      LOYALTY_ERRORS.LOYALTY_CARD_FORBIDDEN_OVER_LIMIT_CREATE
    ) {
      setIsErrorOverLimitDialogOpen(true);
    } else if (
      error_code.toString() ===
      LOYALTY_ERRORS.LOYALTY_CARD_FORBIDDEN_ERROR_CREATE
    ) {
      setIsErrorInvalidDialogOpen(true);
    } else {
      setIsErrorDialogOpen(true);
    }
  };

  const onAddCardSuccess = () => {
    track('Link Loyalty Confirmed', {
      scheme: LOYALTY_SCHEME_NAMES.TEAM_MEMBER_CARD,
    });
    setIsAddLoading(false);
    setIsSuccessDialogOpen(true);
  };

  const addLoyaltyCard = useAddLoyaltyCard({
    onError: onAddCardError,
    onSuccess: onAddCardSuccess,
  });

  const DEFAULT_FORM_VALUES = {
    [InputBarcode]: '',
  } as FieldValues;

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const value = (e.target as HTMLInputElement).value;
    formMethods.setValue(InputBarcode, formatMycolesBarcode(value));
  };

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  const onSubmitForm = async ({ barcode }: FieldValues) => {
    const payload = getPayload(barcode);
    setIsAddLoading(true);
    addLoyaltyCard.mutate(payload);
  };

  const onCloseSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
    handleCloseAddCard();
    formMethods.reset();
  };

  return (
    <>
      <Form
        config={formMethods}
        onSubmitForm={onSubmitForm}
        styles={{ width: '100%' }}
      >
        <div>
          <FormHint />
          <TextField
            helpText="13 digits"
            label="Card barcode*"
            maxLength={15}
            name={InputBarcode}
            onKeyUp={onKeyUp}
            placeholder="• •••••• ••••••"
            required
          />
        </div>
        <Styled.Footer>
          <Divider />
          <ButtonSpinner
            level="primary"
            loading={isAddLoading}
            size="medium"
            spinnerColor={'var(--colors-brand1-on-high)'}
            type="submit"
            variant="branded"
          >
            Save
          </ButtonSpinner>
        </Styled.Footer>
      </Form>

      <SuccessDialog
        buttonText="Done"
        isOpen={isSuccessDialogOpen}
        onOpenChange={onCloseSuccessDialog}
        title={'mycoles discount card linked'}
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isErrorDialogOpen}
        message={ERROR_MYCOLES_LINKING}
        onOpenChange={() => setIsErrorDialogOpen(false)}
        title={ERROR_TITLE_MYCOLES_LINKING}
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isErrorInvalidDialogOpen}
        message={ERROR_MYCOLES_LINKING_INVALID}
        onOpenChange={() => setIsErrorInvalidDialogOpen(false)}
        title={ERROR_TITLE_MYCOLES_LINKING_INVALID}
      />

      <ErrorDialog
        buttonText={'OK'}
        isOpen={isErrorOverLimitDialogOpen}
        message={ERROR_MYCOLES_LINKING_ALREADY_ADDED}
        onOpenChange={() => setIsErrorOverLimitDialogOpen(false)}
        title={ERROR_TITLE_MYCOLES_LINKING_ALREADY_ADDED}
      />
    </>
  );
};
