import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (width >= 30rem) {
    width: auto;
  }
`;

const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: flex-start;
  width: 100%;
`;

const Title = styled.h1`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-24);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-half);
  margin: 0 0 var(--spaces-m);

  @media (width >= 48rem) {
    font-size: var(--font-sizes-32);
    margin-bottom: var(--spaces-xl);
  }
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
`;

export const Styled = {
  Container,
  ContentWrapper,
  Title,
  TitleWrapper,
};
