import PaymentIcon from '@/assets/images/payment-icon.svg';
import { CardsPanel, CardsPanelContentListItem, SideSheet } from '@/components';
import { useTheme } from '@/contexts/theme';
import { useBankCardsStore } from '@/store';

import { useGetBankCards } from '../../services';
import { AddCard } from '../add-card/add-card';
import { AddCardButton } from '../add-card-button/add-card-button';
import { BankCardsDialogError } from '../bank-cards-dialog-error/bank-cards-dialog-error';
import { BankCardsDialogSuccess } from '../bank-cards-dialog-success/bank-cards-dialog-success';
import { PaymentCardListItem } from '../payment-card-list-item/payment-card-list-item';
import { ViewBankCard } from '../view-bank-card/view-bank-card';

import { useBankCardsEvents } from './use-bank-cards-events';
import { useViewBankCardSideSheetRefocusState } from './use-view-bank-card-sidesheet-refocus-state';
import { useViewBankCardSideSheetTitle } from './use-view-bank-card-sidesheet-title';

export function BankCards() {
  const getBankCards = useGetBankCards();

  const isAddBankCardOpen = useBankCardsStore().use.isAddBankCardOpen();
  const isViewBankCardDetailsOpen =
    useBankCardsStore().use.isViewBankCardDetailsOpen();
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();

  const {
    handleAddBankCardClose,
    handleAddBankCardOpen,
    handleSetSelectedBankCard,
    handleViewBankCardClose,
  } = useBankCardsEvents();

  const viewCardDetailsSideSheetTitle = useViewBankCardSideSheetTitle();
  const refocusViewCardDetailsSidesheetState =
    useViewBankCardSideSheetRefocusState();

  const { theme } = useTheme();

  return (
    <>
      <CardsPanel
        addCardButton={<AddCardButton />}
        panelEmpty={{
          addBtnText: 'Add Payment card',
          description:
            'Pay instore with QR or checkout online using your payment cards',
          icon: PaymentIcon,
          onClickAdd: handleAddBankCardOpen,
          title: 'Shop in store or online',
        }}
        panelError={{
          description: `${theme?.brand?.title} payment cards`,
          title: 'Payment cards',
        }}
        query={getBankCards}
        title={'My Cards'}
      >
        {!!getBankCards?.data?.cards?.length &&
          getBankCards?.data?.cards?.map((card) => (
            <CardsPanelContentListItem key={card.id}>
              <PaymentCardListItem
                card={card}
                isSelected={
                  isViewBankCardDetailsOpen && card.id === selectedBankCard?.id
                }
                onClick={handleSetSelectedBankCard(card)}
              />
            </CardsPanelContentListItem>
          ))}
      </CardsPanel>

      {!isViewBankCardDetailsOpen && (
        <SideSheet
          onClose={handleAddBankCardClose}
          open={isAddBankCardOpen}
          title="Add a debit or credit card"
        >
          <AddCard />
        </SideSheet>
      )}

      <SideSheet
        onClose={handleViewBankCardClose}
        open={isViewBankCardDetailsOpen}
        refocusState={refocusViewCardDetailsSidesheetState}
        title={viewCardDetailsSideSheetTitle}
      >
        {!!selectedBankCard && <ViewBankCard />}
      </SideSheet>

      <BankCardsDialogError />
      <BankCardsDialogSuccess />
    </>
  );
}
