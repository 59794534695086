import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { paymentMethodQueryKeys } from '../query-keys';

import { useGetPublicKeyRequest } from './use-get-public-key-request';

import type { PaymentMethodTypes } from '../../types';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetPublicKey(
  options?: Partial<UseQueryOptions<PaymentMethodTypes.TPublicKey>>
) {
  const auth = useAuth();

  return useQuery({
    enabled: auth.isAuthenticated,
    queryFn: useGetPublicKeyRequest(),
    queryKey: paymentMethodQueryKeys.cardsPublickey,
    ...options,
  });
}
