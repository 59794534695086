import { PillVariants } from '@/components/pill/pill';

import { BankCardsEnums } from '../../enums/bank-cards-enums';

import type { BankCardsTypes } from '../../types/bank-cards-types';

const pillVariants = {
  [BankCardsEnums.BankCardStatus.VERIFIED]: PillVariants.BRAND1,
  [BankCardsEnums.BankCardStatus.INVALID]: PillVariants.ERROR,
  [BankCardsEnums.BankCardStatus.EXPIRED]: PillVariants.ERROR,
  [BankCardsEnums.BankCardStatus.VALIDATED]: PillVariants.ERROR,
};

export function getCardStatusPillVariant(
  status: BankCardsTypes.Components.TBankCardStatus,
  isDefault: boolean
): PillVariants {
  if (status === BankCardsEnums.BankCardStatus.EXPIRED) {
    return PillVariants.ERROR;
  } else if (isDefault) {
    return PillVariants.GENERIC;
  }

  return status ? pillVariants[`${status}`] : PillVariants.ERROR;
}
