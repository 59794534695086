import { GiftCardsDialogSuccessStates, useGiftCardsStore } from '@/store';

export function useGiftCardsDialogSuccessReset() {
  const { setCurrentGiftCard, setGiftCardsDialogSuccessState } =
    useGiftCardsStore().use.actions();

  return function handleOpenChangeGiftCardsDialogSuccess() {
    setGiftCardsDialogSuccessState(GiftCardsDialogSuccessStates.NULL);
    setCurrentGiftCard(undefined);
  };
}
