import {
  BankCardsDialogErrorStates,
  useBankCardsStore,
  useSetA11yActions,
} from '@/store';

export function useBankCardsDialogErrorReset() {
  const bankCardsDialogErrorState =
    useBankCardsStore().getState().bankCardsDialogErrorState;

  const { setA11yAnnouncement } = useSetA11yActions();

  const setBankCardsDialogErrorState =
    useBankCardsStore().getState().actions.setBankCardsDialogErrorState;

  const setIsOpenDeleteDialog =
    useBankCardsStore().use.actions().setIsOpenDeleteDialog;

  const setIsAddBankCardPending =
    useBankCardsStore().use.actions().setIsAddBankCardPending;

  return function onOpenChange() {
    if (bankCardsDialogErrorState !== BankCardsDialogErrorStates.NULL) {
      // If already open, we will be closing this dialog
      setA11yAnnouncement('Dialog closed');
    }

    setBankCardsDialogErrorState(BankCardsDialogErrorStates.NULL);
    setIsOpenDeleteDialog(false);
    setIsAddBankCardPending(false);
  };
}
