import { V2QrEnums } from '@/services';
import { useActivityStore } from '@/store';

import styles from './order-details-merchant-location.module.css';

export function OrderDetailsMerchantLocation() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (
    !selectedOrder ||
    selectedOrder?.paymentContext !== V2QrEnums.PaymentContext.IN_STORE
  ) {
    return null;
  }

  const { line1, line2, line3 } = selectedOrder.merchantLocation || {};

  return (
    <div className={styles['details-section']}>
      <p className={styles['text']}>
        <strong>Store</strong>
      </p>
      <p className={styles['text']}>
        {!!line1 && (
          <>
            {line1}
            <br />
          </>
        )}
        {!!line2 && (
          <>
            {line2}
            <br />
          </>
        )}
        {!!line3 && line3}
      </p>
    </div>
  );
}
