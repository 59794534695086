import React, { useState } from 'react';
import { FormProvider as HookFormProvider } from 'react-hook-form';

import type { CSSProperties, ForwardedRef, ReactNode } from 'react';
import type {
  FieldValues,
  FormProviderProps,
  UseFormReturn,
} from 'react-hook-form';

type TFormProps<Values extends FieldValues> = {
  children: ReactNode;
  config: UseFormReturn<Values>;
  isPreventSubmit?: boolean;
  onSubmitForm?: (
    values: Partial<Values>,
    e?: React.FormEvent<HTMLFormElement>
  ) => Promise<Partial<IOnSubmitResult> | void> | void;
  styles?: CSSProperties;
};

interface IOnSubmitResult {
  [prop: string]: unknown;
  FORM_ERROR?: string;
}

type TFormProviderProps<T extends FieldValues> = FormProviderProps<T> & {
  submissionError?: string | null;
};

export const FORM_ERROR = 'FORM_ERROR';

function FormProvider<T extends FieldValues>({
  children,
  ...props
}: TFormProviderProps<T>) {
  return <HookFormProvider {...props}>{children}</HookFormProvider>;
}

function FormComponent<T extends FieldValues = FieldValues>(
  {
    children,
    config,
    isPreventSubmit = false,
    onSubmitForm,
    styles,
  }: TFormProps<T>,
  ref: ForwardedRef<HTMLFormElement>
) {
  const [formSubmissionError, setFormSubmissionError] = useState<string | null>(
    null
  );

  const submitForm = async (values: Partial<T>) => {
    const result = (await onSubmitForm?.(values)) || {};

    if (result.FORM_ERROR) {
      setFormSubmissionError(result.FORM_ERROR);
    }
  };

  return (
    <FormProvider<T> {...config} submissionError={formSubmissionError}>
      <form
        noValidate
        onSubmit={
          !isPreventSubmit
            ? config.handleSubmit(submitForm)
            : (e) => e.preventDefault()
        }
        ref={ref}
        style={styles}
      >
        {children}
      </form>
    </FormProvider>
  );
}

// Allows for typed Form
export const Form = React.forwardRef(FormComponent) as <T extends FieldValues>(
  props: TFormProps<T> & { ref?: ForwardedRef<HTMLUListElement> }
) => ReturnType<typeof FormProvider>;
