import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { Button, ProfileDetails } from '@/components';
import { EditIcon } from '@/components/icons';
import { getUserFullName, useGetProfile } from '@/features/profile';
import { profileQueryKeys } from '@/features/profile/services/profile-query-keys';
import { useAnalytics } from '@/hooks';
import { useHideInProduction } from '@/hooks';

import { Styled } from './styles';
import { UpdateProfileSheet } from './update-profile-sheet';

export const ProfileSettings = () => {
  const queryClient = useQueryClient();
  const hideInProduction = useHideInProduction();
  const { data: user } = useGetProfile();
  const { track } = useAnalytics();

  const [isOpen, setIsOpen] = useState(false);
  const handleCloseDialog = () => {
    setIsOpen(false);
  };
  const handleOpenDialog = () => {
    track('Edit Profile Started');
    setIsOpen(true);
  };
  const reload = async () => {
    await queryClient.invalidateQueries({
      queryKey: profileQueryKeys.profile,
    });
    handleCloseDialog();
  };

  const avatarStyle = {
    background: `var(--colors-${hideInProduction ? 'brand1-lowest' : 'on-surface-inverse'})`,
    color: `var(--colors-${hideInProduction ? 'brand1-on-lowest' : 'on-surface-b'})`,
  };

  return (
    <Styled.Panel>
      <Styled.PanelHeader>
        <Styled.PanelTitle>Profile</Styled.PanelTitle>
        <Button
          aria-label="Edit profile"
          level="secondary"
          onClick={handleOpenDialog}
          variant={hideInProduction ? 'branded' : 'neutral'}
        >
          <EditIcon height={16} width={16} />
          <Styled.ButtonIconSpacer />
          Edit
        </Button>
      </Styled.PanelHeader>
      <Styled.PanelContent>
        <ProfileDetails
          avatarLeft
          avatarStyle={avatarStyle}
          headerColor="on-surface-b"
          name={getUserFullName(user)}
          size="lg"
        />
      </Styled.PanelContent>
      <UpdateProfileSheet
        onClose={handleCloseDialog}
        open={isOpen}
        reload={reload}
        user={user}
      />
    </Styled.Panel>
  );
};
