import { SideNav } from '@/components';
import {
  ActivityMenuIcon,
  AddressMenuIcon,
  HelpMenuIcon,
  ProfileMenuIcon,
  WalletMenuIcon,
} from '@/components/icons';
import { Feature } from '@/config/feature-flags';
import { useTheme } from '@/contexts/theme';
import { Routes } from '@/routes/constants';

type TWalletSideNav = {
  onLogout?: () => void;
};

export const WalletSideNav = ({ onLogout }: TWalletSideNav) => {
  const { theme } = useTheme();

  const getHomePage = (): string => {
    switch (theme.homePage) {
      case Feature.ACTIVITY: {
        return Routes.ACTIVITY;
      }
      case Feature.WALLET: {
        return Routes.WALLET;
      }
      case Feature.ACCOUNT: {
        return Routes.ACCOUNT;
      }
      case Feature.ADDRESSES: {
        return Routes.ADDRESSES;
      }
      default: {
        return Routes.ACTIVITY;
      }
    }
  };

  const sideNavItems = [
    {
      icon: <WalletMenuIcon />,
      isHomePage: Routes.WALLET === getHomePage(),
      name: 'Wallet',
      path: Routes.WALLET,
    },
    {
      icon: <ActivityMenuIcon />,
      isHomePage: Routes.ACTIVITY === getHomePage(),
      name: 'Activity',
      path: Routes.ACTIVITY,
    },
    {
      icon: <ProfileMenuIcon />,
      isHomePage: Routes.ACCOUNT === getHomePage(),
      name: 'Account',
      path: Routes.ACCOUNT,
    },
    {
      icon: <AddressMenuIcon />,
      isHomePage: Routes.ADDRESSES === getHomePage(),
      name: 'Addresses',
      path: Routes.ADDRESSES,
    },
  ];
  const sideNavFooterItems = [
    ...(!!theme.urls?.contact
      ? [
          {
            icon: <HelpMenuIcon />,
            isHomePage: false,
            name: 'Need help?',
            newTab: true,
            url: theme.urls?.contact,
          },
        ]
      : []),
  ];
  return (
    <SideNav
      footerItems={sideNavFooterItems}
      items={sideNavItems}
      onLogout={onLogout}
    />
  );
};
