import { useContext } from 'react';

import { createStoreSelectors } from '../create-selectors';

import { BankCardsStoreContext } from './bank-cards-store-context';

export function useBankCardsStore() {
  const store = useContext(BankCardsStoreContext);

  if (!store) {
    throw new Error('Missing BankCardsStoreProvider');
  }

  return createStoreSelectors(store);
}
