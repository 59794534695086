import { formatCurrency } from '@/utils/format/currency';

import { PaymentSummaryHeader } from '../order-payment-summary-header/order-payment-summary-header';
import { OrderTransaction } from '../order-transaction/order-transaction';

import styles from './order-payment.module.css';

import type { OrderTypes } from '../../types';

type TOrderPaymentSummaryPayment = {
  payment: OrderTypes.TPaymentInfo;
};

export function OrderPayment({ payment }: TOrderPaymentSummaryPayment) {
  return (
    <li className={styles['payment-group']}>
      <div className={styles['summary-row']}>
        <PaymentSummaryHeader payment={payment} />
        <p className={styles['text']}>
          <strong>{formatCurrency(payment.amount, payment.hasRefund)}</strong>
        </p>
      </div>
      <div className={styles['summary-group']}>
        {payment.transactions.map((transaction) => (
          <OrderTransaction
            hasRefund={payment.hasRefund}
            key={transaction.transaction_id}
            transaction={transaction}
          />
        ))}
      </div>
    </li>
  );
}
