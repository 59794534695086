import { sortGiftCardPrograms } from './sort-gift-card-programs';

import type { GiftCardTypes } from '../../types/gift-card-types';

export const select = {
  sortGiftCardPrograms(
    response: GiftCardTypes.Operations.GetGiftCardPrograms.TResponse
  ) {
    const programs = response.programs.sort(sortGiftCardPrograms);

    return {
      ...response,
      programs,
    };
  },
};
