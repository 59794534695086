import { giftCardsSlots } from './gift-cards-slots';
import { transformGiftCard } from './transform-gift-card';

import type { GiftCardTypes } from '../../types/gift-card-types';

export function transformGiftCards(
  response: GiftCardTypes.Operations.GetGiftCards.TResponse
): GiftCardTypes.Operations.GetGiftCards.TResponse {
  return {
    ...response,
    ...giftCardsSlots.addGiftCardsSlotAttributes(response),
    cards: response.cards.map((card: GiftCardTypes.Components.TGiftCard) =>
      transformGiftCard(card)
    ),
  };
}
