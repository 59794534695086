import styled from 'styled-components';

const TopBar = styled.div`
  align-items: center;
  background-color: var(--colors-brand1-highest);
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: var(--spaces-m) var(--spaces-2xl);
  width: 100%;

  img {
    height: 1.5rem;
  }
`;

export const Styled = {
  TopBar,
};
