import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { CardInput, Checkbox, FormHint, TextField } from '@/components';
import { CardInputFieldNames, CardInputLabels } from '@/components/card-input';
import { MAX_CHARS_CARD_NICKNAME } from '@/features/profile';
import { useBankCardsStore } from '@/store';

import styles from './add-card-form-body.module.css';

export function AddCardFormBody() {
  const formContext = useFormContext();

  const is3dsIframeOpen = useBankCardsStore().use.is3dsIframeOpen();
  const isAddBankCardOpen = useBankCardsStore().use.isAddBankCardOpen();
  const isAddBankCardPending = useBankCardsStore().use.isAddBankCardPending();

  const formContainerStyles = clsx(
    styles['add-card-form-body-form-container'],
    is3dsIframeOpen && styles['hidden']
  );

  return (
    <div className={formContainerStyles}>
      <FormHint />

      <div className={styles['add-card-form-body-nickname']}>
        <TextField
          disabled={isAddBankCardPending}
          helpText="Give this card a nickname (optional)"
          label={CardInputLabels.CARD_ALIAS}
          maxLength={MAX_CHARS_CARD_NICKNAME}
          name={CardInputFieldNames.CARD_ALIAS}
        />
      </div>

      <CardInput
        isAddCardOpen={isAddBankCardOpen}
        isSubmitting={isAddBankCardPending}
      />

      <Checkbox
        disabled={isAddBankCardPending}
        id="cardIsDefault"
        label="Set as default payment card"
        {...formContext.register(CardInputFieldNames.CARD_IS_DEFAULT)}
      />
    </div>
  );
}
