import { isBefore, parseISO } from 'date-fns';

import { GiftCardEnums } from '../../enums/gift-card-enums';

import type { GiftCardTypes } from '../../types/gift-card-types';

type TChunkedCards = {
  active: GiftCardTypes.Components.TGiftCards;
  expired: GiftCardTypes.Components.TGiftCards;
  invalid: GiftCardTypes.Components.TGiftCards;
  used: GiftCardTypes.Components.TGiftCards;
};

export function sortGiftCards(
  giftCards: GiftCardTypes.Components.TGiftCards
): GiftCardTypes.Components.TGiftCards {
  const chunkedCards = chunkCards(giftCards);

  return [
    ...chunkedCards.active.sort(sortActiveGiftCards),
    ...chunkedCards.expired.sort(sortExpiredGiftCards),
    ...chunkedCards.used,
    ...chunkedCards.invalid,
  ];
}

function chunkCards(cards: GiftCardTypes.Components.TGiftCards): TChunkedCards {
  return {
    active: cards.filter(
      (card) =>
        card.status === GiftCardEnums.GiftCardStatus.ACTIVE &&
        card.live_data?.current_balance !== 0
    ),
    expired: cards.filter(
      (card) => card.status === GiftCardEnums.GiftCardStatus.EXPIRED
    ),
    invalid: cards.filter(
      (card) =>
        card.status === GiftCardEnums.GiftCardStatus.INVALID ||
        card.status === GiftCardEnums.GiftCardStatus.DELETED
    ),
    used: cards.filter(
      (card) =>
        card.status === GiftCardEnums.GiftCardStatus.USED ||
        // Expired cards are already covered in the expired section
        (card.status !== GiftCardEnums.GiftCardStatus.EXPIRED &&
          card.live_data?.current_balance === 0)
    ),
  };
}

// Sort expired gift cards by balance, largest to smallest
function sortExpiredGiftCards(
  a: GiftCardTypes.Components.TGiftCard,
  b: GiftCardTypes.Components.TGiftCard
) {
  const { live_data: aData } = a;
  const { live_data: bData } = b;

  if (
    aData?.current_balance !== undefined &&
    bData?.current_balance !== undefined
  ) {
    return bData?.current_balance - aData?.current_balance;
  }

  return 0;
}

// Sort active gift cards by expiry date, sooner to later
function sortActiveGiftCards(
  a: GiftCardTypes.Components.TGiftCard,
  b: GiftCardTypes.Components.TGiftCard
) {
  if (!!a.expires?.isoDate && !!b.expires?.isoDate) {
    return isBefore(parseISO(a.expires.isoDate), parseISO(b.expires.isoDate))
      ? -1
      : 1;
  }
  return 0;
}
