import { GiftCardEnums } from '../../enums/gift-card-enums';

import type { GiftCardTypes } from '../../types/gift-card-types';

export const giftCardStatus = {
  addGiftCardStatuses(card: GiftCardTypes.Components.TGiftCard) {
    return {
      ...card,
      isExpired: giftCardStatus.isExpired(card),
      isInvalid: giftCardStatus.isInvalid(card),
      isUsed: giftCardStatus.isUsed(card),
    };
  },

  isExpired(card: GiftCardTypes.Components.TGiftCard) {
    return card.status === GiftCardEnums.GiftCardStatus.EXPIRED;
  },

  isInvalid(card: GiftCardTypes.Components.TGiftCard) {
    return (
      card.status === GiftCardEnums.GiftCardStatus.INVALID ||
      card.status === GiftCardEnums.GiftCardStatus.DELETED
    );
  },

  isUsed(card: GiftCardTypes.Components.TGiftCard) {
    return card.live_data?.current_balance === 0;
  },
};
