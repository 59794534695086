import styled, { css } from 'styled-components';

const textStyles = css`
  color: var(--colors-on-surface-a);
  font-family: var(--fonts-sans);
  line-height: var(--line-heights-half);
`;

const LayoutWrapper = styled.div`
  align-items: center;
  background-color: var(--colors-surface-low);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  [aria-haspopup='dialog'] {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  padding-top: 7dvh;
`;

const Title = styled.h1`
  ${textStyles}
  font-size: 2.25rem;
  font-weight: var(--font-weights-bold);
  margin: 0 0 var(--spaces-m);
  text-align: center;
`;

const Subheader = styled.p`
  ${textStyles}
  font-size: var(--font-sizes-20);
  font-weight: var(--font-weights-bold);
  margin: 0 0 var(--spaces-2xl);
  text-align: center;
`;

const Panel = styled.div`
  align-items: flex-start;
  background-color: var(--colors-surface-lowest);
  border-radius: var(--radii-sm);
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 2%);
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xl);
  margin: 0 auto;
  max-width: 580px;
`;

const PanelHeader = styled.h2`
  ${textStyles}
  font-size: var(--font-sizes-20);
  font-weight: var(--font-weights-bold);
  margin: 0;
`;

const FooterBar = styled.div`
  align-items: center;
  background: var(--colors-surface-high);
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  justify-content: center;
  left: 0;
  padding: var(--spaces-l) var(--spaces-m);
  position: absolute;
  right: 0;
  width: 100%;

  @media (width >= 30rem) {
    flex-direction: row;
    gap: var(--spaces-xl);
  }

  @media (width >= 48rem) {
    gap: var(--spaces-2xl);
  }
`;

const FooterLink = styled.a`
  ${textStyles}
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-normal);
  text-decoration: underline;

  @media (width >= 48rem) {
    font-size: var(--font-sizes-16);
  }
`;

export const Styled = {
  ContentWrapper,
  FooterBar,
  FooterLink,
  LayoutWrapper,
  Panel,
  PanelHeader,
  Subheader,
  Title,
};
