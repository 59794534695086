import { useCallback, useRef, useState } from 'react';

import { ConfirmationDialog, ErrorDialog, SideSheet } from '@/components';
import { Breakpoints } from '@/config/breakpoints';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_FLYBUYS_UNLINKING,
  ERROR_TITLE_FLYBUYS_UNLINKING,
} from '@/config/language/errors';
import {
  LoyaltyCard,
  getLoyaltyBalance,
  useDeleteLoyaltyCard,
} from '@/features/loyalty';
import { useAnalytics, useWindowSize } from '@/hooks';
import { splitNumberWithCommas } from '@/utils';

import { LOYALTY_SCHEME_NAMES } from '../../constants';
import { Styled } from '../../styles';
import { FlybuysDetails } from '../flybuys-details';

import type { PaymentMethodTypes } from '@/features/bank-cards';

interface IFlybuysLinkedProps {
  card?: PaymentMethodTypes.TLoyaltyCard;
  reloadLoyaltyCardList: () => void;
}

export function FlybuysLinked({
  card,
  reloadLoyaltyCardList,
}: IFlybuysLinkedProps) {
  const windowSize = useWindowSize();
  const flybuysCardSize =
    !!windowSize?.width && windowSize.width > Breakpoints.xxl ? 'md' : 'sm';

  const { track } = useAnalytics();

  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
  const [isUnlinkConfirmOpen, setIsUnlinkConfirmOpen] = useState(false);
  const [isUnlinkError, setIsUnlinkError] = useState(false);
  const [isUnlinkLoading, setIsUnlinkLoading] = useState(false);

  const cardRef = useRef<HTMLButtonElement>(null);

  const { mutate: deleteLoyaltyCard } = useDeleteLoyaltyCard(card?.id || '', {
    onError: () => {
      setIsUnlinkError(true);
    },
    onSettled: () => {
      setIsUnlinkLoading(false);
      setIsUnlinkConfirmOpen(false);
    },
    onSuccess: () => {
      setIsSideSheetOpen(false);
      setTimeout(() => {
        reloadLoyaltyCardList();
      }, 100);
    },
  });

  const handleClickUnlink = () => {
    track('Unlink Loyalty Started', {
      scheme: LOYALTY_SCHEME_NAMES.FLYBUYS,
    });
    setIsUnlinkConfirmOpen(true);
  };

  const handleClickCancel = () => {
    setIsUnlinkConfirmOpen(false);
  };

  const handleCloseErrorDialog = () => {
    setIsUnlinkError(false);
  };

  const handleConfirmUnlink = useCallback(async () => {
    track('Unlink Loyalty Confirmed', {
      scheme: LOYALTY_SCHEME_NAMES.FLYBUYS,
    });
    setIsUnlinkLoading(true);

    if (card?.id) {
      deleteLoyaltyCard();
    } else {
      setIsUnlinkError(true);
    }
  }, [card, deleteLoyaltyCard, track]);

  if (!card) {
    return null;
  }

  const pointsBalance = splitNumberWithCommas(
    card.live_data?.points_balance || 0
  );

  const dollarAmount = getLoyaltyBalance(
    card?.live_data?.points_balance,
    card?.live_data?.exchange_rate
  );

  const handleOpenSideSheet = () => {
    track('View Loyalty', {
      scheme: LOYALTY_SCHEME_NAMES.FLYBUYS,
    });
    setIsSideSheetOpen(true);
  };

  const handleCloseSideSheet = () => {
    setIsSideSheetOpen(false);
    cardRef.current?.focus();
  };

  return (
    <>
      <Styled.LoyaltyCardItemContainerButton
        onClick={handleOpenSideSheet}
        ref={cardRef}
        tabIndex={0}
      >
        <Styled.LoyaltyCardItemImage>
          <LoyaltyCard scheme="flybuys" size={flybuysCardSize} />
        </Styled.LoyaltyCardItemImage>

        <Styled.LoyaltyCardItemDetails>
          <Styled.LoyaltyCardItemTitle>Flybuys</Styled.LoyaltyCardItemTitle>

          <Styled.LoyaltyCardItemAmount>
            ${dollarAmount} available
          </Styled.LoyaltyCardItemAmount>

          <Styled.LoyaltyCardItemPoints>
            {pointsBalance} pts
          </Styled.LoyaltyCardItemPoints>
        </Styled.LoyaltyCardItemDetails>
      </Styled.LoyaltyCardItemContainerButton>

      <SideSheet
        onClose={handleCloseSideSheet}
        open={isSideSheetOpen}
        title="Linked Flybuys details"
      >
        <FlybuysDetails card={card} onClickUnlink={handleClickUnlink} />
      </SideSheet>

      <ConfirmationDialog
        acceptButtonText="Yes, unlink it"
        isDanger
        isOpen={isUnlinkConfirmOpen}
        loading={isUnlinkLoading}
        onClickAccept={handleConfirmUnlink}
        onClickCancel={handleClickCancel}
        title="Ready to unlink your Flybuys account?"
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isUnlinkError}
        message={ERROR_FLYBUYS_UNLINKING}
        onOpenChange={handleCloseErrorDialog}
        title={ERROR_TITLE_FLYBUYS_UNLINKING}
        tryWhat=""
      />
    </>
  );
}
