import { Form } from '@/components';

import { FilterFormBody } from '../filter-form-body/filter-form-body';
import { FilterFormFooter } from '../filter-form-footer/filter-form-footer';

import styles from './filter-form.module.css';
import { useFilterFormInit } from './use-filter-form-init';
import { useFilterFormReset } from './use-filter-form-reset';
import { useFilterFormSubmit } from './use-filter-form-submit';

import type { TFilters } from '../filters/filters';

export function FilterForm() {
  const form = useFilterFormInit();

  const handleSubmit = useFilterFormSubmit();

  useFilterFormReset(form);

  return (
    <Form<TFilters> config={form} onSubmitForm={handleSubmit}>
      <div className={styles['filter-form']}>
        <FilterFormBody />
        <FilterFormFooter />
      </div>
    </Form>
  );
}
