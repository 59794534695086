import styled, { css } from 'styled-components';

import { visuallyHidden } from '@/styles/mixins';

import {
  baseInputCSS,
  baseInputDisabledCSS,
  labelCSS,
} from '../text-field/styles';

import { LIST_MAX_HEIGHT } from './const';

const Wrapper = styled.div<{ $direction?: 'left' | 'top' }>`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);

  @media (width >= 48rem) {
    margin-bottom: var(--spaces-xs);
  }

  ${({ $direction }) =>
    $direction === 'left' &&
    css`
      align-items: center;
      flex-direction: row;
      gap: var(--spaces-xs);
    `}
`;

const Label = styled.label<{ $size: 'medium' | 'small'; $variation: boolean }>`
  ${labelCSS};
  font-size: var(--font-sizes-14);

  ${({ $variation }) =>
    $variation &&
    css`
      height: 1px;
      left: -10000px;
      overflow: hidden;
      position: absolute;
      top: auto;
      width: 1px;
    `}
`;

const Selected = styled.div<{
  $hasError?: boolean;
  $placeholder?: string;
  $size?: 'medium' | 'small';
  $variation: boolean;
}>`
  ${baseInputCSS}
  cursor: pointer;
  font-size: ${({ $size }) =>
    $size === 'small' ? 'var(--font-sizes-14)' : 'var(--font-sizes-16)'};
  line-height: var(--line-heights-none);
  margin-top: 0;
  padding-right: var(--spaces-2xl);
  position: relative;
  ${({ $size }) =>
    $size === 'medium' &&
    css`
      height: auto;
      padding: var(--spaces-m);
    `};

  &[disabled] {
    ${baseInputDisabledCSS}
  }

  svg {
    position: absolute;
    right: var(--spaces-s);
    top: 50%;
    transform: translateY(-50%);
  }

  ${({ $variation }) =>
    $variation &&
    css`
      background-color: var(--colors-surface-highest);
      border: none;
      border-radius: var(--radii-2xl);
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: var(--colors-error-on-lowest);
    `}

  ${({ $placeholder }) =>
    !!$placeholder &&
    css`
      &::before {
        color: var(--colors-on-surface-d);
        content: '${$placeholder}';
      }
    `}
`;

const List = styled.ul<{ $dynamicHeight?: number; $isOpen: boolean }>`
  background: var(--colors-surface-lowest);
  border: 1px solid var(--colors-on-surface-d);
  border-radius: var(--radii-md);
  box-shadow: 0 0 12px rgb(0 0 0 / 4%);
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  list-style: none;
  margin-bottom: 0;
  margin-top: var(--spaces-xs);
  max-height: ${({ $dynamicHeight }) =>
    $dynamicHeight ? `${$dynamicHeight}px` : `${LIST_MAX_HEIGHT}px`};

  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: var(--spaces-m) 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const ListItem = styled.li<{ $highlighted: boolean }>`
  background-color: var(--colors-surface-lowest);
  color: var(--colors-on-surface-a);
  cursor: pointer;
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-normal);
  padding: var(--spaces-s) var(--spaces-m);

  &:hover,
  &:focus {
    background: var(--colors-surface-inverse);
    color: var(--colors-on-surface-inverse);
  }

  ${({ $highlighted }) =>
    $highlighted &&
    css`
      background: var(--colors-surface-inverse);
      color: var(--colors-on-surface-inverse);
    `}
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const VisuallyHidden = styled.span`
  ${visuallyHidden};
`;

export const Styled = {
  InputWrapper,
  Label,
  List,
  ListItem,
  Selected,
  VisuallyHidden,
  Wrapper,
};
