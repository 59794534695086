import styled from 'styled-components';

const ProfileDetailsWrapper = styled.div<{ $avatarLeft?: boolean }>`
  align-items: center;
  display: flex;
  gap: var(--spaces-m);
  margin: 0;
  padding: 0;

  ${({ $avatarLeft }) => $avatarLeft && `flex-direction: row-reverse;`}
  @media (width <= 23.438rem) {
    margin-left: var(--spaces-m);
    width: 100%;
  }
`;

const DetailsTextWrapper = styled.div<{ $avatarLeft?: boolean }>`
  align-items: ${({ $avatarLeft }) =>
    $avatarLeft ? 'flex-start' : 'flex-end'};
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DetailsTextWrapperHeading = styled.h1<{ $color?: string }>`
  color: var(
    --colors-${({ $color }) => (!!$color ? $color : 'brand1-highest')}
  );
  font-size: var(--font-sizes-24);
  font-weight: var(--font-weights-normal);
  max-width: 250px;
  overflow: hidden;
  text-align: end;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (width <= 30rem) {
    width: 160px;
  }

  @media (width <= 23.438rem) {
    width: 140px;
  }
`;

const DetailsTextWrapperText = styled.p<{ $color?: string }>`
  color: var(
    --colors-${({ $color }) => (!!$color ? $color : 'brand1-highest')}
  );
  font-size: var(--font-sizes-12);
  font-weight: var(--font-weights-normal);
  line-height: var(--line-heights-normal);
  margin: 0;
  overflow: hidden;
  text-align: end;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
`;

export const Styled = {
  DetailsTextWrapper,
  DetailsTextWrapperHeading,
  DetailsTextWrapperText,
  ProfileDetailsWrapper,
};
