import { useQueryClient } from '@tanstack/react-query';

import { LoadingCard } from '@/components';
import { PaymentMethodEnums } from '@/features/bank-cards';
import { useGetFlybuysCards } from '@/features/loyalty/services';
import { loyaltyQueryKeys } from '@/features/loyalty/services/loyalty-query-keys';
import { useHasUserConsentedTerms } from '@/features/profile';
import { BaasErrors } from '@/services';

import { FlybuysFetchError } from './fetch-error/flybuys-fetch-error';

import { FlybuysLinked, FlybuysNotLinked } from '.';

export function Flybuys() {
  const queryClient = useQueryClient();

  const { data, error, isError, isFetching } = useGetFlybuysCards();

  const consentedTerms = useHasUserConsentedTerms();

  const errorCode =
    error?.details?.error_number?.toString() ?? error?.details?.toString();

  const isGetFlybuysCardsError = isError && errorCode !== BaasErrors.FORBIDDEN;

  const termsNotAcceptedError =
    errorCode === BaasErrors.TERMS_AND_CONDITIONS_NOT_ACCEPTED;

  const isGetFlybuysCardsLoading =
    !consentedTerms ||
    termsNotAcceptedError ||
    (isFetching && !isGetFlybuysCardsError);

  if (isGetFlybuysCardsLoading) {
    return <LoadingCard />;
  }
  if (isError) {
    return <FlybuysFetchError />;
  }
  const linkedCard = data?.cards?.find(
    (card) => card.status === PaymentMethodEnums.LoyaltyCardStatus.LINKED
  );
  const hasExpiredRefreshToken = !!linkedCard && !linkedCard?.live?.status;

  const pendingLinkCard = data?.cards?.find(
    (card) => card.status === PaymentMethodEnums.LoyaltyCardStatus.PENDING_LINK
  );

  const reloadLoyaltyCardList = () => {
    queryClient.invalidateQueries({
      queryKey: loyaltyQueryKeys.flybuysCards,
    });
  };

  if (linkedCard && !hasExpiredRefreshToken) {
    return (
      <FlybuysLinked
        card={linkedCard}
        reloadLoyaltyCardList={reloadLoyaltyCardList}
      />
    );
  }

  return (
    <FlybuysNotLinked
      hasExpiredRefreshToken={hasExpiredRefreshToken}
      linkedCard={linkedCard}
      pendingLinkCard={pendingLinkCard}
      reloadLoyaltyCardList={reloadLoyaltyCardList}
    />
  );
}
