import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { useHasUserConsentedTerms } from '@/features/profile';

import { giftCardsQueryKeys } from '../gift-cards-query-keys';

import { useGetGiftCardsRequest } from './use-get-gift-cards-request';
import { select } from './use-get-gift-cards-selectors';

import type { GiftCardTypes } from '../../types/gift-card-types';
import type { TBaasErrorResponse } from '@/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetGiftCards(
  options?: UseQueryOptions<
    GiftCardTypes.Operations.GetGiftCards.TResponse,
    TBaasErrorResponse
  >
) {
  const consentedTerms = useHasUserConsentedTerms();
  const auth = useAuth();

  return useQuery({
    enabled: auth.isAuthenticated && consentedTerms,
    queryFn: useGetGiftCardsRequest(),
    queryKey: giftCardsQueryKeys.cardsGiftcardsGet,
    select: select.sortedGiftCards,
    ...options,
  });
}
