import { Callout } from '@/components';

import styles from './gift-card-details-body.module.css';

type TGiftCardDetailsExpiry = {
  timeLeft?: string;
};

export function GiftCardDetailsExpiringSoon({
  timeLeft,
}: TGiftCardDetailsExpiry) {
  return (
    <div className={styles['card-details-row']}>
      <div className={styles['card-details-column']} role="listitem">
        <Callout cta={`This gift card is expiring ${timeLeft}`} size="sm" />
      </div>
    </div>
  );
}
