import { LoyaltyCard } from '@/features/loyalty';

import { Styled } from '../../styles';

export const FlybuysFetchError = () => {
  return (
    <Styled.LoyaltyCardItemContainer>
      <Styled.LoyaltyCardItemImage>
        <LoyaltyCard scheme="flybuys" size="md" />
      </Styled.LoyaltyCardItemImage>
      <Styled.LoyaltyInfoWrapper>
        <h3>Flybuys</h3>
        <p>
          Unable to retrieve your Flybuys card details. Refresh the page or try
          again later.
        </p>
      </Styled.LoyaltyInfoWrapper>
    </Styled.LoyaltyCardItemContainer>
  );
};
