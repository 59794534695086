import { clsx } from 'clsx';

import { FlybuysLogoIconLarge } from '@/components';

import styles from './loyalty-card.module.css';

export enum LoyaltySchemes {
  FLYBUYS = 'flybuys',
}

export interface ILoyaltyCardProps {
  scheme?: `${LoyaltySchemes}`;
  size?: 'lg' | 'md' | 'sm' | 'xs';
}

const schemeIcons = {
  [LoyaltySchemes.FLYBUYS]: FlybuysLogoIconLarge,
  other: undefined,
};

const flybuysIconHeights = {
  lg: 67,
  md: 40,
  sm: 26,
  xs: 12,
};

export function LoyaltyCard({ scheme, size = 'lg' }: ILoyaltyCardProps) {
  const SchemeIcon = !!scheme && schemeIcons[`${scheme}`];

  return (
    <div
      className={clsx(
        styles.card,
        styles['loyalty-card'],
        styles[`card-${size}`],
        styles[`loyalty-card-${scheme}`],
        styles[`loyalty-card-${size}`]
      )}
    >
      {SchemeIcon && <SchemeIcon height={flybuysIconHeights[`${size}`]} />}
    </div>
  );
}
