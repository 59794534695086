import clsx from 'clsx';

import {
  CloseIcon,
  PaidIcon,
  PendingIcon,
  RefundIcon,
} from '@/components/icons';
import { useActivityStore } from '@/store';

import { ViewOrderStatus } from '../../enums/order-status';

import styles from './order-details-status.module.css';

import type { JSX } from 'react';

const mappedStatusIcon: Record<ViewOrderStatus, JSX.Element> = {
  [ViewOrderStatus.PAID]: <PaidIcon />,
  [ViewOrderStatus.CANCELLED]: (
    <CloseIcon color={'var(--colors-error-on-lowest)'} height={17} width={17} />
  ),
  [ViewOrderStatus.REFUNDED]: <RefundIcon />,
  [ViewOrderStatus.PARTIALLY_REFUNDED]: <RefundIcon />,
  [ViewOrderStatus.PENDING]: (
    <PendingIcon color={'var(--colors-success-on-lowest)'} />
  ),
};

export function OrderDetailsStatus() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (!selectedOrder) {
    return null;
  }
  const { orderStatus } = selectedOrder;

  const orderStatusClasses = clsx(
    styles['order-status-wrapper'],
    !!orderStatus && styles[orderStatus.toLowerCase()]
  );

  return (
    <div className={orderStatusClasses}>
      {mappedStatusIcon[orderStatus]}
      {orderStatus}
    </div>
  );
}
