import styled from 'styled-components';

import type { ISpinnerProps } from './typings';

const Spinner = styled.div<
  Pick<ISpinnerProps, '$secondaryColor' | '$thickness'> & {
    $color?: string;
    $size?: number;
  }
>`
  animation: spin 1s linear infinite;
  border: ${(props) => props.$thickness || (props.$size || 70) / 10}px solid
    ${(props) => props.$secondaryColor || 'var(--colors-brand1-highest)'};
  border-radius: 50%;
  border-top-color: ${(props) => props.color || 'transparent'};

  display: block;
  height: ${(props) => props.$size}px;
  width: ${(props) => props.$size}px;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SpinnerMessage = styled.p`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-16);
  margin: 0;
  margin-top: var(--spaces-m);
`;

export const Styled = {
  Spinner,
  SpinnerContainer,
  SpinnerMessage,
};
