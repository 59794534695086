import * as RadixSlider from '@radix-ui/react-slider';
import styled from 'styled-components';

const SliderRoot = styled(RadixSlider.Root)`
  align-items: center;
  display: flex;
  height: 1.125rem;
  position: relative;
  touch-action: none;
  user-select: none;
  width: 100%;

  &[data-orientation='vertical'] {
    flex-direction: column;
    height: 100%;
    width: 1.125rem;
  }
`;

const SliderTrack = styled(RadixSlider.Track)`
  background-color: var(--colors-surface-highest);
  border-radius: 9999px;
  flex-grow: 1;
  position: relative;

  &[data-orientation='horizontal'] {
    height: 0.375rem;
  }

  &[data-orientation='vertical'] {
    width: 0.375rem;
  }
`;

const SliderRange = styled(RadixSlider.Range)`
  background-color: var(--colors-brand1-highest);
  border-radius: 9999px;
  position: absolute;

  &[data-orientation='horizontal'] {
    height: 100%;
  }

  &[data-orientation='vertical'] {
    width: 100%;
  }
`;

const SliderThumb = styled(RadixSlider.Thumb)`
  background-color: var(--colors-surface-high);
  border: 2px solid var(--colors-on-surface-d);
  border-radius: 50px;
  display: block;
  height: 1.125rem;
  width: 1.125rem;
`;

export const Styled = {
  SliderRange,
  SliderRoot,
  SliderThumb,
  SliderTrack,
};
