import { ConfirmationDialog } from '@/components';
import { useGiftCardsStore } from '@/store';

import { useDeleteGiftCard } from '../../services/use-delete-gift-card/use-delete-gift-card';
import { GiftCardDetailsBody } from '../gift-card-details-body/gift-card-details-body';
import { GiftCardDetailsFooter } from '../gift-card-details-footer/gift-card-details-footer';

import styles from './gift-card-details.module.css';

export function GiftCardDetails() {
  const setIsOpenDeleteDialog =
    useGiftCardsStore().use.actions().setIsOpenDeleteDialog;
  const setIsDeleteGiftCardPending =
    useGiftCardsStore().use.actions().setIsDeleteGiftCardPending;
  const isDeleteGiftCardPending =
    useGiftCardsStore().use.isDeleteGiftCardPending();
  const isOpenDeleteDialog = useGiftCardsStore().use.isOpenDeleteDialog();
  const selectedCard = useGiftCardsStore().use.selectedGiftCard();
  const deleteGiftCard = useDeleteGiftCard(selectedCard?.id || '');

  const handleCancelDeleteCard = () => {
    setIsOpenDeleteDialog(false);
  };

  const handleDeleteCard = () => {
    deleteGiftCard.mutate();
    setIsDeleteGiftCardPending(true);
  };

  const acceptButtonText = isDeleteGiftCardPending
    ? 'Loading'
    : 'Yes, remove it';

  return (
    <div className={styles['view-card']}>
      <ConfirmationDialog
        acceptButtonText={acceptButtonText}
        isDanger
        isOpen={isOpenDeleteDialog && !!selectedCard}
        loading={isDeleteGiftCardPending}
        onClickAccept={handleDeleteCard}
        onClickCancel={handleCancelDeleteCard}
        title={`Are you sure you want to remove this gift card ending in ${selectedCard?.last4Digits}?`}
      />
      <GiftCardDetailsBody />
      <GiftCardDetailsFooter />
    </div>
  );
}
