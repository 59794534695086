export namespace BankCardsEnums {
  export enum BankCardSchemes {
    AMEX = 'amex',
    DEFAULT = 'default',
    EFTPOS = 'eftpos',
    MASTERCARD = 'mastercard',
    VISA = 'visa',
  }

  export enum BankCardStatus {
    EXPIRED = 'EXPIRED',
    INVALID = 'INVALID',
    VALIDATED = 'VALIDATED',
    VERIFIED = 'VERIFIED',
  }

  export enum BankCardType {
    BANK_CARD = 'bank_card',
  }

  export enum BankCardFunding {
    CREDIT = 'credit',
    DEBIT = 'debit',
  }
}
