import { getBankCardStatus } from './get-bank-card-status';

import type { BankCardsTypes } from '../types/bank-cards-types';

export function getBankCardLabel(
  card: BankCardsTypes.Components.TBankCard,
  isDefault = false
) {
  const cardName = card.alias || card.data.issuer_name || '';
  const cardNameText = `${!!cardName ? cardName + ' ' : ''}`;

  const cardStatusPillText = getBankCardStatus(card.status, isDefault) ?? '';
  const cardStatusText = `${!!cardStatusPillText ? cardStatusPillText + ' ' : ''}`;

  const splitCardNumber = !!card.data?.visualization?.last_four_digits
    ? [...card.data.visualization.last_four_digits].join(' ')
    : '';
  const cardNumberText = !!splitCardNumber
    ? ` ending with ${splitCardNumber}`
    : '';

  const cardType = `${card.data.schemes.join(' ')} ${card.data.type}`;

  return `${cardType}, ${cardStatusText}${cardNameText}Card${cardNumberText}`;
}
