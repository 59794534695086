import { FilterFormBodyAmount } from './filter-form-body-amount';
import { FilterFormBodyDate } from './filter-form-body-date';
import { FilterFormBodyOrderType } from './filter-form-body-order-type';

export function FilterFormBody() {
  return (
    <div>
      <FilterFormBodyDate />
      <FilterFormBodyAmount />
      <FilterFormBodyOrderType />
    </div>
  );
}
