import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-l);
  height: 100%;
  padding: 0 var(--spaces-l);
  width: 100%;

  @media (width >= 48rem) {
    padding: 0;
  }
`;

const PanelCardWrapper = styled.div`
  @media (width >= 48rem) {
    max-width: 520px;
  }
`;

const PanelCardContentContainer = styled.div`
  align-content: start;
  display: flex;
  gap: var(--spaces-m);
  width: 100%;
`;

const PanelCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--spaces-xs);
`;

const PanelCardTitle = styled.h3`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-normal);
  margin: 0;
`;

const PanelCardDescription = styled.p`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-normal);
  line-height: 1.25;
  margin: 0;
`;

const ErrorIcon = styled.div`
  flex-basis: 80px;
  text-align: center;
`;

export const Styled = {
  Container,
  ErrorIcon,
  PanelCardContent,
  PanelCardContentContainer,
  PanelCardDescription,
  PanelCardTitle,
  PanelCardWrapper,
};
