import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const InformationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xl);
  justify-content: center;
  max-width: 580px;
  padding: 0 var(--spaces-m);
  width: 100%;
`;

const Heading = styled.h1`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-32);
  font-style: normal;
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-half);
  margin: 0;
  text-align: center;
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  margin-bottom: 0;
  text-align: center;
`;

export { Container, InformationWrapper, Heading, Paragraph };
