import styled, { css } from 'styled-components';

const DialogDescription = styled.p`
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-normal);
  margin: 0;
  padding: 0;
  text-align: center;
`;

const DialogHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: 8px;
  line-height: 1.2;
`;

const DialogIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const DialogTitle = styled.h2`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-24);
  font-weight: var(--font-weights-bold);
  margin: 0;
  padding: 0;
  text-align: center;
`;

const DialogWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-2xl);
`;

const DialogActions = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  gap: var(--spaces-xs);
  width: 100%;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      flex-direction: column-reverse;
    `};
`;

const DialogBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);
`;

export const Styled = {
  DialogActions,
  DialogBodyWrapper,
  DialogDescription,
  DialogHeader,
  DialogIcon,
  DialogTitle,
  DialogWrapper,
};
