import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  ThreeDsIframe,
  endpoints,
  useFinalizeCard,
} from '@/features/bank-cards';
import { paymentMethodQueryKeys } from '@/features/bank-cards/services';
import { useAnalytics } from '@/hooks/use-analytics';
import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import { useUpdateCard } from '../../services/use-update-card/use-update-card';
import { EditCardForm } from '../edit-card-form/edit-card-form';

import styles from './edit-card.module.css';
import { useEditCardFinalize } from './use-edit-card-finalize';
import { useEditCardFinalizeSuccess } from './use-edit-card-finalize-success';

export function EditCard() {
  const analytics = useAnalytics();
  const queryClient = useQueryClient();

  const is3dsIframeOpen = useBankCardsStore().use.is3dsIframeOpen();
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();
  const threeDSecureId = useBankCardsStore().use.threeDSecureId();

  const { setBankCardsDialogErrorState } = useBankCardsStore().use.actions();

  const { setFinalizePayload, setIs3dsIframeOpen, setThreeDSecureId } =
    useBankCardsStore().use.actions();

  const reloadBankCardList = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.cardsBankGet,
    });
  }, [queryClient]);

  const updateBankCard = useUpdateCard(selectedBankCard?.id || '', {
    onError: () => {
      setBankCardsDialogErrorState(
        BankCardsDialogErrorStates.BANK_CARD_EDIT_ERROR
      );
      reloadBankCardList();
    },
    onSuccess: (data) => {
      onSuccessUpdateBankCard(data?.three_d_secure?.id);
    },
  });

  const onSuccessUpdateBankCard = (threeDsId: string) => {
    setThreeDSecureId(threeDsId);
    setFinalizePayload({
      authorization_id: threeDsId,
      payer: {
        card: {
          id: selectedBankCard?.id || '',
        },
      },
    });
    analytics.track('3ds Start', {
      context: 'update_card',
      passed: 'true',
    });
    setIs3dsIframeOpen(true);
  };

  const finalizeCard = useFinalizeCard({
    onError: () => {
      reloadBankCardList();
      setBankCardsDialogErrorState(
        BankCardsDialogErrorStates.BANK_CARD_EDIT_ERROR
      );
    },
    onSuccess: useEditCardFinalizeSuccess(),
  });

  useEditCardFinalize(finalizeCard);

  const isProcessing =
    finalizeCard.isPending || updateBankCard.isPending || is3dsIframeOpen;

  const iframeSrc = `${import.meta.env.VITE_REACT_APP_BAAS_API_URL}${endpoints.BANK_CARDS_3DS_AUTHENTICATE_INIT(threeDSecureId)}`;

  return (
    <div className={styles['edit-card-details']}>
      {is3dsIframeOpen && threeDSecureId ? (
        <ThreeDsIframe src={iframeSrc} />
      ) : (
        <EditCardForm
          card={selectedBankCard!}
          isProcessing={isProcessing}
          updateBankCard={updateBankCard}
        />
      )}
    </div>
  );
}
