import { useMutation } from '@tanstack/react-query';

import { giftCardsQueryKeys } from '../gift-cards-query-keys';

import { useDeleteGiftCardError } from './use-delete-gift-card-error';
import { useDeleteGiftCardsRequest } from './use-delete-gift-card-request';
import { useDeleteGiftCardSuccess } from './use-delete-gift-card-success';

import type { GiftCardTypes } from '../../types/gift-card-types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useDeleteGiftCard(
  giftCardId: string,
  options?: UseMutationOptions<
    GiftCardTypes.Operations.DeleteGiftCard.TResponse,
    TBaasErrorResponse
  >
) {
  return useMutation({
    mutationFn: useDeleteGiftCardsRequest(giftCardId),
    mutationKey: giftCardsQueryKeys.cardsGiftcardsDelete,
    onError: useDeleteGiftCardError(),
    onSuccess: useDeleteGiftCardSuccess(),
    ...options,
  });
}
