import type { IIcon } from './typings';

export const ProfileMenuIcon = ({
  color = 'var(--colors-on-surface-b)',
}: IIcon) => {
  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6489 13.4867C12.408 11.6837 10.8679 10.2969 8.99924 10.2969C7.13063 10.2969 5.59049 11.691 5.34961 13.4867"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        clipRule="evenodd"
        d="M11.021 6.9698C10.6487 6.22528 9.88961 5.70703 8.9991 5.70703C7.75093 5.70703 6.73633 6.72163 6.73633 7.96981C6.73633 9.21798 7.75093 10.2326 8.9991 10.2326C9.65604 10.2326 10.2473 9.94791 10.6633 9.49535C11.0356 9.09389 11.2619 8.56105 11.2619 7.96981C11.2619 7.61214 11.167 7.27637 11.021 6.9698V6.9698Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        d="M9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </svg>
  );
};
