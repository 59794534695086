export type TBreakpointKeys = keyof typeof Breakpoints;

export enum Breakpoints {
  '2xs' = 375,
  'xs' = 480,
  'sm' = 768,
  'md' = 1024,
  'lg' = 1280,
  'xl' = 1440,
  'xxl' = 1600,
}

export enum BreakpointRems {
  '2xs' = '23.4375rem',
  'lg' = '80rem',
  'md' = '64rem',
  'sm' = '48rem',
  'xl' = '90rem',
  'xs' = '30rem',
}

export const BREAKPOINT_BOTTOM_SHEET_TO_DIALOG = 425;

export const TOP_BAR_HEIGHT = '56px';
