import isEmpty from 'lodash.isempty';
import { createContext, useState } from 'react';
import { createStore } from 'zustand';

import { activityStoreActions } from './activity-store-actions';
import { cardStoreDefaultValues } from './activity-store-defaults';

import type {
  IActivityStore,
  IActivityStoreStateValues,
  TCardStoreProvider,
} from './activity-store-types';

export const ActivityStoreContext = createContext<ReturnType<
  typeof createActivityStore
> | null>(null);

export function ActivityStoreProvider({
  children,
  initialValues,
}: TCardStoreProvider) {
  const initialStoreValue = {
    ...cardStoreDefaultValues,
    ...(!isEmpty(initialValues) && initialValues),
  };

  const [store] = useState(() => createActivityStore(initialStoreValue));

  return (
    <ActivityStoreContext.Provider value={store}>
      {children}
    </ActivityStoreContext.Provider>
  );
}

function createActivityStore(initialStoreValue: IActivityStoreStateValues) {
  return createStore<IActivityStore>()((set) => ({
    actions: activityStoreActions(set),
    ...initialStoreValue,
  }));
}
