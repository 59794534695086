import { useContext } from 'react';

import { createStoreSelectors } from '../create-selectors';

import { ActivityStoreContext } from './activity-store-context';

export function useActivityStore() {
  const store = useContext(ActivityStoreContext);

  if (!store) {
    throw new Error('Missing ActivityStoreProvider');
  }

  const storeWithSelectors = createStoreSelectors(store);

  return storeWithSelectors;
}
