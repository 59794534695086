import { GiftCardDetailsFooterDeleteCardButton } from './gift-card-details-delete-card-button';
import styles from './gift-card-details-footer.module.css';

export function GiftCardDetailsFooter() {
  return (
    <div className={styles.action}>
      <GiftCardDetailsFooterDeleteCardButton />
    </div>
  );
}
