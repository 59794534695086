import { GiftCardsDialogSuccessStates } from '@/store';

import type { GiftCardTypes } from '../../types/gift-card-types';

type TGiftCardsMetaData = {
  giftCardSlotsMaxLimit?: number | null;
  giftCardSlotsRemaining?: number | null;
  giftCardSlotsUsed?: number;
  isMaxedOutGiftCardSlots?: boolean;
};

type TGiftCardsSuccessCopyArgs = {
  giftCard: GiftCardTypes.Components.TGiftCard | undefined;
  metadata: TGiftCardsMetaData;
  walletName?: string;
};

export const giftCardsSuccessCopy = {
  [GiftCardsDialogSuccessStates.GIFT_CARD_ADD](
    data: TGiftCardsSuccessCopyArgs
  ) {
    return {
      buttonText: 'OK',
      description: getAddGiftCardSuccessDescription(data?.giftCard),
      meta: getAddGiftCardSuccessMetaText(data?.metadata, data?.walletName),
      title: 'Gift card added',
    };
  },

  [GiftCardsDialogSuccessStates.GIFT_CARD_DELETE](
    data: TGiftCardsSuccessCopyArgs
  ) {
    const description = !!data?.walletName
      ? `You have successfully removed this gift card from your ${data.walletName} wallet.`
      : 'You have successfully removed this gift card from your wallet.';

    return {
      buttonText: 'Continue',
      description,
      meta: '',
      title: 'Gift card removed',
    };
  },

  [GiftCardsDialogSuccessStates.NULL]: undefined,
};

function getAddGiftCardSuccessDescription(
  giftCard: GiftCardTypes.Components.TGiftCard | undefined
) {
  if (!!giftCard?.data?.visualization?.masked_card_number) {
    const maskedCardNumber = giftCard?.data?.visualization?.masked_card_number;
    const last4Digits = maskedCardNumber.slice(-4);

    return `Your gift card ending in ••• ${last4Digits} was added!`;
  } else {
    return 'Your gift card was added!';
  }
}

function getAddGiftCardSuccessMetaText(
  metadata: TGiftCardsMetaData,
  walletName?: string
) {
  if (metadata?.giftCardSlotsRemaining === 1) {
    return !!walletName
      ? `You can add another 1 gift card to your ${walletName} wallet.`
      : 'You can add another 1 gift card to your wallet.';
  }

  if (
    metadata?.isMaxedOutGiftCardSlots &&
    metadata?.giftCardSlotsUsed &&
    metadata?.giftCardSlotsMaxLimit
  ) {
    return !!walletName
      ? `You have added ${metadata.giftCardSlotsUsed} of ${metadata.giftCardSlotsMaxLimit} gift cards to your ${walletName} wallet.`
      : `You have added ${metadata.giftCardSlotsUsed} of ${metadata.giftCardSlotsMaxLimit} gift cards to your wallet.`;
  }
}
