import type { BankCardsTypes } from '../../types/bank-cards-types';

export const bankCardsSlots = {
  addBankCardsSlotAttributes(
    response: BankCardsTypes.Operations.GetBankCards.TResponse
  ) {
    const maxBankCardsLimit =
      response?.metadata?.user_bank_cards_limit?.max_allowed || 0;
    const totalBankCards = response?.cards?.length;

    return {
      ...response,
      bankCardSlotsMaxLimit:
        response?.metadata?.user_bank_cards_limit?.max_allowed || null,
      bankCardSlotsRemaining: bankCardsSlots.getRemainingBankCardSlots(
        maxBankCardsLimit || 0,
        totalBankCards
      ),
      bankCardSlotsUsed: totalBankCards,
      isMaxedOutBankCardSlots: bankCardsSlots.hasMaxedOutBankCardSlots(
        totalBankCards,
        maxBankCardsLimit
      ),
    };
  },

  getRemainingBankCardSlots(maxBankCardsLimit: number, totalBankCards: number) {
    return !!maxBankCardsLimit && maxBankCardsLimit > 0
      ? maxBankCardsLimit - totalBankCards
      : 0;
  },

  hasMaxedOutBankCardSlots(
    totalBankCards: number,
    maxBankCardsAllowed: number | null
  ) {
    return !!maxBankCardsAllowed ? totalBankCards >= maxBankCardsAllowed : true;
  },
};
