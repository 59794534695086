import { clsx } from 'clsx';

import { Button } from '@/components';
import { AddIcon } from '@/components/icons';
import { useHideInProduction } from '@/hooks';

import styles from './cards-panel-empty.module.css';

type TEmptyPanelCard = {
  addBtnText: string;
  description: string;
  icon: string;
  onClickAdd?: () => void;
  title: string;
};

export function CardsPanelEmpty({
  addBtnText,
  description,
  icon,
  onClickAdd,
  title,
}: TEmptyPanelCard) {
  const hideInProduction = useHideInProduction();
  return (
    <div className={styles['panel-card-content-container']}>
      <img alt="" className={styles['empty-card-icon']} src={icon} />
      <div className={styles['panel-card-content ']}>
        <h3 className={styles['panel-card-title']}>{title}</h3>
        <div className={styles['panel-card-description']}>{description}</div>
        <Button
          className={clsx(styles['panel-card-button'], {
            [styles['panel-card-button-rebrand']]: hideInProduction,
          })}
          level="tertiary"
          onClick={onClickAdd}
          size="small"
          variant={'neutral'}
        >
          <span className={styles['button-wrapper']}>
            <AddIcon height={16} width={16} />
            {addBtnText}
          </span>
        </Button>
      </div>
    </div>
  );
}
