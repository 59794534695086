import clsx from 'clsx';
import { differenceInCalendarDays, isValid, parseISO } from 'date-fns';

import { timeUntilExpiry } from '../../utils/time-until-expiry';

import styles from './gift-card-status.module.css';

export type TGiftCardExpiryProps = { expiryDate: string; noStyles?: boolean };

export function GiftCardExpiry({ expiryDate }: TGiftCardExpiryProps) {
  const expiryDateTime = parseISO(expiryDate);

  if (!isValid(expiryDateTime)) {
    return null;
  }

  const dayDifference = differenceInCalendarDays(expiryDateTime, new Date());

  const classes =
    dayDifference <= 7
      ? clsx(styles['expires-soon'], styles['expiry'])
      : clsx(styles['expires'], styles['expiry']);

  const expiry = timeUntilExpiry(expiryDate);

  return <p className={classes}>Expires {expiry}</p>;
}
