import { baasServiceErrors } from './baas-service-errors';

type TBaasServiceError = {
  debug?: {
    recovery?: string;
  };
  description?: string;
  error_code: string;
  error_number: number;
  http_status: number;
  message: string;
  service_id: number;
  service_name: string;
};

type TBaasServiceErrorsMappings = Record<number, TBaasServiceError>;

export const baasServiceErrorsMappings = baasServiceErrors.reduce(
  (errors, error) => {
    return {
      [error.error_number]: error,
      ...errors,
    };
  },
  {} as TBaasServiceErrorsMappings
);
