import type { IIcon } from './typings';

export const HelpMenuIcon = ({
  color = 'var(--colors-on-surface-b)',
}: IIcon) => {
  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99951 16.5029C13.1425 16.5029 16.501 13.1444 16.501 9.00147C16.501 4.85852 13.1425 1.5 8.99951 1.5C4.85657 1.5 1.49805 4.85852 1.49805 9.00147C1.49805 13.1444 4.85657 16.5029 8.99951 16.5029Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99916 9.83614C8.99916 9.4028 9.35216 8.84894 9.97397 8.43345C10.5507 8.04806 10.855 7.54813 10.7197 6.86779C10.5843 6.18744 10.0222 5.6253 9.34182 5.48996C8.27387 5.27753 7.24609 6.121 7.24609 7.20987"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M8.94742 13.0667C9.37897 13.0667 9.72882 12.7169 9.72882 12.2853C9.72882 11.8538 9.37897 11.5039 8.94742 11.5039C8.51586 11.5039 8.16602 11.8538 8.16602 12.2853C8.16602 12.7169 8.51586 13.0667 8.94742 13.0667Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
