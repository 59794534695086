import { useGetPublicKey } from '@/features/bank-cards';
import { GiftCardsDialogErrorStates, useGiftCardsStore } from '@/store';

import type { GiftCardTypes } from '../../types/gift-card-types';
import type { FieldValues } from 'react-hook-form';

export function useAddGiftCardFormSubmit(
  addGiftCard: GiftCardTypes.Operations.AddGiftCard.TMutation
) {
  const { setGiftCardsDialogErrorState, setIsAddGiftCardPending } =
    useGiftCardsStore().use.actions();
  const getPublicKey = useGetPublicKey();

  return async function ({ cardNumber, pin }: FieldValues) {
    setIsAddGiftCardPending(true);
    const canAddGiftCard =
      (await getPublicKey.isSuccess) && !!getPublicKey?.data;

    if (canAddGiftCard) {
      addGiftCard.mutate({
        data: {
          cardNumber,
          pin,
        },
        encryption: getPublicKey.data,
      });
    } else {
      setIsAddGiftCardPending(false);
      setGiftCardsDialogErrorState(
        GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR
      );
    }
  };
}
