import { Buffer } from 'buffer/';
import flow from 'lodash.flow';
import isEmpty from 'lodash.isempty';

import type { BankCardsTypes } from '../types/bank-cards-types';

type TCipherData = {
  cvv: string;
  expiry_month: string;
  expiry_year: string;
  number: string;
};

type TCreateCardCipher = {
  data: TCipherData;
  publicKey: BankCardsTypes.Components.TPublicKey;
};

type TCreateCardCipherText = {
  cipherText: ArrayBuffer;
  data: TCipherData;
  importKey: CryptoKey;
  publicKey: string;
  publicKeyId: string;
};

export const createBankCardCipherText = async (args: TCreateCardCipher) => {
  if (
    isEmpty(args?.data) ||
    !args?.publicKey?.key_id ||
    !args?.publicKey?.public_key
  ) {
    return;
  }

  return await flow(
    getCardData,
    createEncryptionImportKey,
    createCipherText,
    finaliseCipherText
  )({
    data: args.data,
    publicKey: args.publicKey.public_key,
    publicKeyId: args.publicKey.key_id,
  });
};

function getCardData(args: TCreateCardCipherText) {
  return {
    ...args,
    data: {
      cvv: args?.data?.cvv,
      expiry_month: args?.data?.expiry_month,
      expiry_year: args?.data?.expiry_year,
      number: args?.data?.number,
    },
  };
}

async function createEncryptionImportKey(params: TCreateCardCipherText) {
  return {
    ...params,
    importKey: await window.crypto.subtle.importKey(
      'spki',
      Buffer.from(params?.publicKey, 'base64'),
      {
        hash: 'SHA-256',
        name: 'RSA-OAEP',
      },
      true,
      ['encrypt']
    ),
  };
}

async function createCipherText(params: TCreateCardCipherText) {
  const args = await params;

  return {
    ...args,
    cipherText: await window.crypto.subtle.encrypt(
      { name: 'RSA-OAEP' },
      args.importKey,
      Buffer.from(JSON.stringify(args?.data), 'utf8')
    ),
  };
}

async function finaliseCipherText(params: TCreateCardCipherText) {
  const args = await params;
  const cipherText = Buffer.from(args.cipherText).toString('base64');

  return `${cipherText}.${args?.publicKeyId}`;
}
