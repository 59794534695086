import { GiftCardsDialogErrorStates, useGiftCardsStore } from '@/store';

export function useGiftCardsDialogErrorReset() {
  const { setCurrentGiftCard, setGiftCardsDialogErrorState } =
    useGiftCardsStore().use.actions();

  return function handleOpenChangeGiftCardsDialogError() {
    setCurrentGiftCard(undefined);
    setGiftCardsDialogErrorState(GiftCardsDialogErrorStates.NULL);
  };
}
