import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

export function useUpdateCardNicknameError() {
  const setBankCardsDialogErrorState =
    useBankCardsStore().use.actions().setBankCardsDialogErrorState;

  return () => {
    setBankCardsDialogErrorState(
      BankCardsDialogErrorStates.BANK_CARD_EDIT_NICKNAME_ERROR
    );
  };
}
