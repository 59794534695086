import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '@/features/bank-cards';

import { useSetDefaultCardRequest } from './use-set-default-bank-card-request';

import type { MutationOptions } from '@tanstack/react-query';

export function useSetDefaultBankCard(
  cardId: string,
  options: MutationOptions
) {
  return useMutation({
    mutationFn: useSetDefaultCardRequest(cardId),
    mutationKey: paymentMethodQueryKeys.cardsBankUpdateDefault,
    ...options,
  });
}
