import { Button } from '@/components';

import { AddIcon } from '../icons';

import styles from './cards-panel-button.module.css';

type TCardsPanelButton = {
  addLabel: string;
  handleAddCardOpen: () => void;
};

export function CardsPanelButton({
  addLabel,
  handleAddCardOpen,
}: TCardsPanelButton) {
  return (
    <Button
      aria-label={`Add ${addLabel}`}
      className={styles['add-card']}
      level="tertiary"
      onClick={handleAddCardOpen}
      variant="branded"
    >
      <div className={styles['panel-card-button-wrapper']}>
        <AddIcon /> Add
      </div>
    </Button>
  );
}
