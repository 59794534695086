import styled from 'styled-components';

const Fieldset = styled.fieldset`
  align-items: flex-start;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin: 0;
  padding: 0;
`;

const NameFieldWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  justify-content: space-evenly;
  width: 100%;

  @media (width >= 30rem) {
    align-items: baseline;
    flex-direction: row;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);
`;

export const Styled = { CheckboxWrapper, Fieldset, NameFieldWrapper };
