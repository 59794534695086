import { Button } from '@/components';
import { ErrorIcon } from '@/components/icons';
import { BUTTON_TEXT_TRY_AGAIN } from '@/config/language/errors';
import { useTheme } from '@/contexts/theme';
import { useGetWalletConfigOpen } from '@/features/wallet-config';
import { useBankCardsStore } from '@/store';

import styles from './bank-cards-dialog-error-content.module.css';
import { bankCardsDialogErrorProps } from './bank-cards-dialog-error-props';

type TBankCardsDialogErrorContentProps = {
  handleOpenChange: () => void;
};

export function BankCardsDialogErrorContent({
  handleOpenChange,
}: TBankCardsDialogErrorContentProps) {
  const { theme } = useTheme();

  const getWalletConfigOpen = useGetWalletConfigOpen();

  const bankCardsDialogErrorState =
    useBankCardsStore().use.bankCardsDialogErrorState();

  const dialogProps = bankCardsDialogErrorProps[bankCardsDialogErrorState]?.();
  const customerSupportLinkText = !!getWalletConfigOpen?.data?.wallet_name
    ? `${getWalletConfigOpen?.data?.wallet_name} Customer Care`
    : 'Customer Care';

  return (
    <div className={styles['bank-cards-dialog-error-content']}>
      {dialogProps?.icon ?? <ErrorIcon />}

      <div className={styles['bank-cards-dialog-error-content-header']}>
        <h2 className={styles['bank-cards-dialog-error-content-header-title']}>
          {dialogProps?.title}
        </h2>

        <p
          className={
            styles['bank-cards-dialog-error-content-header-description']
          }
        >
          {dialogProps?.description}{' '}
          {dialogProps?.isShowSupportContact && !!theme.urls?.contact && (
            <span>
              Please try again or contact{' '}
              <a
                className={
                  styles['bank-cards-dialog-error-content-support-link']
                }
                href={theme.urls.contact}
                rel="noopener noreferrer"
                target="_blank"
              >
                {customerSupportLinkText}
              </a>
            </span>
          )}
        </p>
      </div>

      <Button
        className={styles['bank-cards-dialog-error-content-action-button']}
        onClick={handleOpenChange}
        size="medium"
      >
        {dialogProps?.buttonText || BUTTON_TEXT_TRY_AGAIN}
      </Button>
    </div>
  );
}
