import { useAuth } from 'react-oidc-context';

import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/bank-cards/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from '../endpoints';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useGetBankCardsRequest() {
  const auth = useAuth();

  return async function getBankCardsRequest() {
    return await request<BankCardsTypes.Operations.GetBankCards.TResponse>(
      `${endpoints.BANK_CARDS}`,
      auth,
      { method: RequestMethod.GET },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARDS,
        transaction: paymentMethodTransactionKeys.bankCards,
      }
    );
  };
}
