import creditCardType from 'credit-card-type';

import { PaymentMethodEnums } from '@/features/bank-cards';

import type { BankCardsTypes } from '@/features/bank-cards';

export enum CardTypes {
  AMEX = 'American Express',
  DINERS_CLUB = 'Diners Club',
  DISCOVER = 'Discover',
  ELO = 'Elo',
  HIPER = 'Hiper',
  HIPERCARD = 'Hipercard',
  JCB = 'JCB',
  MAESTRO = 'Maestro',
  MASTERCARD = 'Mastercard',
  MIR = 'Mir',
  UNIONPAY = 'UnionPay',
  VISA = 'Visa',
}

export type TCardTypes = `${CardTypes}`;

// * Card reference for security code
// * https://github.com/braintree/credit-card-type
export const securityCode = {
  [CardTypes.AMEX]: {
    label: 'CID',
    length: 4,
  },
  [CardTypes.DINERS_CLUB]: {
    label: 'CVV',
    length: 3,
  },
  [CardTypes.DISCOVER]: {
    label: 'CID',
    length: 3,
  },
  [CardTypes.ELO]: {
    label: 'CVE',
    length: 3,
  },
  [CardTypes.HIPER]: {
    label: 'CVC',
    length: 3,
  },
  [CardTypes.HIPERCARD]: {
    label: 'CVC',
    length: 4,
  },
  [CardTypes.JCB]: {
    label: 'CVV',
    length: 3,
  },
  [CardTypes.MAESTRO]: {
    label: 'CVC',
    length: 3,
  },
  [CardTypes.MASTERCARD]: {
    label: 'CVC',
    length: 3,
  },
  [CardTypes.MIR]: {
    label: 'CVP2',
    length: 3,
  },
  [CardTypes.UNIONPAY]: {
    label: 'CVN',
    length: 3,
  },
  [CardTypes.VISA]: {
    label: 'CVV',
    length: 3,
  },
};

export function getCardSecurityCodeLength(cardNumber: string) {
  const cardType = creditCardType(cardNumber)[0];

  return cardType?.code?.size;
}

export function getCardSecurityCodeLengthBySchemes(
  schemes?: BankCardsTypes.Components.TBankCardSchemes
): number {
  return schemes?.[0] === PaymentMethodEnums.BankCardSchemes.AMEX ? 4 : 3;
}
