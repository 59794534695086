import { EventPriority, sentry } from '@/features/monitoring';
import { useAnalytics } from '@/hooks';

import type { TBaasErrorResponse } from '@/services';

export function useAddCardTracking() {
  const analytics = useAnalytics();

  function reportErrorSegment(description: string) {
    analytics.track('Add Card failed', {
      context: 'add',
      description,
    });
  }

  function reportErrorSentry(
    description: string,
    priority = EventPriority.P2,
    error?: TBaasErrorResponse
  ) {
    sentry.captureError('Add Card failed', description, {
      tags: {
        correlation_id: error?.details?.correlation_id,
        error_message: error?.message,
        error_number: error?.details?.error_number,
        priority,
      },
    });
  }

  function reportEventSegment(
    event: string,
    description: string,
    eventData?: Record<string, string>
  ) {
    analytics.track(event, {
      ...eventData,
      context: 'add_card',
      description,
    });
  }

  function reportEventSentry(
    event: string,
    description: string,
    extra?: Record<string, unknown>
  ) {
    sentry.captureLog(event, description, {
      extra,
    });
  }

  function reportAddCardError(
    description: string,
    priority = EventPriority.P2,
    error?: TBaasErrorResponse
  ) {
    reportErrorSegment(description);
    reportErrorSentry(description, priority, error);
  }

  function reportAddCardEvent(
    event: string,
    description: string,
    extraSentry?: Record<string, unknown>,
    extraSegment?: Record<string, string>
  ) {
    reportEventSentry(event, description, extraSentry);
    reportEventSegment(event, description, extraSegment);
  }

  return { reportAddCardError, reportAddCardEvent };
}
