import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-l);
  margin-top: var(--spaces-xl);
`;

export const Styled = {
  Container,
  Footer,
};
