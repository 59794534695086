import styles from './loading-card.module.css';

export const LoadingCard = ({ loadingLabel }: { loadingLabel?: string }) => {
  return (
    <div
      aria-label={loadingLabel}
      className={styles['loading-card']}
      role="progressbar"
    >
      <div className={styles['card-wrapper']}>
        <div className={styles['card-loader']} />
      </div>

      <div className={styles['text-wrapper']}>
        <div className={styles['text-loader-long']} />
        <div className={styles['text-loader-short']} />
        <div className={styles['text-loader-short']} />
      </div>
    </div>
  );
};
