import { parse } from 'date-fns';

import { DateFormats } from '@/config/language';

import type { TFilters } from '../components/filters/filters';
import type { DateRange } from 'react-day-picker';
import type { FieldValues } from 'react-hook-form';

export const getDateRange = (formState: FieldValues | TFilters): DateRange => {
  const { dateFrom, dateTo } = formState;

  const from = !!dateFrom
    ? parse(dateFrom, DateFormats.DAY_MONTH_YEAR, new Date())
    : undefined;
  const to = !!dateTo
    ? parse(dateTo, DateFormats.DAY_MONTH_YEAR, new Date())
    : undefined;

  return { from, to };
};
