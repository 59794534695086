import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { GiftCardsDialogSuccessStates, useGiftCardsStore } from '@/store';

import { giftCardsQueryKeys } from '../gift-cards-query-keys';
import { useGetGiftCards } from '../use-get-gift-cards/use-get-gift-cards';

export function useDeleteGiftCardSuccess() {
  const queryClient = useQueryClient();
  const {
    setGiftCardsDialogSuccessState,
    setIsDeleteGiftCardPending,
    setIsOpenDeleteDialog,
    setIsViewGiftCardOpen,
    setSelectedGiftCard,
  } = useGiftCardsStore().use.actions();

  const getGiftCards = useGetGiftCards();

  const giftCardsDialogSuccessState =
    useGiftCardsStore().use.giftCardsDialogSuccessState();

  useEffect(() => {
    // Only hide the add gift card side sheet once we finished getting the updated max gift card slots
    if (
      !getGiftCards.isFetching &&
      giftCardsDialogSuccessState ===
        GiftCardsDialogSuccessStates.GIFT_CARD_DELETE
    ) {
      setIsDeleteGiftCardPending(false);
      setIsOpenDeleteDialog(false);
      setIsViewGiftCardOpen(false);
      setSelectedGiftCard(undefined);
    }
  }, [
    getGiftCards.isFetching,
    giftCardsDialogSuccessState,
    setIsDeleteGiftCardPending,
    setIsOpenDeleteDialog,
    setIsViewGiftCardOpen,
    setSelectedGiftCard,
  ]);

  return function useAddGiftCardSuccessCallback() {
    queryClient.invalidateQueries({
      queryKey: giftCardsQueryKeys.allCardsGiftcards,
    });
    setGiftCardsDialogSuccessState(
      GiftCardsDialogSuccessStates.GIFT_CARD_DELETE
    );
  };
}
