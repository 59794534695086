import { useForm } from 'react-hook-form';

import { defaultFilters } from '../../consts/filter-form';

export function useFilterFormInit() {
  return useForm({
    defaultValues: defaultFilters,
    reValidateMode: 'onChange',
  });
}
