import { styled } from 'styled-components';

const SkipButton = styled.a`
  background: var(--colors-brand1-lowest);
  border-radius: var(--radii-md);
  color: var(--colors-brand1-on-lowest);
  display: flex;
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-normal);
  left: 50%;
  line-height: var(--line-heights-normal);
  padding: var(--spaces-xs) var(--spaces-s);
  position: absolute;
  text-decoration: none;
  top: var(--spaces-m);
  transform: translateY(-1000%);

  &:focus {
    outline: 2px solid var(--colors-focus-light);
    transform: translateY(0) translateX(-50%);
  }
`;

export const Styled = { SkipButton };
