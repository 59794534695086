import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '../query-keys';

import { useAuthorizeCardError } from './use-authorize-card-error';
import { useAuthorizeCardRequest } from './use-authorize-card-request';
import { useAuthorizeCardSuccess } from './use-authorize-card-success';

import type { TBaasAddPaymentMethodErrors } from '../../types';
import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useAuthorizeCard(
  options?: UseMutationOptions<
    BankCardsTypes.Operations.AuthorizeBankCard.TResponse,
    TBaasErrorResponse<TBaasAddPaymentMethodErrors>,
    BankCardsTypes.Operations.AuthorizeBankCard.TRequest,
    unknown
  >
) {
  return useMutation({
    mutationFn: useAuthorizeCardRequest(),
    mutationKey: paymentMethodQueryKeys.cardsBankAddAuthorize,
    onError: useAuthorizeCardError(),
    onSuccess: useAuthorizeCardSuccess(),
    ...options,
  });
}
