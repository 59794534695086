import { useBankCardsStore } from '@/store';

import { cardManagementContent } from '../../language';
import { CardStatusPill } from '../card-status-pill/card-status-pill';
import { CardWarning } from '../card-warning';
import { EditCardNickname } from '../edit-card-nickname/edit-card-nickname';
import { ViewBankCardDisplay } from '../view-bank-card-display/view-bank-card-display';

import styles from './view-bank-card-body.module.css';

export function ViewBankCardBody() {
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();

  const isDefault = !!selectedBankCard?.isDefaultPay;
  const isVerified = !!selectedBankCard?.isVerified;

  const shouldShowPill = isDefault || !isVerified;

  return (
    <div className={styles['card-details-container']}>
      <ViewBankCardDisplay />

      <div className={styles['card-details']} role="list">
        <div className={styles['card-details-row']}>
          <div className={styles['card-details-column']} role="listitem">
            <div className={styles['card-details-column-label']}>
              Card number
            </div>
            <p className={styles['card-details-column-text']}>
              {' '}
              ••• {selectedBankCard?.data.visualization.last_four_digits}
            </p>
          </div>

          <div className={styles['card-details-column']} role="listitem">
            <div className={styles['card-details-column-label']}>Expiry</div>
            <p className={styles['card-details-column-text']}>
              {selectedBankCard?.data.expiry.month}/
              {selectedBankCard?.data.expiry.year.slice(-2)}
            </p>
          </div>

          <div className={styles['card-details-column']} role="listitem">
            {shouldShowPill && (
              <CardStatusPill
                isDefault={isDefault}
                status={selectedBankCard!.status}
              />
            )}
          </div>
        </div>

        <EditCardNickname />
      </div>

      {(!!selectedBankCard?.isValidated || !!selectedBankCard?.isInvalid) && (
        <CardWarning
          text={cardManagementContent.WHY_NOT_DELETE_MESSAGE()}
          title={cardManagementContent.WHY_NOT_DELETE_TITLE}
        />
      )}
    </div>
  );
}
