import { CloseFilledIcon, SearchIcon } from '@/components/icons';

import styles from './activity-header-search-input.module.css';

export type TSearchInputProps = React.HTMLAttributes<HTMLInputElement> & {
  onClickClear?: () => void;
  placeholder?: string;
  showClear?: boolean;
  value?: string;
};

export const SearchInput = ({
  id,
  onClickClear,
  showClear = false,
  ...props
}: TSearchInputProps) => {
  return (
    <div className={styles['search-input-container']}>
      <SearchIcon />
      <input className={styles['search-input']} id={id} {...props} />
      {showClear && (
        <button
          aria-label="Clear search"
          className={styles['clear-button']}
          onClick={onClickClear}
          type="reset"
        >
          <CloseFilledIcon
            color="var(--colors-on-surface-a)"
            height={16}
            width={16}
          />
        </button>
      )}
    </div>
  );
};
