import { DangerIcon } from '@/components/icons';

import { Styled } from './styles';

import type { JSX } from 'react';
type TErrorMessage = {
  text: JSX.Element | string;
};
export const ErrorMessage = ({ text }: TErrorMessage) => {
  return (
    <Styled.ErrorMessageWrapper>
      <Styled.ErrorMessageIconWrapper>
        <DangerIcon />
      </Styled.ErrorMessageIconWrapper>
      <Styled.ErrorText>{text}</Styled.ErrorText>
    </Styled.ErrorMessageWrapper>
  );
};
