import { useAuth } from 'react-oidc-context';

import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/bank-cards/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from '../endpoints';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useSetDefaultCardRequest(cardId: string) {
  const auth = useAuth();

  return async function setDefaultCardRequest() {
    return await request<BankCardsTypes.Operations.SetDefaultBankCard.TResponse>(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        data: {
          is_default_pay: true,
        },
        method: RequestMethod.PATCH,
      },
      {
        priority: EventPriority.P3,
        requestKey: PaymentMethodEndpointKeys.CARD_UPDATE_DEFAULT,
        transaction: paymentMethodTransactionKeys.cardUpdateDefault,
      }
    );
  };
}
