import { Form } from '@/components';
import { useBankCardsStore } from '@/store';

import { useUpdateCardNickname } from '../../services/use-update-card-nickname/use-update-card-nickname';

import { EditCardNicknameBody } from './edit-card-nickname-body';
import { EditCardNicknameFormFooter } from './edit-card-nickname-footer';
import { useEditCardNicknameFormInit } from './use-edit-card-nickname-form-init';

import type { FieldValues } from 'react-hook-form';

export function EditCardNicknameForm() {
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();
  const form = useEditCardNicknameFormInit(selectedBankCard?.alias || '');
  const updateCardNickname = useUpdateCardNickname(selectedBankCard!.id);

  const onSubmitForm = async ({ cardAlias }: FieldValues) => {
    updateCardNickname.mutate({
      alias: cardAlias || null,
    });
  };

  return (
    <div>
      <Form config={form} onSubmitForm={onSubmitForm}>
        <EditCardNicknameBody />
        <EditCardNicknameFormFooter updateCardNickname={updateCardNickname} />
      </Form>
    </div>
  );
}
