import type {
  TGiftCardsDialogErrorState,
  TGiftCardsDialogSuccessState,
  TGiftCardsStoreSet,
} from './gift-cards-store-types';
import type { GiftCardTypes } from '@/features/gift-cards';

export const giftCardsStoreActions = (set: TGiftCardsStoreSet) => ({
  setCurrentGiftCard: (
    currentGiftCard: GiftCardTypes.Components.TGiftCard | undefined
  ) => set({ currentGiftCard }),
  setGiftCardsDialogErrorState: (
    giftCardsDialogErrorState: TGiftCardsDialogErrorState
  ) => set({ giftCardsDialogErrorState }),
  setGiftCardsDialogSuccessState: (
    giftCardsDialogSuccessState: TGiftCardsDialogSuccessState
  ) => set({ giftCardsDialogSuccessState }),
  setIsAddGiftCardOpen: (isAddGiftCardOpen: boolean) =>
    set({ isAddGiftCardOpen }),
  setIsAddGiftCardPending: (isAddGiftCardPending: boolean) =>
    set({ isAddGiftCardPending }),
  setIsDeleteGiftCardPending: (isDeleteGiftCardPending: boolean) =>
    set({ isDeleteGiftCardPending }),
  setIsOpenDeleteDialog: (isOpenDeleteDialog: boolean) =>
    set({ isOpenDeleteDialog }),
  setIsOpenGiftCardsDialogError: (isOpenGiftCardsDialogError: boolean) =>
    set({ isOpenGiftCardsDialogError }),
  setIsOpenGiftCardsDialogSuccess: (isOpenGiftCardsDialogSuccess: boolean) =>
    set({ isOpenGiftCardsDialogSuccess }),
  setIsViewEligibleGiftCardsOpen: (isViewEligibleGiftCardsOpen: boolean) =>
    set({ isViewEligibleGiftCardsOpen }),
  setIsViewGiftCardOpen: (isViewGiftCardOpen: boolean) =>
    set({ isViewGiftCardOpen }),
  setSelectedGiftCard: (
    selectedGiftCard: GiftCardTypes.Components.TGiftCard | undefined
  ) => set({ selectedGiftCard }),
});
