import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { ExclamationMarkTriangleIcon } from '@/components/icons';
import { genericContent } from '@/config/language';
import { Routes } from '@/routes/constants';

import { CustomerService } from '../customer-service';

import { Container, Heading, InformationWrapper, Paragraph } from './styles';

interface IProps {
  message: string;
  title: string;
}

export const ErrorSection = ({ message, title }: IProps) => {
  const navigate = useNavigate();
  return (
    <Container>
      <InformationWrapper>
        <ExclamationMarkTriangleIcon color="var(--colors-error-high)" />
        <div>
          <Heading>{title}</Heading>
          <Paragraph>{message}</Paragraph>
          <Paragraph>
            {genericContent.PAGE_NOT_FOUND_CONTACT}{' '}
            <CustomerService capitalise />
          </Paragraph>
        </div>
        <Button
          level="primary"
          onClick={() => navigate(Routes.HOME)}
          size="medium"
          style={{ width: 'auto' }}
        >
          {genericContent.BACK_TO_APP}
        </Button>
      </InformationWrapper>
    </Container>
  );
};
