// Below denotes errors under BaaS service
// For reference, please visit the BaaS Service Errors page
// https://auspayplus.atlassian.net/wiki/spaces/BAAS/pages/2249818642/Release

// If the Confluence page isn't updated, you can refer to BaaS repo for the errors
// https://gitlab.com/auspayplus/open-payments/platform/baas/-/blob/develop/outputs/errors/errors.merged.json

export const baasServiceErrors = [
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format, then try again',
    },
    description:
      'Payment method service errored while creating a payment method',
    error_code: 'PAYMENT_METHOD_CREATE_ERROR',
    error_number: 400001010,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the key alias or key id {{entity_id}} is correct',
    },
    description: 'Getting public key from DB returned error',
    error_code: 'KMS_GET_PUBLIC_KEY_ERROR',
    error_number: 400001011,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Payment method service returned error',
    error_code: 'PAYMENT_METHOD_LIST_ERROR',
    error_number: 400001012,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request that was made to Engagement in order to fetch the user was valid and follows the expected schema',
    },
    description:
      'There was a problem with the request for fetching the Engagement user payment defaults',
    error_code: 'ENGAGEMENT_GET_DEFAULT_BAD_REQUEST',
    error_number: 400001014,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the payload with payment method {{entity_id}} given matches the expected format',
    },
    description:
      'Payment method provided could not be made default in Engagement',
    error_code: 'ENGAGEMENT_MAKE_DEFAULT_ERROR',
    error_number: 400001016,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the response given matches the expected format',
    },
    description: 'Engagement returned an unexpected response payload',
    error_code: 'ENGAGEMENT_MAKE_DEFAULT_BAD_RESPONSE',
    error_number: 400001017,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request was made to Payment Methods API with Id {{entity_id}}',
    },
    description:
      'Payment method service errored while deleting a payment method',
    error_code: 'PAYMENT_METHOD_DELETE_ERROR',
    error_number: 400001018,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request was made to Payment Methods API with Id {{entity_id}}',
    },
    description:
      'Payment method service errored while updating a payment method',
    error_code: 'PAYMENT_METHOD_UPDATE_ERROR',
    error_number: 400001019,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request was made to Payment Methods API with Id {{entity_id}}',
    },
    description:
      'Payment method service errored while getting a payment method',
    error_code: 'PAYMENT_METHOD_GET_ERROR',
    error_number: 400001022,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the key alias or key id {{entity_id}} is correct',
    },
    description: 'Getting public key from KMS returned error',
    error_code: 'KMS_RETRIEVE_PUBLIC_KEY_ERROR',
    error_number: 400001023,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The loyalty card payload received is not valid during create',
    error_code: 'LOYALTY_CARD_VALIDATION_ERROR_CREATE',
    error_number: 400001026,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the loyalty program {{entity_id}} is supported',
    },
    description:
      'The loyalty program live data provided is not supported during initiate link',
    error_code: 'LOYALTY_PROGRAM_NOT_SUPPORTED_INITIATE',
    error_number: 400001027,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the loyalty card {{entity_id}} is linked before trying to unlink',
    },
    description:
      'Loyalty card could not be unlinked as it is not in LINKED status',
    error_code: 'LOYALTY_CARD_NOT_LINKED_UNLINK',
    error_number: 400001030,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Refresh the loyalty credentials and try again. You might have to reauthenticate with the loyalty provider.',
    },
    description: 'Generating an access token for the loyalty program errored',
    error_code: 'LOYALTY_ACCESS_TOKEN_ERROR',
    error_number: 400001032,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description:
      'The loyalty card payload received is not valid during updating',
    error_code: 'LOYALTY_CARD_VALIDATION_ERROR_UPDATE',
    error_number: 400001033,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given loyalty program {{entity_id}} is of a custom program',
    },
    description: "Vendors can't be updated for known loyalty cards",
    error_code: 'LOYALTY_CARD_KNOWN_VENDOR_UPDATE_ERROR',
    error_number: 400001037,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description:
      'The loyalty card payload received is not valid during complete link',
    error_code: 'LOYALTY_CARD_VALIDATION_ERROR_COMPLETE_LINK',
    error_number: 400001038,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the loyalty program {{entity_id}} is supported',
    },
    description:
      'The loyalty program provided is not supported for live data during complete link',
    error_code: 'LOYALTY_PROGRAM_LIVE_DATA_NOT_SUPPORTED_COMPLETE',
    error_number: 400001040,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the loyalty program {{entity_id}} is supported',
    },
    description:
      'The loyalty program provided live data is not supported during unlink',
    error_code: 'LOYALTY_PROGRAM_NOT_SUPPORTED_UNLINK',
    error_number: 400001041,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request was made to the loyalty program API and that program ID {{entity_id}} is in the correct format',
    },
    description:
      'Loyalty program API error during checking if program ID is valid or not',
    error_code: 'LOYALTY_PROGRAM_VALID_CHECK_ERROR',
    error_number: 400001045,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description:
      'The loyalty program payload received is not valid for updating credentials',
    error_code: 'LOYALTY_CARD_VALIDATION_ERROR_UPDATE_CREDENTIALS',
    error_number: 400001047,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the loyalty program {{entity_id}} is supported',
    },
    description:
      'The loyalty program provided live data is not supported for updating credentials',
    error_code: 'LOYALTY_PROGRAM_NOT_SUPPORTED_UPDATE_CREDENTIALS',
    error_number: 400001048,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    description: 'Given refresh token could not be utilized',
    error_code: 'LOYALTY_CARD_INVALID_TOKEN',
    error_number: 400001050,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    description: 'Live data not supported by loyalty program {{entity_id}}',
    error_code: 'LOYALTY_SERVICE_UNLINK_PROGRAM_NOT_SUPPORTED',
    error_number: 400001051,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the loyalty card {{entity_id}} is linked before trying to unlink',
    },
    description:
      'Loyalty card {{entity_id}} could not be unlinked as it is not in LINKED status',
    error_code: 'LOYALTY_SERVICE_UNLINK_CARD_NOT_LINKED',
    error_number: 400001052,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        "Ensure that the loyalty card {{entity_id}}'s status is not static",
    },
    description:
      'The loyalty program provided is not supported for live data during complete link',
    error_code: 'LOYALTY_CARD_LIVE_DATA_NOT_SUPPORTED_COMPLETE',
    error_number: 400001054,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request was made to the loyalty program API for program {{entity_id}} and that it exists',
    },
    description: 'Loyalty program API errored during getting a loyalty program',
    error_code: 'LOYALTY_PROGRAM_GET_SERVICE_ERROR',
    error_number: 400001055,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request was made to Flybuys API for program {{entity_id}}',
    },
    description: 'Unlink failed on Flybuys side',
    error_code: 'LOYALTY_UNLINK_FLYBUY_SERVICE_ERROR',
    error_number: 400001056,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the provided program {{entity_id}} is supported',
    },
    description: 'Given loyalty program id is not supported',
    error_code: 'LOYALTY_PROGRAM_NOT_SUPPORTED_CREATE',
    error_number: 400001057,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the user {{user_id}} and payment method {{payment_method_id}} are correct and try again!',
    },
    description: 'Payment method service returned error',
    error_code: 'PAYMENT_METHOD_DELETE_INTERNAL_ERROR',
    error_number: 400001058,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request was made to Flybuys API and that {{entity_id}} is correct',
    },
    description: 'Error occurred during linking user for Flybuys',
    error_code: 'FLYBUYS_SERVICE_LINK_ERROR',
    error_number: 400001061,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request was made to Flybuys API and that {{entity_id}} is correct',
    },
    description: 'Error occurred during unlinking user for Flybuys',
    error_code: 'FLYBUYS_SERVICE_UNLINK_ERROR',
    error_number: 400001062,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the call was made to Flybuys API',
    },
    description: 'Error occurred during getting balance for Flybuys',
    error_code: 'FLYBUYS_SERVICE_BALANCE_ERROR',
    error_number: 400001063,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please check the order {{qr_code}}, wallet {{wallet_id}} and user {{user_id}} are correct and try again!',
    },
    description: 'Error occurred during creating order entity for QR order',
    error_code: 'QR_CREATE_ORDER_ENTITY_ERROR',
    error_number: 400001066,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please check the order {{qr_code}}, wallet {{wallet_id}} and user {{user_id}} are correct and try again!',
    },
    description: 'Error occurred during updating order entity for QR order',
    error_code: 'QR_UPDATE_ORDER_ENTITY_ERROR',
    error_number: 400001067,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please check the order {{qr_code}}, wallet {{wallet_id}} and user {{user_id}} are correct and try again!',
    },
    description: 'Error occurred during getting order entity for QR order',
    error_code: 'QR_GET_ORDER_ENTITY_ERROR',
    error_number: 400001068,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Unable to claim order {{qr_code}}, please check the original error message and try again!',
    },
    description: 'Error occurred during claiming QR order.',
    error_code: 'QR_CLAIM_ERROR',
    error_number: 400001069,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Unable to update order {{qr_code}}, please check the original error message and try again!',
    },
    description: 'Error occurred during updating QR order.',
    error_code: 'QR_UPDATE_ERROR',
    error_number: 400001070,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Unable to authorize order {{qr_code}}, please check the original error message and try again!',
    },
    description: 'Error occurred during authorizing QR order.',
    error_code: 'QR_AUTHORIZE_ERROR',
    error_number: 400001071,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Unable to abort order {{qr_code}}, please check the original error message and try again!',
    },
    description: 'Error occurred during aborting QR order.',
    error_code: 'QR_ABORT_ERROR',
    error_number: 400001072,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Unable to finalize order {{qr_code}}, please check the original error message and try again!',
    },
    description: 'Error occurred during finalizing QR order.',
    error_code: 'QR_FINALIZE_ERROR',
    error_number: 400001073,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Unable to get order summary for order {{qr_code}}, please check the original error message and try again!',
    },
    description: 'Error occurred during getting QR order summary.',
    error_code: 'QR_GET_ORDER_SUMMARY_ERROR',
    error_number: 400001074,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Unable to make callback request for order {{qr_code}}, please check the original error message and try again!',
    },
    description: 'Error occurred during updating QR order callback.',
    error_code: 'QR_UPDATE_CALLBACK_ERROR',
    error_number: 400001075,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the {{entity_id}} is valid and try again!',
    },
    description: 'Error occurred during linking Flybuys.',
    error_code: 'FLYBUYS_SERVICE_LINK_BAD_REQUEST_ERROR',
    error_number: 400001076,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the {{entity_id}} is valid and try again!',
    },
    description: 'Error occurred during unlinking Flybuys.',
    error_code: 'FLYBUYS_SERVICE_UNLINK_BAD_REQUEST_ERROR',
    error_number: 400001077,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the parameters are valid and try again try',
    },
    description: 'Orders list orders received invalid parameters.',
    error_code: 'ORDER_LIST_BAD_REQUEST',
    error_number: 400001078,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request that was made to Engagement in order to fetch the user was valid and follows the expected schema',
    },
    description:
      'There was a problem with the request for fetching the Engagement user payment settings for admin',
    error_code: 'ENGAGEMENT_ADMIN_GET_PAYMENTS_SETTINGS_BAD_REQUEST',
    error_number: 400001082,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request that was made to Engagement in order to fetch the user was valid and follows the expected schema',
    },
    description:
      'There was a problem with the request for fetching the Engagement user payment defaults for admin',
    error_code: 'ENGAGEMENT_GET_PROFILE_WALLET_USER_BAD_REQUEST',
    error_number: 400001084,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        '"user_id" should be an UUID optionally prefixed by the IDP name with `|`',
    },
    description:
      'There was a problem with the request for fetching the user payment settings for admin',
    error_code: 'ADMIN_GET_PAYMENT_SETTINGS_VALIDATION_ERROR',
    error_number: 400001085,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that "include_deleted" query param is true or false',
    },
    description: 'Invalid query parameter value for include_deleted',
    error_code: 'ADMIN_LIST_PAYMENT_METHODS_QUERY_VALIDATION_ERROR',
    error_number: 400001087,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that "include_deleted" query param is true or false',
    },
    description: 'Invalid query parameter value for include_deleted',
    error_code: 'ADMIN_GET_PAYMENT_METHODS_QUERY_VALIDATION_ERROR',
    error_number: 400001088,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Please check the cipher text is correct and try again.',
    },
    description: 'There is an issue when decrypting the cipher text!',
    error_code: 'KMS_DECRYPT_ERROR',
    error_number: 400001091,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the payment method with id {{id}} exists, belongs to the user making the request, then try again.',
    },
    description:
      'One of the payment methods provided could not be detokenized or the payment method does not exist.',
    error_code: 'QR_AUTHORIZE_DETOKENIZE_CARD_ERROR',
    error_number: 400001094,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the payment method with id {{id}} exists, then try again.',
    },
    description:
      'The cyphertext of one of the payment methods provided could not be decrypted',
    error_code: 'QR_AUTHORIZE_DECRYPT_CARD_ERROR',
    error_number: 400001095,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        "Verify that Opapi's public key is reachable, payment method with id {{id}} exists, then try again.",
    },
    description:
      'The data of one of the payment methods provided could not be encrypted.',
    error_code: 'QR_AUTHORIZE_ENCRYPT_CARD_ERROR',
    error_number: 400001096,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Remove one bank card from your wallet and try again',
    },
    description:
      'The user has reached the maximum number of bank cards allowed.',
    error_code: 'PAYMENT_METHOD_CREATE_MAX_CARD_NUMBER_ERROR',
    error_number: 400001101,
    http_status: 400,
    message: 'The user has reached the maximum number of bank cards allowed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        "Ensure that the bank card with id '{{id}}' is a valid bank card. The given card is {{status}}.",
    },
    description:
      'Error during getting the details of the card specified during authorization.',
    error_code: 'QR_AUTHORIZE_INVALID_CARD_ERROR',
    error_number: 400001102,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description:
      'The payload for loyalty program validation data received is not valid',
    error_code: 'LOYALTY_PROGRAM_VALIDATION_PAYLOAD_ERROR',
    error_number: 400001103,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the loyalty program id is allowed for validation data',
    },
    description:
      'The blacklist action not allowed for the loyalty program {{program_id}}',
    error_code: 'LOYALTY_PROGRAM_VALIDATION_BLACKLIST_REQUEST_ERROR',
    error_number: 400001105,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the loyalty card with payment_method_id {{entity_id}} is not linked to the user. If not, perform the link, then try again',
    },
    description: 'The loyalty card is not linked to the user.',
    error_code: 'QR_AUTHORIZE_LOYALTY_NOT_LINKED_ERROR',
    error_number: 400001107,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        "Ensure that the payload is valid for the given program '{{program_name}}'",
    },
    description: 'The loyalty card data received is not valid',
    error_code: 'LOYALTY_CARD_FORBIDDEN_ERROR_CREATE',
    error_number: 400001110,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the account {{program_identifier}} is valid for the given program',
    },
    description: 'The loyalty card account is forbidden',
    error_code: 'LOYALTY_CARD_FORBIDDEN_NOT_ACTIVE_CREATE',
    error_number: 400001111,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'The maximum number of users ({{program_limit}}) for account {{program_identifier}} has been reached.',
    },
    description:
      'Unable to add the loyalty card. Program is limited to authorised users.',
    error_code: 'LOYALTY_CARD_FORBIDDEN_OVER_LIMIT_CREATE',
    error_number: 400001112,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the entity {{entity_id}} has granted consent to most recent agreement for {{wallet_id}}',
    },
    description: 'The user has not consented to the required agreement',
    error_code: 'ACCEPTANCE_SERVICE_CONSENT_NOT_GRANTED',
    error_number: 400001115,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the user has an active profile in Engagement DB and try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to retrieve the details of the current user.',
    error_code: 'ENGAGEMENT_GET_PROFILE_BAD_REQUEST',
    error_number: 400001117,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the user has an active profile in Engagement DB and try again. If the issue persists, contact customer support.',
    },
    description:
      'An unexpected error was returned by Engagement API while attempting to retrieve the details of the current user.',
    error_code: 'ENGAGEMENT_GET_PROFILE_ERROR',
    error_number: 400001118,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Refresh the loyalty credentials and try again. You might have to reauthenticate with the loyalty provider.',
    },
    description: 'Loyalty provided access token did not match compared token',
    error_code: 'LOYALTY_ACCESS_TOKEN_MISMATCH',
    error_number: 400001122,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the payment method payload given matches the expected format',
    },
    description: 'Payment method is not supported',
    error_code: 'PAYMENT_METHOD_CREATE_PAYMENT_METHOD_NOT_SUPPORTED_ERROR',
    error_number: 400001123,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the payment method payload given matches the expected format',
    },
    description: 'Payment method failed to add card during creation',
    error_code: 'PAYMENT_METHOD_CREATE_ADD_CARD_FAILURE_ERROR',
    error_number: 400001124,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the payment method payload given matches the expected format',
    },
    description: 'Payment method verification failed during creation',
    error_code: 'PAYMENT_METHOD_CREATE_VERIFICATION_FAILED_ERROR',
    error_number: 400001125,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the payment method payload given matches the expected format',
    },
    description: 'Payment method validation failed during creation',
    error_code: 'PAYMENT_METHOD_CREATE_VALIDATION_FAILED_ERROR',
    error_number: 400001126,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Check loyalty program settings and wallet config to ensure that the requested loyalty program exists and that the current wallet has access to it.',
    },
    description:
      'The requested loyalty program is not available to this client',
    error_code: 'LOYALTY_PROGRAM_NOT_AVAILABLE',
    error_number: 400001128,
    http_status: 400,
    message:
      'Loyalty program {{entity_id}} is not available to wallet {{wallet_id}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        "Ensure the loyalty scheme is allowed in the allowed_payment_instruments listed in the order. If it isn't, this card cannot be used for burning points with this merchant",
    },
    description:
      'The loyalty card with id {{loyalty_id}} and scheme {{loyalty_scheme}} is not allowed to burn points with this merchant.',
    error_code: 'QR_AUTHORIZE_LOYALTY_NOT_ALLOWED_TO_BURN_ERROR',
    error_number: 400001130,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the Authorization reference Id is present in the request.',
    },
    description: 'Authorization reference Id missing',
    error_code: 'PAYMENT_METHOD_V3_AUTHENTICATE_MISSING_ID',
    error_number: 400001133,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the query params are correct',
    },
    description: 'The query params with the request are invalid',
    error_code: 'PAYMENT_METHOD_V3_AUTHENTICATE_INVALID_PARAMS',
    error_number: 400001136,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format, then try again',
    },
    description:
      'Payment method service errored while authorizing a payment method',
    error_code: 'PAYMENT_METHOD_V3_AUTHORIZE_ERROR',
    error_number: 400001137,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while finalizing a payment method',
    error_code: 'PAYMENT_METHOD_V3_FINALIZE_ERROR',
    error_number: 400001139,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the Three D Secure Id is present in the request.',
    },
    description: 'Three D Secure Id missing',
    error_code: 'PAYMENT_METHOD_V3_INIT_AUTHENTICATE_MISSING_ID',
    error_number: 400001141,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure the card identifier is correct and try again.',
    },
    description:
      'The blacklist action not allowed for this card {{barcode}} as it was never been whitelisted before.',
    error_code:
      'LOYALTY_PROGRAM_VALIDATION_BLACKLIST_NOT_WHITELIST_BEFORE_ERROR',
    error_number: 400001151,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the key alias or key id {{entity_id}} is correct',
    },
    description: 'Rotate Kms key for card encryption',
    error_code: 'KMS_KEY_ROTATION_ERROR',
    error_number: 400001152,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please check the merchant id {{merchant_id}} is valid and try again!',
    },
    description: 'Error occurred while retrieving merchant logo',
    error_code: 'QR_GET_MERCHANT_LOGO_ERROR',
    error_number: 400001154,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the gift card provider id is correct and in the right format',
    },
    description: 'The gift card provider id {{entity_id}} is invalid',
    error_code: 'GIFT_CARD_PROGRAM_INVALID_PROVIDER_ID',
    error_number: 400001158,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Remove an existing gift card before adding a new one',
    },
    description:
      'Unable to add gift card, the maximum allowed gift card count of {{max_count}} has been reached',
    error_code: 'GIFT_CARD_COUNT_EXCEEDED_ERROR',
    error_number: 400001161,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure the total value of the gift cards does not exceed the maximum allowed amount',
    },
    description:
      'Unable to add gift card, the total maximum allowed amount of {{max_amount}} will be exceeded by adding this card',
    error_code: 'GIFT_CARD_AMOUNT_EXCEEDED_ERROR',
    error_number: 400001162,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure the provided gift card is active',
    },
    description: 'Gift card is inactive',
    error_code: 'GIFT_CARD_INACTIVE_STATUS_ERROR',
    error_number: 400001163,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure the program id provided supports the gift card being added',
    },
    description:
      'Gift card is not supporrted by the provided program id {{entity_id}}',
    error_code: 'GIFT_CARD_BIN_MISMATCH_ERROR',
    error_number: 400001164,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure the program id provided has a bin list',
    },
    description: 'No bin list found for the provided program',
    error_code: 'GIFT_CARD_BIN_LIST_NOT_FOUND_ERROR',
    error_number: 400001165,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Check gift card program settings and wallet config to ensure that the requested gift card program exists and that the current wallet has access to it.',
    },
    description:
      'The requested gift card program is not accepted by the wallet used',
    error_code: 'GIFT_CARD_PROGRAM_NOT_ACCEPTED',
    error_number: 400001166,
    http_status: 400,
    message:
      'Gift card program {{entity_id}} is not accepted by wallet {{wallet_id}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure the gift card data provided corresponds with a valid gift card program',
    },
    description: 'Gift card not supported by any known programs',
    error_code: 'GIFT_CARD_NO_MATCHING_PROGRAM',
    error_number: 400001168,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while checking balance for gift card',
    error_code: 'PAYDOCK_CHECK_BALANCE_ERROR',
    error_number: 400001169,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure the provided gift card has a valid positive balance',
    },
    description: 'Unable to add a gift card with a balance of 0',
    error_code: 'GIFT_CARD_ADD_INVALID_BALANCE',
    error_number: 400001171,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Check the payment method with id {{id}} is valid, then try again.',
    },
    description:
      'The provided scheme card is either unsupported or in an invalid state',
    error_code: 'QR_AUTHORIZE_INVALID_SCHEME_CARD',
    error_number: 400001173,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while creating vault token for gift card',
    error_code: 'PAYDOCK_CREATE_VAULT_TOKEN_ERROR',
    error_number: 400001174,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure the provided gift card is not expired',
    },
    description: 'Gift card is expired',
    error_code: 'GIFT_CARD_EXPIRED_STATUS_ERROR',
    error_number: 400001176,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure the provided gift card is not suspended',
    },
    description: 'Gift card is suspended',
    error_code: 'GIFT_CARD_SUSPENDED_STATUS_ERROR',
    error_number: 400001177,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure the provided gift card is not cancelled',
    },
    description: 'Gift card is cancelled',
    error_code: 'GIFT_CARD_CANCELLED_STATUS_ERROR',
    error_number: 400001178,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Remove one bank card from your wallet and try again',
    },
    description:
      'The user has reached the maximum number of bank cards allowed.',
    error_code: 'PAYMENT_METHOD_AUTHORIZE_MAX_CARD_NUMBER_ERROR',
    error_number: 400001182,
    http_status: 400,
    message: 'The user has reached the maximum number of bank cards allowed.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_CLAIM_101',
      recovery:
        'The following order {{qr_code}} is not able to be claimed, please check the original error message and try again!',
    },
    description: 'Error occurred while making claim request to Opapi.',
    error_code: 'OPAPI_CLAIM_INVALID_WALLET',
    error_number: 400001801,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_CLAIM_106',
      recovery:
        'The following order {{qr_code}} is not able to be claimed, please check the original error message and try again!',
    },
    description: 'Error occurred while making claim request to Opapi.',
    error_code: 'OPAPI_CLAIM_ORDER_ID_NOT_LINKED_TO_ORDER',
    error_number: 400001802,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_CLAIM_120',
      recovery:
        'The following order {{qr_code}} is not able to be claimed, please check the original error message and try again!',
    },
    description: 'Error occurred while making claim request to Opapi.',
    error_code: 'OPAPI_CLAIM_RESOURCE_NOT_FOUND',
    error_number: 400001803,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_UPDATE_119',
      recovery:
        'The following order {{qr_code}} is not able to be updated, please check the original error message and try again!',
    },
    description: 'Error occurred while making update request to Opapi.',
    error_code: 'OPAPI_UPDATE_REQUEST_VALIDATION_FAILED',
    error_number: 400001810,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_UPDATE_149',
      recovery:
        'The following order {{qr_code}} is not able to be updated, please check the original error message and try again!',
    },
    description: 'Error occurred while making update request to Opapi.',
    error_code: 'OPAPI_UPDATE_INVALID_ORDER_STATUS_FOR_OPERATION',
    error_number: 400001811,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_ABORT_116',
      recovery:
        'The following order {{qr_code}} is not able to be aborted, please check the original error message and try again!',
    },
    description: 'Error occurred while making abort request to Opapi.',
    error_code: 'OPAPI_ABORT_INVALID_ORDER_STATUS_FOR_OPERATION',
    error_number: 400001814,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_ABORT_146',
      recovery:
        'The following order {{qr_code}} is not able to be aborted, please check the original error message and try again!',
    },
    description: 'Error occurred while making abort request to Opapi.',
    error_code: 'OPAPI_ABORT_ORDER_ID_NOT_LINKED_TO_WALLET',
    error_number: 400001815,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_AUTHORIZE_114',
      recovery:
        'The following order {{qr_code}} is not able to be authorized, please check the original error message and try again!',
    },
    description: 'Error occurred while making authorize request to Opapi.',
    error_code: 'OPAPI_AUTHORIZE_INVALID_ORDER_STATUS_FOR_OPERATION_STATE',
    error_number: 400001819,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_AUTHORIZE_119',
      recovery:
        'The following order {{qr_code}} is not able to be authorized, please check the original error message and try again!',
    },
    description: 'Error occurred while making authorize request to Opapi.',
    error_code: 'OPAPI_AUTHORIZE_REQUEST_VALIDATION_FAILED',
    error_number: 400001820,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_AUTHORIZE_149',
      recovery:
        'The following order {{qr_code}} is not able to be authorized, please check the original error message and try again!',
    },
    description: 'Error occurred while making authorize request to Opapi.',
    error_code: 'OPAPI_AUTHORIZE_INVALID_ORDER_STATUS_FOR_OPERATION_STATUS',
    error_number: 400001821,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_103',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_ORDER_EXPIRED',
    error_number: 400001829,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_107',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_PAYMENT_FAILED_3DS',
    error_number: 400001830,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_108',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_PAYMENT_FAILED_CARD_FAIL',
    error_number: 400001831,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_109',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_ORDER_AMOUNT_MISMATCH',
    error_number: 400001832,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_110',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_AUTHORIZATION_AMOUNT_MISMATCH',
    error_number: 400001833,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_116',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_INVALID_ORDER_STATUS_FOR_OPERATION_STATE',
    error_number: 400001834,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_117',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_INVALID_ORDER_STATUS_FOR_OPERATION_OTHER_STATE',
    error_number: 400001835,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_119',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_REQUEST_VALIDATION_FAILED',
    error_number: 400001836,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_131',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_MISSING_DETAILS_ORDER_ID',
    error_number: 400001837,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_134',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_INCORRECT_PAYMENT_TYPE_AMOUNT_VALUE',
    error_number: 400001838,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_135',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_INCORRECT_PAYMENT_TYPE_POINTS_VALUE',
    error_number: 400001839,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_137',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_REVERSAL_FAILED',
    error_number: 400001840,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_138',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_INVALID_PAYMENT',
    error_number: 400001841,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_139',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_INVALID_DATA_BAD_ACCOUNT_TYPE',
    error_number: 400001842,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_140',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_INVALID_DATA_MERCHANT_ID_CHANNEL',
    error_number: 400001843,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_146',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_ORDER_ID_NOT_LINKED_TO_WALLET',
    error_number: 400001844,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_147',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_MISSING_DETAILS_PAYMENT_AUTH',
    error_number: 400001845,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_155',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_MISSING_PAYMENT_CHANNEL',
    error_number: 400001846,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_156',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_ORDER_NOT_READY_FOR_PAYMENT',
    error_number: 400001847,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_158',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_MAXIMUM_ONE_LOYALTY_CARD',
    error_number: 400001848,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_160',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_ORDER_INVALID_STATE_FINALIZED',
    error_number: 400001849,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_UPDATE_162',
      recovery:
        'The following order {{qr_code}} is not able to be updated, please check the original error message and try again!',
    },
    description: 'Error occurred while making update request to Opapi.',
    error_code: 'OPAPI_UPDATE_MISSING_MANDATORY_USER_DETAILS',
    error_number: 400001852,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_AUTHORIZE_160',
      recovery:
        'The following order {{qr_code}} is not able to be authorized, please check the original error message and try again!',
    },
    description: 'Error occurred while making authorize request to Opapi.',
    error_code: 'OPAPI_AUTHORIZE_INVALID_ORDER_STATUS_FOR_OPERATION',
    error_number: 400001854,
    http_status: 400,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user {{entity_id}} exists in our system, or that the user_id is in the right format',
    },
    description: 'User could not be found in Engagement DB',
    error_code: 'ENGAGEMENT_PATCH_USER_NOT_FOUND',
    error_number: 400002007,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request that was made to Engagement in order to fetch the user was valid and follows the expected schema',
    },
    description:
      'There was a problem with the request for fetching the Engagement user',
    error_code: 'ENGAGEMENT_GET_USER_BAD_REQUEST',
    error_number: 400002008,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user is registered in Ping, it could be that this user is in Jupiter but not yet in Ping',
    },
    description:
      'There was a problem with the request for fetching the user from Ping',
    error_code: 'PING_GET_USER_ERROR',
    error_number: 400002009,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user is registered in Ping and linked to beem. it could be that this user is in Jupiter but not yet in Ping',
    },
    description:
      'There was a problem with the request for fetching the user from Ping using the Beem id',
    error_code: 'PING_GET_USER_BY_BEEM_ID_BAD_REQUEST',
    error_number: 400002010,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request that was made to Engagement in order to patch the user was valid and follows the expected schema',
    },
    description:
      'There was a problem with the request for patching user {{user_id}} Engagement',
    error_code: 'ENGAGEMENT_PATCH_USER_BAD_REQUEST',
    error_number: 400002011,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request that was made to Engagement in order to delete the user was valid and follows the expected schema',
    },
    description:
      'There was a problem with the request for deleting user {{user_id}} in Engagement',
    error_code: 'ENGAGEMENT_DELETE_USER_BAD_REQUEST',
    error_number: 400002012,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request that was made to Baas Payments in order to delete the user payment methods was valid and follows the expected schema',
    },
    description:
      'There was a problem with the request for deleting payment methods of user {{user_id}}',
    error_code: 'BAAS_PAYMENTS_DELETE_PAYMENT_METHOD_ERROR',
    error_number: 400002015,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user is registered in Ping, it could be that this user is in Jupiter but not yet in Ping',
    },
    description:
      'There was a problem with the request for deleting the user {{user_id}} from Ping',
    error_code: 'PING_DELETE_USER_BAD_REQUEST',
    error_number: 400002017,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Please ensure to provide the required param and try again!',
    },
    description:
      'The following required param {{required_param}} is missing in the request!',
    error_code: 'MISSING_REQUIRED_PARAMS',
    error_number: 400002018,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the user {{user_id}} is correct and try again!',
    },
    description:
      'There was a problem with the request for deleting user {{user_id}} from the platform',
    error_code: 'USER_UNABLE_TO_BE_DELETED_FROM_THE_PLATFORM',
    error_number: 400002019,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the user {{user_id}} is correct and try again!',
    },
    description:
      'There was a problem with the request to list address for user.',
    error_code: 'ERROR_LISTING_ADDRESSES_FOR_USER',
    error_number: 400002020,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user {{user_id}} and address {{address_id}} are correct and try again.',
    },
    description:
      'There was a problem with the request to delete address for user.',
    error_code: 'DELETE_ADDRESS_BAD_REQUEST',
    error_number: 400002021,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the userid {{user_id}} is valid and that the user has an active profile in Ping, then try again.',
    },
    description:
      'An error was returned by Ping while attempting to update a user.',
    error_code: 'PING_UPDATE_USER_ERROR',
    error_number: 400002022,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the userid {{user_id}}, the OTP provided ({{otp}}) is correct, then try again.',
    },
    description:
      'An error was returned by Ping while attempting to verify the email using the OTP provided.',
    error_code: 'PING_VERIFY_EMAIL_BAD_REQUEST_ERROR',
    error_number: 400002025,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the userid {{user_id}} is valid and that the user has an active profile in Ping, the device with id {{device_id}} exists, then try again.',
    },
    description:
      'An error was returned by Ping while attempting to activate an MFA device.',
    error_code: 'PING_ACTIVATE_MFA_DEVICE_ERROR',
    error_number: 400002028,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        "Ensure that the userid {{user_id}} is valid and is linked to a wallet with id '{{wallet_id}}'.",
    },
    description:
      'An error was returned by Engagement API while attempting to get a user by its id and given wallet_id.',
    error_code: 'ENGAGEMENT_USER_BY_ID_AND_WALLET_BAD_REQUEST',
    error_number: 400002035,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        "Ensure that the userid {{user_id}} is valid and is linked to a wallet with id '{{wallet_id}}'.",
    },
    description:
      'An error was returned by Engagement API while attempting to get a user by its id and given wallet_id.',
    error_code: 'ENGAGEMENT_USER_BY_ID_AND_WALLET_UNPROCESSABLE',
    error_number: 400002036,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        "Ensure that the userid {{user_id}} is valid and is linked to a wallet with id '{{wallet_id}}'. If the issue persists, contact customer support.",
    },
    description:
      'An unexpected error was returned by Engagement API while attempting to get a user by its id and given wallet_id.',
    error_code: 'ENGAGEMENT_USER_BY_ID_AND_WALLET_ERROR',
    error_number: 400002037,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the userid {{user_id}} is valid.',
    },
    description:
      'An error was returned by Engagement API while attempting to get a user by its id.',
    error_code: 'ENGAGEMENT_USER_BY_ID_BAD_REQUEST',
    error_number: 400002039,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the userid {{user_id}} is valid.',
    },
    description:
      'An error was returned by Engagement API while attempting to get a user by its id.',
    error_code: 'ENGAGEMENT_USER_BY_ID_UNPROCESSABLE',
    error_number: 400002040,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the contact details provided are correct, formatted correctly, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to get a user using the contact details provided.',
    error_code: 'ENGAGEMENT_GET_USER_BY_CONTACT_DETAILS_BAD_REQUEST',
    error_number: 400002043,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the wallet_id provided ({{wallet_id}}) is valid.',
    },
    description:
      'An error was returned by Engagement API while attempting to return the list of users linked to a given wallet.',
    error_code: 'ENGAGEMENT_LIST_USERS_BY_WALLET_ID_BAD_REQUEST',
    error_number: 400002045,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} and the wallet with id {{wallet_id}} exist, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to update the wallet link details for the user.',
    error_code: 'ENGAGEMENT_PATCH_USER_WALLET_BAD_REQUEST',
    error_number: 400002048,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to block a user.',
    error_code: 'ENGAGEMENT_BLOCK_USER_BAD_REQUEST',
    error_number: 400002051,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to unblock a user.',
    error_code: 'ENGAGEMENT_UNBLOCK_USER_BAD_REQUEST',
    error_number: 400002054,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, wallet with id {{wallet_id}} exists, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to restrict a wallet.',
    error_code: 'ENGAGEMENT_RESTRICT_USER_WALLET_BAD_REQUEST',
    error_number: 400002057,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, wallet with id {{wallet_id}} exists, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to unrestrict a wallet.',
    error_code: 'ENGAGEMENT_UNRESTRICT_USER_WALLET_BAD_REQUEST',
    error_number: 400002060,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user has an active profile in Engagement DB and try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to retrieve the details of the current user.',
    error_code: 'ENGAGEMENT_GET_PROFILE_BAD_REQUEST',
    error_number: 400002063,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user has an active profile in Engagement DB and try again. If the issue persists, contact customer support.',
    },
    description:
      'An unexpected error was returned by Engagement API while attempting to retrieve the details of the current user.',
    error_code: 'ENGAGEMENT_GET_PROFILE_ERROR',
    error_number: 400002064,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the request schema, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to create a user.',
    error_code: 'ENGAGEMENT_ADD_PROFILE_UNPROCESSABLE_REQUEST',
    error_number: 400002065,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the request schema, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to create a user.',
    error_code: 'ENGAGEMENT_ADD_PROFILE_BAD_REQUEST',
    error_number: 400002066,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user {{entity_id}} exists in our system, or that the user_id is in the right format.',
    },
    description:
      "An error was returned by Engagement API while attempting to update a user using Engagement's internal endpoint.",
    error_code: 'ENGAGEMENT_PATCH_USER_INTERNAL_BAD_REQUEST',
    error_number: 400002069,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the given user exists in Ping.',
    },
    description:
      'An error was returned by Ping while attempting to update a user.',
    error_code: 'PING_PATCH_USER_ERROR',
    error_number: 400002070,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with idp id {{idp_user_id}} (Beem id {{user_id}}) exists in Ping then try again.',
    },
    description:
      'No record containing the given email was found in Ping while attempting to resend the verification email to the user.',
    error_code: 'UPDATE_EMAIL_MISSING_EMAIL_RESEND_ERROR',
    error_number: 400002078,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'The user with id {{idp_user_id}} either does not exist in Ping or the mobile number {{mobile_number}} is not pending MFA activation',
    },
    description:
      'The user either does not exist in Ping or the mobile number provided is not pending MFA activation.',
    error_code: 'UPDATE_MOBILE_RESEND_MOBILE_NOT_FOUND_ERROR',
    error_number: 400002085,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the verification code provided is correct, then try again.',
    },
    description: 'The provided verification code is incorrect.',
    error_code: 'ADMIN_UPDATE_EMAIL_INITIATE_INVALID_CODE_BAD_REQUEST_ERROR',
    error_number: 400002090,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the mobile number {{mobile_number}} is not already an active MFA device.',
    },
    description:
      'Activation request for mobile number was given but the number is already activated for MFA',
    error_code: 'INITIATE_CHANGE_MOBILE_ALREADY_ACTIVE_BAD_REQUEST_ERROR',
    error_number: 400002092,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{idp_user_id}} exists in idp and the email {{email}} is not already active',
    },
    description:
      'The user either does not exist in Ping or the email is already active',
    error_code: 'INITIATE_EMAIL_CHANGE_BAD_REQUEST_ERROR',
    error_number: 400002098,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user {{user_id}} and wallet {{wallet_id}} are correct and try again!',
    },
    description:
      'There was a problem with the request to delete the user from the wallet.',
    error_code: 'ENGAGEMENT_DELETE_USER_WALLET_BAD_REQUEST',
    error_number: 400002101,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the user {{user_id}} is correct and try again!',
    },
    description: 'There is no wallet linked to this user',
    error_code: 'NO_WALLETS_LINKED_ERROR',
    error_number: 400002102,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user {{user_id}} and wallet {{wallet_id}} are valid and try again!',
    },
    description: 'There was a problem while deleting a user from the wallet.',
    error_code: 'DELETE_USER_WALLET_ERROR',
    error_number: 400002103,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'The user {{user_id}} is not linked to wallet {{wallet_id}}!',
    },
    description: 'There was a problem when deleting a user from the wallet',
    error_code: 'WALLET_NOT_LINKED_TO_USER_ERROR',
    error_number: 400002104,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the OTP provided ({{otp}}) is correct and that it was sent with the latest email, then try again.',
    },
    description: 'The OTP provided to Ping is not correct.',
    error_code: 'PING_ACTIVATE_MFA_DEVICE_WRONG_OTP',
    error_number: 400002106,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the request schema, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to migrate a user.',
    error_code: 'ENGAGEMENT_MIGRATE_PROFILE_UNPROCESSABLE_REQUEST',
    error_number: 400002114,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the request schema, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to migrate a user.',
    error_code: 'ENGAGEMENT_MIGRATE_PROFILE_BAD_REQUEST',
    error_number: 400002116,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the entity {{entity_id}} has granted consent to most recent agreement for {{wallet_id}}',
    },
    description: 'The user has not consented to the required agreement',
    error_code: 'ACCEPTANCE_SERVICE_CONSENT_NOT_GRANTED',
    error_number: 400002121,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request that was made to Baas Payments is valid and follows the expected schemain order to delete the user orders',
    },
    description:
      'There was a problem with the request for deleting orders for user {{user_id}}',
    error_code: 'BAAS_PAYMENTS_DELETE_USER_ORDERS_ERROR',
    error_number: 400002124,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the code being sent is exactly as presented in the email received, carefully checking characters such as uppercase I, lowercase L and numeric 1.',
    },
    description:
      'The security code is not valid for the current change request.',
    error_code: 'UPDATE_EMAIL_VERIFY_INVALID_CODE_ERROR',
    error_number: 400002125,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the request schema, then try again.',
    },
    description:
      'An error was returned by Engagement API while attempting to verify kyc lite data for a user.',
    error_code: 'ENGAGEMENT_VERIFY_KYC_LITE_UNPROCESSABLE',
    error_number: 400002138,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the user does not already have KYC lite data',
    },
    description:
      'An error was returned by Engagement API while attempting to verify the users kyc lite data',
    error_code: 'ENGAGEMENT_VERIFY_KYC_LITE_BAD_REQUEST',
    error_number: 400002140,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the mobile number {{mobile_number}} is not already an active MFA device.',
    },
    description:
      'Activation request for mobile number was given but the number is already activated for MFA',
    error_code: 'ADMIN_CHANGE_MOBILE_ALREADY_ACTIVE_BAD_REQUEST_ERROR',
    error_number: 400002145,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The wallet create data received is not valid',
    error_code: 'WALLET_CREATE_VALIDATION_ERROR',
    error_number: 400004003,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The Wallet configuration data received is not valid',
    error_code: 'WALLET_CONFIG_UPDATE_VALIDATION_ERROR',
    error_number: 400004005,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that version is provided as part of the request',
    },
    description: 'Version must be provided as part of the request',
    error_code: 'WALLET_CONFIG_UPDATE_NO_VERSION',
    error_number: 400004006,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that Wallet Id is provided as part of the request',
    },
    description: 'Wallet Id must be provided as part of the request',
    error_code: 'WALLET_CONFIG_UPDATE_NO_WALLET',
    error_number: 400004007,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that Client ID is provided as part of the request',
    },
    error_code: 'WALLET_CONFIG_INVALID_CLIENT_ID',
    error_number: 400004008,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the Client ID {{entity_id}} is using an active wallet configuration',
    },
    error_code: 'WALLET_CONFIG_NO_ACTIVE_VARIANT',
    error_number: 400004010,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The active variant data received is not valid',
    error_code: 'APPLY_ACTIVE_VARIANT_VALIDATION_ERROR',
    error_number: 400004011,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that Wallet Id is provided as part of the request',
    },
    description: 'Wallet Id must be provided as part of the request',
    error_code: 'WALLET_CREATE_CLIENT_NO_WALLET',
    error_number: 400004012,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure scopes {{entity_id}} are associated with the provided resource ID',
    },
    description:
      'The provided scope/s {{entity_id}} are not associated with the provided resource ID',
    error_code: 'SCOPES_NOT_LINKED_TO_RESOURCE',
    error_number: 400004014,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that the resource provided is allowed',
    },
    description: 'Unable to create application for the resource {{entity_id}}',
    error_code: 'WALLET_CREATE_CLIENT_INVALID_RESOURCE',
    error_number: 400004015,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while creating a client in ping',
    error_code: 'PING_CREATE_APPLICATION_ERROR',
    error_number: 400004017,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description:
      'Error occurred while getting scopes for the provided resource in ping ',
    error_code: 'PING_GET_SCOPES_ERROR',
    error_number: 400004018,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while assigning grants to application in ping',
    error_code: 'PING_ASSIGN_GRANTS_ERROR',
    error_number: 400004019,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while deleting an application in ping',
    error_code: 'PING_DELETE_APPLICATION_ERROR',
    error_number: 400004020,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description:
      'Error occurred while assigning policies to the application in ping',
    error_code: 'PING_ASSIGN_POLICIES_ERROR',
    error_number: 400004021,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while creating client in access control',
    error_code: 'ACCESS_CONTROL_CREATE_CLIENT_ERROR',
    error_number: 400004022,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the action request is valid.',
    },
    description: 'There was a problem processing the request',
    error_code: 'ACCEPTANCE_SERVICE_BAD_REQUEST_ERROR',
    error_number: 400004024,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'An error occurred while creating wallet',
    error_code: 'WALLET_CREATE_ERROR',
    error_number: 400004025,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the client ids {{entity_id}} are linked to the wallet',
    },
    description:
      'The clients with ids {{entity_id}} are not linked to the wallet',
    error_code: 'CLIENT_IDS_NOT_LINKED_TO_WALLET',
    error_number: 400004026,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while updating client in access control',
    error_code: 'ACCESS_CONTROL_UPDATE_CLIENT_ERROR',
    error_number: 400004027,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while updating a client in ping',
    error_code: 'PING_UPDATE_APPLICATION_ERROR',
    error_number: 400004028,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while retrieving application grants in ping',
    error_code: 'PING_GET_APPLICATION_GRANTS_ERROR',
    error_number: 400004030,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while updating application grant in ping',
    error_code: 'PING_UPDATE_APPLICATION_GRANT_ERROR',
    error_number: 400004031,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while deleting application grant in ping',
    error_code: 'PING_DELETE_APPLICATION_GRANT_ERROR',
    error_number: 400004032,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description:
      'Error occurred while deleting application policy assignment in ping',
    error_code: 'PING_DELETE_APPLICATION_POLICY_ERROR',
    error_number: 400004033,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while retrieving application policies in ping',
    error_code: 'PING_GET_APPLICATION_POLICIES_ERROR',
    error_number: 400004034,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that Wallet Id is provided as part of the request',
    },
    description: 'Wallet Id must be provided as part of the request',
    error_code: 'WALLET_UPDATE_CLIENT_NO_WALLET',
    error_number: 400004036,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that Wallet Id is provided as part of the request',
    },
    description: 'Client Id must be provided as part of the request',
    error_code: 'WALLET_UPDATE_CLIENT_NO_CLIENT',
    error_number: 400004037,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while revoking token',
    error_code: 'PING_REVOKE_TOKEN_ERROR',
    error_number: 400004043,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: "Error occurred while ending the user's SSO session",
    error_code: 'PING_SIGNOFF_ERROR',
    error_number: 400004044,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while retrieving application secret in ping',
    error_code: 'PING_GET_APPLICATION_SECRET_ERROR',
    error_number: 400004046,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The wallet update request is not valid',
    error_code: 'WALLET_UPDATE_VALIDATION_ERROR',
    error_number: 400004047,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the integration id {{integration_id}} is correct and try again!',
    },
    description: 'Integration {{integration_id}} is not valid!',
    error_code: 'INVALID_INTEGRATION',
    error_number: 400004048,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Please ensure the scope is valid, If the issue persists, contact customer support.',
    },
    description: 'The provided scope is invalid. Please try again.',
    error_code: 'PING_SERVICE_TOKEN_REQUEST_INVALID_SCOPE_ERROR',
    error_number: 400004055,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that the key alias or key id {{entity_id}} is correct',
    },
    description: 'Getting public key from KMS returned error',
    error_code: 'KMS_RETRIEVE_PUBLIC_KEY_ERROR',
    error_number: 400004056,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that the key alias or key id {{entity_id}} is correct',
    },
    description: 'Getting public key from KMS returned error',
    error_code: 'KMS_GET_PUBLIC_KEY_ERROR',
    error_number: 400004057,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the action {{consent_action}} is valid.',
    },
    description: 'There was a problem processing the request',
    error_code: 'ACCEPTANCE_SERVICE_BAD_REQUEST_ERROR',
    error_number: 400004120,
    http_status: 400,
    message: 'The request submitted is invalid and could not be processed.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'UNAUTHORIZED',
    error_number: 401001002,
    http_status: 401,
    message: 'The caller does not have permission to access this resource.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'LOYALTY_PROGRAM_UNAUTHORIZED_WALLET',
    error_number: 401001150,
    http_status: 401,
    message: 'The caller does not have permission to access this resource.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'OPAPI_AUTHORIZE_AUTHORIZATION_FAILED_CARD_FAIL_CREDENTIALS',
    error_number: 401001822,
    http_status: 401,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code:
      'OPAPI_AUTHORIZE_AUTHORIZATION_FAILED_CARD_FAIL_AUTH_MISSING_HEADER',
    error_number: 401001823,
    http_status: 401,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'OPAPI_AUTHORIZE_AUTHORIZATION_FAILED_AUTH_FORMAT_BASE64',
    error_number: 401001824,
    http_status: 401,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'OPAPI_AUTHORIZE_AUTHORIZATION_FAILED_AUTH_FORMAT',
    error_number: 401001825,
    http_status: 401,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'UNAUTHORIZED',
    error_number: 401002002,
    http_status: 401,
    message: 'The caller does not have permission to access this resource.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'UNAUTHORIZED',
    error_number: 401004002,
    http_status: 401,
    message: 'The caller does not have permission to access this resource.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'UNAUTHORIZED',
    error_number: 401004002,
    http_status: 401,
    message: 'The caller does not have permission to access this resource.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please ensure you have the correct permissions. Check: bearer_token scopes, audience and issuer.',
    },
    error_code: 'PING_SERVICE_TOKEN_REQUEST_INVALID_CREDENTIALS_ERROR',
    error_number: 401004052,
    http_status: 401,
    message: 'The caller does not have permission to access this resource.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please ensure the Authorization header, api-key or client credentials have been configured correctly in the request and that they are not expired.',
    },
    error_code: 'FORBIDDEN',
    error_number: 403001001,
    http_status: 403,
    message:
      'The request lacks valid authentication credentials for the requested resource.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please ensure the Authorization header, api-key or client credentials have been configured correctly in the request and that they are not expired.',
    },
    error_code: 'PROFILE_NOT_FOUND_FOR_WALLET',
    error_number: 403001086,
    http_status: 403,
    message:
      'The request lacks valid authentication credentials for the requested resource.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please ensure the Authorization header, api-key or client credentials have been configured correctly in the request and that they are not expired.',
    },
    error_code: 'FORBIDDEN',
    error_number: 403002001,
    http_status: 403,
    message:
      'The request lacks valid authentication credentials for the requested resource.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please ensure the Authorization header, api-key or client credentials have been configured correctly in the request and that they are not expired.',
    },
    error_code: 'ENGAGEMENT_ADD_PROFILE_FORBIDDEN_ERROR',
    error_number: 403002136,
    http_status: 403,
    message:
      'The request lacks valid authentication credentials for the requested resource.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please ensure the Authorization header, api-key or client credentials have been configured correctly in the request and that they are not expired.',
    },
    error_code: 'FORBIDDEN',
    error_number: 403004001,
    http_status: 403,
    message:
      'The request lacks valid authentication credentials for the requested resource.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please ensure the Authorization header, api-key or client credentials have been configured correctly in the request and that they are not expired.',
    },
    error_code: 'FORBIDDEN',
    error_number: 403004001,
    http_status: 403,
    message:
      'The request lacks valid authentication credentials for the requested resource.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'order_id {{entity_id}} is either invalid, or this order was not initiated using this service',
    },
    description: 'Order not found in the orders database',
    error_code: 'ORDER_NOT_FOUND',
    error_number: 404001004,
    http_status: 404,
    message: "The Orders Order with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user {{entity_id}} exists in our system, or that the user_id is in the right format',
    },
    description:
      'User could not be found in Engagement DB during getting default payments',
    error_code: 'ENGAGEMENT_GET_DEFAULT_USER_NOT_FOUND',
    error_number: 404001013,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given payment method {{entity_id}} exists in our system, or that the payment_method_id is in the right format',
    },
    description:
      'Payment Method could not be found in Payment Methods API during deleting',
    error_code: 'PAYMENT_METHOD_DELETE_NOT_FOUND',
    error_number: 404001015,
    http_status: 404,
    message: "The Payment Method with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given payment method {{entity_id}} exists in our system, or that the payment_method_id is in the right format',
    },
    description:
      'Payment Method could not be found in Payment Methods API during updating',
    error_code: 'PAYMENT_METHOD_UPDATE_NOT_FOUND',
    error_number: 404001020,
    http_status: 404,
    message: "The Payment Method with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given payment method {{entity_id}} exists in our system, or that the payment_method_id is in the right format',
    },
    description: 'Payment method could not be found',
    error_code: 'PAYMENT_METHOD_GET_NOT_FOUND',
    error_number: 404001021,
    http_status: 404,
    message: "The Payment Method with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the payment method {{entity_id}} exists in the engagement DB',
    },
    description: 'Payment method not found during making default in Engagement',
    error_code: 'ENGAGEMENT_MAKE_DEFAULT_NOT_FOUND',
    error_number: 404001024,
    http_status: 404,
    message: "The Payment Method with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given loyalty card {{entity_id}} exists in our system, or that the id is in the right format',
    },
    description: 'Loyalty card could not be found in the database during get',
    error_code: 'LOYALTY_CARD_NOT_FOUND_GET',
    error_number: 404001029,
    http_status: 404,
    message: "The Loyalty Card with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given loyalty card {{entity_id}} exists in our system, or that the id is in the right format',
    },
    description:
      'Loyalty card could not be found in the database during updating',
    error_code: 'LOYALTY_CARD_NOT_FOUND_UPDATE',
    error_number: 404001035,
    http_status: 404,
    message: "The Loyalty Card with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that a loyalty card for the program {{entity_id}} exists in the user wallet, then try again.',
    },
    description:
      'Loyalty card for the given program could not be found in the database during unlinking',
    error_code: 'LOYALTY_CARD_FOR_PROGRAM_NOT_FOUND_UNLINK',
    error_number: 404001042,
    http_status: 404,
    message: "The Loyalty Program with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given loyalty card {{entity_id}} exists in our system, or that the id is in the right format',
    },
    description:
      'Loyalty card could not be found in the database during setting default',
    error_code: 'LOYALTY_CARD_NOT_FOUND_SET_DEFAULT',
    error_number: 404001043,
    http_status: 404,
    message: "The Loyalty Program with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given loyalty card {{entity_id}} exists in the user wallet and that the id is in the right format, then try again.',
    },
    description:
      'Loyalty card could not be found in the user wallet during deletion',
    error_code: 'LOYALTY_CARD_NOT_FOUND_DELETE',
    error_number: 404001044,
    http_status: 404,
    message: "The Loyalty Program with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given loyalty card for the program is linked for user {{entity_id}}',
    },
    description: 'Loyalty card to unlink not found during updating credentials',
    error_code: 'LOYALTY_CARD_NOT_FOUND_UPDATE_CREDENTIALS',
    error_number: 404001049,
    http_status: 404,
    message: "The Loyalty Card with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the given user {{user_id}} is valid',
    },
    description: 'User for the given orders order could not be found.',
    error_code: 'ORDER_USER_NOT_FOUND',
    error_number: 404001080,
    http_status: 404,
    message: "The Orders Order with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user {{entity_id}} exists in our system, or that the user_id is in the right format',
    },
    description:
      'User could not be found in Engagement DB during getting default payment settings for admin',
    error_code: 'ENGAGEMENT_ADMIN_GET_PAYMENTS_SETTINGS_NOT_FOUND',
    error_number: 404001081,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user {{user_id}} or wallet {{wallet_id}} exists in our system, or that they are in the right format',
    },
    description:
      'User or wallet could not be found in Engagement DB during getting default payments for admin',
    error_code: 'ENGAGEMENT_GET_PROFILE_WALLET_USER_NOT_FOUND',
    error_number: 404001083,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Please contact admin for support!',
    },
    description: 'The key ARN is missing',
    error_code: 'KMS_KEY_ARN_NOT_FOUND',
    error_number: 404001090,
    http_status: 404,
    message: "The KMS with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the loyalty program {{entity_id}} is supported for this operation',
    },
    description: 'The loyalty program provided is not found',
    error_code: 'LOYALTY_PROGRAM_NOT_FOUND',
    error_number: 404001093,
    http_status: 404,
    message: "The Loyalty Program with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the loyalty program name is unique',
    },
    description: 'The name of the loyalty program is already in use',
    error_code: 'LOYALTY_PROGRAM_CONFLICT',
    error_number: 404001097,
    http_status: 404,
    message: "The Loyalty Program with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the loyalty program data provided is in the right format',
    },
    description: 'The loyalty program data is not valid',
    error_code: 'LOYALTY_PROGRAM_CREATE_VALIDIDATION_ERROR',
    error_number: 404001098,
    http_status: 404,
    message: "The Loyalty Program with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user {{entity_id}} exists in our system, or that the user_id is in the right format',
    },
    description:
      'User could not be found in Engagement DB during loyalty cards migrations',
    error_code: 'ENGAGEMENT_MIGRATE_LOYALTY_USER_NOT_FOUND',
    error_number: 404001100,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the loyalty card with payment_method_id {{entity_id}} exists in the user wallet, then try again.',
    },
    description: 'The loyalty card was not found',
    error_code: 'QR_AUTHORIZE_LOYALTY_NOT_FOUND_ERROR',
    error_number: 404001108,
    http_status: 404,
    message: "The loyalty card with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the user has an active profile in Engagement DB and try again.',
    },
    description: 'User could not be found in Engagement DB.',
    error_code: 'ENGAGEMENT_GET_PROFILE_NOT_FOUND',
    error_number: 404001116,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure the scheme name "{{loyalty_scheme}}" is in the correct format as specified in the API Spec with the correct case',
    },
    description: 'Loyalty Program Scheme not Found',
    error_code: 'MIGRATE_LOYALTY_CARD_PROGRAM_NOT_FOUND',
    error_number: 404001119,
    http_status: 404,
    message: "The Loyalty Program with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the requested loyalty card exists and that the current wallet has access to the program',
    },
    description: 'The requested loyalty card is not available to this client',
    error_code: 'LOYALTY_PROGRAM_NOT_AVAILABLE_TO_CARD',
    error_number: 404001129,
    http_status: 404,
    message: "The Loyalty Card with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the Three D Secure Id is correct',
    },
    description: 'Three D Secure Id {{entity_id}} can not be found',
    error_code: 'PAYMENT_METHOD_V3_INIT_AUTHENTICATE_ID_NOT_FOUND',
    error_number: 404001131,
    http_status: 404,
    message: 'Three D Secure Id {{entity_id}} can not be found',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the Authorization reference id is correct',
    },
    description: 'Authorization reference Id {{entity_id}} can not be found',
    error_code: 'PAYMENT_METHOD_V3_AUTHENTICATE_ID_NOT_FOUND',
    error_number: 404001134,
    http_status: 404,
    message: 'Authorization reference Id {{entity_id}} can not be found',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the card_art url provided is correct and points to a valid image in a supported format (e.g. JPG, PNG)',
    },
    description: 'The card art image cannot be found',
    error_code: 'GIFT_CARD_PROGRAM_INVALID_CARD_ART',
    error_number: 404001157,
    http_status: 404,
    message: 'The card art image cannot be found',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the Gift Card program {{entity_id}} is supported for this operation',
    },
    description: 'The Gift Card program provided is not found',
    error_code: 'GIFT_CARD_PROGRAM_NOT_FOUND',
    error_number: 404001159,
    http_status: 404,
    message:
      "The Gift Card Program with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the Gift Card {{entity_id}} is supported for this operation',
    },
    description: 'The Gift Card provided is not found',
    error_code: 'GIFT_CARD_NOT_FOUND',
    error_number: 404001172,
    http_status: 404,
    message: "The Gift Card with id '{{entity_id}}' could not be found.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_CLAIM_114',
      recovery:
        'The following order {{qr_code}} is not able to be claimed, please check the original error message and try again!',
    },
    description: 'Error occurred while making claim request to Opapi.',
    error_code: 'OPAPI_CLAIM_INVALID_ORDER_STATUS_FOR_OPERATION_STATE',
    error_number: 404001804,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_CLAIM_119',
      recovery:
        'The following order {{qr_code}} is not able to be claimed, please check the original error message and try again!',
    },
    description: 'Error occurred while making claim request to Opapi.',
    error_code: 'OPAPI_CLAIM_REQUEST_VALIDATION_FAILED',
    error_number: 404001805,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_CLAIM_149',
      recovery:
        'The following order {{qr_code}} is not able to be claimed, please check the original error message and try again!',
    },
    description: 'Error occurred while making claim request to Opapi.',
    error_code: 'OPAPI_CLAIM_INVALID_ORDER_STATUS_FOR_OPERATION_STATUS',
    error_number: 404001806,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_UPDATE_101',
      recovery:
        'The following order {{qr_code}} is not able to be updated, please check the original error message and try again!',
    },
    description: 'Error occurred while making update request to Opapi.',
    error_code: 'OPAPI_UPDATE_INVALID_WALLET',
    error_number: 404001807,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_UPDATE_106',
      recovery:
        'The following order {{qr_code}} is not able to be updated, please check the original error message and try again!',
    },
    description: 'Error occurred while making update request to Opapi.',
    error_code: 'OPAPI_UPDATE_ORDER_ID_NOT_LINKED_TO_ORDER',
    error_number: 404001808,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_UPDATE_120',
      recovery:
        'The following order {{qr_code}} is not able to be updated, please check the original error message and try again!',
    },
    description: 'Error occurred while making update request to Opapi.',
    error_code: 'OPAPI_UPDATE_RESOURCE_NOT_FOUND',
    error_number: 404001809,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_ABORT_101',
      recovery:
        'The following order {{qr_code}} is not able to be aborted, please check the original error message and try again!',
    },
    description: 'Error occurred while making abort request to Opapi.',
    error_code: 'OPAPI_ABORT_INVALID_WALLET',
    error_number: 404001812,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_ABORT_106',
      recovery:
        'The following order {{qr_code}} is not able to be aborted, please check the original error message and try again!',
    },
    description: 'Error occurred while making abort request to Opapi.',
    error_code: 'OPAPI_ABORT_ORDER_ID_NOT_LINKED_TO_ORDER',
    error_number: 404001813,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_AUTHORIZE_101',
      recovery:
        'The following order {{qr_code}} is not able to be authorized, please check the original error message and try again!',
    },
    description: 'Error occurred while making authorize request to Opapi.',
    error_code: 'OPAPI_AUTHORIZE_INVALID_WALLET',
    error_number: 404001816,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_AUTHORIZE_106',
      recovery:
        'The following order {{qr_code}} is not able to be authorized, please check the original error message and try again!',
    },
    description: 'Error occurred while making authorize request to Opapi.',
    error_code: 'OPAPI_AUTHORIZE_ORDER_ID_NOT_LINKED_TO_ORDER',
    error_number: 404001817,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_AUTHORIZE_120',
      recovery:
        'The following order {{qr_code}} is not able to be authorized, please check the original error message and try again!',
    },
    description: 'Error occurred while making authorize request to Opapi.',
    error_code: 'OPAPI_AUTHORIZE_RESOURCE_NOT_FOUND',
    error_number: 404001818,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_101',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_INVALID_WALLET',
    error_number: 404001826,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_102',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_ORDER_PAYMENT_DATA_NOT_FOUND',
    error_number: 404001827,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_FINALIZE_106',
      recovery:
        'The following order {{qr_code}} is not able to be finalized, please check the original error message and try again!',
    },
    description: 'Error occurred while making finalize request to Opapi.',
    error_code: 'OPAPI_FINALIZE_ORDER_ID_NOT_LINKED_TO_ORDER',
    error_number: 404001828,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      qr_error_code: 'QR_UNKNOWN_404',
      recovery:
        'The following order {{qr_code}} was unable to {{type}}, please check the original error message and try again!',
    },
    description: 'Error occurred while making unknown request to Opapi.',
    error_code: 'OPAPI_ERROR_NOT_FOUND',
    error_number: 404001850,
    http_status: 404,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with user_id {{entity_id}} has been provisioned in Ping',
    },
    description: 'User is not registered with Ping',
    error_code: 'PING_USER_NOT_FOUND',
    error_number: 404002006,
    http_status: 404,
    message: "The Ping User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the address exists in the database',
    },
    description: 'Address not found',
    error_code: 'ADDRESS_NOT_FOUND',
    error_number: 404002014,
    http_status: 404,
    message: "The Address with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        "Ensure that the userid {{user_id}} is valid and is linked to a wallet with id '{{wallet_id}}'.",
    },
    description:
      'User could not be found in Engagement DB by its user_id and the given wallet_id.',
    error_code: 'ENGAGEMENT_USER_BY_ID_AND_WALLET_NOT_FOUND',
    error_number: 404002034,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the userid {{user_id}} is valid.',
    },
    description: 'User could not be found in Engagement DB by its user_id.',
    error_code: 'ENGAGEMENT_USER_BY_ID_NOT_FOUND',
    error_number: 404002038,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the contact details provided are correct, then try again.',
    },
    description:
      'User could not be found in Engagement DB using the contact details provided.',
    error_code: 'ENGAGEMENT_GET_USER_BY_CONTACT_DETAILS_NOT_FOUND',
    error_number: 404002042,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user {{entity_id}} exists in our system, or that the user_id is in the right format',
    },
    description: 'User could not be found in Engagement DB',
    error_code: 'ENGAGEMENT_DELETE_USER_NOT_FOUND',
    error_number: 404002046,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, then try again.',
    },
    description:
      'User could not be found in Engagement DB while attempting to update the wallet link details for the user.',
    error_code: 'ENGAGEMENT_PATCH_USER_WALLET_NOT_FOUND',
    error_number: 404002047,
    http_status: 404,
    message: "The Wallet with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, then try again.',
    },
    description: 'User to be blocked could not be found in Engagement DB.',
    error_code: 'ENGAGEMENT_BLOCK_USER_NOT_FOUND',
    error_number: 404002050,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, then try again.',
    },
    description: 'User to be unblocked could not be found in Engagement DB.',
    error_code: 'ENGAGEMENT_UNBLOCK_USER_NOT_FOUND',
    error_number: 404002053,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, wallet with id {{wallet_id}} exists, then try again.',
    },
    description:
      'User could not be found in Engagement DB while attempting to restrict a wallet.',
    error_code: 'ENGAGEMENT_RESTRICT_USER_WALLET_NOT_FOUND',
    error_number: 404002056,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{user_id}} exists, wallet with id {{wallet_id}} exists, then try again.',
    },
    description:
      'User could not be found in Engagement DB while attempting to unrestrict a wallet.',
    error_code: 'ENGAGEMENT_UNRESTRICT_USER_WALLET_NOT_FOUND',
    error_number: 404002059,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user has an active profile in Engagement DB and try again.',
    },
    description: 'User could not be found in Engagement DB.',
    error_code: 'ENGAGEMENT_GET_PROFILE_NOT_FOUND',
    error_number: 404002062,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the given user {{entity_id}} exists in our system, or that the user_id is in the right format.',
    },
    description:
      "An error was returned by Engagement API while attempting to update a user using Engagement's internal endpoint.",
    error_code: 'ENGAGEMENT_PATCH_USER_INTERNAL_NOT_FOUND',
    error_number: 404002068,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with id {{entity_id}} exists, wallet with id {{wallet_id}} exists, then try again.',
    },
    description:
      'Given user for the given wallet could not be found in Engagement DB.',
    error_code: 'GET_USER_BY_ID_AND_WALLET_NOT_FOUND',
    error_number: 404002073,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the mobile {{entity_id}} belongs to the user, its status is ACTIVATION_REQUIRED, then try again.',
    },
    description:
      'The mobile number was not found in the list of active MFA devices for the user',
    error_code: 'UPDATE_MOBILE_CANCEL_MOBILE_NOT_FOUND',
    error_number: 404002083,
    http_status: 404,
    message: "The mobile with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with Beem id {{user_id}} exists and has an active profile link.',
    },
    description: 'The user was not found in Ping using the provided user id.',
    error_code: 'ADMIN_UPDATE_EMAIL_INITIATE_NOT_FOUND_ERROR',
    error_number: 404002091,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with Beem id {{user_id}} exists and has an active profile link.',
    },
    description: 'The user was not found in Ping using the provided user id.',
    error_code: 'ADMIN_UPDATE_MOBILE_VERIFY_NOT_FOUND_ERROR',
    error_number: 404002096,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the user with Beem id {{entity_id}} exists.',
    },
    description:
      'The user was not found in Engagement while attempting to admin delete it using the provided user id.',
    error_code: 'ENGAGEMENT_DELETE_USER_WALLET_NOT_FOUND',
    error_number: 404002105,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that wallet with id {{entity_id}} exists, then try again.',
    },
    description: 'Given wallet could not be found.',
    error_code: 'BAAS_WALLET_NOT_FOUND',
    error_number: 404002126,
    http_status: 404,
    message: "The Wallet with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Please contact admin for support.',
    },
    description:
      'No marketing consent agreement was found for this participant',
    error_code: 'MARKETING_CONSENT_AGREEMENT_NOT_FOUND',
    error_number: 404002127,
    http_status: 404,
    message: "The Agreement of category '{{category}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Please contact admin for support',
    },
    description: 'There is no configured participant for this wallet',
    error_code: 'PARTICIPANT_NOT_FOUND',
    error_number: 404002129,
    http_status: 404,
    message: "The Participant with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user has an active profile in Engagement DB and try again.',
    },
    description: 'User could not be found in Engagement DB.',
    error_code: 'ENGAGEMENT_VERIFY_KYC_LITE_USER_NOT_FOUND',
    error_number: 404002139,
    http_status: 404,
    message: "The undefined with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the user with Beem id {{user_id}} exists and has an active profile link.',
    },
    description: 'The user was not found in Ping using the provided user id.',
    error_code: 'ADMIN_UPDATE_EMAIL_NOT_FOUND_ERROR',
    error_number: 404002144,
    http_status: 404,
    message: "The User with id '{{entity_id}}' could not be found.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the Client ID {{entity_id}} is correct',
    },
    error_code: 'WALLET_CONFIG_CLIENT_NOT_FOUND',
    error_number: 404004009,
    http_status: 404,
    message:
      "The Wallet Configuration with id '{{entity_id}}' could not be found.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that the wallet Id {{entity_id}} is valid',
    },
    description: 'Wallet {{entity_id}} could not be found',
    error_code: 'WALLET_NOT_FOUND',
    error_number: 404004016,
    http_status: 404,
    message: "The Wallet with id '{{entity_id}}' could not be found.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format. If the issue persists, contact customer support',
    },
    description: 'Error occurred while fetching an application in ping',
    error_code: 'PING_GET_APPLICATION_ERROR',
    error_number: 404004029,
    http_status: 404,
    message: "The Wallet Client with id '{{entity_id}}' could not be found.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that the Client Id {{entity_id}} is valid',
    },
    description: 'Client {{entity_id}} could not be found',
    error_code: 'CLIENT_NOT_FOUND',
    error_number: 404004038,
    http_status: 404,
    message: "The Wallet Client with id '{{entity_id}}' could not be found.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Please contact admin for support',
    },
    description: 'There is no configured participant for this wallet',
    error_code: 'PARTICIPANT_NOT_FOUND',
    error_number: 404004042,
    http_status: 404,
    message: "The Participant with id '{{entity_id}}' could not be found.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Ensure that the user external id {{external_id}} is correct and try again.',
    },
    description:
      'Unable to retrieve the user integration for this user external id.',
    error_code: 'USER_INTEGRATION_NOT_FOUND',
    error_number: 404004050,
    http_status: 404,
    message: "The Integration with id '{{entity_id}}' could not be found.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Ensure that the key key with id {{entity_id}} exists',
    },
    description: 'Could not find the Jwt signing key with the given id',
    error_code: 'SIGNING_KEY_NOT_FOUND_ERROR',
    error_number: 404004058,
    http_status: 404,
    message: "The Signing Key with id '{{entity_id}}' could not be found.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the request matches the request schema, then try again. If the issue persists, contact customer support.',
    },
    description:
      'An unexpected error was returned by Engagement API while attempting to migrate a user.',
    error_code: 'ACCEPTANCE_SERVICE_NOT_FOUND_ERROR',
    error_number: 404004118,
    http_status: 404,
    message: "The undefined with id '{{entity_id}}' could not be found.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        "Given user_id: {{given_user_id}} doesn't match the recorded user_id: {{order_user_id}}",
    },
    description: 'User recorded for this order does not match the given user',
    error_code: 'ORDER_USER_CONFLICT',
    error_number: 409001005,
    http_status: 409,
    message: "The Orders Order with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        "Given wallet_id: {{given_wallet_id}} doesn't match the recorded wallet_id: {{order_wallet_id}}",
    },
    description: 'User recorded for this order does not match the given wallet',
    error_code: 'ORDER_WALLET_CONFLICT',
    error_number: 409001006,
    http_status: 409,
    message: "The Orders Order with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given loyalty program is not already linked to the user {{entity_id}}',
    },
    description:
      'The loyalty program provided is already linked while trying to create',
    error_code: 'LOYALTY_PROGRAM_ALREADY_LINKED_CREATE',
    error_number: 409001028,
    http_status: 409,
    message: "The Loyalty Program with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the given loyalty program {{program_id}} is not linked to the user {{user_id}}',
    },
    description:
      'The loyalty program provided is already linked while trying to complete the link',
    error_code: 'LOYALTY_PROGRAM_ALREADY_LINKED_COMPLETE',
    error_number: 409001039,
    http_status: 409,
    message: "The Loyalty Program with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        "Ensure that the given loyalty card {{entity_id}} isn't already linked",
    },
    description:
      'Loyalty card cannot be linked as an existing loyalty for the same program already exists',
    error_code: 'LOYALTY_PROGRAM_CARD_CONFLICT_COMPLETE_LINK',
    error_number: 409001046,
    http_status: 409,
    message: "The Loyalty Card with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: "Ensure that the given user {{entity_id}} isn't already linked",
    },
    description: 'User was already linked while trying to link',
    error_code: 'FLYBUYS_SERVICE_USER_ALREADY_LINKED_ERROR',
    error_number: 409001064,
    http_status: 409,
    message: "The User with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Ensure that the given user {{entity_id}} is linked',
    },
    description: 'User was not linked while trying to unlink',
    error_code: 'FLYBUYS_SERVICE_USER_NOT_LINKED_ERROR',
    error_number: 409001065,
    http_status: 409,
    message: "The User with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        "Ensure that the identifiers highlighted in the 'fields' property have not already set to {{action}} with different data (e.g employee_type)",
    },
    description: '{{action}} could not be preformed on one or more barcodes',
    error_code: 'LOYALTY_PROGRAM_VALIDATION_DATA_CONFLICT_ERROR',
    error_number: 409001104,
    http_status: 409,
    message: '{{action}} could not be preformed on one or more barcodes',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that a loyalty card with barcode {{entity_id}} is not already added to the user wallet, then try again.',
    },
    description:
      'A loyalty card with barcode {{entity_id}} is already added to the user wallet',
    error_code: 'LOYALTY_ALREADY_ADDED',
    error_number: 409001109,
    http_status: 409,
    message: "The Loyalty Card with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the barcode provided matches the payment method id, then try again',
    },
    description:
      'The barcode provided does not match the provided payment method id',
    error_code: 'PAYMENT_METHOD_MIGRATE_UPDATE_CONFLICT',
    error_number: 409001147,
    http_status: 409,
    message:
      "The barcode provided '{{provided_bar_code}}' does not match the provided payment method id 'payment_method_id'",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Remove the duplicate identifiers when whitelising team member cards',
    },
    description:
      'Whitelist payload contains duplicate identifiers: {{duplicate_identifiers}}',
    error_code: 'WHITE_LIST_DUPLICATE_IDENTIFIERS_ERROR',
    error_number: 409001155,
    http_status: 409,
    message:
      'Whitelist payload contains duplicate identifiers: {{duplicate_identifiers}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Check the bins in use and try again',
    },
    description:
      'The Gift Card program provided contains a bin that is already being used by another program',
    error_code: 'GIFT_CARD_PROGRAM_BIN_ALREADY_IN_USE',
    error_number: 409001160,
    http_status: 409,
    message: "The Gift Card Program with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the gift card has not already been added to the user wallet, then try again.',
    },
    description: 'Gift card has already been added to the user wallet',
    error_code: 'GIFT_CARD_ALREADY_ADDED',
    error_number: 409001179,
    http_status: 409,
    message: "The Gift Card with id '{{entity_id}}' is conflicted.",
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the email {{email}} for the user with idp id {{idp_user_id}} (Beem id {{user_id}}) is not already verified in Ping then try again.',
    },
    description: 'The email is already marked as verified for the given user.',
    error_code: 'UPDATE_EMAIL_RESEND_ALREADY_VERIFIED_CONFLICT_ERROR',
    error_number: 409002079,
    http_status: 409,
    message: "The User with id '{{entity_id}}' is conflicted.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the email {{email}} is not already in use then try again.',
    },
    description:
      'The email the user wants to update the email to is already in use by another user.',
    error_code: 'INITIATE_EMAIL_CHANGE_CONFLICT_ERROR',
    error_number: 409002080,
    http_status: 409,
    message: "The User with id '{{entity_id}}' is conflicted.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that no existing user already has the given mobile number, then try again.',
    },
    description: 'User already exists with the given mobile number.',
    error_code: 'MOBILE_NUMBER_ALREADY_EXISTS_CONFLICT_ERROR',
    error_number: 409002087,
    http_status: 409,
    message: "The User with id '{{entity_id}}' is conflicted.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the email {{entity_id}} and phone number {{mobile_number}} are not already taken, then try again.',
    },
    description:
      'Could not create a user with the provided details as they conflict with an existing user.',
    error_code: 'ENGAGEMENT_ADD_PROFILE_CONFLICT_ERROR',
    error_number: 409002107,
    http_status: 409,
    message: "The User with id '{{entity_id}}' is conflicted.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the email {{entity_id}} and phone number {{mobile_number}} are not already taken, then try again.',
    },
    description:
      'Could not migrate a user with the provided details as they conflict with an existing user.',
    error_code: 'ENGAGEMENT_MIGRATE_PROFILE_CONFLICT_ERROR',
    error_number: 409002115,
    http_status: 409,
    message: "The User with id '{{entity_id}}' is conflicted.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the email {{email}} is not already in use then try again.',
    },
    description:
      'The email the user wants to update the email to is already in use by another user.',
    error_code: 'ADMIN_UPDATE_EMAIL_CONFLICT_ERROR',
    error_number: 409002146,
    http_status: 409,
    message: "The User with id '{{entity_id}}' is conflicted.",
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'A wallet with id {{entity_id}} already exists. Please use a different wallet id.',
    },
    description: 'The Wallet with id {{entity_id}} already exists',
    error_code: 'WALLET_ALREADY_IN_USE_ERROR',
    error_number: 409004004,
    http_status: 409,
    message: "The Wallet with id '{{entity_id}}' is conflicted.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the action request is valid.',
    },
    description: 'There was a problem processing the request',
    error_code: 'ACCEPTANCE_SERVICE_CONFLICT_ERROR',
    error_number: 409004040,
    http_status: 409,
    message: "The undefined with id '{{entity_id}}' is conflicted.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'The following status {{status}} has already been set',
    },
    description: 'The status of user integration is conflicted',
    error_code: 'USER_INTEGRATION_STATUS_CONFLICT',
    error_number: 409004051,
    http_status: 409,
    message: "The Integration with id '{{entity_id}}' is conflicted.",
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'QR event received does not have a valid payload',
    error_code: 'QR_VALIDATION_ERROR',
    error_number: 444001007,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the Payment method payload given matches the expected format',
    },
    description: 'Payment method payload received is not valid during creation',
    error_code: 'PAYMENT_METHOD_CREATE_VALIDATION_ERROR',
    error_number: 444001009,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the Payment method payload {{entity_id}} given matches the expected format',
    },
    description: 'Payment method payload received is not valid during listing',
    error_code: 'PAYMENT_METHOD_LIST_VALIDATION_ERROR',
    error_number: 444001025,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the Payment method payload {{entity_id}} given matches the expected format',
    },
    description: 'Payment method payload received is not valid during updating',
    error_code: 'PAYMENT_METHOD_UPDATE_ALIAS_VALIDATION_ERROR',
    error_number: 444001034,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the Payment method query given matches the expected format',
    },
    description: 'Payment method query received is not valid during listing',
    error_code: 'PAYMENT_METHOD_LIST_INTERNAL_VALIDATION_ERROR',
    error_number: 444001060,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Review the api specs and try again',
    },
    description: 'Incorrect format for loyalty unlink request body',
    error_code: 'LOYALTY_UNLINK_INVALID_REQUEST_BODY',
    error_number: 444001089,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery: 'Please make sure the correct card is provided.',
    },
    description: 'The card data is not in the right format!',
    error_code: 'INVALID_CARD_DATA',
    error_number: 444001092,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    description: 'There was an issue migrating loyalty cards for user',
    error_code: 'ENGAGEMENT_MIGRATE_LOYALTY_BAD_REQUEST',
    error_number: 444001099,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the order payload given matches the expected format',
    },
    description: 'Order payload received is not valid during creation',
    error_code: 'ORDER_MIGRATE_VALIDATION_ERROR',
    error_number: 444001113,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the payment method payload given matches the expected format',
    },
    description: 'Payment method payload received is not valid during creation',
    error_code: 'PAYMENT_METHOD_MIGRATE_VALIDATION_ERROR',
    error_number: 444001121,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'Payment method payload for making default is not valid',
    error_code: 'PAYMENT_METHOD_MIGRATE_MAKE_DEFAULT_VALIDATION_ERROR',
    error_number: 444001127,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the Payment method payload given matches the expected format',
    },
    description:
      'Payment method payload received is not valid during authorization',
    error_code: 'PAYMENT_METHOD_V3_AUTHORIZE_VALIDATION_ERROR',
    error_number: 444001138,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    description: 'Payment method payload received is not valid during creation',
    error_code: 'PAYMENT_METHOD_V3_FINALIZE_VALIDATION_ERROR',
    error_number: 444001140,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the bank card query given matches the expected format',
    },
    description: 'Bank card query received is not valid during listing',
    error_code: 'BANK_CARD_LIST_INTERNAL_VALIDATION_ERROR',
    error_number: 444001142,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the Payment method payload given matches the expected format',
    },
    description: 'Payment method payload received is not valid during updating',
    error_code: 'PAYMENT_METHOD_UPDATE_DATA_VALIDATION_ERROR',
    error_number: 444001143,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the loyalty Card query given matches the expected format',
    },
    description: 'Loyalty Card query received is not valid during listing',
    error_code: 'LOYALTY_CARD_LIST_VALIDATION_ERROR',
    error_number: 444001144,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the query parameters provided are correct and try again',
    },
    description: 'Bank card query is not valid',
    error_code: 'BANK_CARD_LIST_VALIDATION_ERROR',
    error_number: 444001145,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description:
      'Request payload to update a migrated loyalty card is not valid',
    error_code: 'PAYMENT_METHOD_MIGRATE_UPDATE_VALIDATION_ERROR',
    error_number: 444001146,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery:
        'Ensure that the payment method payload given matches the expected format',
    },
    description: 'Payment method payload received is not valid during creation',
    error_code: 'LOYALTY_CARD_MIGRATE_VALIDATION_ERROR',
    error_number: 444001148,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    description: 'Payload signature not valid',
    error_code: 'SIGNATURE_NOT_VALID_ERROR',
    error_number: 444001149,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the gift card program data provided is in the right format',
    },
    description: 'The gift card program data is not valid',
    error_code: 'GIFT_CARD_PROGRAM_VALIDATION_ERROR',
    error_number: 444001156,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the gift card data provided is in the right format',
    },
    description: 'The card data is not in the right format!',
    error_code: 'GIFT_CARD_INVALID_CARD_DATA',
    error_number: 444001167,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the gift card data provided is in the right format',
    },
    description: 'The gift card data is not valid',
    error_code: 'GIFT_CARD_VALIDATION_ERROR',
    error_number: 444001170,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the gift card {{entity_id}} contains the required data',
    },
    description:
      'The gift card with id {{entity_id}} is missing the required encrypted_data field',
    error_code: 'GIFT_CARD_MISSING_ENCRYPTED_DATA',
    error_number: 444001175,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Ensure that the webhook event has a valid schema, possibly check Ping documentation',
    },
    description: 'Ping event received does not valid have a valid payload',
    error_code: 'PING_WEBHOOK_VALIDATION_ERROR',
    error_number: 444002005,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the address follows the expected schema',
    },
    description: 'Address validation error',
    error_code: 'ADDRESS_VALIDATION_ERROR',
    error_number: 444002013,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_EMAIL_INITIATE_VALIDATION_ERROR',
    error_number: 444002071,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_USER_WALLET_VALIDATION_ERROR',
    error_number: 444002072,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'CREATE_PROFILE_VALIDATION_ERROR',
    error_number: 444002074,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_PROFILE_VALIDATION_ERROR',
    error_number: 444002075,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_EMAIL_FINALIZE_VALIDATION_ERROR',
    error_number: 444002076,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_EMAIL_VERIFY_VALIDATION_ERROR',
    error_number: 444002077,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request URL contains all required params.',
    },
    description: 'Request failed validation. Missing required param',
    error_code: 'ADMIN_DELETE_USER_PLATFORM_VALIDATION_ERROR',
    error_number: 444002081,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_MOBILE_INITIATE_VALIDATION_ERROR',
    error_number: 444002082,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_MOBILE_CANCEL_VALIDATION_ERROR',
    error_number: 444002084,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_MOBILE_VALIDATION_ERROR',
    error_number: 444002086,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_MOBILE_VERIFY_VALIDATION_ERROR',
    error_number: 444002088,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_UPDATE_EMAIL_VERIFY_VALIDATION_ERROR',
    error_number: 444002089,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format (the user_id param was provided correctly)',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_UNBLOCK_USER_PLATFORM_BAD_REQUEST_ERROR',
    error_number: 444002093,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format (the user_id param was provided correctly)',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_BLOCK_USER_PLATFORM_BAD_REQUEST_ERROR',
    error_number: 444002094,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the verification code provided is correct, then try again.',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_UPDATE_MOBILE_VERIFY_VALIDATION_ERROR',
    error_number: 444002095,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_UPDATE_MOBILE_INITIATE_VALIDATION_ERROR',
    error_number: 444002097,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format (the user_id param was provided correctly)',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_UPDATE_EMAIL_INITIATE_VALIDATION_ERROR',
    error_number: 444002099,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_LIST_PROFILE_BY_WALLET_ID_VALIDATION_ERROR',
    error_number: 444002108,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the bearer token used for the request contains a valid sub claim',
    },
    description: 'Request failed validation.',
    error_code: 'GET_USER_VALIDATION_ERROR',
    error_number: 444002109,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'GET_USER_BY_WALLET_ID_AND_USER_ID_VALIDATION_ERROR',
    error_number: 444002110,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'The query params are not in the right format, please check the API docs and try again!',
    },
    description: 'Request failed validation.',
    error_code: 'UNSUBSCRIBE_MARKETING_CONTENT_PARAMS_VALIDATION_ERROR',
    error_number: 444002112,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'MIGRATE_PROFILE_VALIDATION_ERROR',
    error_number: 444002113,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the address follows the expected schema',
    },
    description: 'Address validation error',
    error_code: 'MIGRATE_ADDRESS_BAD_REQUEST',
    error_number: 444002122,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'UPDATE_EMAIL_SUBSCRIPTION_STATUS_VALIDATION_ERROR',
    error_number: 444002128,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that query params match the expected format',
    },
    description: 'Request query params failed validation',
    error_code:
      'INTERNAL_FIND_PROFILE_BY_CONTACT_DETAILS_PARAMS_VALIDATION_ERROR',
    error_number: 444002131,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'DELETE_USER_WALLET_VALIDATION_ERROR',
    error_number: 444002132,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_UPDATE_EMAIL_RESEND_VALIDATION_ERROR',
    error_number: 444002133,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery: 'Ensure that the request matches the expected format',
    },
    description: 'Request failed validation.',
    error_code: 'VERIFY_KYC_LITE_VALIDATION_ERROR',
    error_number: 444002137,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format (the user_id param was provided correctly)',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_UPDATE_EMAIL_VALIDATION_ERROR',
    error_number: 444002142,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Ensure that the request matches the expected format (the user_id param was provided correctly)',
    },
    description: 'Request failed validation.',
    error_code: 'ADMIN_UPDATE_MOBILE_NUMBER_VALIDATION_ERROR',
    error_number: 444002143,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The client data received is not valid',
    error_code: 'WALLET_CREATE_CLIENT_VALIDATION_ERROR',
    error_number: 444004013,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The client data received is not valid',
    error_code: 'WALLET_UPDATE_CLIENT_VALIDATION_ERROR',
    error_number: 444004035,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The agreement create data received is not valid',
    error_code: 'AGREEMENT_CREATE_VALIDATION_ERROR',
    error_number: 444004039,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The agreement version create data received is not valid',
    error_code: 'AGREEMENT_VERSION_CREATE_VALIDATION_ERROR',
    error_number: 444004041,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The logout data received is not valid',
    error_code: 'LOGOUT_VALIDATION_ERROR',
    error_number: 444004045,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      original_message: '{{original_message}}',
      recovery: 'Ensure that the payload given matches the expected format',
    },
    description: 'The callback request payload is not valid',
    error_code: 'INVALID_CALLBACK_REQUEST_PAYLOAD',
    error_number: 444004049,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Please review the request body format and try again.',
    },
    description: 'Invalid request body!',
    error_code: 'PING_SERVICE_TOKEN_REQUEST_VALIDATION_ERROR',
    error_number: 444004053,
    http_status: 400,
    message: 'Request failed validation.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'INTERNAL_SERVER_ERRROR',
    error_number: 500001000,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ORDER_INTERNAL_ERROR',
    error_number: 500001079,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'LOYALTY_PROGRAM_VALIDATION_BLACKLIST_USER_REMOVE_ERROR',
    error_number: 500001106,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ORDER_MIGRATE_INTERNAL_ERROR',
    error_number: 500001114,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PAYMENT_METHOD_MIGRATE_INTERNAL_ERROR',
    error_number: 500001120,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PAYMENT_METHOD_V3_INIT_AUTHENTICATE_FAILED',
    error_number: 500001132,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PAYMENT_METHOD_V3_AUTHENTICATE_FAILED',
    error_number: 500001135,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'DYNAMO_STREAM_HANDLER_ERROR',
    error_number: 500001153,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'OPAPI_INTERNAL_SERVER_ERROR',
    error_number: 500001851,
    http_status: 500,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'OPAPI_UNMAPPED_ERROR',
    error_number: 500001853,
    http_status: 500,
    message: '{{opapi_message}}',
    service_id: 1,
    service_name: 'Baas Payments',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'INTERNAL_SERVER_ERRROR',
    error_number: 500002000,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_SEND_MAGIC_LINK_ERROR',
    error_number: 500002023,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_VERIFY_EMAIL_ERROR',
    error_number: 500002024,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_SEND_OTP_EMAIL_ERROR',
    error_number: 500002026,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_CREATE_MFA_DEVICE_ERROR',
    error_number: 500002027,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_READ_ALL_MFA_DEVICES_ERROR',
    error_number: 500002029,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_READ_MFA_DEVICE_ERROR',
    error_number: 500002030,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_DELETE_MFA_DEVICE_ERROR',
    error_number: 500002031,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_READ_ALL_USER_SESSIONS_BAD_REQUEST',
    error_number: 500002032,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_DELETE_ALL_USER_SESSIONS_BAD_REQUEST',
    error_number: 500002033,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_USER_BY_ID_ERROR',
    error_number: 500002041,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_GET_USER_BY_CONTACT_DETAILS_ERROR',
    error_number: 500002044,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_PATCH_USER_WALLET_ERROR',
    error_number: 500002049,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_BLOCK_USER_ERROR',
    error_number: 500002052,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_UNBLOCK_USER_ERROR',
    error_number: 500002055,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_RESTRICT_USER_WALLET_ERROR',
    error_number: 500002058,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_UNRESTRICT_USER_WALLET_ERROR',
    error_number: 500002061,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_ADD_PROFILE_ERROR',
    error_number: 500002067,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_LIST_USERS_BY_WALLET_ID_ERROR',
    error_number: 500002100,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_MIGRATE_PROFILE_ERROR',
    error_number: 500002117,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'MIGRATE_ADDRESS_INTERNAL_ERROR',
    error_number: 500002123,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'BRAZE_SERVICE_ERROR',
    error_number: 500002130,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'WEBHOOK_QUEUE_HANDLER_ERROR',
    error_number: 500002134,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'DYNAMO_STREAM_HANDLER_ERROR',
    error_number: 500002135,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ENGAGEMENT_VERIFY_KYC_LITE_ERROR',
    error_number: 500002141,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'INTERNAL_SERVER_ERRROR',
    error_number: 500004000,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'INTERNAL_SERVER_ERRROR',
    error_number: 500004000,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ACCEPTANCE_SERVICE_ERROR',
    error_number: 500004023,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'PING_SERVICE_TOKEN_REQUEST_ERROR',
    error_number: 500004054,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery:
        'Please try again, if the issue persists contact customer support',
    },
    error_code: 'ACCEPTANCE_SERVICE_ERROR',
    error_number: 500004119,
    http_status: 500,
    message: 'An Internal Server Error occurred.',
    service_id: 4,
    service_name: 'Baas Common',
  },
  {
    debug: {
      recovery: 'Disable the event in Ping Webhooks or request a feature',
    },
    description:
      'The webhook event seems to have been enabled in Ping but is not currently supported by our services',
    error_code: 'WEBHOOK_NOT_SUPPORTED',
    error_number: 501002004,
    http_status: 501,
    message: 'The Webhook Event is not yet implemented.',
    service_id: 2,
    service_name: 'Baas Identity',
  },
];
