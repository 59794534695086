import { Dialog } from '@/components';
import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import { useBankCardsDialogErrorReset } from '../../hooks/use-bank-cards-dialog-error-reset';

import { BankCardsDialogErrorContent } from './bank-cards-dialog-error-content';
import { bankCardsDialogErrorProps } from './bank-cards-dialog-error-props';

export function BankCardsDialogError() {
  const bankCardsDialogErrorState =
    useBankCardsStore().use.bankCardsDialogErrorState();
  const isOpenCardDialogError =
    bankCardsDialogErrorState !== BankCardsDialogErrorStates.NULL;

  const dialogProps = bankCardsDialogErrorProps[bankCardsDialogErrorState]?.();

  const handleOpenChange = useBankCardsDialogErrorReset();

  return (
    <Dialog
      body={<BankCardsDialogErrorContent handleOpenChange={handleOpenChange} />}
      description={dialogProps?.title}
      isOpen={isOpenCardDialogError}
      onOpenChange={handleOpenChange}
    />
  );
}
