import type { GiftCardTypes } from '../../types/gift-card-types';

export const giftCardsSlots = {
  addGiftCardsSlotAttributes(
    response: GiftCardTypes.Operations.GetGiftCards.TResponse
  ) {
    const maxGiftCardsLimit =
      response?.metadata?.user_gift_cards_limit?.max_allowed || 0;
    const totalGiftCards = response?.cards?.length;

    return {
      ...response,
      giftCardSlotsMaxLimit:
        response?.metadata?.user_gift_cards_limit?.max_allowed || null,
      giftCardSlotsRemaining:
        response?.metadata?.user_gift_cards_limit?.remaining,
      giftCardSlotsUsed: totalGiftCards,
      isMaxedOutGiftCardSlots: this.hasMaxedOutGiftCardSlots(
        totalGiftCards,
        maxGiftCardsLimit
      ),
    };
  },

  hasMaxedOutGiftCardSlots(totalCards: number, maxCardsAllowed: number | null) {
    return !!maxCardsAllowed ? totalCards >= maxCardsAllowed : true;
  },
};
