import { Dialog } from '@/components';
import { ExclamationMarkTriangleIcon } from '@/components/icons';
import { useGetWalletConfigOpen } from '@/features/wallet-config';
import { GiftCardsDialogErrorStates, useGiftCardsStore } from '@/store';

import { useGetGiftCards } from '../../services/use-get-gift-cards/use-get-gift-cards';

import { GiftCardsDialogErrorContent } from './gift-cards-dialog-error-content';
import { giftCardsDialogErrorCopy } from './gift-cards-dialog-error-copy';
import { useGiftCardsDialogErrorReset } from './use-gift-cards-dialog-error-reset';

export function GiftCardsDialogError() {
  const getWalletConfig = useGetWalletConfigOpen();
  const getGiftCards = useGetGiftCards();

  const giftCardsDialogErrorState =
    useGiftCardsStore().use.giftCardsDialogErrorState();

  const isOpenGiftCardsDialogError =
    giftCardsDialogErrorState !== GiftCardsDialogErrorStates.NULL;

  const giftCardDialogErrorProps = giftCardsDialogErrorCopy[
    giftCardsDialogErrorState
  ]?.(
    getWalletConfig?.data?.wallet_name,
    getGiftCards?.data?.giftCardSlotsMaxLimit
  );

  const handleOpenChange = useGiftCardsDialogErrorReset();

  return (
    <Dialog
      body={
        <GiftCardsDialogErrorContent
          buttonText={giftCardDialogErrorProps?.buttonText || 'Okay'}
          description={giftCardDialogErrorProps?.description}
          handleOpenChange={handleOpenChange}
          icon={
            giftCardDialogErrorProps?.icon || (
              <ExclamationMarkTriangleIcon color="var(--colors-error-high)" />
            )
          }
          title={giftCardDialogErrorProps?.title || ''}
        />
      }
      description={'Gift card error dialog'}
      isOpen={isOpenGiftCardsDialogError}
      onOpenChange={handleOpenChange}
    />
  );
}
