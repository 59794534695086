import type { IActivityStoreStateValues } from './activity-store-types';

const initialSearchQuery = {
  category: '',
  query: '',
};

export const cardStoreDefaultValues: IActivityStoreStateValues = {
  activeFilters: undefined,
  isFiltersSideSheetOpen: false,
  isViewFilterCalendar: false,
  isViewOrderDetailsOpen: false,
  searchQuery: initialSearchQuery,
  selectedOrder: undefined,
};
