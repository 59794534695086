import { Feature } from '../feature-flags';

export const theme = {
  brand: {
    title: 'Beem',
  },
  content: {
    heading: (
      <h1>
        Make money simple with Beem<span className="tm">™</span>.
      </h1>
    ),
    subHeading: <h2>A digital wallet that does more than store.</h2>,
  },
  features: [
    Feature.ACTIVITY,
    Feature.WALLET,
    Feature.ACCOUNT,
    Feature.ADDRESSES,
  ],
  homePage: Feature.ACTIVITY,
  icon: {
    inverse: 'https://static.beemit.com.au/wallet/logos/beem-new-large.svg',
    large: 'https://static.beemit.com.au/wallet/logos/beem-new-large.svg',
    small: 'https://static.beemit.com.au/wallet/logos/beem-new-small.svg',
  },
};
