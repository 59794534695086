import { QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'react-oidc-context';

import {
  ActivityStoreProvider,
  BankCardsStoreProvider,
  GiftCardsStoreProvider,
} from '@/store';
import { getDefaultTheme } from '@/theme';

import { authConfig } from '../config';

import { combineProviders } from './combine-providers';
import { queryClient } from './query-client';
import { ThemeWrapper } from './theme';

const initialTheme = getDefaultTheme();

export const ContextProviders = combineProviders([
  [
    AuthProvider,
    { ...authConfig.oidc, redirect_uri: `${window.location.origin}/` },
  ],
  [QueryClientProvider, { client: queryClient }],
  [ThemeWrapper, { initialTheme }],
  [BankCardsStoreProvider],
  [GiftCardsStoreProvider],
  [ActivityStoreProvider],
]);
