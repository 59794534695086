import { useMutation } from '@tanstack/react-query';

import { giftCardsQueryKeys } from '../gift-cards-query-keys';

import { useAddGiftCardError } from './use-add-gift-card-error';
import { useAddGiftCardsRequest } from './use-add-gift-card-request';
import { useAddGiftCardSuccess } from './use-add-gift-card-success';

import type { GiftCardTypes } from '../../types/gift-card-types';
import type { TBaasError } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useAddGiftCard(
  options?: UseMutationOptions<
    GiftCardTypes.Operations.AddGiftCard.TResponse,
    TBaasError,
    GiftCardTypes.Operations.AddGiftCard.TRequest
  >
) {
  return useMutation({
    mutationFn: useAddGiftCardsRequest(),
    mutationKey: giftCardsQueryKeys.cardsGiftcardsAdd,
    onError: useAddGiftCardError(),
    onSuccess: useAddGiftCardSuccess(),
    ...options,
  });
}
