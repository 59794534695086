import { useQueryClient } from '@tanstack/react-query';

import { useBankCardsStore } from '@/store';

import { paymentMethodQueryKeys } from '../services';

import type { TBankCardsDialogSuccessState } from '@/store/bank-cards-store/bank-cards-store-types';

export function useBankCardShowSuccessDialog() {
  const queryClient = useQueryClient();

  const setBankCardsDialogSuccessState =
    useBankCardsStore().use.actions().setBankCardsDialogSuccessState;

  return function showSuccessDialog(
    dialogSuccessState: TBankCardsDialogSuccessState
  ) {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.cardsBankGet,
    });

    setBankCardsDialogSuccessState(dialogSuccessState);
  };
}
