import styled from 'styled-components';

const AddressListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);
`;

const Container = styled.div`
  align-items: flex-start;
  background-color: var(--colors-surface-lowest);
  border-radius: var(--radii-xl);
  flex-direction: column;
  gap: 1.5rem;
  max-width: 480px;
  padding: var(--spaces-xl) var(--spaces-l);
  width: 100%;
`;

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  max-width: 430px;
`;

const EmptyListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);

  h3 {
    font-size: var(--font-sizes-16);
    font-weight: var(--font-weights-bold);
  }

  p {
    color: var(--colors-on-surface-b);
    font-size: var(--font-sizes-14);
    font-weight: var(--font-weights-normal);
  }
`;

const Heading = styled.h2`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-20);
  font-weight: var(--font-weights-bold);
  line-height: 120%;
  margin: 0;
`;

const HeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spaces-l);
`;

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 480px;
  width: 100%;
`;

const AddressDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AddressDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AddressDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-l);
`;

const Actions = styled.div`
  border-top: 1px solid #807f80;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-m);
  padding-top: var(--spaces-l);
`;

const TextLabel = styled.p`
  color: var(--colors-on-surface-c);
  font-size: var(--font-sizes-14);
  font-style: normal;
  font-weight: var(--font-weights-normal);
  line-height: 1.2;
  margin: 0;
`;

const TextValue = styled.p<{ $color?: string }>`
  color: ${(props) => props.$color || 'var(--colors-on-surface-b)'};
  font-size: var(--font-sizes-16);
  font-style: normal;
  font-weight: var(--font-weights-normal);
  line-height: 1.2;
  margin: 0;
`;

const AddressDetailsColumn = styled.div<{ $width?: string }>`
  display: flex;
  flex-direction: column;
  gap: var(--spaces-2xs);
  width: ${(props) => props.$width || '50%'};
`;

const DefaultAddressPill = styled.div`
  align-items: center;
  background: var(--colors-brand1-lowest);
  border-radius: 120px;
  color: var(--colors-brand1-on-lowest);
  display: flex;
  font-size: var(--font-sizes-14);
  font-style: normal;
  font-weight: var(--font-weights-normal);
  gap: var(--spaces-2xs);
  justify-content: center;
  line-height: 1.2;
  padding: var(--spaces-xs) var(--spaces-s);
  width: 70px;
`;

const DefaultAddressColumn = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Grid = styled.div<{ $noJustify?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$noJustify ? 'unset' : 'space-between')};
`;

const FormRow = styled.div`
  display: flex;
  gap: 20px;
`;

const ErrorMessageWrapper = styled.div`
  color: var(--colors-error-on-lowest);
  margin: 10px 0;
`;

const DescriptionWrapper = styled.div`
  color: var(--colors-on-surface-b);
  margin-top: var(--spaces-2xs);

  a {
    color: var(--colors-on-surface-b);
    font-weight: var(--font-weights-normal);
  }
`;

export const Styled = {
  Actions,
  AddressDetails,
  AddressDetailsColumn,
  AddressDetailsContainer,
  AddressDetailsWrapper,
  AddressListWrapper,
  Container,
  DefaultAddressColumn,
  DefaultAddressPill,
  DescriptionWrapper,
  EmptyList,
  EmptyListTextWrapper,
  ErrorMessageWrapper,
  FormRow,
  Grid,
  Heading,
  HeadingWrapper,
  SpinnerWrapper,
  TextLabel,
  TextValue,
};
