import clsx from 'clsx';

import { Breakpoints } from '@/config/breakpoints';
import { useAnalytics, useWindowSize } from '@/hooks';
import { useGiftCardsStore } from '@/store';

import { GiftCardArt } from '../gift-card-art/gift-card-art';
import { GiftCardStatus } from '../gift-card-status/gift-card-status';

import styles from './gift-card-list-item.module.css';

import type { GiftCardTypes } from '../../types/gift-card-types';

type TGiftCardListItemProps = {
  giftCard: GiftCardTypes.Components.TGiftCard;
  onClick?: () => void;
  selected?: boolean;
};

export const GiftCardListItem = ({ giftCard }: TGiftCardListItemProps) => {
  const { track } = useAnalytics();

  const setSelectedGiftCard =
    useGiftCardsStore().use.actions().setSelectedGiftCard;

  const setIsViewGiftCardOpen =
    useGiftCardsStore().use.actions().setIsViewGiftCardOpen;

  const selectedGiftCard = useGiftCardsStore().use.selectedGiftCard();

  const windowSize = useWindowSize();

  const cardSize =
    !!windowSize?.width && windowSize.width > Breakpoints.xl ? 'md' : 'sm';

  const handleClick = () => {
    setSelectedGiftCard?.(giftCard);
    track('View Gift Card Detail');
    setIsViewGiftCardOpen(true);
  };

  const containerStyles = clsx(
    styles['container'],
    selectedGiftCard?.id === giftCard.id && styles['selected']
  );

  const showBalance = !giftCard.isUsed && !!giftCard.currentBalance;

  return (
    <button className={containerStyles} onClick={handleClick}>
      <GiftCardArt
        giftCardName={giftCard.program?.display_name}
        imageUrl={giftCard.program?.display?.card_art_url}
        size={cardSize}
      />
      <div className={styles['details']}>
        {showBalance && (
          <p className={styles['balance']}>
            {giftCard.currentBalance}{' '}
            <span className={styles['visually-hidden']}>remaining</span>
          </p>
        )}
        <GiftCardStatus giftCard={giftCard} />
      </div>
    </button>
  );
};
