import { PaymentMethodEnums } from '@/features/bank-cards/enums';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export const bankCardStatus = {
  addBankCardStatuses(card: BankCardsTypes.Components.TBankCard) {
    return {
      ...card,
      isExpired: bankCardStatus.isExpired(card),
      isInvalid: bankCardStatus.isInvalid(card),
      isUnavailable: bankCardStatus.isUnavailable(card),
      isValidated: bankCardStatus.isValidated(card),
      isVerified: bankCardStatus.isVerified(card),
    };
  },

  isExpired(card: BankCardsTypes.Components.TBankCard) {
    return card?.status === PaymentMethodEnums.BankCardStatus.EXPIRED;
  },

  isInvalid(card: BankCardsTypes.Components.TBankCard) {
    return card?.status === PaymentMethodEnums.BankCardStatus.INVALID;
  },

  isUnavailable(card: BankCardsTypes.Components.TBankCard) {
    return this.isInvalid(card) || this.isExpired(card);
  },

  isValidated(card: BankCardsTypes.Components.TBankCard) {
    return card?.status === PaymentMethodEnums.BankCardStatus.VALIDATED;
  },

  isVerified(card: BankCardsTypes.Components.TBankCard) {
    return card?.status === PaymentMethodEnums.BankCardStatus.VERIFIED;
  },
};
