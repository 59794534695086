import clsx from 'clsx';
import { isValid } from 'date-fns';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CalendarIcon } from '@/components/icons';
import { useActivityStore } from '@/store';

import { filterLabels } from '../../consts/filter-form';
import { FilterFields } from '../../enums/filter-fields';
import { formatDateValue, parseDate } from '../../utils';
import {
  validateDate,
  validateDateRange,
} from '../../utils/validate-date-range';
import { FilterInput } from '../filter-input/filter-input';

import { FilterFormBodyDatePicker } from './filter-form-body-date-picker';
import styles from './filter-form-body.module.css';

import type { TFilters } from '../filters/filters';

const validateDateFields = {
  validDate: validateDate,
  validRange: validateDateRange,
};

export function FilterFormBodyDate() {
  const [viewMonth, setViewMonth] = useState(new Date());

  const {
    clearErrors,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<TFilters>();

  const calendarRef = useRef<HTMLDivElement>(null);

  const isViewFilterCalendar = useActivityStore().use.isViewFilterCalendar();
  const setIsViewFilterCalendar =
    useActivityStore().use.actions().setIsViewFilterCalendar;

  const updateViewMonth = (value: string) => {
    const date = parseDate(value);

    if (isValid(date)) {
      !!date && setViewMonth(date);
    }
  };

  const handleIconClick = () => {
    setIsViewFilterCalendar(!isViewFilterCalendar);
  };

  const hasDateError = !!errors.dateFrom?.message || !!errors.dateTo?.message;

  const inputWrapperClasses = clsx(
    styles['input-wrapper'],
    hasDateError && styles['error']
  );

  const isCalendarExpanded = isViewFilterCalendar ? 'true' : 'false';

  const dateFieldLabel = (dateField: FilterFields) =>
    !!watch(dateField)
      ? `Change ${filterLabels[dateField]} date, ${watch(dateField)}, calendar`
      : 'Date from - calendar';

  const dateFromBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(e.target.name as FilterFields, formatDateValue(e.target.value));
    clearErrors(FilterFields.DATE_TO);
    updateViewMonth(e.target.value);
  };

  const dateToBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(e.target.name as FilterFields, formatDateValue(e.target.value));
  };

  return (
    <div
      aria-labelledby="date-group"
      className={styles['filter-group']}
      ref={calendarRef}
      role="group"
    >
      <p className={styles['group-label']} id="date-group">
        Date
      </p>
      <div
        className={clsx(styles['input-wrapper'], styles['input-label-wrapper'])}
      >
        <label
          className={styles['input-label']}
          htmlFor={FilterFields.DATE_FROM}
        >
          From (DD/MM/YYYY)
        </label>
        <label className={styles['input-label']} htmlFor={FilterFields.DATE_TO}>
          To (DD/MM/YYYY)
        </label>
      </div>
      <div className={inputWrapperClasses}>
        <FilterInput
          {...register(FilterFields.DATE_FROM, {
            onBlur: dateFromBlur,
            validate: validateDateFields,
          })}
          afterIcon={<CalendarIcon />}
          aria-expanded={isCalendarExpanded}
          error={errors.dateFrom?.message}
          iconLabel={dateFieldLabel(FilterFields.DATE_FROM)}
          id={FilterFields.DATE_FROM}
          onClickAfterIcon={handleIconClick}
        />
        <div className={styles['separator']} />
        <FilterInput
          {...register(FilterFields.DATE_TO, {
            onBlur: dateToBlur,
            validate: validateDateFields,
          })}
          afterIcon={<CalendarIcon />}
          aria-expanded={isCalendarExpanded}
          error={errors.dateTo?.message}
          iconLabel={dateFieldLabel(FilterFields.DATE_TO)}
          id={FilterFields.DATE_TO}
          onClickAfterIcon={handleIconClick}
        />
        <FilterFormBodyDatePicker
          calendarRef={calendarRef}
          setViewMonth={setViewMonth}
          viewMonth={viewMonth}
        />
      </div>
    </div>
  );
}
