import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { useHasUserConsentedTerms } from '@/features/profile';
import { LOYALTY_PROGRAM_ID_COLES_TEAM_MEMBERS } from '@/pages/wallet/loyalty-card/constants';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from './endpoints';
import {
  LoyaltyEndpointKeys,
  loyaltyQueryKeys,
  loyaltyTransactionKeys,
} from './loyalty-query-keys';

import type { PaymentMethodTypes } from '@/features/bank-cards';
import type { TBaasErrorResponse } from '@/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetMycolesCards(
  options?: UseQueryOptions<
    PaymentMethodTypes.TLoyaltyCardsByProgramIdResponse,
    TBaasErrorResponse
  >
) {
  const auth = useAuth();
  const consentedTerms = useHasUserConsentedTerms();

  const getMycolesCardsRequest = async () =>
    await request<PaymentMethodTypes.TLoyaltyCardsByProgramIdResponse>(
      `${endpoints.LOYALTY_CARDS}?program_id=${LOYALTY_PROGRAM_ID_COLES_TEAM_MEMBERS}`,
      auth,
      { method: RequestMethod.GET },
      {
        priority: EventPriority.P2,
        requestKey: LoyaltyEndpointKeys.MYCOLES_CARDS,
        transaction: loyaltyTransactionKeys.mycolesCards,
      }
    );

  return useQuery({
    enabled: auth.isAuthenticated && consentedTerms,
    queryFn: getMycolesCardsRequest,
    queryKey: loyaltyQueryKeys.mycolesCards,
    ...options,
  });
}
