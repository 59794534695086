import compact from 'lodash.compact';

import { Pill } from '@/components';
import { MoreIcon } from '@/components/icons';
import { PillVariants } from '@/components/pill/pill';
import { useHideInProduction } from '@/hooks';

import { Styled } from './styles';

import type { AddressesTypes } from '@/features/profile/';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  address: Pick<
    AddressesTypes.AddressResponse,
    | 'address_line_1'
    | 'address_line_2'
    | 'nickname'
    | 'postcode'
    | 'state'
    | 'suburb'
  >;
  isDefault?: boolean;
  onClick: () => void;
  selected?: boolean;
}

export const AddressCard = ({
  address,
  isDefault,
  onClick,
  selected,
}: IProps) => {
  const hideInProduction = useHideInProduction();
  const longAddress = compact([address?.address_line_1, address?.suburb])
    .join(', ')
    .trim();

  const shortAddress = compact([
    address?.address_line_1,
    address?.address_line_2,
    compact([address?.suburb, address?.state, address?.postcode])
      .join(' ')
      .trim(),
  ])
    .join(', ')
    .trim();

  return (
    <Styled.Container $selected={selected} onClick={onClick}>
      <Styled.AddressCardDetails>
        <Styled.AddressPreviewLong>
          {address.nickname || longAddress}
        </Styled.AddressPreviewLong>
        <Styled.AddressPreviewShort>{shortAddress}</Styled.AddressPreviewShort>
      </Styled.AddressCardDetails>
      <Styled.AddressPreviewAction>
        {isDefault && (
          <Pill
            rootCSS={{
              borderRadius: hideInProduction ? '120px' : 'var(--radii-md)',
            }}
            variant={PillVariants.BRAND1}
          >
            Default
          </Pill>
        )}
        <MoreIcon />
      </Styled.AddressPreviewAction>
    </Styled.Container>
  );
};
