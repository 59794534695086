import clsx from 'clsx';

import { formatCurrency } from '@/utils/format/currency';

import styles from './order-transaction.module.css';

import type { OrderTypes } from '../../types';

type TOrderTransaction = {
  hasRefund: boolean;
  transaction: OrderTypes.TTransactionInfo;
};

export function OrderTransaction({
  hasRefund,
  transaction,
}: TOrderTransaction) {
  const last4 = !!transaction.card.last4
    ? [...transaction.card.last4].join(' ')
    : '';

  const transactionCardLabel = !!last4
    ? `${transaction.card.type} ending with ${last4}`
    : transaction.card.type;

  return (
    <div className={styles['payment-group']}>
      <div className={clsx(styles['summary-row'])}>
        <p
          aria-label={transactionCardLabel}
          className={clsx(styles['text'], styles['color-c'])}
        >
          {transaction.card.type} {transaction.card.number}
        </p>
        <p className={styles['text']}>
          {formatCurrency(transaction?.amount?.value, hasRefund)}
        </p>
      </div>
    </div>
  );
}
