import { Form } from '@/components';

import { useAddGiftCard } from '../../services/use-add-gift-card/use-add-gift-card';

import { AddGiftCardFormBody } from './add-gift-card-form-body';
import { AddCardFormFooter } from './add-gift-card-form-footer';
import { useAddGiftCardFormInit } from './use-add-gift-card-form-init';
import { useAddGiftCardFormReset } from './use-add-gift-card-form-reset';
import { useAddGiftCardFormSubmit } from './use-add-gift-card-form-submit';

export function AddGiftCardForm() {
  const addGiftCard = useAddGiftCard();

  const form = useAddGiftCardFormInit();
  const handleSubmitAddGiftCardForm = useAddGiftCardFormSubmit(addGiftCard);

  useAddGiftCardFormReset(form);

  return (
    <Form config={form} onSubmitForm={handleSubmitAddGiftCardForm}>
      <AddGiftCardFormBody />
      <AddCardFormFooter />
    </Form>
  );
}
