import { BankCardsEnums } from '@/features/bank-cards';

import type { BankCardsTypes } from '@/features/bank-cards';

export function getDisplayScheme(
  schemes?: string[]
): BankCardsTypes.Components.TBankCardScheme {
  if (!Array.isArray(schemes) || !schemes?.length) {
    return BankCardsEnums.BankCardSchemes.DEFAULT;
  }

  if (schemes.includes('amex') || schemes.includes('american-express')) {
    return BankCardsEnums.BankCardSchemes.AMEX;
  }

  if (schemes.includes('mastercard')) {
    return BankCardsEnums.BankCardSchemes.MASTERCARD;
  }

  if (schemes.includes('visa')) {
    return BankCardsEnums.BankCardSchemes.VISA;
  }

  if (schemes.includes('eftpos')) {
    return BankCardsEnums.BankCardSchemes.EFTPOS;
  }

  return BankCardsEnums.BankCardSchemes.DEFAULT;
}
