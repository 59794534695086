import { useQuery } from '@tanstack/react-query';
import flow from 'lodash.flow';
import { useAuth } from 'react-oidc-context';

import { paymentMethodQueryKeys } from '@/features/bank-cards';
import { useHasUserConsentedTerms } from '@/features/profile';

import { useGetBankCardsRequest } from './use-get-bank-cards-request';
import { select } from './use-get-bank-cards-selector';

import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { TBaasErrorResponse } from '@/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetBankCards(
  options?: UseQueryOptions<
    BankCardsTypes.Operations.GetBankCards.TResponse,
    TBaasErrorResponse
  >
) {
  const auth = useAuth();
  const consentedTerms = useHasUserConsentedTerms();

  return useQuery({
    enabled: auth.isAuthenticated && consentedTerms,
    queryFn: useGetBankCardsRequest(),
    queryKey: paymentMethodQueryKeys.cardsBankGet,
    select: flow(select.sortedBankCards, select.removableCards),
    ...options,
  });
}
