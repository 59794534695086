import { BankCardsDialogSuccessStates } from '@/store';

export const bankCardsDialogSuccessCopy = {
  [BankCardsDialogSuccessStates.BANK_CARD_ADD]: {
    buttonText: 'Continue',
    description:
      'You have successfully added a new card to your flypay wallet.',
    title: 'New card added',
  },

  [BankCardsDialogSuccessStates.BANK_CARD_DELETE]: {
    buttonText: 'Continue',
    description:
      'You have successfully removed this card from your flypay wallet.',
    title: 'Card removed',
  },

  [BankCardsDialogSuccessStates.BANK_CARD_EDIT]: {
    buttonText: 'Continue',
    description: 'You have successfully updated your card details.',
    title: 'Card updated',
  },

  [BankCardsDialogSuccessStates.BANK_CARD_SET_DEFAULT]: {
    buttonText: 'Continue',
    description: 'This card will be automatically selected during checkout.',
    title: 'Default card set',
  },

  [BankCardsDialogSuccessStates.BANK_CARD_UPDATE_NICKNAME]: {
    buttonText: 'Continue',
    description: "You have successfully updated your card's nickname.",
    title: 'Card nickname updated',
  },

  [BankCardsDialogSuccessStates.NULL]: undefined,
};
