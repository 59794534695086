import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spaces-m);
`;

const Icon = styled.div`
  color: var(--colors-on-surface-b);
  flex-grow: 0;
  line-height: var(--line-heights-normal);
  padding-top: var(--spaces-2xs);
`;

const Paragraph = styled.p`
  color: var(--colors-brand1-highest);
  cursor: pointer;
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-half);
  margin: 0;
  text-align: left;
  text-decoration: underline;
`;

const ActionDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: left;
`;
const ActionDialogTitle = styled.h2`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-24);
  font-weight: var(--font-weights-bold);
  margin: 0;
  padding: 0;
`;
const ActionDialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: 8px;
  line-height: 1.2;
`;

const ActionDialogDescription = styled.div`
  color: var(--colors-on-surface-b);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-normal);
  margin: 0;
  padding: 0;
`;

const TriggerButton = styled.button`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 0;
`;

export const Styled = {
  ActionDialogDescription,
  ActionDialogHeader,
  ActionDialogTitle,
  ActionDialogWrapper,
  Container,
  Icon,
  Paragraph,
  TriggerButton,
};
