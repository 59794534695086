import styled from 'styled-components';

const PanelWrapper = styled.div`
  background: var(--colors-surface-lowest);
  border-radius: var(--radii-xl);
  flex: 1;
  padding: var(--spaces-xl) var(--spaces-l);
  width: 100%;
`;

const PanelTitleWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`;

const PanelTitle = styled.h2`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-20);
  font-weight: var(--font-weights-bold);
  line-height: 1.4;
  margin: 0 0 var(--spaces-l);
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-normal);
  gap: 6px;
  line-height: 24px;

  @media (width >= 48rem) {
    font-size: var(--font-sizes-16);
  }
`;

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Styled = {
  ButtonWrapper,
  PanelTitle,
  PanelTitleWrapper,
  PanelWrapper,
  SpinnerWrapper,
};
