/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { hasAuthParams, useAuth } from 'react-oidc-context';

import { LoadingScreen } from '@/components';
import { idleConfig } from '@/config';
import { useAnalytics } from '@/hooks/use-analytics';

import { Routes } from './constants';

declare global {
  interface Window {
    ReactNativeWebView: any;
    appInterface: any;
    webkit: any;
  }
}

interface IProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: IProps) => {
  const auth = useAuth();
  const { track } = useAnalytics();

  const handleIdle = () => {
    if (auth.isAuthenticated) {
      auth.removeUser().then(() => {
        window.location.replace(Routes.TIMED_OUT);
      });
    }
  };

  const { isIdle } = useIdleTimer({
    ...idleConfig,
    onIdle: handleIdle,
  });

  const hasNoAuth =
    !hasAuthParams() &&
    !auth.isAuthenticated &&
    !auth.activeNavigator &&
    !auth.isLoading &&
    !isIdle();

  const hasInvalidAuth = !!auth.error && !auth.activeNavigator;

  const redirectToPing = useCallback(async (): Promise<void> => {
    await new Promise<void>((resolve) => {
      track('Initialise Wallet');
      resolve();
    });
    auth.signinRedirect();
  }, [auth, track]);

  useEffect(() => {
    if (hasNoAuth || hasInvalidAuth) {
      redirectToPing();
    }
  }, [hasInvalidAuth, hasNoAuth, redirectToPing]);

  if (
    auth.isLoading ||
    auth.activeNavigator ||
    !!auth.error ||
    !auth.isAuthenticated
  ) {
    return <LoadingScreen />;
  }

  return children;
};
