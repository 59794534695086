import { AnalyticsBrowser } from '@segment/analytics-next';
import { useCallback, useState } from 'react';

import { type ProfileTypes, useGetProfile } from '@/features/profile';
import { authClientId } from '@/services/auth';

const writeKey = import.meta.env.VITE_REACT_APP_SEGMENT_WRITE_KEY;

export const analytics = writeKey && AnalyticsBrowser.load({ writeKey });

export const useAnalytics = () => {
  const { data: user } = useGetProfile();

  const [hasIdentified, setHasIdentified] = useState(false);

  const identify = useCallback(
    (userProfile: ProfileTypes.TUser) => {
      if (userProfile && !hasIdentified) {
        analytics.identify(userProfile?.id, {
          email: userProfile.email,
          name: `${userProfile?.first_name} ${userProfile?.last_name}`.trim(),
        });
        setHasIdentified(true);
      }
    },
    [hasIdentified]
  );

  const track = useCallback(
    (event: string, properties?: Record<string, string>) => {
      analytics.track(event, {
        channel: 'web_wallet',
        client_id: authClientId,
        wallet_id: user?.current_wallet_id,
        ...properties,
      });
    },
    [user?.current_wallet_id]
  );

  if (!writeKey) {
    return {
      identify: () => null,
      track: () => null,
    };
  }

  return {
    identify,
    track,
  };
};
