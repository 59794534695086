import { Root } from '@radix-ui/react-dialog';

import { DialogContent } from './dialog-content';
import { DialogTrigger } from './dialog-trigger';

type TDialogProps = {
  ariaLabel?: string;
  body?: React.ReactNode;
  contentCss?: string;
  defaultOpen?: boolean;
  description?: string;
  footer?: React.ReactNode;
  hasCloseIcon?: boolean;
  hideOverflow?: boolean;
  intro?: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  title?: string;
  trigger?: React.ReactNode;
  triggerIsButton?: boolean;
};

export function DialogBase({
  ariaLabel,
  body,
  contentCss,
  defaultOpen,
  description,
  footer,
  hasCloseIcon,
  hideOverflow,
  intro,
  onOpenChange,
  open,
  title,
  trigger,
  triggerIsButton,
}: TDialogProps) {
  return (
    <Root defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={open}>
      {!!trigger && (
        <DialogTrigger asChild={!!triggerIsButton}>{trigger}</DialogTrigger>
      )}
      <DialogContent
        ariaLabel={ariaLabel}
        body={body}
        contentCss={contentCss}
        description={description}
        footer={footer}
        hasCloseIcon={hasCloseIcon}
        hideOverflow={hideOverflow}
        intro={intro}
        title={title}
      />
    </Root>
  );
}
