import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { ButtonSpinner } from '@/components';
import { DownArrowIcon } from '@/components/icons';
import { useActivityStore } from '@/store';

import { useGetOrders } from '../../services';
import { formatActiveFilters } from '../../utils/format-filters';
import { getOrderById } from '../../utils/get-order-by-id';
import { getOrderQuery } from '../../utils/get-order-query';

import { LoadMoreMessage } from './load-more-message';
import { OrdersTableData } from './orders-table-data';
import styles from './orders-table.module.css';

TimeAgo.addDefaultLocale(en);

export function OrdersTable() {
  const activeFilters = useActivityStore().use.activeFilters();
  const searchQuery = useActivityStore().use.searchQuery();
  const setSelectedOrder = useActivityStore().use.actions().setSelectedOrder;
  const setIsViewOrderDetailsOpen =
    useActivityStore().use.actions().setIsViewOrderDetailsOpen;

  const {
    data: orders,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading,
  } = useGetOrders({
    params: {
      ...formatActiveFilters(activeFilters),
      ...getOrderQuery(searchQuery),
      status: ['pending', 'finalized', 'aborted'],
    },
  });

  const handleViewOrder = (id: string) => {
    const order = getOrderById(id, orders?.orders);
    if (!!order) {
      setSelectedOrder(order);
      setIsViewOrderDetailsOpen(true);
    }
  };

  return (
    <div className={styles['table-wrapper']}>
      <div aria-live="polite" className={styles['container']}>
        <OrdersTableData
          isError={isError}
          isLoading={isLoading}
          onClick={handleViewOrder}
          orders={orders?.groupedOrders}
        />
      </div>
      {hasNextPage && (
        <ButtonSpinner
          level="secondary"
          loading={isFetchingNextPage}
          onClick={fetchNextPage}
          size="small"
          style={{ margin: '1rem auto 0 auto' }}
          variant="neutral"
        >
          {!isFetchingNextPage && <DownArrowIcon />} Load More
        </ButtonSpinner>
      )}
      <LoadMoreMessage
        hasNextPage={hasNextPage}
        isError={isError}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        ordersListLength={orders?.groupedOrders?.length}
      />
    </div>
  );
}
