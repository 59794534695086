import { CardInput, FormHint } from '@/components';
import { useBankCardsStore } from '@/store';

import { ViewBankCardDisplay } from '../view-bank-card-display/view-bank-card-display';

import styles from './edit-card-form-body.module.css';

type TEditCardDetailsFormBodyProps = {
  isProcessing: boolean;
};

export function EditCardFormBody({
  isProcessing,
}: TEditCardDetailsFormBodyProps) {
  const selectedBankCard = useBankCardsStore().use.selectedBankCard();

  return (
    <div className={styles['edit-card-details-form-body']}>
      <ViewBankCardDisplay />

      <div className={styles['card-details']}>
        <FormHint />

        <CardInput
          isEditing
          isSubmitting={isProcessing}
          lastFour={selectedBankCard?.data?.visualization?.last_four_digits}
          schemes={selectedBankCard?.data?.schemes}
        />
      </div>
    </div>
  );
}
