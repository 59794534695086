import {
  Close as CloseButton,
  Description,
  Title,
} from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { CloseIcon } from '../icons';

import styles from './dialog-content-header.module.css';

export type TDialogContentHeaderProps = {
  describeId?: string;
  description?: string;
  hasCloseIcon?: boolean;
  intro?: React.ReactNode;
  title?: string;
  titleId?: string;
};

export function DialogContentHeader({
  describeId,
  description,
  hasCloseIcon,
  intro,
  title,
  titleId,
}: TDialogContentHeaderProps) {
  return (
    <>
      <div className={styles['dialog-title']}>
        <Title className={styles['dialog-title-text']} id={titleId}>
          {title}
        </Title>

        {hasCloseIcon && (
          <CloseButton
            aria-label="Close dialog"
            className={styles['dialog-close-button']}
            name="close"
          >
            <CloseIcon />
          </CloseButton>
        )}
      </div>

      {!!description && (
        <VisuallyHidden asChild>
          <Description className={styles['dialog-description']} id={describeId}>
            {description}
          </Description>
        </VisuallyHidden>
      )}
      {!!intro && <div className={styles['dialog-intro']}>{intro}</div>}
    </>
  );
}
