import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from '../endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '../query-keys';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useUpdateCardRequest(cardId: string) {
  const auth = useAuth();

  return async function updateCardRequest(
    payload: BankCardsTypes.Operations.UpdateBankCard.TRequest
  ) {
    return await request<BankCardsTypes.Operations.UpdateBankCard.TResponse>(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        data: payload,
        method: RequestMethod.PUT,
      },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_UPDATE_3DS,
        transaction: paymentMethodTransactionKeys.bankCardUpdate3DS,
      }
    );
  };
}
