import { useQueryClient } from '@tanstack/react-query';

import { useAnalytics } from '@/hooks';
import { useBankCardsStore } from '@/store';

import { paymentMethodQueryKeys } from '../../services';

import type { BankCardsTypes } from '../../types/bank-cards-types';

export function useBankCardsEvents() {
  const { track } = useAnalytics();
  const queryClient = useQueryClient();

  const isViewBankCardDetailsOpen =
    useBankCardsStore().use.isViewBankCardDetailsOpen();

  const {
    setIs3dsIframeOpen,
    setIsAddBankCardOpen,
    setIsEditingCard,
    setIsEditingCardNickname,
    setIsViewBankCardDetailsOpen,
    setSelectedBankCard,
  } = useBankCardsStore().use.actions();

  const handleAddBankCardOpen = () => {
    track('Add Card Started');
    setIsAddBankCardOpen(true);
  };

  const handleAddBankCardClose = () => {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.cardsBankGet,
    });

    setIsAddBankCardOpen(false);
    setIs3dsIframeOpen(false);
  };

  const handleSetSelectedBankCard =
    (card: BankCardsTypes.Components.TBankCard) => () => {
      setSelectedBankCard(card);

      if (!isViewBankCardDetailsOpen) {
        track('View Card Detail');
        setIsViewBankCardDetailsOpen(true);
      }
    };

  const handleViewBankCardClose = () => {
    setIs3dsIframeOpen(false);
    setIsViewBankCardDetailsOpen(false);
    setIsEditingCardNickname(false);
    setIsEditingCard(false);
  };

  return {
    handleAddBankCardClose,
    handleAddBankCardOpen,
    handleSetSelectedBankCard,
    handleViewBankCardClose,
  };
}
