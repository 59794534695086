import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { GiftCardsEndpointKeys } from '../gift-cards-endpoint-keys';
import { giftCardsEndpoints } from '../gift-cards-endpoints';
import { giftCardsTransactionKeys } from '../gift-cards-transaction-keys';

import type { GiftCardTypes } from '../../types/gift-card-types';

export function useGetGiftCardsRequest() {
  const auth = useAuth();

  return async function getGiftCardsRequest() {
    return await request<GiftCardTypes.Operations.GetGiftCards.TResponse>(
      giftCardsEndpoints.GIFT_CARDS,
      auth,
      {
        method: RequestMethod.GET,
      },
      {
        priority: EventPriority.P2,
        requestKey: GiftCardsEndpointKeys.GIFT_CARDS,
        transaction: giftCardsTransactionKeys.giftCards,
      }
    );
  };
}
