export enum BankCardsDialogSuccessStates {
  BANK_CARD_ADD = 'BANK_CARD_ADD',
  BANK_CARD_DELETE = 'BANK_CARD_DELETE',
  BANK_CARD_EDIT = 'BANK_CARD_EDIT',
  BANK_CARD_SET_DEFAULT = 'BANK_CARD_SET_DEFAULT',
  BANK_CARD_UPDATE_NICKNAME = 'BANK_CARD_UPDATE_NICKNAME',
  NULL = 'NULL',
}

export enum BankCardsDialogErrorStates {
  BANK_CARD_ADD_ERROR = 'BANK_CARD_ADD_ERROR',
  BANK_CARD_COUNT_LIMIT_REACHED = 'BANK_CARD_COUNT_LIMIT_REACHED',
  BANK_CARD_DELETE_ERROR = 'BANK_CARD_DELETE_ERROR',
  BANK_CARD_EDIT_ERROR = 'BANK_CARD_EDIT_ERROR',
  BANK_CARD_EDIT_NICKNAME_ERROR = 'BANK_CARD_EDIT_NICKNAME_ERROR',
  BANK_CARD_SET_DEFAULT_ALREADY_DEFAULT_ERROR = 'BANK_CARD_SET_DEFAULT_ALREADY_DEFAULT_ERROR',
  BANK_CARD_SET_DEFAULT_ERROR = 'BANK_CARD_SET_DEFAULT_ERROR',
  NULL = 'NULL',
}
