import { GiftCardEnums } from '../../enums/gift-card-enums';

import { GiftCardExpiry } from './gift-card-expiry';
import { GiftCardStatusPill } from './gift-card-status-pill';

import type { GiftCardTypes } from '@/features/gift-cards';

export type TGiftCardStatusProps = {
  giftCard: GiftCardTypes.Components.TGiftCard;
};

export function GiftCardStatus({ giftCard }: TGiftCardStatusProps) {
  const { expires } = giftCard;

  if (!expires?.isoDate || giftCard.isInvalid) {
    return <GiftCardStatusPill status={GiftCardEnums.GiftCardStatus.INVALID} />;
  }

  if (giftCard.isUsed) {
    return <GiftCardStatusPill status={GiftCardEnums.GiftCardStatus.USED} />;
  }

  if (giftCard.isExpired) {
    return <GiftCardStatusPill status={GiftCardEnums.GiftCardStatus.EXPIRED} />;
  }

  return <GiftCardExpiry expiryDate={expires.isoDate} />;
}
