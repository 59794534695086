import clsx from 'clsx';
import { format, max } from 'date-fns';

import { DateFormats } from '@/config/language';
import { useActivityStore } from '@/store';
import { formatCurrency } from '@/utils/format/currency';

import { ViewOrderStatus } from '../../enums/order-status';

import styles from './order-payment-summary-total.module.css';

export function OrderPaymentSummaryTotal() {
  const selectedOrder = useActivityStore().use.selectedOrder();

  if (!selectedOrder) {
    return null;
  }

  const { amount, orderStatus, payments } = selectedOrder;

  const isOrderPending = orderStatus === ViewOrderStatus.PENDING;

  const transactionDates = payments.map((transaction) => transaction.date);

  const orderTotalText = isOrderPending ? 'Estimated Total*' : 'Total Paid';

  return (
    <div className={styles['total-paid-group']}>
      <div className={styles['summary-row']}>
        <p className={clsx(styles['text'], styles['text-l'])}>
          <strong>{orderTotalText}</strong>
        </p>
        <p className={clsx(styles['text'], styles['text-l'])}>
          <strong>{formatCurrency(amount)}</strong>
        </p>
      </div>
      <p className={clsx(styles['text'], styles['text-xs'])}>
        (inc. GST){' '}
        {!isOrderPending && (
          <span className={styles['total-paid-date']}>
            {format(max(transactionDates), DateFormats.DAY_MONTH)}
          </span>
        )}
      </p>
    </div>
  );
}
