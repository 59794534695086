import { GiftCardsDialogErrorStates, useGiftCardsStore } from '@/store';

import { giftCardsErrorMap } from '../gift-cards-error-map';

import type { TBaasError } from '@/services';

export function useAddGiftCardError() {
  const { setGiftCardsDialogErrorState, setIsAddGiftCardPending } =
    useGiftCardsStore().use.actions();

  return function useAddGiftCardErrorCallback(error: TBaasError) {
    const errorNumber = error?.response?.data.details.error_number;
    const errorState = !!errorNumber
      ? giftCardsErrorMap[errorNumber]?.errorState
      : GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR;

    setIsAddGiftCardPending(false);
    setGiftCardsDialogErrorState(
      errorState || GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR
    );
  };
}
