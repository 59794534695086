import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '@/components';

import { ContextProviders } from './contexts';
import { useDvc } from './features/monitoring';
import { AppRoutes } from './routes';
import './styles/normalize.css';

const themeDefault = import.meta.env.VITE_REACT_APP_DEFAULT_THEME;

if (themeDefault === 'colespay') {
  import('./theme/theme-tokens-colespay.css');
  import('./styles/fonts-colespay.css');
} else {
  import('./theme/theme-tokens.css');
  import('./styles/fonts.css');
}

export const App = () => {
  useDvc();

  // Prevents iframe injection
  useEffect(() => {
    if (window.top !== window.self && window.top) {
      // @ts-expect-error: Should expect a Location object
      window.top.location = window.self.location;
    }
  }, []);

  return (
    <ContextProviders>
      <ReactQueryDevtools initialIsOpen={false} />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppRoutes />
      </ErrorBoundary>
    </ContextProviders>
  );
};
