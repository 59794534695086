import isEmpty from 'lodash.isempty';
import { createContext, useState } from 'react';
import { createStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { bankCardsStoreActions } from './bank-cards-store-actions';
import { bankCardsStoreDefaultValues } from './bank-cards-store-defaults';

import type {
  IBankCardStoreStateValues,
  TBankCardsStoreProvider,
} from './bank-cards-store-types';
import type { IBankCardsStore } from './bank-cards-store-types';

export const BankCardsStoreContext = createContext<ReturnType<
  typeof createBankCardsStore
> | null>(null);

export function BankCardsStoreProvider({
  children,
  initialValues,
}: TBankCardsStoreProvider) {
  const initialStoreValue = {
    ...bankCardsStoreDefaultValues,
    ...(!isEmpty(initialValues) && initialValues),
  };

  const [store] = useState(() => createBankCardsStore(initialStoreValue));

  return (
    <BankCardsStoreContext.Provider value={store}>
      {children}
    </BankCardsStoreContext.Provider>
  );
}

function createBankCardsStore(initialStoreValue: IBankCardStoreStateValues) {
  return createStore<IBankCardsStore>()(
    persist(
      (set) => {
        return {
          ...initialStoreValue,
          actions: bankCardsStoreActions(set),
        };
      },
      {
        name: 'BankCardsStore',
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) => !!['is3dsIframeOpen'].includes(key)
            )
          ),
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  );
}
