import { baasServiceErrorsMappings } from '@/services/baas-service-errors/baas-service-error-mappings';
import { GiftCardsDialogErrorStates } from '@/store';

type TGiftCardsErrorMap = Record<
  number,
  { errorCode: string; errorState: keyof typeof GiftCardsDialogErrorStates }
>;

export const giftCardsErrorMap: TGiftCardsErrorMap = {
  400001158: {
    errorCode: baasServiceErrorsMappings[400001158].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_NOT_SUPPORTED,
  },
  400001161: {
    errorCode: baasServiceErrorsMappings[400001161].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_COUNT_LIMIT_REACHED,
  },
  400001163: {
    errorCode: baasServiceErrorsMappings[400001163].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_NOT_ACTIVATED_OR_ISSUED,
  },
  400001164: {
    errorCode: baasServiceErrorsMappings[400001164].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_NOT_SUPPORTED,
  },
  400001165: {
    errorCode: baasServiceErrorsMappings[400001165].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_NOT_SUPPORTED,
  },
  400001166: {
    errorCode: baasServiceErrorsMappings[400001166].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_NOT_SUPPORTED,
  },
  400001168: {
    errorCode: baasServiceErrorsMappings[400001168].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_NOT_SUPPORTED,
  },
  400001171: {
    errorCode: baasServiceErrorsMappings[400001171].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_INSUFFICIENT_BALANCE,
  },
  400001176: {
    errorCode: baasServiceErrorsMappings[400001176].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_EXPIRED,
  },
  400001177: {
    errorCode: baasServiceErrorsMappings[400001177].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_SUSPENDED,
  },
  400001178: {
    errorCode: baasServiceErrorsMappings[400001178].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_CANCELLED,
  },
  404001159: {
    errorCode: baasServiceErrorsMappings[404001159].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_NOT_SUPPORTED,
  },
  404001172: {
    errorCode: baasServiceErrorsMappings[404001172].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR,
  },
  409001179: {
    errorCode: baasServiceErrorsMappings[409001179].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_ALREADY_ADDED,
  },
  444001167: {
    errorCode: baasServiceErrorsMappings[444001167].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR,
  },
  444001170: {
    errorCode: baasServiceErrorsMappings[444001170].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR,
  },
  444001175: {
    errorCode: baasServiceErrorsMappings[444001175].error_code,
    errorState: GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR,
  },
};
