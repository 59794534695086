import { BaasErrors, type TBaasErrorResponse } from '@/services';
import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import type { TBaasAddPaymentMethodErrors } from '../../types';

export function useAddCardFinalizeError() {
  const { setBankCardsDialogErrorState } = useBankCardsStore().use.actions();
  const { setIsAddBankCardPending, setIsBankCardLimitReachedOpen } =
    useBankCardsStore().use.actions();

  return function addCardFinalizeError(
    error: TBaasErrorResponse<TBaasAddPaymentMethodErrors>
  ) {
    setIsAddBankCardPending(false);

    error?.details?.error_number?.toString() === BaasErrors.MAX_CARDS_LIMIT
      ? setIsBankCardLimitReachedOpen(true)
      : setBankCardsDialogErrorState(
          BankCardsDialogErrorStates.BANK_CARD_ADD_ERROR
        );
  };
}
