import { useEffect } from 'react';

import { authClientId } from '@/services/auth';

import type { WalletConfigOpenTypes } from '@/features/wallet-config';

export function useWalletConfiguration(
  config?: WalletConfigOpenTypes.Operations.GetWalletConfigOpen.TResponse
) {
  useEffect(() => {
    const colors = config?.appearance?.theme?.palette?.light;

    const isApplyConfigTheme =
      !import.meta.env.MODE.startsWith('sandbox') &&
      !import.meta.env.MODE.startsWith('prod');

    if (!!authClientId && !!colors && isApplyConfigTheme) {
      applyThemeColors(colors);
    }
  }, [config]);
}

function applyThemeColors(
  colors: WalletConfigOpenTypes.Components.TWalletOpenConfigPalette
) {
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.append('body {');
  for (const [key, value] of Object.entries(colors)) {
    style.append(`--colors-${snakeToKebab(key)}: ${value};`);
  }
  style.append('}');
  head.appendChild(style);
}

function snakeToKebab(str: string) {
  return str.replace(/_/g, '-');
}
