import { clsx } from 'clsx';

import { Breakpoints } from '@/config/breakpoints';
import { useWindowSize } from '@/hooks';

import { BankCardArt } from '../../components/bank-card-art/bank-card-art';
import { getBankCardLabel } from '../../utils/get-bank-card-label';
import { CardStatusPill } from '../card-status-pill/card-status-pill';

import styles from './payment-card-list-item.module.css';

import type { BankCardsTypes } from '@/features/bank-cards/types/bank-cards-types';

type TPaymentCardListItemProps = {
  card: BankCardsTypes.Components.TBankCard;
  isSelected?: boolean;
  onClick?: () => void;
};

export function PaymentCardListItem({
  card,
  isSelected,
  onClick,
}: TPaymentCardListItemProps) {
  const windowSize = useWindowSize();
  const paymentCardSize =
    !!windowSize?.width && windowSize.width > Breakpoints.xxl ? 'md' : 'sm';

  const isDefault = !!card.is_default_pay;
  const isExpired = !!card.isExpired;
  const isUnavailable = !!card?.isInvalid;
  const hasPill = isDefault || !card.isVerified;

  const cardName = card.alias || card.data.issuer_name || '';
  const maskedCardNumber = `••• ${card.data.visualization.last_four_digits}`;

  return (
    <button
      aria-label={getBankCardLabel(card, isDefault)}
      className={clsx(styles['payment-card-list-item'], {
        [styles['payment-card-list-item-is-unavailable']]: isUnavailable,
        [styles['payment-card-list-item-selected']]: isSelected,
      })}
      onClick={onClick}
    >
      <div
        aria-hidden={'true'}
        className={styles['payment-card-list-item-content-container']}
      >
        <BankCardArt
          cardType={card.data.type}
          schemes={card.data.schemes}
          size={paymentCardSize}
        />

        <div className={styles['payment-card-list-item-content']}>
          <div className={styles['payment-card-list-item-content-details']}>
            <p
              className={clsx(
                styles['payment-card-list-item-content-details-name'],
                {
                  [styles[
                    'payment-card-list-item-content-details-name-is-expired'
                  ]]: isExpired,
                }
              )}
              data-testid={'card-name'}
            >
              {cardName}
            </p>

            <p
              className={clsx(
                styles['payment-card-list-item-content-details-number'],
                {
                  [styles[
                    'payment-card-list-item-content-details-number-is-expired'
                  ]]: isExpired,
                }
              )}
              data-testid={'card-number'}
            >
              {maskedCardNumber}
            </p>
          </div>

          {hasPill && (
            <CardStatusPill
              isDefault={card.is_default_pay}
              status={card.status}
            />
          )}
        </div>
      </div>
    </button>
  );
}
