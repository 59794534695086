import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';

import { validate } from '@/utils';

import { FieldName } from './add-gift-card-form-enums';

import type { FieldValues } from 'react-hook-form';

export function useAddGiftCardFormInit() {
  const DEFAULT_FORM_VALUES = {
    [FieldName.CARD_NUMBER]: '',
    [FieldName.PIN]: '',
  } as FieldValues;

  const validationSchema = Joi.object({
    [FieldName.CARD_NUMBER]: validate.giftCardCardNumber(),
    [FieldName.PIN]: validate.giftCardPin(),
  });

  return useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });
}
