import * as Label from '@radix-ui/react-label';
import styled, { css } from 'styled-components';

import { visuallyHidden } from '@/styles/mixins';

import type { TComboBoxSize } from './typings';

const AutoComplete = styled.div`
  position: relative;
`;

const AutoCompleteOptionsList = styled.ul<{ $isOpen: boolean }>`
  background: var(--colors-surface-lowest);
  border: 1px solid var(--colors-on-surface-d);
  border-radius: var(--radii-md);
  box-shadow: 0 0 12px rgb(0 0 0 / 4%);
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  list-style: none;
  margin: 0;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: var(--spaces-m) 0;
  position: absolute;
  top: 54px;
  width: 100%;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const AutoCompleteOptionsListItem = styled.li<{ $isFocused: boolean }>`
  color: var(--colors-on-surface-a);
  cursor: pointer;
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-normal);
  padding: var(--spaces-s) var(--spaces-m);
  transition: background 300ms ease-out;

  &:hover {
    background: var(--colors-surface-low);
  }
  ${({ $isFocused }) =>
    $isFocused &&
    css`
      background: var(--colors-surface-low);
    `}
`;

const ComboBoxInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weights-normal);
`;

const ComboBoxTextInput = styled.input<{
  $hasError: boolean;
  $size?: TComboBoxSize;
}>`
  background-color: var(--colors-surface-lowest);
  border: 1px solid var(--colors-on-surface-c);
  border-radius: var(--radii-md);
  box-sizing: border-box;
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-normal);
  height: 2.5rem;
  line-height: 0;
  margin-bottom: var(--spaces-xs);
  padding: var(--spaces-s) var(--spaces-xl) var(--spaces-s) var(--spaces-m);
  text-overflow: ellipsis;
  transition: border 300ms ease-in-out;
  width: 100%;

  &::placeholder {
    color: var(--colors-on-surface-d);
  }

  &:focus {
    border-color: var(--colors-on-surface-c);
    outline: var(--colors-brand1-on-lowest);
  }

  &:hover {
    border-color: var(--colors-on-surface-b);
  }

  &:disabled,
  &:hover:disabled {
    background-color: var(--colors-surface-low);
    border-color: var(--colors-on-surface-d);
    color: var(--colors-on-surface-d);
    cursor: not-allowed;
  }

  ${({ $size }) =>
    $size === 'small' &&
    css`
      font-size: var(--font-sizes-14);
      padding: var(--spaces-s);
    `}

  ${({ $size }) =>
    $size === 'regular' &&
    css`
      font-size: var(--font-sizes-16);
      padding: var(--spaces-m);
    `}
  
  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: var(--colors-error-on-lowest);
    `}
`;

const ComboBoxToggle = styled.div<{
  $isOpen: boolean;
  disabled?: boolean;
  size?: TComboBoxSize;
}>`
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  position: absolute;
  right: var(--spaces-xs);
  top: var(--spaces-xs);

  svg {
    transform: ${({ $isOpen }) => ($isOpen ? 'none' : 'rotate(180deg)')};
    transition: transform 250ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  ${({ disabled }) => `
    ${disabled && `cursor: not-allowed;`}
  `}
  ${({ size }) =>
    size === 'small' &&
    css`
      top: var(--spaces-xs);
    `}
  
  ${({ size }) =>
    size === 'regular' &&
    css`
      top: var(--spaces-s);
    `}
`;

const ComboBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weights-normal);
  gap: var(--spaces-xs);
  width: 100%;
`;

const LabelRoot = styled(Label.Root)`
  color: var(--colors-on-surface-a);
  font-size: var(--font-sizes-16);
  font-weight: var(--font-weights-bold);
  text-align: left;
`;

const VisuallyHiddenComboBoxResultsStatus = styled.div`
  ${visuallyHidden}
`;

export const Styled = {
  AutoComplete,
  AutoCompleteOptionsList,
  AutoCompleteOptionsListItem,
  ComboBoxInputWrapper,
  ComboBoxTextInput,
  ComboBoxToggle,
  ComboBoxWrapper,
  LabelRoot,
  VisuallyHiddenComboBoxResultsStatus,
};
