import isEmpty from 'lodash.isempty';
import { useEffect } from 'react';

import { Button, LoadingCard } from '@/components';
import { GiftCardsDialogErrorStates, useGiftCardsStore } from '@/store';

import { useGetGiftCardsPrograms } from '../../services/use-get-gift-cards-programs/use-get-gift-cards-programs';
import { EligibleGiftCardListItem } from '../eligible-gift-card-list-item/eligible-gift-card-list-item';

import styles from './eligible-gift-card-list.module.css';

export function EligibleGiftCardList() {
  const programs = useGetGiftCardsPrograms();

  const { setGiftCardsDialogErrorState, setIsViewEligibleGiftCardsOpen } =
    useGiftCardsStore().use.actions();

  const handleCloseDialog = () => {
    setIsViewEligibleGiftCardsOpen(false);
  };

  useEffect(() => {
    if (programs.isError) {
      setGiftCardsDialogErrorState(
        GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR
      );
      setIsViewEligibleGiftCardsOpen(false);
    }
  }, [programs, setGiftCardsDialogErrorState, setIsViewEligibleGiftCardsOpen]);

  useEffect(() => {
    if (
      programs.isSuccess &&
      !programs.isLoading &&
      isEmpty(programs?.data?.programs)
    ) {
      setGiftCardsDialogErrorState(
        GiftCardsDialogErrorStates.GIFT_CARD_EMPTY_ELIGIBLE_LIST
      );
      setIsViewEligibleGiftCardsOpen(false);
    }
  }, [programs, setGiftCardsDialogErrorState, setIsViewEligibleGiftCardsOpen]);

  if (!programs?.data?.programs || isEmpty(programs?.data?.programs)) {
    return <LoadingCard />;
  }

  if (isEmpty(programs.data.programs)) {
    setGiftCardsDialogErrorState(
      GiftCardsDialogErrorStates.GIFT_CARD_UNKNOWN_ERROR
    );
    setIsViewEligibleGiftCardsOpen(false);
    return null;
  }

  const giftCardPrograms = programs.data.programs;

  return (
    <>
      <p className={styles['paragraph']}>
        Add up to 5 gift cards from the list below.
      </p>
      <div className={styles['list-container']}>
        <ul
          className={styles['list']}
          tabIndex={giftCardPrograms.length > 4 ? 0 : undefined}
        >
          {giftCardPrograms.map((program, index) => (
            <EligibleGiftCardListItem
              currentIndex={index + 1}
              key={program.id}
              program={program}
              totalCount={giftCardPrograms.length}
            />
          ))}
        </ul>
      </div>
      <div>
        <Button onClick={handleCloseDialog} size="medium">
          Okay, got it
        </Button>
      </div>
    </>
  );
}
