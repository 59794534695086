export function GiftCardsDialogError() {}
import { Button } from '@/components';
import { ErrorIcon } from '@/components/icons';

import styles from './gift-cards-dialog-error-content.module.css';

import type { ReactNode } from 'react';

type TCardDialogErrorBodyProps = {
  buttonText: string;
  description?: ReactNode;
  handleOpenChange: () => void;
  icon?: ReactNode;
  title: string;
};

export function GiftCardsDialogErrorContent({
  buttonText,
  description,
  handleOpenChange,
  icon,
  title,
}: TCardDialogErrorBodyProps) {
  return (
    <div className={styles['card-error-dialog-body']}>
      {icon ?? <ErrorIcon />}

      <div className={styles['card-error-dialog-body-header']}>
        <h2 className={styles['card-error-dialog-body-header-title']}>
          {title}
        </h2>

        {!!description && (
          <p className={styles['card-error-dialog-body-header-description']}>
            {description}
          </p>
        )}
      </div>

      <Button
        className={styles['card-error-dialog-body-action-button']}
        onClick={handleOpenChange}
        size="medium"
      >
        {buttonText}
      </Button>
    </div>
  );
}
