import { useEffect } from 'react';

import { useGiftCardsStore } from '@/store';

import type { UseFormReturn } from 'react-hook-form';

let timeout: NodeJS.Timeout;

export function useAddGiftCardFormReset(form: UseFormReturn) {
  const isAddGiftCardOpen = useGiftCardsStore().use.isAddGiftCardOpen();

  useEffect(() => {
    timeout = setTimeout(() => {
      form.reset();
      // Delay to clear form after side sheet animation is closed
    }, 300);

    return () => clearTimeout(timeout);
  }, [form, isAddGiftCardOpen]);
}
