import { Dialog } from '@/components';
import { useBankCardsStore } from '@/store';

import { CardListDialogBody } from './card-list-dialog-body';

import type { BankCardsTypes } from '../../types/bank-cards-types';

type TCardListDialogProps = {
  cards?: BankCardsTypes.Components.TBankCards;
};

export function CardListDialog({ cards }: TCardListDialogProps) {
  const title = `Which card would you like to set as your new default card?`;
  const intro =
    'You need to set a new default card before removing your current default card.';

  const isOpenBankCardsList = useBankCardsStore().use.isOpenBankCardsList();
  const setIsOpenBankCardsList =
    useBankCardsStore().use.actions().setIsOpenBankCardsList;

  const handleCloseCardListDialog = () => setIsOpenBankCardsList(false);

  return (
    <Dialog
      body={<CardListDialogBody cards={cards} />}
      hasCloseIcon
      intro={intro}
      isOpen={isOpenBankCardsList}
      onOpenChange={handleCloseCardListDialog}
      title={title}
    />
  );
}
