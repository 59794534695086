import { CustomerService } from '@/components';

import { genericContent } from './generic';

const DONT_WORRY = "Don't worry, no changes have been made yet.";
const HMM = genericContent.ERROR_GENERIC_TITLE;

export const BUTTON_TEXT_TRY_AGAIN = 'Try again';

// card
export const ERROR_ADD_CARD_GENERIC =
  "We're sorry, but there was an issue adding your card.";
export const ERROR_SET_DEFAULT_CARD_FAILED =
  "We can't seem to set your default card just now.";
export const ERROR_TITLE_DELETE_CARD = HMM;
export const ERROR_TITLE_SAVE_CARD = HMM;
export const ERROR_DELETE_CARD_GENERIC =
  "We can't seem to remove your card just now.";
export const ERROR_SAVE_CARD_GENERIC =
  "We can't seem to save your new card just now.";
export const ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT = () => (
  <>
    This payment card is already set as default. If you need help,{' '}
    <CustomerService />.
  </>
);
export const TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT =
  'Payment card already default';

// flybuys
export const ERROR_TITLE_FLYBUYS_LINKING = HMM;
export const ERROR_TITLE_FLYBUYS_UNLINKING = HMM;
export const ERROR_FLYBUYS_LINKING =
  'We can’t seem to link your Flybuys account to your flypay wallet just now.';
export const ERROR_FLYBUYS_UNLINKING =
  'We can’t seem to unlink your Flybuys account from your flypay wallet just now.';
export const ERROR_TITLE_FLYBUYS_LINKING_ALREADY_LINKED =
  'Flybuys already linked';
export const ERROR_FLYBUYS_LINKING_ALREADY_LINKED = () => (
  <>
    Looks like your Flybuys account is already linked to a flypay wallet. If you
    want to switch it over, remove it from the existing wallet and link it to
    this one. If you need help, <CustomerService />.
  </>
);
export const ERROR_TITLE_FLYBUYS_LINKING_ACCOUNT_ERROR = HMM;
export const ERROR_FLYBUYS_LINKING_ACCOUNT_ERROR = () => (
  <>
    At this stage you’ll need to unlink your existing Flybuys account, or{' '}
    <CustomerService /> for more details.
  </>
);

export const ERROR_TITLE_FLYBUYS_TOKEN_EXPIRED =
  'Unable to load your Flybuys account';
export const ERROR_FLYBUYS_TOKEN_EXPIRED = () => (
  <>
    We can’t access the Flybuys account linked to your wallet just now. Try
    logging in again or remove it from the existing wallet and link it again. If
    you need help, <CustomerService />.
  </>
);

// mycoles
export const ERROR_TITLE_MYCOLES_LINKING = 'Link mycoles discount card error';
export const ERROR_TITLE_MYCOLES_LINKING_ALREADY_ADDED =
  'mycoles discount card already linked';
export const ERROR_TITLE_MYCOLES_LINKING_INVALID =
  'Invalid mycoles discount card';
export const ERROR_TITLE_MYCOLES_UNLINKING =
  'Unlink mycoles discount card error';
export const ERROR_MYCOLES_LINKING = 'We’re sorry something went wrong.';
export const ERROR_MYCOLES_LINKING_ALREADY_ADDED =
  'The barcode you have entered is already linked to the maximum allowed flypay accounts.';
export const ERROR_MYCOLES_LINKING_INVALID =
  'Looks like the card number you entered is invalid.';
export const ERROR_MYCOLES_UNLINKING = 'We’re sorry, something went wrong.';

// address
export const ERROR_ADD_ADDRESS_GENERIC = `We can’t seem to save your new address just now.`;
export const ERROR_DELETE_ADDRESS_GENERIC = `We can’t seem to remove your address just now.`;
export const ERROR_SAVE_ADDRESS_GENERIC = `We’re sorry, but there was an issue saving your address. ${DONT_WORRY}`;
export const ERROR_SET_DEFAULT_ADDRESS_ALREADY_DEFAULT =
  'To change your default address, please select a different address and choose “Set as default address“.';
export const ERROR_SET_DEFAULT_ADDRESS_FAILED =
  'We can’t seem to set your default address just now.';
export const ERROR_UPDATE_CARD_NICKNAME = `We can’t seem to update the nickname for your card just now.`;
export const ERROR_TITLE_DELETE_ADDRESS = HMM;
export const ERROR_TITLE_SET_DEFAULT_ADDRESS = HMM;
export const ERROR_TITLE_SET_DEFAULT_ADDRESS_ALREADY_DEFAULT =
  'Default address already set as default';

// profile
export const ERROR_VERIFY_EMAIL = `We can’t update your email address just now.`;
export const ERROR_UPDATE_PROFILE_GENERIC = `We couldn’t update your profile just now.`;
export const ERROR_VERIFY_MOBILE = `We couldn’t update your mobile number just now.`;
export const ERROR_TITLE_SUBSCRIBE = HMM;
export const ERROR_TITLE_UNSUBSCRIBE = HMM;
export const ERROR_MARKETING_SUBSCRIBE = `We couldn’t subscribe you to our marketing updates just now.`;
export const ERROR_MARKETING_UNSUBSCRIBE = `We couldn’t unsubscribe you from our marketing updates just now.`;
export const ERROR_DELETE_PROFILE = `We can't delete your account just now.`;
export const ERROR_TITLE_DELETE_PROFILE = HMM;
export const ERROR_INITIATE_MOBILE_CONFLICT =
  'This number already has an account associated with it. Please use a different number.';
