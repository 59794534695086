import { styled } from 'styled-components';

import type { Property } from 'csstype';

interface TErrorMessageContainer {
  $hasError: boolean;
  textAlign?: Property.TextAlign;
}

const ErrorMessageContainer = styled.div<TErrorMessageContainer>`
  align-items: center;
  display: ${({ $hasError }) => ($hasError ? 'grid' : 'none')};
  flex-direction: row;
  gap: var(--spaces-xs);
  grid-template-columns: 1.25rem 1fr;
  margin-bottom: var(--spaces-xs);
  opacity: ${({ $hasError }) => ($hasError ? 1 : 0)};
  text-align: ${({ textAlign }) => (!!textAlign ? textAlign : 'left')};
  transition: opacity 400ms ease-out;

  svg {
    margin-top: 1px;
  }
`;

const ErrorMessageText = styled.p`
  color: var(--colors-error-on-lowest);
  font-size: var(--font-sizes-14);
  font-weight: var(--font-weights-normal);
  line-height: var(--line-heights-half);
  margin: 0;
`;

const FormField = styled.div`
  position: relative;
`;

const Required = styled.p`
  color: var(--colors-on-surface-b);
  margin: 0 0 var(--spaces-m) 0;
`;

export const Styled = {
  ErrorMessageContainer,
  ErrorMessageText,
  FormField,
  Required,
};
