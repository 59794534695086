import styled from 'styled-components';

const LayoutWrapper = styled.div`
  align-items: center;
  background-color: var(--colors-surface-low);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  padding-top: 25dvh;
  text-align: center;
`;

const Title = styled.h1`
  font-size: var(--font-sizes-24);
  font-weight: var(--font-weights-bold);
  margin: var(--spaces-2xl) 0 var(--spaces-l);
`;

const Description = styled.p`
  font-size: var(--font-sizes-16);
  margin-bottom: var(--spaces-2xl);
`;

const Link = styled.a`
  background-color: var(--colors-brand1-highest);
  border-radius: var(--radii-md);
  color: var(--colors-brand1-on-high);
  display: inline-block;
  padding: var(--spaces-m);
  text-decoration: none;
`;

export const Styled = {
  ContentWrapper,
  Description,
  LayoutWrapper,
  Link,
  Title,
};
