import { useEffect } from 'react';

import { useBankCardsStore } from '@/store';

import type { UseFormReturn } from 'react-hook-form';

export function useAddCardFormReset(form: UseFormReturn) {
  const isAddBankCardOpen = useBankCardsStore().use.isAddBankCardOpen();
  const {
    setFinalizePayload,
    setIsAddBankCardPending,
    setIsDefaultPay,
    setSelectedBankCard,
  } = useBankCardsStore().use.actions();

  useEffect(() => {
    form.reset();

    if (!isAddBankCardOpen) {
      setFinalizePayload(undefined);
      setIsAddBankCardPending(false);
      setSelectedBankCard(undefined);
      setIsDefaultPay(false);
    }
  }, [
    form,
    isAddBankCardOpen,
    setFinalizePayload,
    setIsAddBankCardPending,
    setSelectedBankCard,
    setIsDefaultPay,
  ]);
}
