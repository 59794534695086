import { FilterFields } from '../enums/filter-fields';

import type { TFilters } from '../components/filters/filters';

export const defaultFilters: TFilters = {
  dateFrom: undefined,
  dateTo: undefined,
  maxAmount: undefined,
  minAmount: undefined,
  type: '',
};

export const filterLabels: Record<FilterFields, string> = {
  [FilterFields.DATE_FROM]: 'from',
  [FilterFields.DATE_TO]: 'to',
  [FilterFields.MAX_AMOUNT]: 'max',
  [FilterFields.MIN_AMOUNT]: 'min',
  [FilterFields.TYPE]: 'type',
};
