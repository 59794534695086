import { V2QrEnums } from '@/services';
import { capitalizeFirstChar, toTitleCase } from '@/utils';
import { formatFlybuysNumber } from '@/utils/format';

import type { OrderTypes } from '@/features/activity/types';
import type { V2QrTypes } from '@/services';

const getCardScheme = (
  scheme: `${V2QrEnums.CardScheme}`
): string | undefined => {
  if (scheme === V2QrEnums.CardScheme.EFTPOS) {
    return 'eftpos Card';
  }

  if (scheme === V2QrEnums.CardScheme.MASTERCARD) {
    return capitalizeFirstChar(scheme);
  }

  return `${capitalizeFirstChar(scheme)} Card`;
};

export const getCard = (
  transaction: V2QrTypes.TTransaction
): OrderTypes.TTransactionCardInfo => {
  if (transaction.card_type === V2QrEnums.SchemePaymentMethodType.CARD) {
    return {
      isSchemeCard: true,
      last4: `${transaction.card.last_4}`,
      number: `•• ${transaction.card.last_4}`,
      type: getCardScheme(transaction.card.scheme) ?? 'Payment Card',
    };
  }

  if (transaction.card_type === V2QrEnums.NonSchemePaymentMethodType.LOYALTY) {
    const maskedNumber =
      typeof transaction.card?.metadata?.masked_number === 'string'
        ? transaction.card.metadata.masked_number
        : '';
    const last4 = maskedNumber.slice(-4);
    return {
      isSchemeCard: false,
      last4,
      number: formatFlybuysNumber(maskedNumber),
      type: toTitleCase(transaction.card.provider),
    };
  }

  const last4 = !!transaction.card?.masked_number
    ? transaction.card.masked_number?.slice(-4)
    : '';

  return {
    isSchemeCard: false,
    last4,
    number: !!last4 ? `•• ${last4}` : '',
    type: 'Gift card',
  };
};
