export enum DateFormats {
  DAY_MONTH = 'd MMM',
  DAY_MONTH_TIME = 'd MMM h:mm a',
  DAY_MONTH_YEAR = 'dd/MM/yyyy',
  DAY_MONTH_YEAR_LONG = 'd MMMM yyyy',
  DAY_MONTH_YEAR_LONG_ALT = 'dd MMM yyyy',
  DAY_MONTH_YEAR_SHORT = 'dd/MM/yy',
  DAY_MONTH_YEAR_TIME = 'dd MMM yy h:mmaaa',
  DAY_MONTH_YEAR_TIME_FORMAT = 'yyyy-MM-dd-hh:mm:ss aa',
}
