import { Spinner } from '@/components';

import { Styled } from './styles';

export const LoadingScreen = () => {
  return (
    <Styled.Container>
      <Spinner
        $secondaryColor={'var(--colors-brand1-highest)'}
        color="transparent"
      />
    </Styled.Container>
  );
};
