import { Form } from '@/components';

import { EditCardFormBody } from './edit-card-form-body';
import { EditCardFormFooter } from './edit-card-form-footer';
import { useEditCardDFormInit } from './use-edit-card-form-init';
import { useEditCardFormSubmit } from './use-edit-card-form-submit';

import type { BankCardsTypes } from '../../types/bank-cards-types';

type TEditCardFormProps = {
  card: BankCardsTypes.Components.TBankCard;
  isProcessing: boolean;
  updateBankCard: BankCardsTypes.Operations.UpdateBankCard.TMutation;
};

export function EditCardForm({
  card,
  isProcessing,
  updateBankCard,
}: TEditCardFormProps) {
  const form = useEditCardDFormInit(card);
  const handleSubmitForm = useEditCardFormSubmit(updateBankCard);

  return (
    <Form config={form} onSubmitForm={handleSubmitForm}>
      <EditCardFormBody isProcessing={isProcessing} />
      <EditCardFormFooter isProcessing={isProcessing} />
    </Form>
  );
}
