import styles from './filter-input.module.css';

import type { PropsWithChildren } from 'react';

type TFilterInputIcon = PropsWithChildren & {
  ariaExpanded?: boolean | 'false' | 'true';
  hasOnclick?: boolean;
  label?: string;
  onClick?: (e: React.MouseEvent) => void;
};

export function FilterInputIcon({
  ariaExpanded = false,
  children,
  hasOnclick,
  label,
  onClick,
}: TFilterInputIcon) {
  if (hasOnclick) {
    return (
      <button
        aria-expanded={ariaExpanded}
        aria-label={label}
        className={styles['icon-wrapper-button']}
        onClick={onClick}
        type={hasOnclick ? 'button' : undefined}
      >
        {children}
      </button>
    );
  }
  return <div>{children}</div>;
}
