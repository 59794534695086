import { GiftCardEnums } from '../enums/gift-card-enums';

import type { GiftCardTypes } from '@/features/gift-cards';

const giftCardStatusText = {
  EXPIRED: 'Expired',
  UNAVAILABLE: 'Unavailable',
  USED: 'Used',
};

export function getGiftCardStatus(
  status: GiftCardTypes.Components.TGiftCardStatus
): string | undefined {
  switch (status) {
    case GiftCardEnums.GiftCardStatus.EXPIRED: {
      return giftCardStatusText.EXPIRED;
    }

    case GiftCardEnums.GiftCardStatus.INVALID: {
      return giftCardStatusText.UNAVAILABLE;
    }

    case GiftCardEnums.GiftCardStatus.USED: {
      return giftCardStatusText.USED;
    }

    default: {
      return undefined;
    }
  }
}
