/* eslint-disable unicorn/no-thenable */

import Joi from 'joi';

import { CardInputFieldNames } from '@/components/card-input';
import { PIN_LENGTH } from '@/features/gift-cards';
import {
  MAX_CHARS_ADDRESS_NICKNAME,
  MAX_CHARS_CARD_NICKNAME,
  MAX_CHARS_PROFILE_NAME,
  MIN_CHARS_DEFAULT,
} from '@/features/profile';

import {
  AddressValidationErrors,
  CardValidationErrors,
  GenericValidationErrors,
  OtherValidationErrors,
} from './errors';
import { regex } from './regex';
import {
  getCardSecurityCodeLength,
  getCardSecurityCodeLengthBySchemes,
  validateAddressNickname,
  validateCardAlias,
  validateCardExpiryDate,
  validateCardNumber,
} from './validation';

import type { BankCardsTypes } from '@/features/bank-cards';

const MAX_LENGTH_CHAR = 60;

export const validate = {
  addressCity(): Joi.StringSchema {
    const regex = /^(?!\\s*$)[A-Za-z]+/;
    return Joi.string()
      .required()
      .min(3)
      .max(MAX_LENGTH_CHAR)
      .regex(regex)
      .messages({
        'any.required': `City/Suburb ${GenericValidationErrors.REQUIRED}`,
        'string.base': `City/Suburb ${GenericValidationErrors.TEXT}`,
        'string.empty': `City/Suburb ${GenericValidationErrors.EMPTY}`,
        'string.max': AddressValidationErrors.SUBURB_MAX_MIN,
        'string.min': AddressValidationErrors.SUBURB_MAX_MIN,
        'string.pattern.base': `${AddressValidationErrors.SUBURB_VALID}`,
      });
  },

  addressLine1(): Joi.StringSchema {
    return Joi.string()
      .required()
      .min(5)
      .max(MAX_LENGTH_CHAR)
      .messages({
        'any.required': `Address line 1 ${GenericValidationErrors.REQUIRED}`,
        'string.base': `Address line 1 ${GenericValidationErrors.TEXT}`,
        'string.empty': `Address line 1 ${GenericValidationErrors.EMPTY}`,
        'string.max': AddressValidationErrors.ADDRESS_MAX_MIN_LINE_1,
        'string.min': AddressValidationErrors.ADDRESS_MAX_MIN_LINE_1,
      });
  },

  addressLine2(): Joi.StringSchema {
    return Joi.string()
      .max(MAX_LENGTH_CHAR)
      .allow('')
      .messages({
        'string.max': `Address line 2 ${AddressValidationErrors.ADDRESS_NAME_MAX_CHAR}`,
      });
  },

  addressNickname(): Joi.StringSchema {
    return Joi.string()
      .optional()
      .allow('')
      .max(MAX_CHARS_ADDRESS_NICKNAME)
      .custom((value, helpers) => {
        const isValidAlias = value === '' || validateAddressNickname(value);

        if (!isValidAlias) {
          return helpers.error('any.custom');
        }

        return value;
      })
      .messages({
        'any.custom': `${AddressValidationErrors.NICKNAME_LENGTH}`,
      });
  },

  addressPostcode(): Joi.StringSchema {
    return Joi.string()
      .required()
      .min(4)
      .regex(regex.AU_POSTCODE)
      .messages({
        'any.required': `Postcode ${GenericValidationErrors.REQUIRED}`,
        'string.empty': `Postcode ${GenericValidationErrors.EMPTY}`,
        'string.min': AddressValidationErrors.POSTCODE_MIN,
        'string.pattern.base': AddressValidationErrors.POSTCODE_VALID,
      });
  },

  addressState(): Joi.StringSchema {
    return Joi.string()
      .required()
      .messages({
        'any.required': `State ${GenericValidationErrors.REQUIRED}`,
        'string.base': `State ${GenericValidationErrors.TEXT}`,
        'string.empty': `State ${GenericValidationErrors.EMPTY}`,
      });
  },

  cardAlias(): Joi.StringSchema {
    return Joi.string()
      .optional()
      .allow('')
      .max(MAX_CHARS_CARD_NICKNAME)
      .custom((value, helpers) => {
        const isValidAlias = value === '' || validateCardAlias(value);

        if (!isValidAlias) {
          return helpers.error('any.custom');
        }

        return value;
      })
      .messages({
        'any.custom': `${CardValidationErrors.ALIAS_LENGTH}`,
      });
  },

  cardExpiryDate(): Joi.StringSchema {
    return Joi.string()
      .required()
      .custom((value, helpers) => {
        const isValidCardExpiry = validateCardExpiryDate(value);

        if (!isValidCardExpiry) {
          return helpers.error('any.custom');
        }

        return value;
      })
      .messages({
        'any.custom': `${CardValidationErrors.EXPIRY_INVALID}`,
        'any.required': `Card expiry ${GenericValidationErrors.REQUIRED}`,
        'string.base': `Card expiry date ${GenericValidationErrors.NUMBER}`,
        'string.empty': `Card expiry ${GenericValidationErrors.EMPTY}`,
      });
  },

  cardIsDefault(): Joi.BooleanSchema {
    return Joi.boolean().optional();
  },

  cardNumber(): Joi.StringSchema {
    return Joi.string()
      .required()
      .custom((value, helpers) => {
        const isValidCardNumber = validateCardNumber(value);

        if (!isValidCardNumber) {
          return helpers.error('any.custom');
        }

        return value;
      })
      .messages({
        'any.custom': CardValidationErrors.NUMBER_INVALID,
        'any.required': `${CardValidationErrors.NUMBER_GENERIC}`,
        'string.base': `${CardValidationErrors.NUMBER_GENERIC}`,
        'string.empty': `${CardValidationErrors.NUMBER_GENERIC}`,
      });
  },

  cardSecurityCode(
    card?: BankCardsTypes.Components.TBankCard
  ): Joi.StringSchema {
    return Joi.string()
      .required()
      .when(
        Joi.ref(CardInputFieldNames.CARD_NUMBER || card, {
          adjust: (value) => {
            return card
              ? getCardSecurityCodeLengthBySchemes(card?.data?.schemes) === 4
              : getCardSecurityCodeLength(value) === 4;
          },
        }),
        {
          is: Joi.boolean().valid(true),
          otherwise: Joi.custom((cardSecurityCode: string, helpers) => {
            const SECURITY_CODE_LENGTH_3_REGEX = /^\d{3}$/;
            const isValidSecurityCode =
              SECURITY_CODE_LENGTH_3_REGEX.test(cardSecurityCode);

            if (!isValidSecurityCode) {
              return helpers.error('string.pattern.base');
            }

            return cardSecurityCode;
          }),
          then: Joi.custom((cardSecurityCode: string, helpers) => {
            const SECURITY_CODE_LENGTH_4_REGEX = /^\d{4}$/;
            const isValidSecurityCode =
              SECURITY_CODE_LENGTH_4_REGEX.test(cardSecurityCode);

            if (!isValidSecurityCode) {
              return helpers.error('string.pattern.base');
            }

            return cardSecurityCode;
          }),
        }
      )
      .messages({
        'any.required': `Card security code ${GenericValidationErrors.REQUIRED}`,
        'string.base': `Card security code ${GenericValidationErrors.NUMBER}`,
        'string.empty': `Card security code ${GenericValidationErrors.EMPTY}`,
        'string.pattern.base': `${CardValidationErrors.CVV_INVALID}`,
      });
  },

  email(): Joi.StringSchema {
    const EMAIL_REGEX = /^[\w+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;
    return Joi.string()
      .trim()
      .email({ tlds: false })
      .required()
      .regex(EMAIL_REGEX)
      .messages({
        'any.required': `Email ${GenericValidationErrors.REQUIRED}`,
        'string.email': `${OtherValidationErrors.EMAIL_INVALID}`,
        'string.empty': `Email ${GenericValidationErrors.EMPTY}`,
        'string.pattern.base': `${OtherValidationErrors.EMAIL_INVALID}`,
      });
  },

  firstName(): Joi.StringSchema {
    return Joi.string()
      .required()
      .max(MAX_CHARS_PROFILE_NAME)
      .min(MIN_CHARS_DEFAULT)
      .regex(regex.ALPHABETS_ONLY)
      .messages({
        'any.required': `First name ${GenericValidationErrors.REQUIRED}`,
        'string.empty': `First name ${GenericValidationErrors.REQUIRED}`,
        'string.max': `First name must be less than ${MAX_CHARS_PROFILE_NAME} characters`,
        'string.min': `First name must be at least ${MIN_CHARS_DEFAULT} characters`,
        'string.pattern.base': `Enter a valid first name. ${GenericValidationErrors.NAME}`,
      });
  },

  giftCardCardNumber(): Joi.StringSchema {
    return Joi.string().required().messages({
      'string.empty': 'Enter a valid card number',
      'string.required': 'Enter a valid card number',
    });
  },

  giftCardPin(): Joi.StringSchema {
    return Joi.string().required().length(PIN_LENGTH).messages({
      'string.empty': 'Enter a valid PIN',
      'string.length': 'Enter a valid PIN',
      'string.required': 'Enter a valid PIN',
    });
  },

  lastName(): Joi.StringSchema {
    return Joi.string()
      .required()
      .max(MAX_CHARS_PROFILE_NAME)
      .min(MIN_CHARS_DEFAULT)
      .pattern(regex.ALPHABETS_ONLY)
      .messages({
        'any.required': `Last name ${GenericValidationErrors.REQUIRED}`,
        'string.empty': `Last name ${GenericValidationErrors.REQUIRED}`,
        'string.max': `Last name must be less than ${MAX_CHARS_PROFILE_NAME} characters`,
        'string.min': `Last name must be at least ${MIN_CHARS_DEFAULT} characters`,
        'string.pattern.base': `Enter a valid last name. ${GenericValidationErrors.NAME}`,
      });
  },

  marketingConsent(): Joi.BooleanSchema {
    return Joi.boolean().optional();
  },

  mycolesBarcode(): Joi.StringSchema {
    return Joi.string()
      .required()
      .max(15)
      .min(13)
      .pattern(regex.MYCOLES_BARCODE)
      .messages({
        'any.required': `Card barcode ${GenericValidationErrors.REQUIRED}`,
        'string.base': `Card barcode must be 13 digits`,
        'string.empty': `Card barcode ${GenericValidationErrors.EMPTY}`,
        'string.max': `Card barcode must be 13 digits`,
        'string.min': `Card barcode must be 13 digits`,
        'string.pattern.base': `Card barcode must be 13 digits`,
      });
  },

  name(): Joi.StringSchema {
    return Joi.string()
      .required()
      .max(MAX_LENGTH_CHAR)
      .messages({
        'any.required': `Name ${GenericValidationErrors.REQUIRED}`,
        'string.base': `Name ${GenericValidationErrors.TEXT}`,
        'string.empty': `Name ${GenericValidationErrors.EMPTY}`,
        'string.max': `Name ${AddressValidationErrors.ADDRESS_NAME_MAX_CHAR}`,
      });
  },

  optionalText(fieldName: string): Joi.StringSchema {
    return Joi.string().messages({
      'string.base': `${fieldName} ${GenericValidationErrors.TEXT}`,
    });
  },

  phoneNumber(): Joi.StringSchema {
    return Joi.string()
      .trim()
      .required()
      .custom((value, helpers) => {
        const cleanedPhoneNumber = value.replaceAll(regex.SPACES_AND_TABS, '');
        const isValidPhoneNumber =
          regex.AU_MOBILE_NUMBER.test(cleanedPhoneNumber);

        if (!isValidPhoneNumber) {
          return helpers.error('any.custom');
        }

        return cleanedPhoneNumber;
      })

      .messages({
        'any.custom': `Please enter a valid mobile number`,
        'any.required': `Phone number ${GenericValidationErrors.REQUIRED}`,
        'string.empty': `Phone number ${GenericValidationErrors.EMPTY}`,
      });
  },

  requiredReason(): Joi.StringSchema {
    return Joi.string().required().messages({
      'string.empty': `Please select a reason for deleting your account`,
    });
  },

  requiredText(fieldName: string): Joi.StringSchema {
    return Joi.string()
      .required()
      .messages({
        'any.empty': `${fieldName} ${GenericValidationErrors.EMPTY}`,
        'any.required': `${fieldName} ${GenericValidationErrors.REQUIRED}`,
        'string.base': `${fieldName} ${GenericValidationErrors.TEXT}`,
      });
  },

  terms(): Joi.BooleanSchema {
    return Joi.boolean().required().not(false).messages({
      'any.invalid': `Please accept the updated Terms & Conditions and Privacy Policy to continue.`,
      'any.required': `Please accept the updated Terms & Conditions and Privacy Policy to continue.`,
    });
  },

  verificationCodeEmail(): Joi.StringSchema {
    return Joi.string()
      .trim()
      .required()
      .length(8)
      .messages({
        'any.required': `Verification code ${GenericValidationErrors.REQUIRED}`,
        'string.empty': `Verification code ${GenericValidationErrors.EMPTY}`,
        'string.length': `Verification code must be exactly 8 characters`,
      });
  },

  verificationCodePhoneNumber(): Joi.StringSchema {
    return Joi.string()
      .trim()
      .required()
      .length(6)
      .messages({
        'any.required': `Verification code ${GenericValidationErrors.REQUIRED}`,
        'string.empty': `Verification code ${GenericValidationErrors.EMPTY}`,
        'string.length': `Verification code must be exactly 6 characters`,
      });
  },
};
