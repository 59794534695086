import { clsx } from 'clsx';

import styles from './pill.module.css';

export enum PillVariants {
  BRAND1 = 'brand1',
  ERROR = 'error',
  GENERIC = 'generic',
  SUCCESS = 'success',
  WARNING = 'warning',
}
export type TPillVariant = `${PillVariants}`;

export interface IPillProps {
  children?: React.ReactNode;
  contentCSS?: React.CSSProperties;
  rootCSS?: React.CSSProperties;
  size?: 'sm' | 'xs';
  variant?: TPillVariant;
}

export function Pill({
  children,
  contentCSS,
  rootCSS,
  size = 'sm',
  variant = 'generic',
}: IPillProps) {
  return (
    <div
      className={clsx(
        styles['pill-root'],
        styles[`pill-root-size-${size}`],
        styles[`pill-root-variant-${variant}`]
      )}
      style={rootCSS}
    >
      <span
        className={clsx(
          styles['pill-content'],
          styles[`pill-content-variant-${variant}`]
        )}
        style={contentCSS}
      >
        {children}
      </span>
    </div>
  );
}
