import styled from 'styled-components';

const Fieldset = styled.fieldset`
  border: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spaces-xs);
  margin: 0;
  padding: 0;
`;

const GridParentWrapper = styled.div`
  > div {
    place-items: baseline;
  }
`;
const GridParent = styled.div`
  display: grid;
  gap: var(--spaces-m);
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(--spaces-m);
  width: 100%;
`;

export const Styled = {
  Fieldset,
  GridParent,
  GridParentWrapper,
};
