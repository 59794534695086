import { Buffer } from 'buffer/';

import type { GiftCardTypes } from '../../types/gift-card-types';

export async function generateGiftCardCipherText({
  data,
  encryption,
}: GiftCardTypes.Operations.AddGiftCard.TRequest): Promise<string> {
  const cipherData = {
    number: data.cardNumber,
    pin: data.pin,
  };

  const importedKey = await window.crypto.subtle.importKey(
    'spki',
    Buffer.from(encryption.public_key, 'base64'),
    {
      hash: 'SHA-256',
      name: 'RSA-OAEP',
    },
    true,
    ['encrypt']
  );

  const cipher = await window.crypto.subtle.encrypt(
    { name: 'RSA-OAEP' },
    importedKey,
    Buffer.from(JSON.stringify(cipherData), 'utf8')
  );

  const cipherText = Buffer.from(cipher).toString('base64');
  return `${cipherText}.${encryption.key_id}`;
}
