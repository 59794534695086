import { CardsPanelEmpty } from '../cards-panel-empty/cards-panel-empty';
import { PanelError } from '../panel-error';

import { CardsPanelContentListItem } from './cards-panel-content-list-item';
import styles from './cards-panel-content.module.css';

import type { TCardsPanelContentProps } from './cards-panel-common-types';

export function CardsPanelContent({
  children,
  panelEmpty,
  panelError,
  query,
}: TCardsPanelContentProps) {
  return (
    <ul className={styles['cards-panel-content']}>
      {query.isError && (
        <CardsPanelContentListItem>
          <PanelError {...panelError} />
        </CardsPanelContentListItem>
      )}

      {query.isSuccess && !query?.data?.cards?.length && (
        <CardsPanelContentListItem>
          <CardsPanelEmpty {...panelEmpty} />
        </CardsPanelContentListItem>
      )}

      {children}
    </ul>
  );
}
